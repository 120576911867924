const setFavicon = () => {
  const companyData = JSON.parse(localStorage.getItem("company"));

  if (companyData.favicon) {
    const faviconLink = document.querySelector("link[rel='icon']");
    if (faviconLink) {
      faviconLink.href = companyData.favicon;
    } else {
      const newFaviconLink = document.createElement("link");
      newFaviconLink.rel = "icon";
      newFaviconLink.href = companyData.favicon;
      document.head.appendChild(newFaviconLink);
    }
  }
};

const setPageTitle = (title) => {
  const companyData = JSON.parse(localStorage.getItem("company"));
  if (!companyData) {
    document.title = `${title} - ${process.env.REACT_APP_COMPANY_SHORT_NAME}`;
    return;
  }
  document.title = `${title} - ${
    companyData && companyData.short_name ? companyData.short_name : ""
  }`;
};

export { setFavicon, setPageTitle };
