import React from "react";

const TransactionDetail = ({ label, value, onClick }) => {
  return (
    <div className="flex flex-col">
      <label className="text-sm font-normal text-paragraph-text">{label}</label>
      {onClick ? (
        <span
          className="text-sm font-medium text-primary-item cursor-pointer underline line-clamp-1"
          onClick={onClick}
        >
          {value}
        </span>
      ) : (
        <span className="text-sm font-medium text-body-text break-all max-md:max-w-[300px]">{value}</span>
      )}
    </div>
  );
};

export default TransactionDetail;
