import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const RecentTransactions = (data) => async (dispatch) => {
  try {
    const res = await commonService.post("/client/account/last-3-trns", data);
    const recentTransactions = res.data;
    
    return recentTransactions;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};
