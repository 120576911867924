import React from "react";

const Button = ({
  type,
  handleClick,
  className,
  title,
  isDisabled,
  id,
  icon,
}) => {
  return (
    <button
      type={type}
      onClick={handleClick}
      className={className}
      disabled={isDisabled ? true : false}
      id={id}
    >
      {icon && icon}
      {title}
    </button>
  );
};

export default Button;
