import React, { useEffect, useState } from "react";
import Modal from "../../../Common/Modal";
import { ActiveCard } from "../../../Card/PrimaryCardBG/partials/ActiveCard";
import AccountPopUp from "../../../Transactions/Transfer/AccountPopUp";
import { useDispatch, useSelector } from "react-redux";
import Label from "../../../Label";
import AccountDropdown from "../../../Common/AccountDropdown";
import FormInputField from "../../../FormInputField";
import { FormatTRDate, GetCurrenySymbol } from "../../../../helper";
import { useForm } from "../../../../hooks";
import CommonButton from "../../../Buttons/CommonButton";
import { TopUp, UpdateTopUp } from "../../../../redux/actions/Cards/TopUp";
import TransactionOTPModal from "../../TransactionsOTPModal";
// import HangTightLoading from "../../../Common/HangTightLoading";
import { VerifyTopUpOTP } from "../../../../redux/actions/Cards/VerifyTopUpOTP";
import { useNavigate } from "react-router-dom";
import { getTransactionDetails } from "../../../../redux/actions/Transaction/TransactionHistory";

const TopupCardModal = ({
  children,
  activeCard,
  transaction = null,
  refreshTable,
}) => {
  const [CardModalOpen, setCardModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const fiatAccounts = useSelector((state) =>
    state.client.clientInfo.accounts.filter(
      (account) => account.is_crypto === 0 || account.is_crypto === null
    )
  );
  const [accountOpen, setAccountOpen] = useState(false);
  const [account, setAccount] = useState(activeAccount);
  const amount = useForm("");
  const remarks = useForm("");
  const [trn_id, setTrnId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const transactionDate = FormatTRDate(new Date(), "DDMMYYYY");

  useEffect(() => {
    const fetchTopupDetails = async () => {
      const data = {
        trn_id: transaction.id,
        transaction_nature: "Top-up",
      };
      const res = await dispatch(getTransactionDetails(data));

      if (res.success) {
        amount.setValue(res.data.amount);
        remarks.setValue(res.data.remarks);

        const account = fiatAccounts.find(
          (account) => account.id === parseInt(res.data.account_id)
        );
        if (account) {
          setAccount(account);
        }
      }
    };

    if (transaction && CardModalOpen) {
      fetchTopupDetails();
    }
    // eslint-disable-next-line
  }, [transaction, CardModalOpen]);

  useEffect(() => {
    if (!isOtpModalOpen) {
      setCardModalOpen(false);
    }
  }, [isOtpModalOpen]);

  const handleChangeAccount = async (account) => {
    setAccountOpen(false);
    setAccount(account);
  };

  const handleSubmit = async () => {
    setisLoading(true);
    let data = {
      card_id: activeCard.id,
      from_account_id: account.id,
      amount: amount.value,
      transaction_date: transactionDate,
      remarks: remarks.value,
      from_web: true,
    };

    let res;
    if (transaction) {
      data.trn_id = transaction.id;
      res = await dispatch(UpdateTopUp(data));
    } else {
      res = await dispatch(TopUp(data));
    }

    if (res.success) {
      setTrnId(res.data.trn_id);
      setSuccess(true);
      // setCardModalOpen(false);
      setIsOtpModalOpen(true);
    } else {
      setError("Something went wrong, please try again later.");
    }
    setisLoading(false);
    setSuccess(false);
  };

  const isFormValid = () => {
    return amount.value && remarks.value;
  };

  useEffect(() => {
    if (!CardModalOpen) {
      amount.reset();
      remarks.reset();
      setError("");
    }
    // eslint-disable-next-line
  }, [CardModalOpen]);

  useEffect(() => {
    if (activeAccount.is_crypto && CardModalOpen) {
      setAccount(fiatAccounts[0]);
    }
    // eslint-disable-next-line
  }, [activeAccount, CardModalOpen]);

  const handleViewTransaction = () => {
    setSuccess(false);
    setIsOtpModalOpen(false);
    setCardModalOpen(false);
    setTimeout(() => {
      navigate("/card-topup");
    }, 300);
  };

  return (
    <>
      <div onClick={() => setCardModalOpen(true)}>{children}</div>
      <Modal
        isOpen={CardModalOpen}
        handleOnCloseModal={() => setCardModalOpen(false)}
        header={!isLoading && !success}
        // loading={isLoading}
        headerTitle="Top Up Card"
        className={`${isLoading ? "bg-transparent" : "bg-popup-bg"} bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl pt-4`}
      >
        {!isLoading && !success && (
          <div className="pb-6">
            <div className="h-[242px] bg-body-bg">
              <div className="pt-5 flex flex-col items-center">
                <ActiveCard activeCard={activeCard} />
              </div>
            </div>
            <div className="space-y-4 px-6 max-[360px]:px-4">
              <Label
                className="text-body-text text-sm font-medium tracking-tight"
                text={"From Account"}
              />
              <AccountPopUp
                clientAccounts={fiatAccounts}
                activeAccount={account}
                accountPopUpOpen={accountOpen}
                setAccountPopUpOpen={setAccountOpen}
                handleChangeAccount={handleChangeAccount}
                title="From account"
              >
                <AccountDropdown activeAccount={account} />
              </AccountPopUp>
              <div>
                <FormInputField
                  label="Amount"
                  name="amount"
                  id="amount"
                  type="number"
                  placeholder="Enter amount"
                  currency={GetCurrenySymbol(
                    account.currency_code,
                    "13",
                    "13"
                  )}
                  {...amount}
                />
              </div>
              <div>
                <FormInputField
                  label="Remarks"
                  name="remarks"
                  id="remarks"
                  type="text"
                  placeholder="Add note"
                  {...remarks}
                />
              </div>
              <div className="mt-3"></div>
              {error && (
                <div className="text-highlighter-red text-sm font-semibold">
                  {error}
                </div>
              )}
              <hr className="flex w-full border-divider-border" />
              <CommonButton onClick={handleSubmit} disabled={!isFormValid()}>
                Confirm
              </CommonButton>
            </div>
          </div>
        )}
        {/* {isLoading && !success && !isOtpModalOpen && (
          <HangTightLoading message="We are placing your request!" />
        )} */}
        <TransactionOTPModal
          TrnId={trn_id}
          setTrnId={setTrnId}
          isLoading={isLoading}
          setisLoading={setisLoading}
          isModalOpen={isOtpModalOpen}
          setIsModalOpen={setIsOtpModalOpen}
          handleTransaction={handleSubmit}
          reduxaction={VerifyTopUpOTP}
          handleViewTransaction={handleViewTransaction}
          refreshTable={refreshTable}
        />
      </Modal>
    </>
  );
};

export default TopupCardModal;
