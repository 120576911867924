import { OwnCompanyActions } from "../../action-types";

const company = JSON.parse(localStorage.getItem("company"));

const initialState = {
  company: company ? company : null
};

export default function ownCompanyReducer(state = initialState, action) {
  switch (action.type) {
    case OwnCompanyActions.GET_OWN_COMPANY_DETAILS:
      return {
        company: action.payload,
      };
    default:
      return state;
  }
}
