import { AuthActionTypes } from "../../redux/action-types";

const ResponseErrorHandling = (res,dispatch) => {
  // Todo: Refactor this switch case also the apis responses to retrun appropriate status codes.
  switch (res.status) {
    case 401:
      return dispatch({type:AuthActionTypes.LOGOUT});
    default:
      return true;
  }
};

export default ResponseErrorHandling;
