import React, { useState } from "react";
import { CardStatement } from "../../../../redux/actions/Cards/Statement";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../Common/Modal";
import HangTightLoading from "../../../Common/HangTightLoading";
import Alert from "../../../Alert";
import SelectCardModal from "../SelectCardModal";
import CardDropdown from "../../../Common/CardDropdown";
import { PrimaryButton } from "../../../Buttons";
import { DownloadIcon } from "../../../Icons";
import Dropdown from "../../../Dropdown";
import ErrorMessage from "../../../Common/ErrorMessage";
import Label from "../../../Label";

const CardStatmentModal = ({ children, activeCard }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(0);
  const [error, setError] = useState("");
  const [alert, setAlert] = useState(null);
  const dispatch = useDispatch();
  const { clientCards } = useSelector((state) => state.clientCards);

  const handledDownloadCardStatement = async () => {
    setIsLoading(true);
    setError("");
    const data = {
      card_id: activeCard.id,
      period: selectedPeriod,
    };
    const res = await dispatch(CardStatement(data));

    if (res.status === 200 && res.data) {
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const fileName = `${activeCard.card_number.slice(
        -4
      )}_${new Date().toLocaleDateString()}.pdf`;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setAlert({
        type: "success",
        message: "Statement downloaded successfully",
      });
      handleOnCloseModal();
    } else {
      // setAlert({ type: "error", message: "No statement found for this card" });
      setError("Statement download failed");
    }
    setIsLoading(false);
  };

  const handleCloseAlert = () => {
    setAlert(null);
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handleOnCloseModal = () => {
    setIsOpenModal(false);
    setSelectedPeriod(0);
    setError("");
  };

  return (
    <>
      <div onClick={() => setIsOpenModal(true)}>{children}</div>
      <Modal
        isOpen={isOpenModal}
        loading={isLoading}
        handleOnCloseModal={handleOnCloseModal}
        header={!isLoading}
        headerTitle="Card statement"
        className="bg-popup-bg w-full max-w-[432px] py-4 overflow-scroll hide-scrollbar shadow-box-shadow rounded-3xl mx-auto"
      >
        {!isLoading ? (
          <>
            {error && (
              <div className="px-6 max-[360px]:px-4">
                <ErrorMessage message={error} onClose={() => setError("")} />
              </div>
            )}
            <div className="flex flex-col px-6 max-[360px]:px-4 space-y-4">
              <div>
                <Label
                  className="block text-sm font-medium text-body-text mb-3"
                  htmlFor="card"
                  text="Select card"
                />
                <SelectCardModal
                  clientCards={clientCards}
                  activeCard={activeCard}
                >
                  <CardDropdown activeCard={activeCard} />
                </SelectCardModal>
              </div>
              <div>
                <Dropdown
                  id="period"
                  name="period"
                  placeholder="Select statement period"
                  label="Select Period"
                  options={[
                    { value: "1", label: "Last month" },
                    { value: "3", label: "3 months" },
                    { value: "6", label: "6 months" },
                  ]}
                  onChange={handlePeriodChange}
                  value={selectedPeriod}
                />
              </div>
            </div>

            <div className="flex items-center justify-center w-full mt-8 px-4 max-[360px]:px-3 py-3">
              <PrimaryButton
                text="Download statement"
                icon={<DownloadIcon />}
                disabled={!selectedPeriod}
                onClick={handledDownloadCardStatement}
              />
            </div>
          </>
        ) : (
          <HangTightLoading message="Downloading card statement..." />
        )}
      </Modal>
      {alert && (
        <div className="absolute hidden md:block">
          <Alert
            type={alert.type}
            message={alert.message}
            onClose={handleCloseAlert}
          />
        </div>
      )}
    </>
  );
};

export default CardStatmentModal;
