import React from "react";
import { DownArrowIcon } from "../../Icons";

const CardDropdown = ({ activeCard }) => {
  return (
    <>
      {activeCard && (
        <div className="relative flex w-full justify-start items-center border border-divider-border py-2 px-3 bg-input-bg rounded-xl cursor-pointer">
          <div className="flex">
            {activeCard.status === "Active" ? (
              <img
                src="assets/logos/otherCardBg.jpg"
                alt="Other Cards"
                className="rounded-md"
              />
            ) : (
              <img
                src="assets/logos/blockedOtherCardBg.jpg"
                alt="Other Cards"
                className="rounded-md"
              />
            )}
            <div className="px-3">
              <p className="text-primary-item text-sm font-medium leading-5 tracking-[0.07px] ">
                {activeCard.name}
              </p>
              <p className="text-primary-item font-normal text-sm leading-5 tracking-[0.07px] whitespace-nowrap">
              {activeCard.status} | · · {activeCard.card_number.slice(-4)}
              </p>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-[18px] hover:cursor-pointer">
            <DownArrowIcon className="h-5 w-5" />
          </div>
        </div>
      )}
    </>
  );
};

export default CardDropdown;
