const EditIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20.067 6.83441C20.0554 6.75309 20.0554 6.66016 20.0438 6.56723C19.9973 6.14904 19.8114 5.80055 19.5907 5.46367C19.3119 5.06871 18.975 4.7086 18.5917 4.41819C18.2897 4.18586 17.9296 4.0697 17.5578 4.02323C17.5346 4.02323 17.5114 4.01162 17.4881 4C17.3836 4 17.279 4 17.1861 4C17.1745 4 17.1513 4.01162 17.1397 4.01162C16.8492 4.04647 16.5821 4.15101 16.3614 4.33688C16.0129 4.6389 15.6644 4.96416 15.3159 5.27781C16.4543 6.41622 17.5927 7.55462 18.7311 8.69303C18.7311 8.69303 18.7427 8.68142 18.7543 8.6698C19.0564 8.36777 19.37 8.08898 19.672 7.77534C19.7882 7.65917 19.8811 7.50816 19.9508 7.35715C20.0205 7.20613 20.0321 7.03189 20.0786 6.86926C20.067 6.86926 20.067 6.84602 20.067 6.83441ZM13.8987 6.68339C13.8987 6.67178 13.8871 6.68339 13.8754 6.69501C11.1456 9.43648 8.41572 12.1663 5.68586 14.9078C5.31413 15.2795 5.04696 15.6977 4.89594 16.1856C4.62877 17.022 4.37321 17.8584 4.12926 18.6948C4.04795 18.9736 3.98986 19.2524 4.00148 19.5428C4.0131 19.8099 4.15249 19.9842 4.41967 19.9958C4.61715 20.0074 4.81463 19.9958 5.00049 19.9493C5.74394 19.7983 6.45254 19.566 7.17276 19.3104C7.46317 19.2059 7.75358 19.0897 8.04399 18.9852C8.57835 18.7877 9.00816 18.4508 9.3915 18.0559C12.0284 15.4189 14.6654 12.7704 17.3023 10.1335C17.3139 10.1219 17.3255 10.1102 17.3487 10.0754C16.1987 8.9486 15.0487 7.81018 13.8987 6.68339Z"
          className="fill-primary-item dark:fill-button-text"
        />
      </svg>
    </div>
  );
};

export default EditIcon;
