import React from "react";

const USDCSymbol = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width ? width : 16}
      height={height ? height : 23}
      viewBox="0 0 16 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2188 14.1406C15.0938 15.8281 14.5312 17.2448 13.5312 18.3906C12.5625 19.5052 11.151 20.1562 9.29688 20.3438V23H6.71875V20.3125C4.66667 20.0312 3.07292 19.1354 1.9375 17.625C0.8125 16.125 0.25 14.2031 0.25 11.8594C0.25 9.50521 0.8125 7.57812 1.9375 6.07812C3.05208 4.58854 4.64583 3.70312 6.71875 3.42188V0.59375H9.29688V3.39062C11.1198 3.60938 12.5312 4.29167 13.5312 5.4375C14.5417 6.59375 15.0938 7.98958 15.1875 9.625H11.5469C11.4219 8.13542 10.6719 7.17708 9.29688 6.75V17.0469C10.6615 16.6198 11.4219 15.651 11.5781 14.1406H15.2188ZM3.95312 11.8594C3.95312 14.7448 4.875 16.4635 6.71875 17.0156V6.75C4.875 7.30208 3.95312 9.00521 3.95312 11.8594Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};
export default USDCSymbol;
