import React from "react";

const USDSymbol = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width ? width : 18}
      height={height ? height : 29}
      viewBox="0 0 18 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.875 19.6562C17.875 21.5208 17.2448 23.0365 15.9844 24.2031C14.7031 25.3594 12.8594 26.0312 10.4531 26.2188V28.875H7.85938V26.25C5.44271 26.0312 3.58854 25.276 2.29688 23.9844C0.984375 22.6719 0.28125 20.9635 0.1875 18.8594H3.98438C4.07812 19.974 4.46354 20.8698 5.14062 21.5469C5.84896 22.2552 6.75521 22.6823 7.85938 22.8281V16.0469L7.28125 15.9219C5.23958 15.474 3.66146 14.75 2.54688 13.75C1.46354 12.7708 0.921875 11.3802 0.921875 9.57812C0.921875 7.83854 1.54167 6.41146 2.78125 5.29688C4.03125 4.17188 5.72396 3.53125 7.85938 3.375V0.71875H10.4531V3.375C12.5677 3.59375 14.2656 4.32812 15.5469 5.57812C16.8177 6.81771 17.4688 8.375 17.5 10.25H13.6875C13.5938 8.33333 12.5156 7.19271 10.4531 6.82812V12.875L11.0938 13.0156C15.6146 13.9427 17.875 16.1562 17.875 19.6562ZM4.79688 9.57812C4.79688 10.9427 5.81771 11.8646 7.85938 12.3438V6.76562C5.81771 7.01562 4.79688 7.95312 4.79688 9.57812ZM10.4531 22.8594C12.8281 22.5781 14.0156 21.5365 14.0156 19.7344C14.0156 18.8906 13.7344 18.2188 13.1719 17.7188C12.599 17.2396 11.724 16.875 10.5469 16.625L10.4531 16.5938V22.8594Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default USDSymbol;
