const ProfileIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M10.0999 10.6507C10.0416 10.6424 9.9666 10.6424 9.89993 10.6507C8.43327 10.6007 7.2666 9.40072 7.2666 7.92572C7.2666 6.41738 8.48327 5.19238 9.99993 5.19238C11.5083 5.19238 12.7333 6.41738 12.7333 7.92572C12.7249 9.40072 11.5666 10.6007 10.0999 10.6507Z"
          className="stroke-primary-item"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.6161 16.1498C14.1328 17.5081 12.1661 18.3331 9.99948 18.3331C7.83281 18.3331 5.86615 17.5081 4.38281 16.1498C4.46615 15.3665 4.96615 14.5998 5.85781 13.9998C8.14115 12.4831 11.8745 12.4831 14.1411 13.9998C15.0328 14.5998 15.5328 15.3665 15.6161 16.1498Z"
          className="stroke-primary-item"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0003 18.3337C14.6027 18.3337 18.3337 14.6027 18.3337 10.0003C18.3337 5.39795 14.6027 1.66699 10.0003 1.66699C5.39795 1.66699 1.66699 5.39795 1.66699 10.0003C1.66699 14.6027 5.39795 18.3337 10.0003 18.3337Z"
          className="stroke-primary-item"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ProfileIcon;