import React from "react";
import ActiveAccount from "./partials/ActiveAccount";
import SelectAccountModal from "../../Modals/SelectAccount/SelectAccountModal";
import SelectedAccountSidebar from "../../Modals/SelectAccount/SelectAccountSidebar";
import { FormatBalance, GetCurrenySymbol } from "../../../helper";
import ButtonCardType from "../partials/ButtonCardType";

const PrimaryCard = ({ activeAccount, clientAccounts, crypto, type }) => {
  return (
    <>
      <div
        className={`max-h-[322px] border border-dropdown-popup-bg bg-primarycard-gradient py-6 shadow-box-shadow flex flex-col items-center rounded-3xl`}
      >
        <div className="md:block hidden">
          <SelectAccountModal
            activeAccount={activeAccount}
            clientAccounts={clientAccounts}
          >
            <ActiveAccount activeAccount={activeAccount} />
          </SelectAccountModal>
        </div>

        <div className="max-md:block hidden max-[360px]:max-w-[310px]">
          <SelectedAccountSidebar
            activeAccount={activeAccount}
            clientAccounts={clientAccounts}
            crypto={crypto}
          >
            <ActiveAccount activeAccount={activeAccount} />
          </SelectedAccountSidebar>
        </div>

        <div className="flex-col justify-center items-center gap-1 inline-flex py-9">
          <div className="flex space-x-2 items-center justify-center text-body-text font-medium text-[32px] leading-[48px] tracking-[0.16px]">
            <span>
              {activeAccount && GetCurrenySymbol(activeAccount.currency_code)}
            </span>
            <span className="flex items-center gap-3 max-md:text-2xl">
              {activeAccount &&
                FormatBalance(activeAccount.balance, activeAccount.is_crypto)}
            </span>
          </div>
          <p className="text-center text-paragraph-text text-base font-normal leading-normal tracking-tight">
            Account balance
          </p>
        </div>
        <div className="flex md:flex-nowrap flex-wrap items-center justify-between md:space-x-8 space-x-4">
          {ButtonCardType(type)}
        </div>
      </div>
    </>
  );
};

export default PrimaryCard;
