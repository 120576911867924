import React from "react";
import Skeleton from "../../Common/Skeleton";

const LoadMoreTransactions = () => {
  return (
    <div>
      {[1, 2, 3].map((item) => (
        <Skeleton key={item} className="flex items-center justify-between px-5 py-4 rounded-3xl my-2">
          <Skeleton className="rounded-full h-5 w-20" />
          <span className="flex items-center justify-between space-x-2">
            <Skeleton className="rounded-full h-10 w-10" />
            <Skeleton className="rounded-full h-5 w-20" />
          </span>
          <Skeleton className="rounded-full h-5 w-20" />
          <Skeleton className="rounded-full h-5 w-20" />
        </Skeleton>
        // <Skeleton
        //   key={item}
        //   className="flex items-center justify-between px-5 py-4 my-2 rounded-3xl h-[70px]"
        // />
      ))}
    </div>
  );
};

export default LoadMoreTransactions;
