import React from "react";
import Modal from "../../../Common/Modal";
import Account from "../../../Modals/SelectAccount/partials/account";

const AccountPopUp = ({
  clientAccounts,
  activeAccount,
  handleChangeAccount,
  accountPopUpOpen,
  setAccountPopUpOpen,
  title,
  children,
}) => {
  const handleOnCloseModal = () => {
    setAccountPopUpOpen(false);
  };

  return (
    <>
      <div onClick={() => setAccountPopUpOpen(true)}>{children}</div>
      <Modal
        isOpen={accountPopUpOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={true}
        headerTitle={title}
        className="w-[432px] max-h-[578px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl"
      >
        <div className="flex flex-col md:px-6 px-3 pb-6 ">
          {clientAccounts.length ? (
            clientAccounts.map((account) => (
              <div
                key={account.id}
                onClick={() => handleChangeAccount(account)}
              >
                <Account {...account} activeAccount={activeAccount} />
              </div>
            ))
          ) : (
            <div className="text-body-text p-16 font-medium">
              <p>No Account Found</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AccountPopUp;
