import React from "react";

const StatementIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="24"
        viewBox="0 0 23 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.28529 3.67567C5.71776 3.2432 6.30432 3.00024 6.91592 3.00024H13.6099C13.7776 3.00024 13.9385 3.06689 14.0572 3.18552L19.0776 8.20598C19.1962 8.32461 19.2629 8.48551 19.2629 8.65328V18.6942C19.2629 19.3058 19.0199 19.8923 18.5875 20.3248C18.155 20.7573 17.5684 21.0002 16.9568 21.0002H6.91592C6.30432 21.0002 5.71776 20.7573 5.28529 20.3248C4.85282 19.8923 4.60986 19.3058 4.60986 18.6942V5.30631C4.60986 4.6947 4.85282 4.10814 5.28529 3.67567ZM6.91592 4.2654C6.63986 4.2654 6.3751 4.37507 6.17989 4.57027C5.98468 4.76548 5.87502 5.03024 5.87502 5.30631V18.6942C5.87502 18.9702 5.98468 19.235 6.17989 19.4302C6.3751 19.6254 6.63986 19.7351 6.91592 19.7351H16.9568C17.2329 19.7351 17.4977 19.6254 17.6929 19.4302C17.8881 19.235 17.9977 18.9702 17.9977 18.6942V8.9153L13.3478 4.2654H6.91592Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.95724 16.184C7.95724 15.8346 8.24046 15.5514 8.58982 15.5514H15.2838C15.6331 15.5514 15.9163 15.8346 15.9163 16.184C15.9163 16.5333 15.6331 16.8165 15.2838 16.8165H8.58982C8.24046 16.8165 7.95724 16.5333 7.95724 16.184Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.95724 12.8354C7.95724 12.486 8.24046 12.2028 8.58982 12.2028H15.2838C15.6331 12.2028 15.9163 12.486 15.9163 12.8354C15.9163 13.1847 15.6331 13.4679 15.2838 13.4679H8.58982C8.24046 13.4679 7.95724 13.1847 7.95724 12.8354Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.95724 9.4892C7.95724 9.13984 8.24046 8.85662 8.58982 8.85662H10.2633C10.6127 8.85662 10.8959 9.13984 10.8959 9.4892C10.8959 9.83856 10.6127 10.1218 10.2633 10.1218H8.58982C8.24046 10.1218 7.95724 9.83856 7.95724 9.4892Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6099 3.00024C13.9592 3.00024 14.2428 3.28346 14.2428 3.63282V8.0207H18.6307C18.9801 8.0207 19.2629 8.30391 19.2629 8.65328C19.2629 9.00264 18.9801 9.28585 18.6307 9.28585H13.6103C13.2609 9.28585 12.9777 9.00264 12.9777 8.65328V3.63282C12.9777 3.28346 13.2605 3.00024 13.6099 3.00024Z"
          className="fill-primary-item"
        />
        <path
          d="M13.6099 3.00024H6.91592C6.30432 3.00024 5.71776 3.2432 5.28529 3.67567C4.85282 4.10814 4.60986 4.6947 4.60986 5.30631V18.6942C4.60986 19.3058 4.85282 19.8923 5.28529 20.3248C5.71776 20.7573 6.30432 21.0002 6.91592 21.0002H16.9568C17.5684 21.0002 18.155 20.7573 18.5875 20.3248C19.0199 19.8923 19.2629 19.3058 19.2629 18.6942V8.65327M13.6099 3.00024C13.7776 3.00024 13.9385 3.06689 14.0572 3.18552L19.0776 8.20598C19.1962 8.32461 19.2629 8.48551 19.2629 8.65327M13.6099 3.00024C13.9592 3.00024 14.2428 3.28346 14.2428 3.63282V8.0207H18.6307C18.9801 8.0207 19.2629 8.30391 19.2629 8.65327M13.6099 3.00024C13.2605 3.00024 12.9777 3.28346 12.9777 3.63282V8.65327C12.9777 9.00264 13.2609 9.28585 13.6103 9.28585H18.6307C18.9801 9.28585 19.2629 9.00264 19.2629 8.65327M6.17989 4.57027C6.3751 4.37507 6.63986 4.2654 6.91592 4.2654H13.3478L17.9977 8.9153V18.6942C17.9977 18.9702 17.8881 19.235 17.6929 19.4302C17.4977 19.6254 17.2329 19.7351 16.9568 19.7351H6.91592C6.63986 19.7351 6.3751 19.6254 6.17989 19.4302C5.98468 19.235 5.87502 18.9702 5.87502 18.6942V5.30631C5.87502 5.03024 5.98468 4.76548 6.17989 4.57027ZM8.58982 15.5514C8.24046 15.5514 7.95724 15.8346 7.95724 16.184C7.95724 16.5333 8.24046 16.8165 8.58982 16.8165H15.2838C15.6331 16.8165 15.9163 16.5333 15.9163 16.184C15.9163 15.8346 15.6331 15.5514 15.2838 15.5514H8.58982ZM8.58982 12.2028C8.24046 12.2028 7.95724 12.486 7.95724 12.8354C7.95724 13.1847 8.24046 13.4679 8.58982 13.4679H15.2838C15.6331 13.4679 15.9163 13.1847 15.9163 12.8354C15.9163 12.486 15.6331 12.2028 15.2838 12.2028H8.58982ZM8.58982 8.85662C8.24046 8.85662 7.95724 9.13984 7.95724 9.4892C7.95724 9.83856 8.24046 10.1218 8.58982 10.1218H10.2633C10.6127 10.1218 10.8959 9.83856 10.8959 9.4892C10.8959 9.13984 10.6127 8.85662 10.2633 8.85662H8.58982Z"
          className="fill-primary-item"
          strokeWidth="0.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default StatementIcon;
