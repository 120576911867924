import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import FormDataService from "../../../../services/FormDataService";
import { CommonActionTypes } from "../../../action-types";

export const externalTR = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.append("from_web", 1);

    const res = await FormDataService.post("/client/transfer", data);
    const external = res.data;

    return external;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });

    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const updateExternalTR = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.append("from_web", 1);
    
    const res = await FormDataService.post("/client/update-transfer", data);
    const external = res.data;

    return external;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });

    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
