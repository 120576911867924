import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientEligibleCurrency } from "../../redux/actions/ServiceRequests/getClientEligibleCurrency";

const useEligibleCurrencies = () => {
  const [currencies, setCurrencies] = useState({});
  const dispatch = useDispatch();
  const { eligibleCurrencies } = useSelector((state) => state.eligibleCurrencies);
  const [LoadingCurrencies, setLoadingCurrencies] = useState(false);
  const { user } = useSelector((state) => state.user);
  
  const getClientsAccounts = async () => {
    try {
      setLoadingCurrencies(true);
      // Fetch data
      const res = await dispatch(getClientEligibleCurrency());
      setCurrencies(res.data);
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
    setLoadingCurrencies(false);
  };

  useEffect(() => {

    if ((!eligibleCurrencies || eligibleCurrencies.length === 0) && !user.is_basic_client) {
      getClientsAccounts();
    } else {
        setCurrencies(eligibleCurrencies);
    }
    // eslint-disable-next-line
  }, [eligibleCurrencies]);

  return {
    currencies,
    LoadingCurrencies,
    getClientsAccounts
  };
};

export default useEligibleCurrencies;
