import React from "react";

const ListItem = ({ title, value }) => {
  return (
    <div className="flex-col justify-start items-start gap-1 inline-flex pb-4">
      <div className="text-paragraph-text text-sm font-normal leading-tight tracking-tight">
        {title}
      </div>
      <div className="text-right text-body-text text-sm font-medium leading-tight tracking-tight">
        {value}
      </div>
    </div>
  );
};

export default ListItem;
