import { useEffect, useState } from "react";
import { getCardRecentTransaction } from "../../redux/actions/Cards/getCardRecentTransaction";
import { useSelector, useDispatch } from "react-redux";
import { RecentTransactions } from "../../redux/actions/home/RecentTransactions";

export const useRecentTransaction = (setRecentTransactionsLoading, type) => {
  const [recentCardTransactions, setRecentCardTransactions] = useState([]);
    const [recentAccountTransactions, setRecentAccountTransactions] = useState([]);
  const [lastThreeTransactions, setLastThreeTransactions] = useState([]);
  const dispatch = useDispatch();

  const { activeCard } = useSelector((state) => state.activeCard);
  const { activeAccount } = useSelector((state) => state.activeAccount);

  const fetchCardRecentTransactions = async () => {
    try {
      // Check if the transactions for this card are already in the local state
      const existingTransaction = recentCardTransactions.find(
        (trans) => trans.card_id === activeCard.id
      );

      if (existingTransaction) {
        // If transactions are already in the state, use them
        return setLastThreeTransactions(existingTransaction.transactions);
      }

      // Otherwise, fetch from API
      const data = {
        card_id: activeCard.id,
        period: "6",
      };
      setRecentTransactionsLoading(true);

      let trans = await dispatch(getCardRecentTransaction(data));

      // Get the first three transactions and map with card_id
      let firstThreeTransactions = trans.data.last_10
        .slice(0, 3)
        .map((transaction) => {
          return {
            card_id: data.card_id,
            ...transaction, // Spread the rest of the transaction data
          };
        });

      // Append new transactions to local state
      setRecentCardTransactions((prevState) => [
        ...prevState,
        { card_id: activeCard.id, transactions: firstThreeTransactions },
      ]);

      // Set last three transactions for display
      return setLastThreeTransactions(firstThreeTransactions);
    } catch (error) {
      console.error("Error in fetchRecentTransactions:", error);
    } finally {
      setRecentTransactionsLoading(false);
    }
  };

  const fetchAccountRecentTransactions = async () => {
    try {
      // Check if the transactions for this account are already in the local state
      const existingTransaction = recentAccountTransactions.find(
        (trans) => trans.account_id === activeAccount.id
      );

      if (existingTransaction) {
        // If transactions are already in the state, use them
        return setLastThreeTransactions(existingTransaction.transactions);
      }

      // Otherwise, fetch from API
      const data = {
        account_id: activeAccount.id,
      };
      setRecentTransactionsLoading(true);

      // Call the correct action to fetch account transactions
      let transactions = await dispatch(RecentTransactions(data));

      // Get the first three transactions and map with account_id
      let firstThreeTransactions = transactions.data.map((transaction) => {
          return {
            account_id: data.account_id,
            ...transaction, // Spread the rest of the transaction data
          };
        });

      // Append new transactions to local state
      setRecentAccountTransactions((prevState) => [
        ...prevState,
        {
          account_id: activeAccount.id,
          transactions: firstThreeTransactions,
        },
      ]);

      // Set last three transactions for display
      return setLastThreeTransactions(firstThreeTransactions);
    } catch (error) {
      console.error("Error in fetchRecentTransactions:", error);
    } finally {
      setRecentTransactionsLoading(false);
    }
  };

  useEffect(() => {
    if (type === "card" && activeCard && activeCard.id) {
      fetchCardRecentTransactions();
    } else if (type === "account" && activeAccount && activeAccount.id) {
      fetchAccountRecentTransactions();
    }
    // eslint-disable-next-line
  }, [activeCard, activeAccount, type]);

  return {
    lastThreeTransactions,
  };
};
