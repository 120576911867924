import React, { useEffect, useState } from "react";
import { NavigationButton, PrimaryButton } from "../../../Buttons";
import Label from "../../../Label";
import InputFile from "../../../InputFile";
import FormInputField from "../../../FormInputField";
import DateSelector from "../../../DateSelector";
import RadioButton from "../../../RadioButton";
import CountriesInput from "../../../Common/CountriesInput";
import { AddIcon, DropdownCrossIcon, TrashIcon } from "../../../Icons";
import useAllCountries from "../../../../hooks/useAllCountries";
import ConfirmModal from "../../../Modals/ConfirmModal";

const CompanyPersonalInfo = ({ handleNext, formData, onChange }) => {
  const allCountries = useAllCountries();
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedResidency, setSelectedResidency] = useState("");
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [selecteDirector, setSelectedDirector] = useState("");

  const preferenceOptions = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
  ];

  useEffect(() => {
    const updateNationalityAndResidency = async () => {
      try {
        const nationality_id = formData.nationality_id
          ? formData.nationality_id - 1
          : 223;

        const residence_country_id = formData.residence_country_id
          ? formData.residence_country_id - 1
          : 223;

        onChange({
          target: {
            name: "nationality_id",
            value: allCountries[nationality_id]?.id,
          },
        });
        onChange({
          target: {
            name: "residence_country_id",
            value: allCountries[residence_country_id]?.id,
          },
        });

        setSelectedNationality(allCountries[nationality_id]);
        setSelectedResidency(allCountries[residence_country_id]);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
    if (allCountries.length > 0) {
      updateNationalityAndResidency();
    }
  }, [allCountries, formData.nationality_id, formData.residence_country_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const isFormValid = () => {
    const isDirectorValid = formData.directors.every(
      (director) => director.name && director.email
    );

    const requiredFields = [
      "passport_copy",
      "passport_number",
      "nationality_id",
      "dob",
      "residence_country_id",
      "passport_expiry_date",
      "gender",
    ];

    const areFieldsValid = requiredFields.every((field) => formData[field]);

    return areFieldsValid && isDirectorValid;
  };

  const handleSelectNationality = (option) => {
    onChange({ target: { name: "nationality_id", value: option.id } });
    setSelectedNationality(option);
  };

  const handleSelectResidency = (option) => {
    onChange({ target: { name: "residence_country_id", value: option.id } });
    setSelectedResidency(option);
  };

  const handleOnChnageDirector = (data, id) => {
    let newData = formData.directors.map((director) => {
      if (director.id === id) {
        return { ...director, [data.target.name]: data.target.value };
      }
      return director;
    });
    onChange({
      target: {
        name: "directors",
        value: newData,
      },
    });
  };

  const addDirectors = () => {
    onChange({
      target: {
        name: "directors",
        value: [
          ...formData.directors,
          {
            id: formData.directors.length + 1,
            name: "",
            email: "",
          },
        ],
      },
    });
  };

  const deleteDirectors = () => {
    const newDirectors = formData.directors.filter(
      (director) => director.id !== selecteDirector
    );
    onChange({
      target: {
        name: "directors",
        value: newDirectors,
      },
    });
    setIsModalOpen(false);
  };

  const handleSelectDirector = (id) => {
    const director = formData.directors.find((director) => director.id === id);
    if (director.name && director.email) {
      setSelectedDirector(id);
      setIsModalOpen(true);
    } else {
      setSelectedDirector(id);
      deleteDirectors();
    }
  };

  return (
    <div className="space-y-4 md:w-[750px]">
      <Label
        className="block text-sm font-bold text-body-text mb-3"
        htmlFor="company-info"
        text="Your Personal Information"
      />
      <Label
        className="block text-xs font-bold text-paragraph-text mb-3"
        htmlFor="company-info"
        text="We are not sharing personal information with any 3rd party."
      />
      <div className="grid md:grid-cols-2 grid-cols-1 gap-4 w-full">
        <InputFile
          name="passport_copy"
          id="passport_copy"
          onClear={() =>
            onChange({ target: { name: "passport_copy", value: "" } })
          }
          onChange={onChange}
          text="Upload Passport"
          supportingDocument={formData.passport_copy}
          placeholder="Upload passport front page, which includes your photo."
        />

        <FormInputField
          label="Contact person name"
          name="contact_person_name"
          id="contact_person_name"
          type="text"
          placeholder="Enter your name"
          value={formData.contact_person_name}
          onChange={onChange}
        />

        <FormInputField
          label="Passport Number"
          name="passport_number"
          id="passport_number"
          type="text"
          placeholder="Enter passport number"
          value={formData.passport_number}
          onChange={onChange}
        />

        <DateSelector
          text="Passport Expiry Date"
          name="passport_expiry_date"
          id="passport_expiry_date"
          placeholder="Select your passport expiry date"
          onChange={onChange}
          selectedDate={formData.passport_expiry_date}
          disableFutureDates={false}
        />

        <CountriesInput
          name="nationality_id"
          label="Nationality"
          placeholder="Choose your nationality"
          countries={allCountries}
          SelectedCountry={selectedNationality}
          onChange={handleSelectNationality}
        >
          <div>
            <Label
              htmlFor={"nationality_id"}
              className="block text-xs font-medium text-body-text pb-1"
              text="Nationality"
            />
            <div className="flex items-center justify-between space-x-3">
              <img
                className="rounded-full w-8 h-8"
                src={selectedNationality?.flag}
                alt="flag"
              />
              <Label
                htmlFor={"nationality_id"}
                className="text-sm font-medium text-body-text"
                text={selectedNationality?.name}
              />
            </div>
          </div>
        </CountriesInput>

        <CountriesInput
          name="residence_country_id"
          label="Country of Residency"
          placeholder="Choose your Residency"
          countries={allCountries}
          SelectedCountry={selectedResidency}
          onChange={handleSelectResidency}
        >
          <div>
            <Label
              htmlFor={"residence_country_id"}
              className="block text-xs font-medium text-body-text pb-1"
              text="Country of Residence"
            />
            <div className="flex items-center space-x-3">
              <img
                className="rounded-full w-8 h-8"
                src={selectedResidency?.flag}
                alt="flag"
              />
              <Label
                htmlFor={"residency"}
                className="text-sm font-medium text-body-text"
                text={selectedResidency?.name}
              />
            </div>
          </div>
        </CountriesInput>

        <FormInputField
          label="Designation"
          name="contact_person_designation"
          id="contact_person_designation"
          type="text"
          placeholder="Enter your designation"
          value={formData.contact_person_designation}
          onChange={onChange}
        />

        <div>
          <DateSelector
            text="Date of Birth"
            name="dob"
            id="dob"
            placeholder="Select your date of birth as per passport"
            selectedDate={formData.dob}
            onChange={onChange}
          />
        </div>

        <div>
          <RadioButton
            name="gender"
            preferenceOptions={preferenceOptions}
            onChange={onChange}
            value={formData.gender}
          />
        </div>
      </div>
      <hr className="border border-divider-border" />

      <Label
        className="block text-sm font-bold text-body-text mb-3"
        htmlFor="client-directors"
        text="Client Directors / UBO's"
      />
      {formData.directors.length
        ? formData.directors.map((director) => (
            <div
              key={director.id}
              className="md:flex md:space-x-3 items-center md:border-none border border-divider-border rounded-3xl"
            >
              <span
                onClick={() => handleSelectDirector(director.id)}
                className="md:hidden block cursor-pointer hover:bg-hover-state-bg active:bg-active-state-bg p-2 rounded-full float-end m-2"
              >
                <DropdownCrossIcon />
              </span>
              <div className="flex w-full md:space-x-3 flex-col space-y-3 md:space-y-0 md:flex-row">
                <FormInputField
                  required={true}
                  label="Director Full Name"
                  name="name"
                  id={`name-${director.id}`} // Make ID unique
                  type="text"
                  placeholder="Enter your director full name"
                  value={director.name}
                  onChange={(e) => handleOnChnageDirector(e, director.id)}
                />

                <FormInputField
                  required={true}
                  label="Email"
                  name="email"
                  id={`email-${director.id}`} // Make ID unique
                  type="text"
                  placeholder="Enter your director email"
                  value={director.email}
                  onChange={(e) => handleOnChnageDirector(e, director.id)}
                />
              </div>
              <span
                onClick={() => handleSelectDirector(director.id)}
                className="md:block hidden cursor-pointer hover:bg-hover-state-bg active:bg-active-state-bg p-3 rounded-full float-end"
              >
                <TrashIcon />
              </span>
            </div>
          ))
        : ""}

      <ConfirmModal
        isModalOpen={ismodalOpen}
        setIsModalOpen={setIsModalOpen}
        handleAction={deleteDirectors}
        message="Are you sure you want to delete this director?"
        loading={false}
      />

      <div className="flex md:w-40 w-full">
        <NavigationButton
          onClick={addDirectors}
          text={"Add Director"}
          icon={<AddIcon />}
        />
      </div>

      <hr className="border border-divider-border" />

      <div className="flex md:w-96 w-full">
        <PrimaryButton
          onClick={handleNext}
          text="Continue"
          disabled={!isFormValid()}
        />
      </div>
    </div>
  );
};

export default CompanyPersonalInfo;
