import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const SubmitHelpReasons = (data) => async (dispatch) => {
  // dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/help/query", data);
    const resData = res.data;

    return resData;
  } catch (err) {
    const message = err.response.data.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
  // finally {
  //   dispatch({ type: CommonActionTypes.RESET, payload: true });
  // }
};
