import { CommonActionTypes, CardActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";

export const GetCards = (data) => async (dispatch) => {
  dispatch({
    type: CommonActionTypes.PENDING,
    payload: true,
  });

  try {
    const res = await commonService.get("/client/cards", data);
    const cards = res.data;
    if (cards.data.length > 0) {
      dispatch({
        type: CardActionTypes.SET_ACTIVE_CARD,
        payload: cards.data[0],
      });
    }
    dispatch({
      type: CardActionTypes.FETCH_CLIENT_CARDS,
      payload: cards.data,
    });
    localStorage.setItem("activeCard", JSON.stringify(cards.data[0]));
    localStorage.setItem("clientCards", JSON.stringify(cards.data));
    return cards;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
