import React from "react";
import { EmptyCurrencyListIcons } from "../../Icons";

const EmptyAccountList = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-3">
      <EmptyCurrencyListIcons className="w-40 h-40" />
      <span className="font-medium text-body-text text-xl text-center">
        There are no accounts available
      </span>
      <span className="font-medium text-paragraph-text text-sm text-center">
        Oops! It looks like you have not added any accounts yet. Click on the button below to add an account.
      </span>
    </div>
  );
};

export default EmptyAccountList;
