import React from "react";
import {
  DropdownCheckIcon,
  GreenEllipse,
  RedEllipse,
  // RightArrowIcon,
} from "../../../../../Icons";
// import { GetCurrenySymbol } from "../../../../../../helper";

const CardItem = ({ card, activeCardId }) => {
  return (
    <div
      key={card.id}
      className={`flex relative justify-between cursor-pointer border-2 shadow-sm rounded-3xl p-6 ${
        card.id === activeCardId
          ? "bg-option-list-selected border-icon-btn"
          : "bg-popup-bg border-divider-border hover:bg-option-list-hover"
      }`}
    >
      {card?.id === activeCardId && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 -top-1 bg-[#EFC660] rounded-full shadow border-2 border-white">
          <DropdownCheckIcon />
        </div>
      )}
      <div className="flex">
        {card.status === "Active" ? (
          <img
            src="assets/logos/otherCardBg.jpg"
            alt="Other Cards"
            className="rounded-md"
          />
        ) : (
          <img
            src="assets/logos/blockedOtherCardBg.jpg"
            alt="Other Cards"
            className="rounded-md"
          />
        )}
        <div className="ml-3">
          <p className="text-body-text text-sm font-medium">{card.name}</p>
          <span className="flex items-center justify-center space-x-2">
            {card.status === "Active" ? <GreenEllipse /> : <RedEllipse />}
            <p className="text-paragraph-text text-sm ">
              {card.status} | · · {card.card_number.slice(-4)}
            </p>
          </span>
        </div>
      </div>

      {/* <div className="flex items-center justify-center space-x-0.5 text-body-text text-sm font-medium leading-tight tracking-tight">
        <span>{GetCurrenySymbol("USD", "16", "16")}</span>
        <p className="text-body-text font-normal">{card.balance}</p>
      </div> */}
    </div>
  );
};

export default CardItem;
