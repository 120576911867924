const DarkModeIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Vector"
          d="M15.6857 8.78153C15.5703 8.68615 15.4305 8.62509 15.2821 8.6053C15.1337 8.58551 14.9827 8.6078 14.8464 8.66961C14.001 9.05644 13.082 9.25553 12.1523 9.25318C10.4327 9.25111 8.78359 8.56924 7.56462 7.35626C6.34565 6.14328 5.65567 4.49756 5.64512 2.77794C5.64876 2.239 5.71584 1.70237 5.84497 1.17912C5.87237 1.03965 5.86209 0.895399 5.8152 0.761226C5.7683 0.627053 5.68648 0.50781 5.57816 0.415782C5.46984 0.323753 5.33895 0.262268 5.19896 0.237659C5.05898 0.213051 4.91496 0.226209 4.78175 0.275779C3.52853 0.839292 2.43913 1.71213 1.61588 2.8123C0.792639 3.91247 0.262575 5.20386 0.0754883 6.56515C-0.111599 7.92643 0.050432 9.31294 0.546359 10.5944C1.04229 11.8759 1.85583 13.0102 2.91057 13.8909C3.9653 14.7717 5.22661 15.3698 6.57599 15.6291C7.92537 15.8885 9.31854 15.8006 10.6246 15.3737C11.9307 14.9467 13.1068 14.1948 14.0425 13.1885C14.9781 12.1822 15.6426 10.9546 15.9735 9.62091C16.0138 9.46822 16.008 9.30701 15.9568 9.15763C15.9056 9.00824 15.8113 8.87737 15.6857 8.78153ZM8.09132 14.1296C6.74971 14.1201 5.44383 13.6962 4.35246 12.9159C3.2611 12.1355 2.43761 11.0369 1.99475 9.7705C1.55188 8.50406 1.5113 7.13169 1.87856 5.84129C2.24581 4.5509 3.00294 3.40556 4.04629 2.5621V2.77794C4.04841 4.92715 4.90311 6.98772 6.42283 8.50744C7.94255 10.0272 10.0031 10.8819 12.1523 10.884C12.7166 10.8861 13.2795 10.8271 13.8311 10.7081C13.2794 11.7518 12.4533 12.625 11.4418 13.2336C10.4303 13.8422 9.2718 14.163 8.09132 14.1616V14.1296Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default DarkModeIcon;