import React, { useState } from "react";
import Sidebar from "../../../Common/SideBar";
import Card from "../SelectCardModal/partials/CardItem";
import { useDispatch } from "react-redux";
import { setActiveCard } from "../../../../redux/actions/Cards/setActiveCard";

const SelectCardSidebar = ({ activeCard, clientCards, children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleChangeCard = async (account) => {
    await dispatch(setActiveCard(account));
    setIsSidebarOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsSidebarOpen(true)}>{children}</div>
      <Sidebar
       open={isSidebarOpen}
       handleOnCloseSidebar={handleOnCloseSidebar}
       header={true}
       crossButton={true}
       crossButtonAction={handleOnCloseSidebar}
       title={"Select Card"}
       className="bg-popup-bg overflow-scroll hide-scrollbar"
      >
        <div className="flex flex-col pb-6 space-y-4 pt-3">
          {clientCards.length ? (
            clientCards.map((card) => (
              <div key={card.id} onClick={() => handleChangeCard(card)}>
                <Card card={card} activeCardId={activeCard.id} />
              </div>
            ))
          ) : (
            <div className="text-body-text p-16 font-medium">
              <p>No Card Found</p>
            </div>
          )}
        </div>
      </Sidebar>
    </>
  );
};

export default SelectCardSidebar;
