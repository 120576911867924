import { CardActionTypes } from "../../action-types";

const clientCards = JSON.parse(localStorage.getItem("clientCards"));

const initialState = {
  clientCards: clientCards ? clientCards : null,
};

export default function clientCardsReducer(state = initialState, action) {
  switch (action.type) {
    case CardActionTypes.FETCH_CLIENT_CARDS:
      return {
        clientCards: action.payload,
      };
    default:
      return state;
  }
}
