import React, { useEffect, useState } from "react";
import { RejectedSign } from "../../../components/Icons";
import { logout } from "../../../redux/actions/auth";
import { useDispatch } from "react-redux";
import LoadingModal from "../../../components/Modals/LoadingModal";
import { SmallButton } from "../../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { updateUserStatus } from "../../../redux/actions/register/updateUserStatus";
import { setPageTitle } from "../../../helper/metaDataHelper";

const RejectedClient = ({ rejectedReason }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isloadingModal = (status) => {
    setisLoading(status);
    setIsModalOpen(status);
  };

  useEffect(() => {
    setPageTitle("Rejected Account");
  }, []);

  const handleLogout = async () => {
    isloadingModal(true);
    await dispatch(logout());
    isloadingModal(false);
    navigate("/signin");
  };

  const handleUpdate = async () => {
    await dispatch(updateUserStatus("Update"));
    navigate("/");
  };

  return (
    <main>
      <div className="max-w-[1024px] max-[360px]:max-w-[340px] mx-auto text-body-text md:h-screen h-[80vh] overflow-scroll hide-scrollbar">
        <div className="inline-flex space-x-2 px-2 pb-8">
          <SmallButton onClick={handleLogout} text={"Logout"} cancel={true} />
        </div>
        <div className="bg-popup-bg py-10 md:py-16 space-y-6 rounded-xl">
          <div className="flex justify-center items-center">
            <RejectedSign className="w-40 h-40" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <h1 className="text-2xl font-bold text-center">
              Your account is rejected
            </h1>
            <p className="text-center mt-4 md:px-40">{rejectedReason}</p>
            <div className="mt-10">
              <SmallButton onClick={handleUpdate} text={"Update"} />
            </div>
          </div>
        </div>
      </div>
      <LoadingModal
        isLoading={isLoading}
        setisLoading={setisLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </main>
  );
};

export default RejectedClient;
