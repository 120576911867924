import React from "react";
import Calendar from "react-calendar";
import "./MyCalendar.css";

function MyCalendar({ disableFutureDates = true, onChange, value }) {
  const formatDay = (locale, date) => {
    return date.getDate().toString().padStart(2, "0");
  };

  const formatMonthYear = (locale, date) => {
    return date.toLocaleDateString(locale, { month: "long" });
  };

  const tileDisabled = ({ activeStartDate, date, view }) => {
    if (disableFutureDates) {
      return date > new Date();
    }
  };

  const handleCalendarClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="calendar-container shadow-2xl rounded-3xl" onClick={handleCalendarClick}>
      <Calendar
        onChange={onChange}
        value={value}
        prev2Label={null}
        next2Label={null}
        formatMonthYear={formatMonthYear}
        formatDay={formatDay}
        tileDisabled={tileDisabled}
      />
    </div>
  );
}

export default MyCalendar;
