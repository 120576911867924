import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const verifyTROTP = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/client/transaction/verify-email-otp", data);
    const otpverification = res.data;

    return otpverification;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
