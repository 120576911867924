import React from "react";
import Sidebar from "../SideBar";
import ViewRecentTransaction from "../../RecentTranactions/partials/ViewRecentTransaction";

const ReccentTransactionSideBar = ({
  activeTransaction,
  ShowRecentTransaction,
  setShowRecentTransaction,
  setActiveTransaction,
  type,
}) => {
  return (
    <div>
      {/* Recent Transaction sidebar for small devices  */}
      <Sidebar open={window.innerWidth > 768 ? false : ShowRecentTransaction}>
        {ShowRecentTransaction && (
          <div className="bg-popup-bg mt-2 space-y-6 overflow-y-auto h-screen">
            <ViewRecentTransaction
              transaction={activeTransaction}
              ShowRecentTransaction={ShowRecentTransaction}
              setShowRecentTransaction={setShowRecentTransaction}
              setActiveTransaction={setActiveTransaction}
              type={type}
            />
          </div>
        )}
      </Sidebar>
    </div>
  );
};

export default ReccentTransactionSideBar;
