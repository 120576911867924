import React from "react";

const InitialsAvatar = ({ name }) => {
  const getInitials = () => {
    const words = name.split(" ");
    const initials = words
      .slice(0, 2)
      .map((word) => word[0])
      .join("");
    return initials.toUpperCase();
  };

  return (
    <div className="w-11 h-11 rounded-full bg-active-state-bg flex items-center justify-center text-xl font-bold text-primary-item">
      {getInitials()}
    </div>
  );
};

export default InitialsAvatar;
