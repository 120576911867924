import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetDepositAddressQRCode } from "../../../../../../redux/actions/CryptoTransaction/GetDepositAddress";
import Skeleton from "../../../../../Common/Skeleton";

const QRCode = ({ logo, walletAddress, size = 250 }) => {
  const [QRcodeData, setQRcodeData] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchQRCode = async () => {
      setIsLoading(true);
      const data = {
        size,
        wallet_address: walletAddress,
        logo: logo || null,
      };

      const res = await dispatch(GetDepositAddressQRCode(data));
      if (res.success) {
        setQRcodeData(res.qr_code);
        setIsLoading(false);
      } else {
        setQRcodeData("");
      }
    };

    if (walletAddress) {
      fetchQRCode();
    }
  }, [walletAddress, dispatch, logo, size]);
  return (
    <>
      {!isloading && QRcodeData ? (
        <img
          src={`data:image/png;base64,${QRcodeData}`}
          alt="QRcodeData-qr-code"
          className="mx-auto max-[360px]:pt-2"
        />
      ) : (
        <div className="flex items-center justify-center max-[360px]:pt-2">
          <Skeleton className="w-[250px] h-[250px]" />
        </div>
      )}
    </>
  );
};

export default QRCode;
