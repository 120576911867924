import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../Buttons";
import Label from "../../Label";
import InputFile from "../../InputFile";
import FormInputField from "../../FormInputField";
import DateSelector from "../../DateSelector";
import RadioButton from "../../RadioButton";
import CountriesInput from "../../Common/CountriesInput";
import useAllCountries from "../../../hooks/useAllCountries";

const PersonalInfo = ({ handleNext, formData, onChange }) => {
  const allCountries = useAllCountries();
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedResidency, setSelectedResidency] = useState("");

  const preferenceOptions = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
  ];

  useEffect(() => {
    const updateNationalityAndResidency = async () => {
      try {
        const nationality_id = formData.nationality_id
          ? formData.nationality_id - 1
          : 223;
        const residence_country_id = formData.residence_country_id
          ? formData.residence_country_id - 1
          : 223;

        onChange({
          target: {
            name: "nationality_id",
            value: allCountries[nationality_id].id,
          },
        });
        onChange({
          target: {
            name: "residence_country_id",
            value: allCountries[residence_country_id].id,
          },
        });

        setSelectedNationality(allCountries[nationality_id]);
        setSelectedResidency(allCountries[residence_country_id]);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
    if (allCountries.length > 0) {
      updateNationalityAndResidency();
    }
    // eslint-disable-next-line
  }, [allCountries, formData.nationality_id, formData.residence_country_id]); // eslint-disable-line react-hooks/exhaustive-deps

  const isFormValid = () => {
    return (
      formData.passport_copy &&
      formData.passport_number &&
      formData.mobile_number &&
      formData.nationality_id &&
      formData.date_of_birth &&
      formData.passport_expiry_date &&
      formData.gender
    );
  };

  const handleSelectNationality = (option) => {
    onChange({ target: { name: "nationality_id", value: option.id } });
    setSelectedNationality(option);
  };

  const handleSelectResidency = (option) => {
    onChange({ target: { name: "residence_country_id", value: option.id } });
    setSelectedResidency(option);
  };

  return (
    <div className="space-y-4 md:w-fit">
      <div>
        <Label
          className="block text-sm font-bold text-body-text mb-3"
          htmlFor="personal-info"
          text="Personal Information"
        />
        <Label
          className="block text-xs font-bold text-paragraph-text mb-3"
          htmlFor="personal-info"
          text="We are not sharing personal information with any 3rd party."
        />
        <InputFile
          name="passport_copy"
          id="passport_copy"
          onClear={() =>
            onChange({ target: { name: "passport_copy", value: "" } })
          }
          onChange={onChange}
          text="Upload Passport"
          supportingDocument={formData.passport_copy}
          placeholder="Upload passport front page, which includes your photo."
        />
      </div>
      <div>
        <FormInputField
          label="Passport Number"
          name="passport_number"
          id="passport_number"
          type="text"
          placeholder="Enter passport number"
          value={formData.passport_number}
          onChange={onChange}
        />
      </div>

      <CountriesInput
        name="nationality_id"
        label="Nationality"
        placeholder="Choose your nationality"
        countries={allCountries}
        SelectedCountry={selectedNationality}
        onChange={handleSelectNationality}
        phonecode={false}
      >
        <div>
          <Label
            htmlFor={"nationality_id"}
            className="block text-xs font-medium text-body-text pb-1"
            text="Nationalality"
          />
          <div className="flex items-center justify-between space-x-3 px-">
            <img
              className="rounded-full w-8 h-8"
              src={selectedNationality.flag}
              alt="flag"
            />
            <Label
              htmlFor={"nationality_id"}
              className="text-sm font-medium text-body-text"
              text={`${selectedNationality.name}`}
            />
          </div>
        </div>
      </CountriesInput>

      <CountriesInput
        name="residence_country_id"
        label="Country of Residency "
        placeholder="Choose your Residency"
        countries={allCountries}
        SelectedCountry={selectedResidency}
        onChange={handleSelectResidency}
        phonecode={false}
      >
        <div>
          <Label
            htmlFor={"residence_country_id"}
            className="block text-xs font-medium text-body-text pb-1"
            text="Country of Residence"
          />
          <div className="flex items-center space-x-3">
            <img
              className="rounded-full w-8 h-8"
              src={selectedResidency.flag}
              alt="flag"
            />
            <Label
              htmlFor={"residency"}
              className="text-sm font-medium text-body-text"
              text={`${selectedResidency.name}`}
            />
          </div>
        </div>
      </CountriesInput>

      <div className="flex space-x-2">
        <div className="w-full">
          <FormInputField
            label="Mobile Number"
            name="mobile_number"
            id="mobile_number"
            type="number"
            placeholder="Enter your mobile number"
            value={formData.mobile_number}
            onChange={onChange}
          />
        </div>
      </div>
      <div>
        <DateSelector
          text="Date of Birth"
          name="date_of_birth"
          id="date_of_birth"
          placeholder="Select your date of birth as per passport"
          selectedDate={formData.date_of_birth}
          onChange={onChange}
        />
      </div>
      <div>
        <DateSelector
          text="Passport Expiry Date"
          name="passport_expiry_date"
          id="passport_expiry_date"
          placeholder="Select your passport expiry date"
          onChange={onChange}
          selectedDate={formData.passport_expiry_date}
          disableFutureDates={false}
        />
      </div>
      <div>
        <RadioButton
          name="gender"
          preferenceOptions={preferenceOptions}
          onChange={onChange}
          value={formData.gender}
        />
      </div>
      <hr className="border border-divider-border" />
      <div className="flex md:w-96 w-full">
        <PrimaryButton
          onClick={handleNext}
          text="Continue"
          disabled={!isFormValid()}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
