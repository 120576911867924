import { Fragment } from "react";
import { Menu } from "@headlessui/react";
import { logout } from "../../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  AccountIcon,
  DarkModeIcon,
  LogoutIcon,
  ManagebeneficiariesIcon,
  ReportIcon,
  SettingIcon,
  StatementIcon,
  UpArrowIcon,
} from "../../Icons";
import CustomTransition from "../../CustomTransition";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Profile({
  email,
  userName,
  profilePicture,
  setBalanceCertificate,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const handelLogout = async () => {
    await dispatch(logout());
  };
  return (
    <Menu as="div" className="relative inline-block  text-left">
      <div>
        <Menu.Button>
          {({ open }) => (
            <>
              <div
                className={classNames(
                  open
                    ? "bg-active-state-bg"
                    : "bg-secondary-icon-bg hover:bg-hover-state-bg",
                  "flex items-center rounded-full p-2  "
                )}
              >
                {profilePicture ? (
                  <img
                    className="h-8 w-8 rounded-full mx-1 mr-3 max-[576px]:mr-0"
                    src={
                      profilePicture ? profilePicture : "assets/gifs/loader.gif"
                    }
                    alt="profile_picture"
                  />
                ) : (
                  <AccountIcon className="h-8 w-8 rounded-full mx-1 mr-3 max-[576px]:mr-0" />
                )}
                <label className="max-[445px]:hidden max-[576px]:pl-2 text-body-text text-sm font-medium leading-tight tracking-tight">
                  {userName && userName}
                </label>
                <div className="mx-3">
                  <UpArrowIcon
                    src="/assets/up_arrow.svg"
                    className={`h-6 w-6 text-PrimaryBtn ${
                      open
                        ? "rotate--180 transition-transform duration-500"
                        : "rotate-180 transition-transform duration-500"
                    }`}
                    alt="up arrow"
                  />
                </div>
              </div>
            </>
          )}
        </Menu.Button>
      </div>

      <CustomTransition
        as={Fragment}
        enter="transition ease-out duration-500"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-500"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 min-w-max max-[375px]:max-w-[360px] origin-top-right rounded-3xl bg-popup-bg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-6">
          <div className="flex justify-between">
            <div className="flex flex-col space-y-1">
              <label className="text-body-text text-sm font-medium   leading-tight tracking-tight">
                {userName && userName}
              </label>
              <label className="text-paragraph-text text-sm font-normal   leading-tight tracking-tight">
                {email && email}
              </label>
            </div>
            <div className="flex items-center">
              {profilePicture ? (
                <img
                  className="w-12 h-12 rounded-full border-2 border-divider-border"
                  src={
                    profilePicture ? profilePicture : "assets/gifs/loader.gif"
                  }
                  alt="profile_picture"
                />
              ) : (
                <AccountIcon className="w-12 h-12 rounded-full border-2 border-divider-border" />
              )}
            </div>
          </div>

          <hr className="h-px my-2 bg-divider-border border-0 " />
          <MenuItem
            title="Switch to Dark Mode"
            className={"max-[575px]:block hidden"}
            icon={<DarkModeIcon />}
          />
          {user && !user.is_basic_client && (
            <>
              <MenuItem
                title="Manage Beneficiaries"
                icon={<ManagebeneficiariesIcon />}
                onClick={() => navigate("/manage_beneficiery")}
              />
              <MenuItem
                title="Balance Certificate"
                icon={<ReportIcon />}
                onClick={() => setBalanceCertificate(true)}
              />
              <MenuItem
                title="Services Requests List"
                icon={<StatementIcon />}
                onClick={() => navigate("/services-requests")}
              />
            </>
          )}
          <MenuItem
            title="Settings"
            icon={<SettingIcon />}
            onClick={() => navigate("/settings")}
          />
          <MenuItem
            title="Logout"
            icon={<LogoutIcon />}
            onClick={handelLogout}
          />
        </Menu.Items>
      </CustomTransition>
    </Menu>
  );
}

const MenuItem = ({ title, icon, onClick, className }) => {
  return (
    <div onClick={onClick} className={className}>
      <Menu.Item>
        <div className="self-stretch p-2 hover:bg-body-bg rounded-full justify-start items-center gap-x-2.5 inline-flex">
          <div className="relative rounded-full">
            <div className="bg-primary-Icon-bg w-12 h-12 rounded-full flex items-center justify-center">
              {icon}
            </div>
          </div>
          <label className="w-[244px] text-primary-item text-base font-medium   leading-tight tracking-tight">
            {title}
          </label>
        </div>
      </Menu.Item>
    </div>
  );
};
