import { ResponseErrorHandling } from "../../../../helper";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes, OwnCompanyActions } from "../../../action-types";

export const getOwnCompany = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.get("/lookups/company-own-data");
    const ownCompany = res.data.data;

    localStorage.setItem("company", JSON.stringify(ownCompany));
    dispatch({
      type: OwnCompanyActions.GET_OWN_COMPANY_DETAILS,
      payload: ownCompany,
    });

    return ownCompany;
  } catch (err) {
    const message = err.response.data.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
