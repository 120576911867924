import React, { useEffect, useState, useRef } from "react";
import { NavigationButton } from "../../Buttons";
import CommonButton from "../../Buttons/CommonButton";

const IdentityVerification = ({
  handleNext,
  handlePrev,
  setFormData,
}) => {
  const [liveness, setLiveness] = useState("Pending");
  const iframeRef = useRef(null);

  const reloadIframe = () => {
    if (iframeRef.current) {
      const currentSrc = iframeRef.current.src;
      iframeRef.current.src = "";
      iframeRef.current.src = currentSrc;
    }
    setLiveness("Pending");
  };

  useEffect(() => {
    const handleKyc = (e) => {
      try {
        if (e.origin !== process.env.REACT_APP_LIVENESS_URL) return;
        if (e.data.liveness_status === true) {
          setLiveness(true);
          setFormData((prevFormData) => ({
            ...prevFormData,
            selfie_photo: e.data.selfie_image,
          }));
          if (e.data.selfie_image && handleNext) {
            handleNext();
            return;
          }
        } else {
          setLiveness(false);
        }
      } catch (error) {
        console.error("Error in handleKyc:", error);
      }
    };

    window.addEventListener("message", handleKyc);

    return () => {
      window.removeEventListener("message", handleKyc);
    };
  }, [setFormData, handleNext]);

  return (
    <div className="flex flex-col space-y-4 items-center justify-center">
      <div className="md:w-[551px] w-full rounded-xl border-divider-border bg-popup-bg">
        {liveness === "Pending" && (
          <iframe
            ref={iframeRef}
            id="liveness"
            title="Facial Recognition"
            className="w-full max-[375px]:h-[108vh] h-[87vh] md:h-[88vh] border-none rounded-xl"
            src={`${process.env.REACT_APP_LIVENESS_URL}?platform=web&baseUrl=${process.env.REACT_APP_BASE_URL}`}
            allow="camera"
          ></iframe>
        )}
        {liveness === false && (
          <div className="flex flex-col items-center justify-center h-[369px]">
            <div className="text-highlighter-red text-lg font-medium">
              Liveness check failed
            </div>
            <span className="text-highlighter-red text-sm font-normal">
              click on the Re-try button to try again.{" "}
            </span>
          </div>
        )}
      </div>
      <hr className="border border-divider-border w-full" />

      <div className="flex w-full justify-between">
        <NavigationButton onClick={handlePrev} text="Back" />
        {liveness === false && (
          <CommonButton onClick={reloadIframe}>Retry kyc</CommonButton>
        )}
      </div>
    </div>
  );
};

export default IdentityVerification;
