import React from "react";
import { RightArrowIcon } from "../../Icons";

const TertiaryButton = ({ text, ...props }) => {
  return (
    <div className="flex items-center">
      <button
        className="flex bg-transparent underline px-3 py-2 rounded-full hover:bg-hover-state-bg disabled:bg-[#DFDBD3] disabled:bg-opacity-50 text-primary-item text-sm font-medium"
        {...props}
      >
        {text}
        <RightArrowIcon />
      </button>
    </div>
  );
};

export default TertiaryButton;
