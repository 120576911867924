import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../redux/actions/lookups/Countries";

const useAllCountries = () => {
  const [allCountries, setAllCountries] = useState([]);
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.allCountries.countries);

  useEffect(() => {
    const fetchCountries = async () => {
      if (!countries) {
        const fetchedCountries = await dispatch(getCountries());
        processCountries(fetchedCountries);
      } else {
        processCountries(countries);
      }
    };

    const processCountries = (data) => {
      const countriesWithFlags = data.map((country) => ({
        ...country,
        flag: `assets/flags/${country.iso2}.png`,
      }));
      setAllCountries(countriesWithFlags);
    };

    fetchCountries();
  }, [countries, dispatch]);

  return allCountries;
};

export default useAllCountries;
