import React from "react";

const LivenessError = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center h-[369px]">
        <div className="text-highlighter-red text-lg font-medium">
          Liveness check failed
        </div>
        <span className="text-highlighter-red text-sm font-normal">
          Click on the Retry button to try again later.
        </span>
      </div>
    </>
  );
};

export default LivenessError;
