import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientProfile } from "../../redux/actions/Settings/ClientProfile";

const useClientProfiles = () => {
  const [clientProfiles, setClientProfiles] = useState([]);
  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.clientProfiles.clientProfiles);
  const [loadingProfiles, setLoadingProfiles] = useState(false);

  useEffect(() => {
    const getClientsAccounts = async () => {
      try {
        setLoadingProfiles(true);
        const res = await dispatch(ClientProfile());
        setClientProfiles(res);  // Update state with fetched data
      } catch (error) {
        console.error("Error fetching client profiles:", error);
      } finally {
        setLoadingProfiles(false);
      }
    };

    // Only fetch if profiles are not available or empty
    if (!profiles || profiles.length === 0) {
      getClientsAccounts();
    } else {
      setClientProfiles(profiles);  // Use profiles from Redux store
    }
  }, [dispatch, profiles]);

  return {
    clientProfiles,
    loadingProfiles,
  };
};

export default useClientProfiles;
