import React from "react";
import ReceiveArrowIcon from "../ReceiveArrowIcon";

const CryptoCreditIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-button-text rounded-full">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="#FDD671"
          />
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="url(#paint0_linear_1521_37931)"
          />
          <path
            d="M14.98 14.98C14.73 11.81 12.19 9.27 9.02 9.02C8.85 9.01 8.67 9 8.5 9C4.91 9 2 11.91 2 15.5C2 19.09 4.91 22 8.5 22C12.09 22 15 19.09 15 15.5C15 15.33 14.99 15.15 14.98 14.98Z"
            stroke="url(#paint1_linear_1521_37931)"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.2 18.6C9.2 18.9314 8.93137 19.2 8.6 19.2C8.26863 19.2 8 18.9314 8 18.6V16.1969H5.6C5.26863 16.1969 5 15.9282 5 15.5969C5 15.2655 5.26863 14.9969 5.6 14.9969H8V12.6C8 12.2686 8.26863 12 8.6 12C8.93137 12 9.2 12.2686 9.2 12.6V14.9969H11.6C11.9314 14.9969 12.2 15.2655 12.2 15.5969C12.2 15.9282 11.9314 16.1969 11.6 16.1969H9.2V18.6Z"
            fill="#5F4D21"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.2 18.6C9.2 18.9314 8.93137 19.2 8.6 19.2C8.26863 19.2 8 18.9314 8 18.6V16.1969H5.6C5.26863 16.1969 5 15.9282 5 15.5969C5 15.2655 5.26863 14.9969 5.6 14.9969H8V12.6C8 12.2686 8.26863 12 8.6 12C8.93137 12 9.2 12.2686 9.2 12.6V14.9969H11.6C11.9314 14.9969 12.2 15.2655 12.2 15.5969C12.2 15.9282 11.9314 16.1969 11.6 16.1969H9.2V18.6Z"
            fill="url(#paint2_linear_1521_37931)"
          />
          <path
            d="M8 16.1969H8.15C8.15 16.114 8.08284 16.0469 8 16.0469V16.1969ZM8 14.9969V15.1469C8.08284 15.1469 8.15 15.0797 8.15 14.9969H8ZM9.2 14.9969H9.05C9.05 15.0797 9.11716 15.1469 9.2 15.1469V14.9969ZM9.2 16.1969V16.0469C9.11716 16.0469 9.05 16.114 9.05 16.1969H9.2ZM8.6 19.35C9.01421 19.35 9.35 19.0142 9.35 18.6H9.05C9.05 18.8485 8.84853 19.05 8.6 19.05V19.35ZM7.85 18.6C7.85 19.0142 8.18579 19.35 8.6 19.35V19.05C8.35147 19.05 8.15 18.8485 8.15 18.6H7.85ZM7.85 16.1969V18.6H8.15V16.1969H7.85ZM5.6 16.3469H8V16.0469H5.6V16.3469ZM4.85 15.5969C4.85 16.0111 5.18579 16.3469 5.6 16.3469V16.0469C5.35147 16.0469 5.15 15.8454 5.15 15.5969H4.85ZM5.6 14.8469C5.18579 14.8469 4.85 15.1827 4.85 15.5969H5.15C5.15 15.3483 5.35147 15.1469 5.6 15.1469V14.8469ZM8 14.8469H5.6V15.1469H8V14.8469ZM7.85 12.6V14.9969H8.15V12.6H7.85ZM8.6 11.85C8.18579 11.85 7.85 12.1858 7.85 12.6H8.15C8.15 12.3515 8.35147 12.15 8.6 12.15V11.85ZM9.35 12.6C9.35 12.1858 9.01421 11.85 8.6 11.85V12.15C8.84853 12.15 9.05 12.3515 9.05 12.6H9.35ZM9.35 14.9969V12.6H9.05V14.9969H9.35ZM11.6 14.8469H9.2V15.1469H11.6V14.8469ZM12.35 15.5969C12.35 15.1827 12.0142 14.8469 11.6 14.8469V15.1469C11.8485 15.1469 12.05 15.3483 12.05 15.5969H12.35ZM11.6 16.3469C12.0142 16.3469 12.35 16.0111 12.35 15.5969H12.05C12.05 15.8454 11.8485 16.0469 11.6 16.0469V16.3469ZM9.2 16.3469H11.6V16.0469H9.2V16.3469ZM9.35 18.6V16.1969H9.05V18.6H9.35Z"
            fill="#5F4D21"
          />
          <path
            d="M8 16.1969H8.15C8.15 16.114 8.08284 16.0469 8 16.0469V16.1969ZM8 14.9969V15.1469C8.08284 15.1469 8.15 15.0797 8.15 14.9969H8ZM9.2 14.9969H9.05C9.05 15.0797 9.11716 15.1469 9.2 15.1469V14.9969ZM9.2 16.1969V16.0469C9.11716 16.0469 9.05 16.114 9.05 16.1969H9.2ZM8.6 19.35C9.01421 19.35 9.35 19.0142 9.35 18.6H9.05C9.05 18.8485 8.84853 19.05 8.6 19.05V19.35ZM7.85 18.6C7.85 19.0142 8.18579 19.35 8.6 19.35V19.05C8.35147 19.05 8.15 18.8485 8.15 18.6H7.85ZM7.85 16.1969V18.6H8.15V16.1969H7.85ZM5.6 16.3469H8V16.0469H5.6V16.3469ZM4.85 15.5969C4.85 16.0111 5.18579 16.3469 5.6 16.3469V16.0469C5.35147 16.0469 5.15 15.8454 5.15 15.5969H4.85ZM5.6 14.8469C5.18579 14.8469 4.85 15.1827 4.85 15.5969H5.15C5.15 15.3483 5.35147 15.1469 5.6 15.1469V14.8469ZM8 14.8469H5.6V15.1469H8V14.8469ZM7.85 12.6V14.9969H8.15V12.6H7.85ZM8.6 11.85C8.18579 11.85 7.85 12.1858 7.85 12.6H8.15C8.15 12.3515 8.35147 12.15 8.6 12.15V11.85ZM9.35 12.6C9.35 12.1858 9.01421 11.85 8.6 11.85V12.15C8.84853 12.15 9.05 12.3515 9.05 12.6H9.35ZM9.35 14.9969V12.6H9.05V14.9969H9.35ZM11.6 14.8469H9.2V15.1469H11.6V14.8469ZM12.35 15.5969C12.35 15.1827 12.0142 14.8469 11.6 14.8469V15.1469C11.8485 15.1469 12.05 15.3483 12.05 15.5969H12.35ZM11.6 16.3469C12.0142 16.3469 12.35 16.0111 12.35 15.5969H12.05C12.05 15.8454 11.8485 16.0469 11.6 16.0469V16.3469ZM9.2 16.3469H11.6V16.0469H9.2V16.3469ZM9.35 18.6V16.1969H9.05V18.6H9.35Z"
            fill="url(#paint3_linear_1521_37931)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1521_37931"
              x1="15.5401"
              y1="2"
              x2="23.8553"
              y2="11.9783"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1521_37931"
              x1="8.5"
              y1="9"
              x2="16.8667"
              y2="19.0401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1521_37931"
              x1="8.6"
              y1="12"
              x2="13.2339"
              y2="17.5607"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1521_37931"
              x1="8.6"
              y1="12"
              x2="13.2339"
              y2="17.5607"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <ReceiveArrowIcon />
      </div>
    </div>
  );
};

export default CryptoCreditIcon;
