import React, { useEffect, useState } from "react";
import { NavigationButton, PrimaryButton } from "../../../Buttons";
import Label from "../../../Label";
import FormInputField from "../../../FormInputField";
import DateSelector from "../../../DateSelector";
import RadioButton from "../../../RadioButton";
import { useDispatch } from "react-redux";
import { getCompanyTypes } from "../../../../redux/actions/lookups/CompanyTypes";
import CompanyTypesSkeleton from "../../../Skeleton/CompanyTypesSkeletons";

const CompanyInformation = ({ handleNext, handlePrev, formData, onChange }) => {
  const [preferenceOptions, setPreferenceOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCompanyTypes = async () => {
      try {
        const res = await dispatch(getCompanyTypes());
        setPreferenceOptions(res);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
    if (preferenceOptions.length === 0) {
      fetchCompanyTypes();
    }
  }, [dispatch, preferenceOptions.length]);

  const isFormValid = () => {
    return (
      // formData.name &&
      formData.company_place &&
      formData.registration_date &&
      formData.business_reg_number &&
      formData.expiry_date &&
      formData.business_reg_address &&
      formData.nature_of_business &&
      formData.company_type
    );
  };

  return (
    <div className="space-y-4 w-full md:w-[750px]">
      <Label
        className="block text-sm font-bold text-body-text mb-3"
        htmlFor="personal-info"
        text="Company Information"
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* <FormInputField
          label="Company Name"
          name="name"
          id="name"
          type="text"
          placeholder="Enter company name"
          value={formData.name}
          onChange={onChange}
        /> */}
        <FormInputField
          label="Place of Registration of Company"
          name="company_place"
          id="company_place"
          type="text"
          placeholder="Enter place of registration"
          value={formData.company_place}
          onChange={onChange}
        />
        <DateSelector
          text="Date of Incorporation"
          name="registration_date"
          id="registration_date"
          placeholder="Enter date of incorporation"
          selectedDate={formData.registration_date}
          onChange={onChange}
        />
        <FormInputField
          label="Company Registration Number"
          name="business_reg_number"
          id="business_reg_number"
          type="text"
          placeholder="Enter registration number"
          value={formData.business_reg_number}
          onChange={onChange}
          />
        <DateSelector
          text="License Expiry Date"
          name="expiry_date"
          id="expiry_date"
          placeholder="Enter license expiry date"
          selectedDate={formData.expiry_date}
          disableFutureDates={false}
          onChange={onChange}
        />
        <FormInputField
          label="Business Registration Address"
          name="business_reg_address"
          id="business_reg_address"
          type="text"
          placeholder="Enter company address"
          value={formData.business_reg_address}
          onChange={onChange}
        />
        <FormInputField
          label="Nature of Business"
          name="nature_of_business"
          id="nature_of_business"
          type="text"
          placeholder="Enter nature of business"
          value={formData.nature_of_business}
          onChange={onChange}
        />
        <div className="md:col-span-2">
          {preferenceOptions.length ? (
            <>
              <p className="text-body-text font-semibold text-sm">
                Company type
              </p>
              <RadioButton
                name="company_type"
                preferenceOptions={preferenceOptions}
                onChange={onChange}
                value={parseInt(formData.company_type)}
                layout="column"
              />
            </>
          ) : (
            <CompanyTypesSkeleton />
          )}
        </div>
      </div>
      <hr className="border border-divider-border" />
      <div className="flex md:w-96 w-full space-x-3">
        <NavigationButton onClick={handlePrev} text="Back" />
        <PrimaryButton
          disabled={!isFormValid()}
          onClick={handleNext}
          text="Continue"
        />
      </div>
    </div>
  );
};

export default CompanyInformation;
