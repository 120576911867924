import React, { useRef } from "react";

const OtpInputs = ({ otp, setOtp, error, setError }) => {
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value.slice(0, 1); // Ensure only one character is taken
    setOtp(newOtp);
    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    } else if (!value && index > 0) {
      inputRefs[index - 1].current.focus();
      setError("");
    } else if (index === 5 && value) {
      inputRefs[index].current.blur();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const paste = e.clipboardData.getData("text");
    const newOtp = paste.split("").slice(0, 6); // Only take the first 6 characters
    setOtp(newOtp);

    // Focus the next input after pasting
    newOtp.forEach((value, index) => {
      if (inputRefs[index] && inputRefs[index].current) {
        inputRefs[index].current.value = value;
      }
    });
  };

  return (
    <>
      <p className="text-body-text text-sm font-normal leading-tight tracking-tight">
        For security purposes, please enter the six digits OTP sent to your
        email address.
      </p>

      <div className="flex md:space-x-2 space-x-1.5 mt-4">
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="tel"
            placeholder="*"
            maxLength={1}
            className={`w-14 h-14 max-[360px]:w-12 max-[360px]:h-12 rounded-xl text-center border-[1.6px] bg-popup-bg text-body-text focus:ring-0 focus:border-primary-item font-bold
            ${error ? "border-highlighter-red" : " border-divider-border"}`}
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            onPaste={handlePaste}
            autoComplete="off"
          />
        ))}
      </div>
      {error && (
        <p className="text-highlighter-red text-xs font-normal leading-tight tracking-tight mt-3">
          {error}
        </p>
      )}
    </>
  );
};

export default OtpInputs;
