import React from "react";

const PendingIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#FFBE5E" />
        <path
          d="M9.87441 3H6.63441C4.75441 3 4.60941 4.69 5.62441 5.61L10.8844 10.39C11.8994 11.31 11.7544 13 9.87441 13H6.63441C4.75441 13 4.60941 11.31 5.62441 10.39L10.8844 5.61C11.8994 4.69 11.7544 3 9.87441 3Z"
          stroke="#8D5F19"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PendingIcon;
