import React, { useState, useEffect } from "react";
import SideBar from "../../../Common/SideBar";
import Account from "./../partials/account";
import { setActiveAccount } from "../../../../redux/actions/home/ActiveAccountAction";
import { useDispatch } from "react-redux";
import EmptyAccountList from "../../../Empty/EmptyAccounts";

const SelectedAccountSidebar = ({
  clientAccounts,
  activeAccount,
  children,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangeAccount = async (account) => {
    await dispatch(setActiveAccount(account));
    setIsSidebarOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsSidebarOpen(true)}>{children}</div>
      <SideBar
        open={isSidebarOpen}
        handleOnCloseSidebar={handleOnCloseSidebar}
        header={true}
        crossButton={true}
        crossButtonAction={handleOnCloseSidebar}
        title={"Select Account"}
        className="bg-popup-bg overflow-scroll hide-scrollbar"
      >
        <div className="flex flex-col pb-6 max-[360px]:w-[325px]">
          {clientAccounts.length ? (
            clientAccounts.map((account) => (
              <div
                key={account.id}
                onClick={() => handleChangeAccount(account)}
              >
                <Account
                  key={account.id}
                  {...account}
                  activeAccount={activeAccount}
                />
              </div>
            ))
          ) : (
            <div className="text-body-text font-medium w-[320px]">
              <EmptyAccountList/>
            </div>
          )}
        </div>
      </SideBar>
    </>
  );
};

export default SelectedAccountSidebar;
