import React, { useEffect } from "react";
import BitpaceSell from "../../../components/Crypto/CryptoBitpaceTransactions/BitpaceSell";
import ManualSell from "../../../components/Crypto/CryptoManualTransactions/ManualSell";
import { useModules } from "../../../hooks";
import { setPageTitle } from "../../../helper/metaDataHelper";

// handle the error messages and add an api to get the max and min count for currencies from bitpace.
const Sell = () => {
  const isModuleEnabled = useModules("Bitpace");

  useEffect(() => {
    setPageTitle("Crypto Sell");
  }, []);

  return <>{isModuleEnabled ? <BitpaceSell /> : <ManualSell />}</>;
};

export default Sell;
