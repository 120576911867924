import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import CommonButton from "../../Buttons/CommonButton";
import ListItem from "../Beneficiary/partails/ListItem";
import { FormatDate } from "../../../helper";
import StatusIcon from "../../Common/StatusIcon";
import EnterOTP from "../../Common/EnterOTP";
import SuccessMessage from "../../Common/SuccessMessage";
// import HangTightLoading from "../../Common/HangTightLoading";
import { serviceRequestVerifyOTP } from "../../../redux/actions/ServiceRequests/serviceRequestVerifyOTP";
import { applyForNewAccount } from "../../../redux/actions/ServiceRequests/applyForNewAccount";
import { balanceCertificateRequest } from "../../../redux/actions/ServiceRequests/balanceCertificateRequest";
import { applyForNewCard } from "../../../redux/actions/ServiceRequests/newCard";
import { useDispatch } from "react-redux";
import { CloseClientAccount } from "../../../redux/actions/ServiceRequests/closeAccount";
import GetTransactionStatus from "../../RecentTranactions/partials/ViewRecentTransaction/partials/GetTransactionStatus";
import LoadingSpinner from "../../Common/LoadingSpinner";

const ShowServiceRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  showServiceRequest,
  formateRequestType,
  fetchServicesRequests,
}) => {
  const [requestId, setRequestId] = useState(null);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [resendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = useState(60);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  // Function to handle OTP submission
  const handleOTP = async () => {
    setIsLoading(true);
    const res = await dispatch(
      serviceRequestVerifyOTP({
        request_id: requestId,
        otp: otp.join(""),
      })
    );

    if (res.success) {
      setPage(3);
      fetchServicesRequests();
    } else {
      setError(res.error);
    }
    setIsLoading(false);
  };

  // Function to resend the OTP
  const handleResendCode = () => {
    setCounter(60);
    setResendDisabled(true);
    handleServiceRequest();
  };

  // Countdown for OTP resend button
  useEffect(() => {
    if (!isLoading && resendDisabled) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            setResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLoading, resendDisabled]);

  // Reset modal state on close
  const handleOnCloseModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setError("");
      setPage(1);
      setOtp(Array(6).fill(""));
    }, 300);
  };

    useEffect(() => {
      if (isModalOpen) {
        setCounter(60);
      }
    }, [isModalOpen]);

  // Handle request for new currency account
  const handleServiceRequest = async () => {
    if (showServiceRequest) {
      setIsLoading(true);
      setError("");
      const res = await handleVerificationOfServiceRequest(showServiceRequest);
      if (res.success) {
        setRequestId(res.data.request_id);
        setPage(2);
      } else {
        setError(res.error);
      }
      setIsLoading(false);
    }
  };

  const handleVerificationOfServiceRequest = async (request) => {
    switch (request.type) {
      case "APPLY_CARD":
        return await dispatch(
          applyForNewCard({
            client_id: showServiceRequest.client_id,
          })
        );
      case "APPLY_ACCOUNT":
        return await dispatch(
          applyForNewAccount({
            client_id: showServiceRequest.client_id,
            currency_id: showServiceRequest.currency_id,
          })
        );
      case "APPLY_CRYPTO":
        return await dispatch(
          applyForNewAccount({
            client_id: showServiceRequest.client_id,
            currency_id: showServiceRequest.currency_id,
          })
        );
      case "BALANCE_CERTIFICATE":
        return await dispatch(
          balanceCertificateRequest({
            client_id: showServiceRequest.client_id,
            account_id: showServiceRequest.account_id,
          })
        );
      case "CLOSE_ACCOUNT":
        return await dispatch(
          CloseClientAccount({
            client_id: showServiceRequest.client_id,
          })
        );
      default:
        return { success: false, error: "Invalid request type" };
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Modal
          isOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          header={isLoading || page === 3 ? false : true}
          headerTitle={"Service Request Details"}
          className="bg-popup-bg w-[432px] max-h-[640px] overflow-scroll hide-scrollbar rounded-3xl"
        >
          {page === 1 && !isLoading && (
            <div className="px-6">
              <hr className="mb-4 border border-secondary-icon-bg dark:border-divider-border" />
              <div className="flex flex-col pb-6">
                {showServiceRequest.type && (
                  <ListItem
                    title={"Service request type"}
                    value={formateRequestType(showServiceRequest.type)}
                  />
                )}
                <ListItem
                  title={"Status"}
                  value={
                    <span className="flex">
                      <GetTransactionStatus
                        status={showServiceRequest.status}
                      />
                    </span>
                  }
                />
                <ListItem
                  title={"Date and time"}
                  value={FormatDate(showServiceRequest.created_at, "dd m, yyyy", "h:M a")}
                />
                {showServiceRequest.showServiceRequest && (
                  <ListItem
                    title={"Requested By"}
                    value={showServiceRequest.client.name}
                  />
                )}
                {showServiceRequest.currency && (
                  <ListItem
                    title={"Currency"}
                    value={
                      <div className="flex space-x-2 items-start">
                        <img
                          src={showServiceRequest.currency.logo}
                          alt="currency logo"
                          className="w-8 h-8 rounded-full"
                        />
                        <div className="flex flex-col items-start">
                          <span className="font-medium text-sm">
                            {showServiceRequest.currency.code}
                          </span>
                          <p className="font-medium text-xs text-paragraph-text">
                            {showServiceRequest.currency.name}
                          </p>
                        </div>
                      </div>
                    }
                  />
                )}
                {showServiceRequest.account && (
                  <ListItem
                    title={"Account"}
                    value={showServiceRequest.account.title}
                  />
                )}
                <ListItem
                  title={"OTP Verified"}
                  value={
                    showServiceRequest.is_otp_verified ? (
                      <div className="flex space-x-1 items-center">
                        <StatusIcon status="Approved" />
                        <span>Verified</span>
                      </div>
                    ) : (
                      <div className="flex space-x-1 items-center">
                        <StatusIcon status="Rejected" />
                        <span>Not Verified</span>
                      </div>
                    )
                  }
                />

                {showServiceRequest.reject_reason && (
                  <ListItem
                    title={"Rejected Reason"}
                    value={
                      <div className="flex w-full text-start">
                        <p className="font-medium">
                          {showServiceRequest.reject_reason}
                        </p>
                      </div>
                    }
                  />
                )}
              </div>

              <div className="pb-6">
                {!showServiceRequest.is_otp_verified && (
                  <CommonButton onClick={handleServiceRequest}>
                    Verified Request
                  </CommonButton>
                )}
              </div>
            </div>
          )}

          {/* OTP entry on page 2 */}
          {page === 2 && !isLoading && (
            <div className="md:px-6 px-3 py-2">
              <EnterOTP
                handleOTP={handleOTP}
                otp={otp}
                setOtp={setOtp}
                error={error}
                setError={setError}
                resendDisabled={resendDisabled}
                counter={counter}
                handleResendCode={handleResendCode}
              />
            </div>
          )}

          {/* Success message on page 3 */}
          {page === 3 && !isLoading && (
            <div className="md:p-6 px-3 py-3">
              <SuccessMessage
                title="New currency account requested"
                description="You will receive an update within 2-3 working days."
                handleCloseButton={handleOnCloseModal}
              />
            </div>
          )}

          {/* Loading spinner */}
          {/* {isLoading && (
          <HangTightLoading message="We are placing your request!" />
        )} */}
        </Modal>
      )}
    </>
  );
};

export default ShowServiceRequestModal;
