import React, { useEffect } from "react";
import { CheckIcon, DropdownCrossIcon } from "../Icons";

const Alert = ({ type, message, onClose }) => {
  const bgColor =
    type === "success" ? "bg-highlighter-green" : "bg-highlighter-red";
  const icon = type === "success" ? <CheckIcon /> : "";

  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div
      className={`rounded-md ${bgColor} p-4 fixed top-[105px] right-4 z-50 shadow-lg`}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">{icon}</div>
        <div className="ml-3">
          <p className="text-sm font-medium text-white">{message}</p>
        </div>
        <div className="flex x ml-3">
          <button
            type="button"
            className="inline-flex text-white"
            onClick={onClose}
          >
            <DropdownCrossIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Alert;
