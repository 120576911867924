const FormatDate = (inputDate, dateFormat = "d m, yyyy", timeFormat = "") => {  // "h:M a" for 12-hour format
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const shortYear = year.toString().slice(-2);

  let hours = date.getHours();
  let minutes = date.getMinutes();

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // 0 should be 12
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const longMonthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Date formatting using replace
  const formattedDate = dateFormat
    .replace(/dd/g, day.toString().padStart(2, '0')) // Day (2 digits)
    .replace(/d/g, day.toString()) // Day (1 digit)
    .replace(/mm/g, longMonthNames[month - 1]) // Long month name
    .replace(/m/g, shortMonthNames[month - 1]) // Short month name
    .replace(/yyyy/g, year) // Year (4 digits)
    .replace(/yy/g, shortYear); // Year (2 digits)

  // Time formatting using replace
  const formattedTime = timeFormat
    .replace(/h{1,2}/g, hours.toString().padStart(2, '0')) // Hours (1 or 2 digits)
    .replace(/M{1,2}/g, minutes) // Minutes (1 or 2 digits)
    .replace(/a/g, ampm); // AM/PM

  // Concatenate formatted date and time
  return `${formattedDate} ${formattedTime}`;
};

export default FormatDate;
