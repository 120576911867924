import React from "react";
import ReceiveArrowIcon from "../ReceiveArrowIcon";

const CreditMoneyIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-transaction-bg rounded-full">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="money-stack">
            <g id="Group 12648">
              <g id="Rectangle (Stroke)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.25 8.4502C0.25 7.4837 1.0335 6.7002 2 6.7002H3C3.9665 6.7002 4.75 7.4837 4.75 8.4502V15.7229C4.75 15.8336 4.81759 15.9058 4.89205 15.9256C5.54309 16.0991 6.65209 16.3652 8.1233 16.6104C9.42602 16.8275 10.5246 16.5335 11.7336 16.0741C11.98 15.9805 12.2347 15.878 12.4981 15.772C13.494 15.3714 14.6134 14.9209 15.8767 14.7104C17.3351 14.4673 18.7806 14.7851 19.5737 15.0164C20.3177 15.2334 20.75 15.9191 20.75 16.6217V17.4346C20.75 18.5332 19.7641 19.3493 18.731 19.2701C18.3957 19.2444 18.0935 19.2248 17.845 19.2087C17.6885 19.1985 17.5534 19.1897 17.4447 19.1817C15.8551 19.0641 14.6509 19.5564 13.2886 20.1245L13.2488 20.1411C11.9095 20.6997 10.4119 21.3242 8.44468 21.1787C5.80852 20.9837 3.56952 20.4625 1.24882 19.3993C0.625035 19.1135 0.25 18.4901 0.25 17.8288V8.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.25 8.4502C0.25 7.4837 1.0335 6.7002 2 6.7002H3C3.9665 6.7002 4.75 7.4837 4.75 8.4502V15.7229C4.75 15.8336 4.81759 15.9058 4.89205 15.9256C5.54309 16.0991 6.65209 16.3652 8.1233 16.6104C9.42602 16.8275 10.5246 16.5335 11.7336 16.0741C11.98 15.9805 12.2347 15.878 12.4981 15.772C13.494 15.3714 14.6134 14.9209 15.8767 14.7104C17.3351 14.4673 18.7806 14.7851 19.5737 15.0164C20.3177 15.2334 20.75 15.9191 20.75 16.6217V17.4346C20.75 18.5332 19.7641 19.3493 18.731 19.2701C18.3957 19.2444 18.0935 19.2248 17.845 19.2087C17.6885 19.1985 17.5534 19.1897 17.4447 19.1817C15.8551 19.0641 14.6509 19.5564 13.2886 20.1245L13.2488 20.1411C11.9095 20.6997 10.4119 21.3242 8.44468 21.1787C5.80852 20.9837 3.56952 20.4625 1.24882 19.3993C0.625035 19.1135 0.25 18.4901 0.25 17.8288V8.4502Z"
                  fill="#5F4D21"
                />
              </g>
              <g id="Rectangle (Stroke)_2">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.8117 3.72072C14.2509 3.23334 15.8253 2.7002 17.5 2.7002C18.8744 2.7002 20.6604 3.21799 21.5705 3.51078C22.2936 3.74341 22.75 4.41658 22.75 5.14585V15.0629C22.75 16.2542 21.6017 17.0734 20.4917 16.7766C19.5591 16.5273 18.3534 16.2487 17.4447 16.1815C15.8551 16.0639 14.651 16.5563 13.2887 17.1244L13.2489 17.141C11.9095 17.6996 10.4119 18.3242 8.44467 18.1787C7.13627 18.0819 5.41459 17.6272 4.49223 17.3625C3.74217 17.1472 3.25 16.4605 3.25 15.7018V5.88342C3.25 4.65548 4.45645 3.84609 5.57225 4.16717C6.49622 4.43305 7.64485 4.69742 8.5 4.69742C9.90634 4.69742 11.2635 4.24495 12.7595 3.73842L12.8117 3.72072Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.8117 3.72072C14.2509 3.23334 15.8253 2.7002 17.5 2.7002C18.8744 2.7002 20.6604 3.21799 21.5705 3.51078C22.2936 3.74341 22.75 4.41658 22.75 5.14585V15.0629C22.75 16.2542 21.6017 17.0734 20.4917 16.7766C19.5591 16.5273 18.3534 16.2487 17.4447 16.1815C15.8551 16.0639 14.651 16.5563 13.2887 17.1244L13.2489 17.141C11.9095 17.6996 10.4119 18.3242 8.44467 18.1787C7.13627 18.0819 5.41459 17.6272 4.49223 17.3625C3.74217 17.1472 3.25 16.4605 3.25 15.7018V5.88342C3.25 4.65548 4.45645 3.84609 5.57225 4.16717C6.49622 4.43305 7.64485 4.69742 8.5 4.69742C9.90634 4.69742 11.2635 4.24495 12.7595 3.73842L12.8117 3.72072Z"
                  fill="url(#paint0_linear_2996_15467)"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12.8117 3.72072C14.2509 3.23334 15.8253 2.7002 17.5 2.7002C18.8744 2.7002 20.6604 3.21799 21.5705 3.51078C22.2936 3.74341 22.75 4.41658 22.75 5.14585V15.0629C22.75 16.2542 21.6017 17.0734 20.4917 16.7766C19.5591 16.5273 18.3534 16.2487 17.4447 16.1815C15.8551 16.0639 14.651 16.5563 13.2887 17.1244L13.2489 17.141C11.9095 17.6996 10.4119 18.3242 8.44467 18.1787C7.13627 18.0819 5.41459 17.6272 4.49223 17.3625C3.74217 17.1472 3.25 16.4605 3.25 15.7018V5.88342C3.25 4.65548 4.45645 3.84609 5.57225 4.16717C6.49622 4.43305 7.64485 4.69742 8.5 4.69742C9.90634 4.69742 11.2635 4.24495 12.7595 3.73842L12.8117 3.72072Z"
                  stroke="#DDBF5D"
                  strokeWidth="0.5"
                  strokeLinejoin="round"
                />
              </g>
              <path
                id="Rectangle (Stroke)_3"
                d="M13.1883 5.17689C11.7491 5.66428 10.1747 6.19742 8.5 6.19742C7.7 6.44861 5.74706 5.94426 4.87058 5.66069C4.78972 5.71905 4.75 5.79842 4.75 5.88342V15.7018C4.75 15.8132 4.8197 15.8959 4.90598 15.9207C5.83214 16.1865 7.41876 16.5987 8.55533 16.6828C10.1449 16.8004 11.349 16.308 12.7113 15.7399L12.7511 15.7233L12.7512 15.7233C14.0905 15.1647 15.5881 14.54 17.5553 14.6856C18.6147 14.7639 19.9365 15.0755 20.8791 15.3275C21.0778 15.3807 21.25 15.2261 21.25 15.0629V5.14585C21.25 5.03744 21.1854 4.96262 21.1111 4.93871C20.1908 4.64266 18.611 4.2002 17.5 4.2002C16.0937 4.2002 14.7365 4.65267 13.2405 5.15919L13.1883 5.17689Z"
                stroke="#5F4D21"
                strokeWidth="0.5"
                strokeLinejoin="round"
              />
              <g id="Ellipse (Stroke)">
                <path
                  d="M9.5 10.4502C9.5 8.5172 11.067 6.9502 13 6.9502C14.933 6.9502 16.5 8.5172 16.5 10.4502C16.5 12.3832 14.933 13.9502 13 13.9502C11.067 13.9502 9.5 12.3832 9.5 10.4502Z"
                  fill="#FDD671"
                />
                <path
                  d="M9.5 10.4502C9.5 8.5172 11.067 6.9502 13 6.9502C14.933 6.9502 16.5 8.5172 16.5 10.4502C16.5 12.3832 14.933 13.9502 13 13.9502C11.067 13.9502 9.5 12.3832 9.5 10.4502Z"
                  fill="#5F4D21"
                />
                <path
                  d="M9.5 10.4502C9.5 8.5172 11.067 6.9502 13 6.9502C14.933 6.9502 16.5 8.5172 16.5 10.4502C16.5 12.3832 14.933 13.9502 13 13.9502C11.067 13.9502 9.5 12.3832 9.5 10.4502Z"
                  stroke="#DDBF5D"
                  strokeWidth="0.5"
                  strokeLinejoin="round"
                />
              </g>
              <g id="Vector (Stroke)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.25 11.4502C6.25 11.036 6.58579 10.7002 7 10.7002H7.01C7.42421 10.7002 7.76 11.036 7.76 11.4502C7.76 11.8644 7.42421 12.2002 7.01 12.2002H7C6.58579 12.2002 6.25 11.8644 6.25 11.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.25 11.4502C6.25 11.036 6.58579 10.7002 7 10.7002H7.01C7.42421 10.7002 7.76 11.036 7.76 11.4502C7.76 11.8644 7.42421 12.2002 7.01 12.2002H7C6.58579 12.2002 6.25 11.8644 6.25 11.4502Z"
                  fill="#5F4D21"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.25 11.4502C6.25 11.036 6.58579 10.7002 7 10.7002H7.01C7.42421 10.7002 7.76 11.036 7.76 11.4502C7.76 11.8644 7.42421 12.2002 7.01 12.2002H7C6.58579 12.2002 6.25 11.8644 6.25 11.4502Z"
                  stroke="#DDBF5D"
                  strokeWidth="0.5"
                  strokeLinejoin="round"
                />
              </g>
              <g id="Vector (Stroke)_2">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.25 9.4502C18.25 9.03598 18.5858 8.7002 19 8.7002H19.01C19.4242 8.7002 19.76 9.03598 19.76 9.4502C19.76 9.86441 19.4242 10.2002 19.01 10.2002H19C18.5858 10.2002 18.25 9.86441 18.25 9.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.25 9.4502C18.25 9.03598 18.5858 8.7002 19 8.7002H19.01C19.4242 8.7002 19.76 9.03598 19.76 9.4502C19.76 9.86441 19.4242 10.2002 19.01 10.2002H19C18.5858 10.2002 18.25 9.86441 18.25 9.4502Z"
                  fill="#5F4D21"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.25 9.4502C18.25 9.03598 18.5858 8.7002 19 8.7002H19.01C19.4242 8.7002 19.76 9.03598 19.76 9.4502C19.76 9.86441 19.4242 10.2002 19.01 10.2002H19C18.5858 10.2002 18.25 9.86441 18.25 9.4502Z"
                  stroke="#DDBF5D"
                  strokeWidth="0.5"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_2996_15467"
              x1="13"
              y1="2.70019"
              x2="22.3384"
              y2="16.7983"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 -right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <ReceiveArrowIcon />
      </div>
    </div>
  );
};

export default CreditMoneyIcon;
