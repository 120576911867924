import React from "react";
import Skeleton from "../../Common/Skeleton";

const PrimaryCardSkeleton = ({ count = 4 }) => {
  return (
    <div className="flex flex-col space-y-7 items-center pb-10">
      <Skeleton className="rounded-full flex items-center h-12 w-[326px] mt-6">
        <Skeleton className="dark:block hidden rounded-full h-10 w-10 mx-2" />
        <img
          src="assets/gifs/loader.gif"
          className="w-10 h-10 rounded-full m-2 block dark:hidden"
          alt="loader"
        />
        <div className="flex flex-col space-y-1">
          <Skeleton className="rounded-full w-[203px] h-[18px]" />
          <Skeleton className="rounded-full w-[203px] h-[18px]" />
        </div>
      </Skeleton>
      <div className="flex flex-col space-y-2 items-center justify-center">
        <Skeleton className="rounded-full w-[234px] h-10 mt-6" />
        <Skeleton className="rounded-full w-[142px] h-[19px]" />
      </div>
      <div className="flex md:space-x-9 space-x-4 pt-1">
        {[...Array(count)].map((_, item) => (
          <div
            key={item}
            className="flex flex-col md:space-y-3 space-y-4 items-center justify-center"
          >
            <Skeleton className="rounded-full md:w-11 md:h-11 w-10 h-10" />
            <Skeleton className="rounded-full md:w-12 md:h-[17px] w-12 h-3" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrimaryCardSkeleton;
