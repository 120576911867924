import React, { useState } from "react";
import FormInputField from "../../../FormInputField";
import { PrimaryButton } from "../../../Buttons";
import { useDispatch } from "react-redux";
import useForm from "../../../../hooks/useForm";
import { ChangeUserPassword } from "../../../../redux/actions/Settings/ChangeUserPassword";
import LoadingModal from "../../../Modals/LoadingModal";
import PasswordToggleIcon from "../../../Common/PasswordToggleIcon";

const ChangePassword = ({
  loading,
  setLoading,
  success,
  setSuccess,
  isModalOpen,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch();

  const currentPassword = useForm("", "password");
  const newPassword = useForm("", "password");
  const confirmPassword = useForm("", "password");

  const [showPassword, setShowPassword] = useState({
    current_password: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const [error, setError] = useState("");

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  const handleSubmit = async () => {
    setError("");
    if (newPassword.value !== confirmPassword.value) {
      setError("Passwords do not match");
      return;
    }

    if (currentPassword.value === newPassword.value) {
      setError("New password cannot be the same as the current password");
      return;
    }

    if (
      // currentPassword.validate() ||
      newPassword.validate() ||
      confirmPassword.validate()
    ) {
      return;
    }

    setIsModalOpen(true);
    setLoading(true);
    setError("");

    let data = {
      current_password: currentPassword.value,
      new_password: newPassword.value,
      new_password_confirmation: confirmPassword.value,
    };
    const res = await dispatch(ChangeUserPassword(data));
    if (res.success === true) {
      setLoading(false);
      setSuccess(true);
      currentPassword.reset();
      newPassword.reset();
      confirmPassword.reset();
      return;
    } else {
      setError(res.message);
    }
    setIsModalOpen(false);
    setLoading(false);
  };

  const isFormValid = () => {
    return currentPassword.value && newPassword.value && confirmPassword.value;
  };

  return (
    <>
      <div className="flex flex-col space-y-4 mx-2 max-[360px]:mx-0">
        <div className="flex flex-col md:w-[384px] w-full space-y-4">
          <FormInputField
            type={showPassword.current_password ? "text" : "password"}
            label="Current password"
            name="current_password"
            id="current_password"
            {...currentPassword}
            placeholder="********"
            icon={
              <PasswordToggleIcon
                isVisible={showPassword.current_password}
                onToggle={() => togglePasswordVisibility("current_password")}
              />
            }
          />
          <FormInputField
            type={showPassword.new_password ? "text" : "password"}
            placeholder="********"
            label="New password"
            name="new_password"
            id="new_password"
            {...newPassword}
            icon={
              <PasswordToggleIcon
                isVisible={showPassword.new_password}
                onToggle={() => togglePasswordVisibility("new_password")}
              />
            }
          />
          <FormInputField
            type={showPassword.new_password_confirmation ? "text" : "password"}
            placeholder="********"
            label="Confirm new password"
            name="new_password_confirmation"
            id="new_password_confirmation"
            {...confirmPassword}
            icon={
              <PasswordToggleIcon
                isVisible={showPassword.new_password_confirmation}
                onToggle={() =>
                  togglePasswordVisibility("new_password_confirmation")
                }
              />
            }
          />
          {error && <div className="text-highlighter-red">{error}</div>}
          <div className="mt-6"></div>
          <PrimaryButton
            onClick={handleSubmit}
            text="Save Changes"
            disabled={!isFormValid()}
          />
        </div>
      </div>
      <LoadingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isSuccess={success}
        setSuccess={setSuccess}
        isLoading={loading}
        setisLoading={setLoading}
        title={"Password changed"}
        description={"Your profile password changed successfully"}
      />
    </>
  );
};

export default ChangePassword;
