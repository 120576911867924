const FilterIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9999 15.5998C13.1756 15.5998 14.1757 16.3512 14.5463 17.3999L20.0999 17.3998C20.5969 17.3998 20.9999 17.8027 20.9999 18.2998C20.9999 18.7969 20.5969 19.1998 20.0999 19.1998L14.546 19.2006C14.1751 20.2488 13.1752 20.9998 11.9999 20.9998C10.8247 20.9998 9.82476 20.2488 9.45388 19.2006L3.89999 19.1998C3.40294 19.1998 3 18.7969 3 18.2998C3 17.8027 3.40294 17.3998 3.89999 17.3998L9.45357 17.3999C9.82419 16.3512 10.8243 15.5998 11.9999 15.5998ZM11.9996 17.3994C11.5026 17.3994 11.0996 17.8024 11.0996 18.2994C11.0996 18.7965 11.5026 19.1994 11.9996 19.1994C12.4967 19.1994 12.8996 18.7965 12.8996 18.2994C12.8996 17.8024 12.4967 17.3994 11.9996 17.3994ZM18.2999 9.2998C19.7911 9.2998 20.9999 10.5086 20.9999 11.9998C20.9999 13.4909 19.7911 14.6998 18.2999 14.6998C17.1246 14.6998 16.1247 13.9488 15.7538 12.9006L3.89999 12.8998C3.40294 12.8998 3 12.4968 3 11.9998C3 11.5027 3.40294 11.0998 3.89999 11.0998L15.7535 11.0999C16.1242 10.0512 17.1243 9.2998 18.2999 9.2998ZM18.3004 11.1006C17.8033 11.1006 17.4004 11.5035 17.4004 12.0006C17.4004 12.4976 17.8033 12.9006 18.3004 12.9006C18.7974 12.9006 19.2004 12.4976 19.2004 12.0006C19.2004 11.5035 18.7974 11.1006 18.3004 11.1006ZM5.69998 3C6.88059 3 7.88422 3.75776 8.25105 4.81345C8.29915 4.80421 8.34907 4.79999 8.39997 4.79999L20.0999 4.79999C20.5969 4.79999 20.9999 5.20293 20.9999 5.69998C20.9999 6.19704 20.5969 6.59998 20.0999 6.59998L8.39997 6.59998C8.34907 6.59998 8.29915 6.59575 8.25056 6.58763C7.88422 7.64221 6.88059 8.39997 5.69998 8.39997C4.20882 8.39997 3 7.19114 3 5.69998C3 4.20882 4.20882 3 5.69998 3ZM5.70078 4.7998C5.20372 4.7998 4.80078 5.20275 4.80078 5.6998C4.80078 6.19685 5.20372 6.59979 5.70078 6.59979C6.19783 6.59979 6.60077 6.19685 6.60077 5.6998C6.60077 5.20275 6.19783 4.7998 5.70078 4.7998Z"
          className="fill-button-text"
        />
      </svg>
    </div>
  );
};

export default FilterIcon;