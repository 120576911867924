import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Accounts,
  Card,
  CardTopup,
  Crypto,
  ServicesRequestsList,
  ManageBeneficiery,
  Settings,
  Signin,
  Signup,
  ClientPassportUpload,
  ClientLivenessCheck,
} from "./index";
import PrivateRoutes from "./PrivateRoutes";
import ForgotPassword from "./ForgetPassword";
import ResetPassword from "./ResetPassword";
import Deposit from "./TransactionRequest/Deposit";
import AllTransactions from "./AllTransactions";
import Transfer from "./TransactionRequest/Transfer";
import CardStatement from "./CardStatement";
import { Buy, CryptoDeposit, Sell, Withdraw } from "./CryptoTransactions";
import CryptoRoutes from "./CryptoRoutes";
import RestrictRoute from "./RestrictRoute";
import ErrorPage from "../components/ErrorPage";
import Footer from "../components/Footer";
import KYCRequired from "./KYCRequired";
import KYCLayout from "./KYCLayout";
import CheckImperonate from "./CheckImpersonate";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Accounts />} path="/" />
          <Route element={<Settings />} path="/settings" />
          <Route element={<AllTransactions />} path="/all-Transactions" />
          <Route element={<KYCRequired />} path="/draft-client" />

          <Route element={<RestrictRoute />}>
            <Route element={<Card />} path="/card" />
            <Route element={<CardTopup />} path="/card-topup" />
            <Route element={<ManageBeneficiery />} path="/manage_beneficiery" />
            <Route element={<CardStatement />} path="/card-statement" />
            <Route element={<Deposit />} path="/deposit" />
            <Route element={<Transfer />} path="/transfer" />
            <Route
              element={<ServicesRequestsList />}
              path="/services-requests"
            />

            <Route element={<CryptoRoutes />}>
              <Route element={<Crypto />} path="/crypto" />
              <Route element={<CryptoDeposit />} path="/crypto-deposit" />
              <Route element={<Buy />} path="/crypto-buy" />
              <Route element={<Sell />} path="/crypto-sell" />
              <Route element={<Withdraw />} path="/crypto-withdraw" />
            </Route>
          </Route>
        </Route>
        <Route element={<KYCLayout />}>
          <Route
            element={<ClientPassportUpload />}
            path="/client-passport-upload"
          />
          <Route
            element={<ClientLivenessCheck />}
            path="/client-liveness-check"
          />
        </Route>
        <Route element={<CheckImperonate />}>
          <Route element={<Signin />} path="/signin" />
        </Route>
        <Route element={<Signup />} path="/signup" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<ResetPassword />} path="/reset-password" />

        {/* Add a catch-all route for 404 errors */}
        <Route
          element={
            <>
              <ErrorPage />
              <Footer />
            </>
          }
          path="*"
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
