import { ActiveAccountActionTypes } from "../../action-types";

const activeAccount = JSON.parse(localStorage.getItem("activeAccount"));
const initialState = {
  activeAccount: activeAccount ? activeAccount : null,
};

export default function activeAccountReducer(state = initialState, action) {
  switch (action.type) {
    case ActiveAccountActionTypes.SET_ACTIVE_ACCOUNT:
      return {
        activeAccount: action.payload,
      };
    default:
      return state;
  }
}
