import commonService from "../../../../services/Common/service";
import { AuthActionTypes, CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

const user = localStorage.getItem("user");
export const ChangeUserPassword = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/change-password", data);
    dispatch({
      type: AuthActionTypes.CHANGE_PASSWORD,
      payload: res.data.token,
    });

    localStorage.setItem(
      "user",
      JSON.stringify({ ...user, token: res.data.token })
    );

    return res.data;
  } catch (error) {
    const message = error.response.data.message || error.message;

    ResponseErrorHandling(error.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return error.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
