import React from "react";
import Card from "./partials/Card";
import CardButton from "../Card/CardButton";
import AddNewCardModal from "../Modals/Card/AddNewCardModal";
import { useDispatch } from "react-redux";
import { setActiveCard } from "../../redux/actions/Cards/setActiveCard";

const OtherCards = ({ cards, activeCard }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <h3 className="text-body-text text-sm font-medium leading-tight tracking-tight mb-6">
        Other Cards
      </h3>
      <div className="space-y-4 ">
        {cards.length && (
          cards.map((card) => {
            if (activeCard.id !== card.id)
              return (
                <div  key={card.id}  onClick={() => dispatch(setActiveCard(card))}>
                  <Card key={card.id} card={card} />
                </div>
              );
            else return "";
          })
        )}
        <AddNewCardModal>
          <CardButton
            title="Add New Card"
            subtitle="Let's get your card ready!"
          />
        </AddNewCardModal>
      </div>
    </div>
  );
};

export default OtherCards;
