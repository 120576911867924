const CopyToClipboard = (
  text,
  setCopiedText,
  setIsCopied,
  setIsMessage,
  successMessage
) => {
  // This method will only work on secure contexts (HTTPS)
  if (navigator.clipboard) {
    return navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(text);
        setIsCopied(true);
        setIsMessage(successMessage);
        setTimeout(() => {
          setIsCopied(false);
          setIsMessage("");
        }, 3000);
      })
      .catch((err) => {
        console.error("Clipboard write failed:", err);
        setIsMessage("Failed to copy");
      });
  } else {
    // Fallback for non-secure origins (show manual copy message)
    setIsMessage("Please copy manually");
    setTimeout(() => {
      setIsMessage("");
    }, 3000);
  }
};

export default CopyToClipboard;
