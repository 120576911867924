const BankDetailsIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0624 0C9.6459 0.832963 9.00491 1.53296 8.21176 2.021L1.14202 6.37114C1.37037 6.72635 1.84344 6.82919 2.19865 6.60084L10.0467 1.55566L17.9105 6.99979C18.2576 7.24015 18.734 7.15355 18.9743 6.80636C19.2147 6.45916 19.1281 5.98285 18.7809 5.74248L10.0624 0Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0572 0C10.4813 0.424072 10.9393 0.812775 11.4267 1.16222L18.692 6.37114C18.4573 6.73615 17.9841 6.86374 17.5978 6.66615L15.7918 5.74248L10.0572 1.91152L1.92353 6.99979C1.57634 7.24015 1.10002 7.15355 0.859655 6.80636C0.619289 6.45916 0.705891 5.98285 1.05309 5.74248L10.0572 0Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3044 17.1879C18.3044 16.748 17.9478 16.3914 17.5079 16.3914H16.1805L16.1805 6.94005H18.3046C19.4929 6.94005 18.5026 6.07522 17.4847 5.35728L1.67565 5.35728C1.32262 5.75692 1.21547 6.25897 1.6196 6.94005H3.96801L3.96801 16.3914L2.64057 16.3914C2.20069 16.3914 1.8441 16.748 1.8441 17.1879C1.8441 17.6278 2.20069 17.9844 2.64057 17.9844L17.5079 17.9844C17.9478 17.9844 18.3044 17.6278 18.3044 17.1879ZM5.56094 16.3914H9.27778L9.27778 6.94005H5.56094L5.56094 16.3914ZM10.8707 16.3914L10.8707 6.94005L14.5876 6.94005L14.5876 16.3914H10.8707Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default BankDetailsIcon;
