import React from "react";
import { RejectedSign } from "../../Icons";
import CommonButton from "../../Buttons/CommonButton";
import { useNavigate } from "react-router-dom";

const KYCError = ({ error, login = false }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center space-y-6 justify-center bg-primarycard-gradient shadow-lg px-4 py-12 rounded-3xl max-w-lg w-full">
      <RejectedSign className="w-28 h-28 my-4" />
      <div className="w-full">
        <p className="text-lg text-highlighter-red md:text-xl text-center px-6">
          {error}
        </p>
      </div>
      {login && (
        <CommonButton onClick={() => navigate("/")}>Login</CommonButton>
      )}
    </div>
  );
};

export default KYCError;
