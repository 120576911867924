import React, { useState } from "react";
import { SmallButton } from "../../Buttons";
import { EmptyExchangeIcons } from "../../Icons";
import HelpModal from "../../Modals/HelpModal";

const EmptyServiceRequestList = () => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  return (
    <div className="flex flex-col items-center space-y-3 min-h-[70vh]">
      <EmptyExchangeIcons className="w-40 h-40" />
      <span className="font-medium text-body-text text-xl px-6 text-center">
        No service requests
      </span>
      <span className="font-medium text-paragraph-text text-sm px-6 text-center">
        Oops! It looks like you have dont have any service requests
      </span>
      <HelpModal
        HelpModalOpen={helpModalOpen}
        setHelpModalOpen={setHelpModalOpen}
      >
        <SmallButton text="Get help" />
      </HelpModal>
    </div>
  );
};

export default EmptyServiceRequestList;
