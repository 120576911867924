import React from "react";

const RUBSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 18}
      height={height ? height : 23}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.104 23V0.151999H9.12C11.9147 0.151999 13.984 0.717332 15.328 1.848C16.6933 2.97867 17.376 4.62133 17.376 6.776C17.376 8.22667 17.0453 9.47467 16.384 10.52C15.7227 11.544 14.7413 12.3333 13.44 12.888C12.16 13.4427 10.5707 13.72 8.672 13.72H5.984V23H3.104ZM0.32 18.648V16.568H11.264V18.648H0.32ZM0.32 13.72V11.288H7.648V13.72H0.32ZM8.256 11.288C9.55733 11.288 10.656 11.1493 11.552 10.872C12.4693 10.5947 13.1733 10.136 13.664 9.496C14.1547 8.856 14.4 7.98133 14.4 6.872C14.4 5.42133 13.952 4.344 13.056 3.64C12.16 2.936 10.7627 2.584 8.864 2.584H5.984V11.288H8.256Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default RUBSymbol;
