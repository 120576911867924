import React from "react";

const TransferGifs = () => {
  return (
    <div className="flex flex-col items-center">
      <img
        src="/assets/gifs/transactionDark.gif"
        alt="arrows"
        className="hidden dark:block w-5 h-10"
      />
      <img
        src="/assets/gifs/transactionLight.gif"
        alt="arrows"
        className="block dark:hidden w-5 h-10"
      />
    </div>
  );
};

export default TransferGifs;
