import React, { useState } from "react";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import SignaturePad from "./partials/signaturePad";
import InputFile from "../../InputFile";
import OptionButton from "../../Common/OptionButton";
import ErrorMessage from "../../Common/ErrorMessage";

const Signature = ({
  handlePrev,
  setFormData,
  formData,
  onChange,
  handleLiveness,
}) => {
  const [option, setOption] = useState("upload");
  const [error, setError] = useState("");

  const handleContinue = async () => {
    if (!formData.signature_photo) {
      setError("Please upload or draw your signature");
      return;
    }
    handleLiveness();
  };

  const handleClearSignature = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      signature_photo: null,
    }));
    setError("");
  };

  return (
    <div className="space-y-4 md:w-[384px]">
      {error && <ErrorMessage message={error} onClose={() => setError("")} />}
      <div className="text-body-text text-sm font-medium tracking-tight">
        Signature details
      </div>
      <div className="flex items-center justify-between w-full space-x-3">
        <OptionButton
          lable={"Upload Signature"}
          value={"upload"}
          option={option}
          setOption={setOption}
        />
        <OptionButton
          lable={"Draw Signature"}
          value={"draw"}
          option={option}
          setOption={setOption}
        />
      </div>

      <div className={option === "draw" ? "block" : "hidden"}>
        <SignaturePad
          setError={setError}
          setFormData={setFormData}
          handleClearSignature={handleClearSignature}
        />
      </div>

      <div className={option === "upload" ? "block" : "hidden"}>
        <p className="text-paragraph-text text-xs font-normal tracking-tight py-3">
          Upload or choose a image of your signature
        </p>

        <InputFile
          name="signature_photo"
          id="signature_photo"
          placeholder="Upload or choose a image of your signature"
          onClear={handleClearSignature}
          onChange={onChange}
          text="Upload Signature Image"
          supportingDocument={formData.signature_photo}
        />
        <div className="py-3">
          <hr className="border border-divider-border" />
        </div>
      </div>

      <div className="flex md:w-96 w-full space-x-4">
        <NavigationButton onClick={handlePrev} text="Back" />
        <PrimaryButton onClick={handleContinue} text="Continue" />
      </div>
    </div>
  );
};

export default Signature;
