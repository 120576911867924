import React, { useState } from "react";
import Modal from "../../Common/Modal";
import Dropdown from "../../Dropdown";
import { useDispatch } from "react-redux";
import FormTextAreaField from "../../FormTextAreaField";
import CommonButton from "../../Buttons/CommonButton";
import useForm from "../../../hooks/useForm";
import { SubmitHelpReasons } from "../../../redux/actions/help/SubmitHelp";
import SuccessMessage from "../../Common/SuccessMessage";
import HangTightLoading from "../../Common/HangTightLoading";
import useHelpReasons from "../../../hooks/useHelpReasons";

const HelpModal = ({ HelpModalOpen, setHelpModalOpen, children }) => {
  const { helpReasons } = useHelpReasons();
  const [isLoading, setisLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const selectHelpReason = useForm("");
  const description = useForm("");
  const dispatch = useDispatch();

  const handleSubmitHelp = async () => {
    setisLoading(true);
    let data = {
      reason: selectHelpReason.value,
      description: description.value,
    };
    const res = await dispatch(SubmitHelpReasons(data));
    if (res.success) {
      setSuccess(true);
      selectHelpReason.reset();
      description.reset();
    }
    setisLoading(false);
  };

  const handleCloseButton = () => {
    setHelpModalOpen(false);
    setTimeout(() => {
      setSuccess(false);
    }, 300);
  };

  return (
    <>
      <div onClick={() => setHelpModalOpen(true)}>{children}</div>
      <Modal
        isOpen={HelpModalOpen}
        handleOnCloseModal={() => setHelpModalOpen(false)}
        header={!isLoading && !success}
        headerTitle={"Help"}
        loading={isLoading}
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl md:pt-2"
      >
        {!isLoading && !success && (
          <div className="px-6 max-[360px]:px-4 pb-3 max-[360px]:pt-1 space-y-4">
            <Dropdown
              id="help-dropdown"
              name="help-dropdown"
              placeholder="Select a reason for your inquiry"
              label="Reason for contact"
              options={helpReasons}
              onChange={selectHelpReason.onChange}
              value={selectHelpReason.value}
            />
            <FormTextAreaField
              label="Additional comments"
              name="description"
              id="description"
              rows="5"
              cols="33"
              placeholder="Add any additional details or questions here..."
              value={description.value}
              onChange={description.onChange}
            />
            <CommonButton
              onClick={handleSubmitHelp}
              disabled={!description.value || !selectHelpReason.value}
            >
              Submit
            </CommonButton>
          </div>
        )}
        {isLoading && !success && (
          <HangTightLoading message="Your inquiry is submitting" />
        )}
        {success && !isLoading && (
          <div className="p-6 space-y-4">
            <SuccessMessage
              title="Your service request sent"
              description="Your query has been sent successfully. One of our agent will respond to your query shortly."
              handleCloseButton={handleCloseButton}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default HelpModal;
