import React from "react";
import AccountDropdown from "../../Common/AccountDropdown";
import FilterButton from "./partials/FilterButton";
import DateSelector from "../../DateSelector";
import CommonButton from "../../Buttons/CommonButton";
import SelectedAccountModal from "../../Modals/SelectAccount/SelectAccountModal";
import { NavigationButton, SmallButton } from "../../Buttons";

const SideNav = ({
  transactionStatus,
  setTransactionStatus,
  activeAccount,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  fetchTransactions,
  period,
  setPeriod,
  resetFilters,
  loading,
  accounts,
  type = "fiat",
}) => {
  return (
    <div className="md:h-[670px] md:p-4 p-3 w-full bg-primarycard-gradient rounded-3xl flex-col justify-start gap-6 inline-flex">
      <div className="space-y-4">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Select account
        </p>

        <div className="gap-4">
          <SelectedAccountModal
            activeAccount={activeAccount}
            clientAccounts={accounts}
          >
            <AccountDropdown activeAccount={activeAccount} />
          </SelectedAccountModal>
        </div>
      </div>

      <div className="space-y-4">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Select Period
        </p>

        <div className="grid grid-cols-3 gap-3 auto-rows-max md:pr-6">
          <FilterButton
            value={"1"}
            selected={period}
            setValue={setPeriod}
            title="Last Month"
          />
          <FilterButton
            value={"3"}
            selected={period}
            setValue={setPeriod}
            title="3 Months"
          />
          <FilterButton
            value={"6"}
            selected={period}
            setValue={setPeriod}
            title="6 Months"
          />
          <FilterButton
            value={"12"}
            selected={period}
            setValue={setPeriod}
            title="Last Year"
          />
        </div>
      </div>

      <div className="space-y-4 hidden md:block">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Transaction status
        </p>
        <div className="grid grid-cols-3 gap-3 auto-rows-max pr-10">
          <FilterButton
            selected={transactionStatus}
            value={"pending"}
            title="Pending"
            setValue={setTransactionStatus}
          />
          <FilterButton
            selected={transactionStatus}
            value={"approved"}
            title="Approved"
            setValue={setTransactionStatus}
          />
          <FilterButton
            selected={transactionStatus}
            value={"Rejected"}
            title="Rejected"
            setValue={setTransactionStatus}
          />
          {
            (type === "fiat" && (
              <FilterButton
                selected={transactionStatus}
                value={"initial review"}
                title="Initial"
                setValue={setTransactionStatus}
              />
            ))
          }

          {transactionStatus !== "" && (
            <SmallButton
              onClick={() => {
                setTransactionStatus("");
                resetFilters();
              }}
              cancel={true}
              text="Reset"
            />
          )}
        </div>
      </div>

      <div className="space-y-4">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Custom date
        </p>
        <div className="flex flex-col space-y-3">
          <DateSelector
            text="From"
            name="from"
            id="from"
            placeholder="Select from date"
            onChange={(e) => {
              setFromDate(e.target.value);
            }}
            selectedDate={fromDate}
            disableFutureDates={true}
          />

          <DateSelector
            text="To"
            name="to"
            id="to"
            placeholder="Select to date"
            onChange={(e) => {
              setToDate(e.target.value);
            }}
            selectedDate={toDate}
            disableFutureDates={true}
          />
        </div>
      </div>

      <div className="flex justify-between w-full md:space-x-28 space-x-20">
        <NavigationButton
          text="Reset Filters"
          onClick={resetFilters}
          disabled={fromDate || toDate || period ? false : true}
        />

        <CommonButton
          onClick={fetchTransactions}
          disabled={!fromDate || !toDate || period || loading}
        >
          Apply Filters
        </CommonButton>
      </div>
    </div>
  );
};

export default SideNav;
