import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const GetDepositAddress = (data) => async (dispatch) => {
  try {
    const res = await commonService.post(
      "/client/crypto-transaction-deposit/bitpace/get-address-data",
      data
    );
    const address = res.data;

    return address;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  }
};

export const GetDepositAddressQRCode = (data) => async (dispatch) => {
  try {
    const QRCode = await commonService.post("/client/crypto/create-qr-code", data );

    return QRCode.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  }
};
