import React, { useState } from "react";
import { DropdownCrossIcon, FileIcon, UploadIcon } from "../Icons";

const InputFile = ({
  name,
  id,
  onChange,
  supportingDocument,
  onClear,
  text,
  placeholder,
  error,
}) => {
  const [file, setFile] = useState(supportingDocument);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (onChange) {
      onChange(e);
    }
  };

  const clearFile = () => {
    setFile(null);
    document.getElementById(id).value = null;
    if (onClear) {
      onClear();
    }
  };

  return (
    <div
      className={`relative w-full px-3 py-2 rounded-xl border ${
        file ? "border-gray-500" : "border-divider-border"
      } ${
        error
          ? "border-highlighter-red bg-highlighter-red/10 focus-within:border-highlighter-red focus-within:bg-highlighter-red/10"
          : "bg-input-bg focus-within:border-primary-item"
      } shadow-sm`}
    >
      <div
        className={`flex items-center w-full ${
          file
            ? "bg-secondary-icon-bg rounded-lg px-2 py-2 h-10"
            : "bg-transparent"
        }`}
      >
        <div className="flex-shrink-0">
          {file ? <FileIcon /> : <UploadIcon />}
        </div>
        <label
          htmlFor={id}
          className={`line-clamp-1 w-full items-center justify-between pl-2 text-xs font-medium text-primary-item cursor-pointer 

          `}
        >
          {file ? file.name : text}
        </label>
      </div>
      {!file && (
        <p className="font-normal flex items-center ml-7 mt-1 text-xs text-paragraph-text">
          {placeholder}
        </p>
      )}
      <input
        type="file"
        name={name}
        id={id}
        onChange={handleFileChange}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
        title=""
      />
      {file && (
        <div
          className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
          onClick={clearFile}
        >
          <DropdownCrossIcon />
        </div>
      )}
      {error && <span className="text-highlighter-red text-sm">{error}</span>}
    </div>
  );
};

export default InputFile;
