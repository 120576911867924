import { useEffect, useRef, useState } from "react";
import Label from "../Label";
import Input from "../Input";
import DownArrowIcon from "../Icons/DownArrowIcon";
import MyCalendar from "../Calendar";
import { scrollIntoViewWithEase } from "../../helper/ScrollHelper";

const DateSelector = ({
  text,
  name,
  id,
  placeholder,
  onChange,
  selectedDate,
  disableFutureDates,
  scrollInModal,
  error,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);
  const containerRef = useRef(null); // Ref for the entire component container

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (scrollInModal && showCalendar && containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else if (showCalendar && containerRef.current) {
      scrollIntoViewWithEase(containerRef.current);
    }
  }, [showCalendar, scrollInModal]);

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleOnChange = (date) => {
    const formattedDate = formatDate(date);
    setShowCalendar(false);
    onChange({ target: { value: formattedDate, name } });
  };

  useEffect(() => {
    if (selectedDate && selectedDate !== "Invalid date") {
      try {
        const parsedDate = parseDate(selectedDate);
        if (isNaN(parsedDate)) {
          onChange({ target: { value: "", name } });
        }
      } catch (e) {
        onChange({ target: { value: "", name } });
      }
    }
  }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className={`relative rounded-xl cursor-pointer  py-2 px-4 border
      ${
        error
          ? "border-highlighter-red bg-highlighter-red/10 focus-within:border-highlighter-red focus-within:bg-highlighter-red/10"
          : "border-divider-border bg-input-bg focus-within:border-primary-item"
      }
        `}
        onClick={toggleCalendar}
      >
        <Label
          htmlFor={name}
          id={id}
          className="block text-body-text font-medium text-xs"
          text={text}
        />
        <div className="relative">
          <Input
            type="text"
            name={name}
            id={id}
            className="block w-full border-0 p-0 text-paragraph-text font-normal text-sm focus:ring-0 rounded-md bg-transparent hover:cursor-pointer"
            placeholder={!selectedDate ? placeholder : ""}
            value={selectedDate ? selectedDate : ""}
            readOnly
          />
          <div className="absolute inset-y-0 -right-4 -top-3 flex items-center pr-3">
            <DownArrowIcon />
          </div>
        </div>
        {showCalendar && (
          <div
            ref={containerRef}
            className="absolute top-full mt-1 left-0 z-50 pb-5"
          >
            <MyCalendar
              disableFutureDates={disableFutureDates}
              onChange={handleOnChange}
              value={selectedDate ? parseDate(selectedDate) : null}
            />
          </div>
        )}
      </div>
      {error && <span className="text-highlighter-red text-sm">{error}</span>}
    </>
  );
};

export default DateSelector;
