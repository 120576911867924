import React from "react";
import { PendingIcon } from "../../Icons";
import CommonButton from "../../Buttons/CommonButton";

const KYCRequiredCard = ({ handleKYCRedirection }) => {
  return (
    <div className="bg-primarycard-gradient shadow-lg px-4 py-12 rounded-3xl max-w-lg w-full">
      <div
        className={`text-3xl md:text-4xl font-bold mb-4 flex flex-col items-center justify-center text-center text-primary-item`}
      >
        <PendingIcon className="w-28 h-28 my-4" />
        <h3>KYC Required</h3>
      </div>
      <p className="text-lg text-body-text md:text-xl text-center">
        Your account is incomplete please perform liveness check.
      </p>
      <div className="mt-6 flex justify-center">
        <CommonButton onClick={handleKYCRedirection}>
          Perform Liveness Check
        </CommonButton>
      </div>
    </div>
  );
};

export default KYCRequiredCard;
