import React from "react";
import FilterButton from "../../../AllTransactions/SideNav/partials/FilterButton";
import { useSelector } from "react-redux";
import DateSelector from "../../../DateSelector";
import CommonButton from "../../../Buttons/CommonButton";
import SelectCardModal from "../../../Modals/Card/SelectCardModal";
import CardDropdown from "../../../Common/CardDropdown";
import { NavigationButton } from "../../../Buttons";

const CardTopupNav = ({
  clientCards,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  fetchTopups,
  period,
  setPeriod,
  resetFilters,
  loading,
}) => {
  const { activeCard } = useSelector((state) => state.activeCard);
  return (
    <div className="md:w-[404px] md:h-[670px] p-4 bg-primarycard-gradient rounded-3xl flex-col justify-start gap-6 inline-flex">
      <div className="space-y-4">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Select cards
        </p>

        <div className="gap-4">
          <div className="gap-4">
            <SelectCardModal activeCard={activeCard} clientCards={clientCards}>
              <CardDropdown activeCard={activeCard} />
            </SelectCardModal>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Select Period
        </p>

        <div className="grid grid-cols-3 gap-3 auto-rows-max md:pr-6">
          <FilterButton
            value={"1"}
            selected={period}
            setValue={setPeriod}
            title="Last Month"
          />
          <FilterButton
            value={"3"}
            selected={period}
            setValue={setPeriod}
            title="3 Months"
          />
          <FilterButton
            value={"6"}
            selected={period}
            setValue={setPeriod}
            title="6 Months"
          />
          <FilterButton
            value={"12"}
            selected={period}
            setValue={setPeriod}
            title="Last Year"
          />
        </div>
      </div>

      <div className="space-y-4">
        <p className="text-body-text text-sm font-medium tracking-tight">
          Custom date
        </p>
        <div className="flex flex-col space-y-3">
          <DateSelector
            text="From"
            name="from"
            id="from"
            placeholder="Select from date"
            onChange={(e) => {
              setFromDate(e.target.value);
              period && setPeriod(0);
            }}
            selectedDate={fromDate}
            disableFutureDates={true}
          />

          <DateSelector
            text="To"
            name="to"
            id="to"
            placeholder="Select to date"
            onChange={(e) => {
              setToDate(e.target.value);
              period && setPeriod(0);
            }}
            selectedDate={toDate}
            disableFutureDates={true}
          />
        </div>
      </div>

      <div className="flex justify-between w-full md:space-x-28 space-x-20">
        <NavigationButton
          text="Reset Filters"
          onClick={resetFilters}
          disabled={fromDate || toDate || period ? false : true}
        />

        <CommonButton
          onClick={fetchTopups}
          disabled={!fromDate || !toDate || period || loading}
        >
          Apply Filters
        </CommonButton>
      </div>
    </div>
  );
};

export default CardTopupNav;
