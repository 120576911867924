import React, { useState, useEffect, useRef } from "react";
import SmallButton from "../../components/Buttons/SmallButton";
import { useDispatch, useSelector } from "react-redux";
import { getCardStatement } from "../../redux/actions/Cards/getCardStatement";
import CardStatementTable from "../../components/Cards/CardStatement/CardStatementTable";
import CardStatementSideNav from "../../components/Cards/CardStatement/SideNav";
import ViewRecentTransaction from "../../components/RecentTranactions/partials/ViewRecentTransaction";
import CustomTransition from "../../components/CustomTransition";
import CardStatmentModal from "../../components/Modals/Card/CardStatmentModal";
import { PrimaryButton } from "../../components/Buttons";
import { DownloadIcon } from "../../components/Icons";
import ReccentTransactionSideBar from "../../components/Common/RecentTransactionsSideBar";
import TransactionsFiltersSidebar from "../../components/Modals/TransactionsFiltersSidebar";
import SelectCardModal from "../../components/Modals/Card/SelectCardModal";
import CardDropdown from "../../components/Common/CardDropdown";
import { setPageTitle } from "../../helper/metaDataHelper";

const CardStatement = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { activeCard } = useSelector((state) => state.activeCard);
  const clientCards = useSelector((state) => state.clientCards.clientCards);
  const [ShowTransaction, setShowTransaction] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [period, setPeriod] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [loadMore, setLoadMore] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const loadMoreTransactions = async (nextPage) => {
    try {
      setLoadMore(true);
      let response = {};
      let payload = {
        card_id: activeCard.id,
        from_date: fromDate,
        to_date: toDate,
        period: period ? period : null,
        limit: 10,
        page: nextPage,
      };
      response = await dispatch(getCardStatement(payload));
      const allTransactions = response.data.card_statement;
      const currentPage = response.data.pagination.current_page;
      const lastPage = response.data.pagination.last_page;

      if (allTransactions.length === 0 || currentPage >= lastPage) {
        setHasMore(false);
      } else if (currentPage === nextPage) {
        setTransactions((prev) => [...prev, ...allTransactions]);
      }
      setLoadMore(false);
    } catch (error) {
      console.error(error);
      setLoadMore(false);
    }
  };

  const fetchCardStatement = async () => {
    try {
      let payload = {
        card_id: activeCard.id,
        from_date: fromDate,
        to_date: toDate,
        period: period ? period : null,
        limit: 10,
      };
      setIsLoading(true);
      const res = await dispatch(getCardStatement(payload));
      if (res.success === true) {
        let cardStatement = res.data.card_statement;
        setTransactions(cardStatement);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPageTitle("Card Statement");
  }, []);

  useEffect(() => {
    if (period !== "") {
      setFromDate("");
      setToDate("");
      fetchCardStatement();
    }
    // eslint-disable-next-line
  }, [period]);

  useEffect(() => {
    fetchCardStatement();
    // eslint-disable-next-line
  }, [dispatch, activeCard.id]);

  const resetFilters = () => {
    setFromDate("");
    setToDate("");
    setPeriod(0);
  };

  const handleSelectTransaction = (transaction) => {
    setActiveTransaction(transaction);
    setShowTransaction(true);
  };
  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 min-h-screen">
        <div className="flex flex-col md:w-[82px]">
          <SmallButton href={"/card"} text="Back" back={true} />
        </div>

        <div className="flex md:flex-row flex-col md:items-center md:w-[572px] w-full justify-between py-2 md:py-4">
          <h1 className="text-2xl font-semibold text-body-text">
            Cards Statement
          </h1>
          <div className="flex space-x-3 py-3">
            <CardStatmentModal activeCard={activeCard}>
              <div className="md:block hidden">
                <PrimaryButton
                  text="Statement Download"
                  icon={<DownloadIcon />}
                />
              </div>
              <div className="md:hidden block">
                <PrimaryButton text="Statement" icon={<DownloadIcon />} />
              </div>
            </CardStatmentModal>

            <div className="md:hidden block">
              <TransactionsFiltersSidebar
                activeAccount={activeCard}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                fetchTransactions={fetchCardStatement}
                period={period}
                setPeriod={setPeriod}
                resetFilters={resetFilters}
              >
                <div className="space-y-4">
                  <p className="text-body-text text-sm font-medium tracking-tight">
                    Select card
                  </p>

                  <div className="gap-4">
                    <SelectCardModal activeCard={activeCard} clientCards={clientCards}>
                      <CardDropdown activeCard={activeCard} />
                    </SelectCardModal>
                  </div>
                </div>
              </TransactionsFiltersSidebar>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-3">
          {/* First sections */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <CardStatementTable
              transactions={transactions}
              handleSelectTransaction={handleSelectTransaction}
              activeTransaction={activeTransaction}
              isLoading={isLoading}
              scrollRef={scrollRef}
              loadMoreTransactions={loadMoreTransactions}
              loadMore={loadMore}
              hasMore={hasMore}
              setPage={setPage}
              page={page}
              loading={loadMore}
              type={"card"}
            />
          </div>
          {/* Second sections */}
          <div className="xl:w-[404px] w-[350px] hidden md:block">
            <>
              <CustomTransition show={ShowTransaction}>
                {ShowTransaction && (
                  <>
                    <div className="bg-primarycard-gradient rounded-3xl p-6 space-y-4 h-[644px]">
                      <ViewRecentTransaction
                        transaction={activeTransaction}
                        setShowRecentTransaction={setShowTransaction}
                        ShowTransaction={ShowTransaction}
                        setActiveTransaction={setActiveTransaction}
                        type="card"
                      />
                    </div>
                  </>
                )}
              </CustomTransition>

              {/* SideBar */}

              <CustomTransition show={!ShowTransaction}>
                {!ShowTransaction && (
                  <CardStatementSideNav
                    activeCard={activeCard}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    period={period}
                    setPeriod={setPeriod}
                    resetFilters={resetFilters}
                    fetchCardStatement={fetchCardStatement}
                    loading={isLoading}
                  />
                )}
              </CustomTransition>
            </>
          </div>
        </div>

        <ReccentTransactionSideBar
          activeTransaction={activeTransaction}
          ShowRecentTransaction={ShowTransaction}
          setShowRecentTransaction={setShowTransaction}
          setActiveTransaction={setActiveTransaction}
        />
      </div>
    </main>
  );
};

export default CardStatement;
