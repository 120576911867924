import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClientAccounts } from "../../redux/actions/home/ClientInfoAction";

const useClientAccounts = () => {
  const [clientAllAccounts, setClientAllAccounts] = useState(null);
  const dispatch = useDispatch();
  const clientAccounts = useSelector(
    (state) => state.client?.clientInfo?.accounts
  );
  
  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const data = await dispatch(getClientAccounts());
        setClientAllAccounts(data);
      } catch (error) {
        console.error("Error fetching client accounts:", error);
      }
    };

    if (!clientAccounts) {
      fetchCompanyData();
    } else {
      setClientAllAccounts(clientAccounts);
    }
  }, [clientAccounts, dispatch]);

  return clientAllAccounts;
};

export default useClientAccounts;
