import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getHelpReasons } from "../../redux/actions/help/getHelpReasons";

const useHelpReasons = () => {
  const [helpReasons, setHelpReasons] = useState([]);
  const dispatch = useDispatch();
  const { clientHelpReasons } = useSelector((state) => state.clientHelpReasons);

  const getHelpReason = async () => {
    try {
      // Fetch data
      const Reasons = await dispatch(getHelpReasons());
      setHelpReasons(Reasons);
    } catch (error) {
      console.error("Error in useEffect:", error);
    }
  };

  useEffect(() => {
    if (!clientHelpReasons || clientHelpReasons.length === 0) {
      getHelpReason();
    } else {
      setHelpReasons(clientHelpReasons);
    }

    // eslint-disable-next-line
  }, [clientHelpReasons]);

  return {
    helpReasons,
    getHelpReason,
  };
};

export default useHelpReasons;
