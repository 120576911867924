import React from "react";

const Label = ({ htmlFor, id, className, text }) => {
  return (
    <label htmlFor={htmlFor} id={id} className={className}>
      {text}
    </label>
  );
};
export default Label;
