import React, { useState } from "react";
import Modal from "../../../Common/Modal";
import SelectAccountList from "../../../Common/SelectAccountList";
import AddNewAccountSkeleton from "../../../Skeleton/AddNewAccountSkeleton";
import useClientProfiles from "../../../../hooks/useClientProfiles";

const SelectCloseAccountModal = ({
  children,
  account,
  setAccount,
  setErrorMessage,
}) => {
  const { clientProfiles } = useClientProfiles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleSelectAccount = () => {
    const selectedAccount = clientProfiles.find(
      (acc) => acc.id === account.id
    );
    setAccount(selectedAccount);
    setErrorMessage("");
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={closeModal}
        header={true}
        headerTitle={"Choose your account"}
        className="bg-popup-bg w-[432px] max-h-[578px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        <div className="px-6 pb-4">
          {/* {clientProfiles.length ? ( */}
          {clientProfiles.length ? (
            <SelectAccountList
              clientsAccounts={clientProfiles}
              CloseAddAcccount={account}
              setCloseAddAccount={setAccount}
              handleSelectAccount={handleSelectAccount}
            />
          ) : (
            <AddNewAccountSkeleton />
          )}
        </div>
      </Modal>
    </>
  );
};

export default SelectCloseAccountModal;
