import React from "react";
import Skeleton from "../../Common/Skeleton";

const OtherAccountsSkeletons = () => {
  return (
    <div className="space-y-10">
      <Skeleton className="rounded-full h-6 w-[95px] pt-8" />
      <div className="flex flex-col space-y-[61px] pb-12">
        {[1, 2, 3].map((item) => (
          <div key={item} className="flex w-full items-center justify-between">
            <div className="flex items-center px-5">
              <Skeleton className="dark:block hidden rounded-full h-10 w-10 mx-2" />
              <img
                src="assets/gifs/loader.gif"
                className="w-11 h-11 rounded-full mr-3 block dark:hidden"
                alt="loader"
              />
              <span className="flex flex-col space-y-1">
                <Skeleton className="rounded-full w-[146px] h-[16px]" />
                <Skeleton className="rounded-full w-[135px] h-[16px]" />
              </span>
            </div>
            <Skeleton className="rounded-full w-[126px] h-[16px]" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherAccountsSkeletons;
