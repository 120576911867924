import React, { useState } from "react";
import Modal from "../../Common/Modal";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import { useNavigate } from "react-router-dom";

const CancelTransactionModal = ({
  children,
  contextLabel,
  navigateTo = "/",
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCancelTransaction = () => {
    setIsModalOpen(false);
    navigate(navigateTo);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={closeModal}
        header={true}
        headerTitle={contextLabel}
        className="bg-popup-bg w-full max-w-[432px] max-h-[232px] py-3 overflow-scroll hide-scrollbar shadow-xl rounded-3xl mx-auto"
      >
        <div className="flex flex-col px-6 py-4 space-y-4 ">
          <h3 className="text-sm font-normal text-body-text">
            Would you like to cancel the transaction? This action cannot be
            undone.
          </h3>
          <div className="flex justify-start space-x-4">
            <NavigationButton text="Close" onClick={closeModal} />
            <PrimaryButton
              text={contextLabel}
              onClick={handleCancelTransaction}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CancelTransactionModal;
