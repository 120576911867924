import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../Common/Modal";
import Label from "../../Label";
import AccountDropdown from "../../Common/AccountDropdown";
import { DownloadIcon } from "../../Icons";
import Dropdown from "../../Dropdown";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { fetchAccountStatement } from "../../../redux/actions/account-statement";
import HangTightLoading from "../../Common/HangTightLoading";
import SelectedAccountModal from "../SelectAccount/SelectAccountModal";
import DateSelector from "../../DateSelector";
import CustomTransition from "../../CustomTransition";
import ErrorMessage from "../../Common/ErrorMessage";

const AccountStatementModal = ({ children }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const { activeAccount } = useSelector((state) => state.activeAccount);
  const clientAccounts = useSelector((state) => state.client.clientInfo);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    const accounts = clientAccounts.accounts.filter(
      (account) =>
        Boolean(account.is_crypto) === Boolean(activeAccount.is_crypto)
    );
    setFilteredAccounts(accounts);
    // eslint-disable-next-line
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setFromDate("");
    setToDate("");
    setSelectedPeriod("");
    setError("");
  };

  const handledDownloadStatement = async () => {
    setIsLoading(true);
    let data = {};

    if (selectedPeriod === "range") {
      data = {
        account_id: activeAccount.id,
        from_date: fromDate,
        to_date: toDate,
      };
    } else {
      data = {
        account_id: activeAccount.id,
        period: selectedPeriod,
      };
    }

    const response = await dispatch(fetchAccountStatement(data));
    if (response.status === 200 && response.data) {
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const fileName = `${
        activeAccount.account_number
      }_${new Date().toLocaleDateString()}.pdf`;

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      closeModal();
    } else {
      setError("No statement found for this account.");
    }
    setIsLoading(false);
  };

  const handlePeriodChange = (e) => {
    setSelectedPeriod(e.target.value);
    if (error) {
      setError("");
    }
    if (e.target.value !== "range") {
      setFromDate("");
      setToDate("");
    }
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={closeModal}
        header={!isLoading}
        headerTitle={"Statement"}
        className="bg-popup-bg w-full max-w-[432px] pb-4 overflow-scroll hide-scrollbar shadow-box-shadow rounded-3xl mx-auto"
        loading={isLoading}
      >
        {!isLoading ? (
          <>
            {error && (
              <div className="px-6 max-[360px]:px-4">
                <ErrorMessage message={error} onClose={() => setError("")} />
              </div>
            )}
            <div className="flex flex-col px-6 max-[360px]:px-4 space-y-4">
              <div>
                <Label
                  className="block text-sm font-medium text-body-text mb-3"
                  htmlFor="account"
                  text="From account"
                />
                {/* Todo: Update this modal, after "feature/crypto_transactions baranch merges" */}
                <SelectedAccountModal
                  activeAccount={activeAccount}
                  clientAccounts={filteredAccounts}
                >
                  <AccountDropdown activeAccount={activeAccount} />
                </SelectedAccountModal>
              </div>
              <div>
                <Dropdown
                  id="period"
                  name="period"
                  placeholder="Select statement period"
                  label="Select Period"
                  options={[
                    { value: "1", label: "Last month" },
                    { value: "3", label: "3 months" },
                    { value: "6", label: "6 months" },
                    { value: "12", label: "Last year" },
                    { value: "range", label: "Custom Date Range" },
                  ]}
                  onChange={handlePeriodChange}
                  value={selectedPeriod}
                />
              </div>

              <CustomTransition show={selectedPeriod === "range"}>
                <div className="flex flex-col space-y-3">
                  <DateSelector
                    text="From"
                    name="from"
                    id="from"
                    placeholder="Select from date"
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    selectedDate={fromDate}
                    disableFutureDates={true}
                    scrollInModal={true}
                  />

                  <DateSelector
                    text="To"
                    name="to"
                    id="to"
                    placeholder="Select to date"
                    onChange={(e) => {
                      setToDate(e.target.value);
                    }}
                    selectedDate={toDate}
                    disableFutureDates={true}
                    scrollInModal={true}
                  />
                </div>
              </CustomTransition>
            </div>

            <div className="flex items-center justify-center w-full mt-8 px-4 py-3">
              <PrimaryButton
                text="Download statement"
                icon={<DownloadIcon />}
                disabled={selectedPeriod === "range" ?  !fromDate || !toDate : !selectedPeriod}
                onClick={handledDownloadStatement}
              />
            </div>
          </>
        ) : isLoading ? (
          <HangTightLoading message="Downloading account statement..." />
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default AccountStatementModal;
