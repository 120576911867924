import React, { useEffect, useState } from "react";
import SmallButton from "../../components/Buttons/SmallButton";
import { SearchIcons } from "../../components/Icons";
import { CardButton } from "../../components/Card";
import BeneficiaryButton from "../../components/Buttons/BeneficiaryButton";
import { useDispatch } from "react-redux";
import { listBeneficiary } from "../../redux/actions/TRrequest/Beneficiary";
import ShowBeneficiaryModal from "../../components/Modals/Beneficiary/ShowBeneficiaryModal";
import AddBeneficiaryModal from "../../components/Modals/Beneficiary/AddBeneficiaryModal";
import EmptyBeneficiary from "../../components/Empty/EmptyBeneficiary";
import { BeneficiarySkeleton } from "../../components/Skeleton";
import { setPageTitle } from "../../helper/metaDataHelper";

const ManageBeneficiaries = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectBeneficiary, setSelectBeneficiary] = useState({});
  const [addBeneficiaryModalOpen, setaddBeneficiaryModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isBeneficiaryEmpty, setIsBeneficiaryEmpty] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setPageTitle("Manage Beneficiaries");
  }, []);

  useEffect(() => {
    const GetBeneficiaries = async () => {
      try {
        // Fetch data
        setIsLoading(true);
        const res = await dispatch(listBeneficiary());
        if (res.length) {
          setBeneficiaries(res.reverse());
          setIsBeneficiaryEmpty(false);
        } else {
          setBeneficiaries([]);
          setIsBeneficiaryEmpty(true);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error in Manage Beneficiary useEffect:", error);
      }
    };
    GetBeneficiaries();
  }, [addBeneficiaryModalOpen, dispatch]);

  const filteredBeneficiaries = beneficiaries.filter(
    (account) =>
      account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      account.account_number
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      account.swift_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 min-h-screen">
        <div className="flex flex-col sm:flex-row justify-center gap-3">
          {/* First sections */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <SmallButton cancel={true} text={"Close"} href={"/"} />
            <div className="text-body-text text-3xl font-semibold tracking-tight">
              Manage beneficiaries
            </div>

            <div className="flex items-center justify-center ">
              {!isBeneficiaryEmpty && <div className="w-[572px]">
                {/* Search input */}
                <div className="px-3 py-1 bg-secondary-icon-bg dark:bg-[#35322A] rounded-full flex justify-start items-center">
                  <SearchIcons className="w-6 h-6 relative" />
                  <input
                    onChange={(e) => setSearchQuery(e.target.value)}
                    qurey={searchQuery}
                    placeholder="Search"
                    className="w-full text-paragraph-text text-sm font-normal bg-transparent border-none focus:ring-0 leading-tight tracking-tight"
                  />
                </div>
                <AddBeneficiaryModal
                  addBeneficiaryModalOpen={addBeneficiaryModalOpen}
                  setaddBeneficiaryModalOpen={setaddBeneficiaryModalOpen}
                >
                  <CardButton
                    title={"Add new beneficiary"}
                    subtitle={"Add new beneficiary to send money"}
                  />
                </AddBeneficiaryModal>

                <hr className="my-3 border border-divider-border" />

                {isLoading ? (
                  <BeneficiarySkeleton />
                ) : filteredBeneficiaries.length ? (
                  filteredBeneficiaries.map((beneficiary) => (
                    <div
                      key={beneficiary.id}
                      onClick={() => {
                        setSelectBeneficiary(beneficiary);
                        setIsModalOpen(true);
                      }}
                    >
                      <BeneficiaryButton
                        beneficiaryName={beneficiary.name}
                        accountNumber={beneficiary.account_number}
                        swiftCode={beneficiary.swift_code}
                      />
                    </div>
                  ))
                ) : (
                  <div className="text-sm font-medium text-body-text">
                    <p>
                      No beneficiary with this <b>{searchQuery}</b> name.
                    </p>
                  </div>
                )}

                <ShowBeneficiaryModal
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  beneficiary={selectBeneficiary}
                />
              </div>}
              {isBeneficiaryEmpty && (
                <EmptyBeneficiary
                  addBeneficiaryModalOpen={addBeneficiaryModalOpen}
                  setaddBeneficiaryModalOpen={setaddBeneficiaryModalOpen}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ManageBeneficiaries;
