import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { ClientProfilesActionTypes, CommonActionTypes } from "../../../action-types";

export const ClientProfile = () => async (dispatch) => {
  try {
    const res = await commonService.get("/client/profiles");
    
    dispatch({
      type: ClientProfilesActionTypes.GET_ALL_CLIENT_PROFILES,
      payload: res.data.data,
    });

    localStorage.setItem("clientProfiles", JSON.stringify(res.data.data));

    return res.data.data;
  } catch (error) {
    const message = error.response.data.message || error.message;

    ResponseErrorHandling(error.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
