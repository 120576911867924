import React, { useState } from "react";
import { SmallButton } from "../../Buttons";
import { EmptyCurrencyListIcons } from "../../Icons";
import HelpModal from "../../Modals/HelpModal";

const EmptyCurrencyList = () => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  return (
    <div className="flex flex-col items-center justify-center space-y-4 my-14">
      <EmptyCurrencyListIcons className="w-40 h-40" />
      <span className="font-medium text-body-text text-xl px-4 text-center">
        Maximum currency accounts reached
      </span>
      <span className="font-medium text-paragraph-text text-sm px-6 text-center">
        Oops! It looks like you have already added all the available currency accounts.
      </span>
      <HelpModal
        HelpModalOpen={helpModalOpen}
        setHelpModalOpen={setHelpModalOpen}
      >
        <SmallButton text="Get help" />
      </HelpModal>
    </div>
  );
};

export default EmptyCurrencyList;
