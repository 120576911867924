import React from "react";

const ApprovedSign = ({ className }) => {
  return (
    <div className={className ? className : "w-4 h-4"}>
      <svg
        // width="16"
        // height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Icon/Transfer-approved-ico">
          <path
            id="Vector"
            d="M13.7251 3.75257C13.6457 3.67254 13.5513 3.60902 13.4472 3.56567C13.3432 3.52232 13.2316 3.5 13.1189 3.5C13.0062 3.5 12.8946 3.52232 12.7905 3.56567C12.6865 3.60902 12.592 3.67254 12.5126 3.75257L6.15139 10.1224L3.47882 7.44124C3.3964 7.36162 3.29911 7.29902 3.1925 7.25701C3.08589 7.215 2.97205 7.19439 2.85748 7.19638C2.74291 7.19836 2.62985 7.22289 2.52476 7.26856C2.41967 7.31424 2.3246 7.38017 2.24499 7.46258C2.16538 7.545 2.10278 7.64229 2.06077 7.7489C2.01875 7.85551 1.99815 7.96935 2.00013 8.08392C2.00211 8.19849 2.02664 8.31155 2.07232 8.41664C2.11799 8.52173 2.18392 8.6168 2.26634 8.69641L5.54515 11.9752C5.62453 12.0553 5.71897 12.1188 5.82302 12.1621C5.92707 12.2055 6.03867 12.2278 6.15139 12.2278C6.26411 12.2278 6.37572 12.2055 6.47977 12.1621C6.58382 12.1188 6.67826 12.0553 6.75763 11.9752L13.7251 5.00774C13.8118 4.92778 13.881 4.83074 13.9283 4.72273C13.9756 4.61472 14 4.49808 14 4.38016C14 4.26224 13.9756 4.14559 13.9283 4.03758C13.881 3.92957 13.8118 3.83253 13.7251 3.75257Z"
            fill="#167E42"
          />
        </g>
      </svg>
    </div>
  );
};

export default ApprovedSign;
