import FormDataService from "../../../../services/FormDataService";
import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const depositTR = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.append("from_web", true);
    const res = await FormDataService.post("/client/deposit", data);
    const deposit = res.data;

    return deposit;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const updateDepositTR = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.append("from_web", true);
    const res = await FormDataService.post("/client/update-deposit", data);
    const deposit = res.data;

    return deposit;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
}
