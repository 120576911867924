const TopUpIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 8.5H13.5"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 13.5H10"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22 12.03V14.11C22 17.62 21.11 18.5 17.56 18.5H6.44C2.89 18.5 2 17.62 2 14.11V7.89C2 5.38 2.89 4.5 6.44 4.5H13.5"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5 7.25H22"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M19.25 10V4.5"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default TopUpIcon;