import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { HeaderTabs, OtherAccounts } from "../../components";
import { CardButton, PrimaryCard } from "../../components/Card";
import RecentTransactions from "../../components/RecentTranactions";
import ViewRecentTransaction from "../../components/RecentTranactions/partials/ViewRecentTransaction";
import { setActiveAccount } from "../../redux/actions/home/ActiveAccountAction";
import AddAccountModal from "../../components/Modals/AddAccount/AddAccountModal";
import {
  CardButtonSkeleton,
  OtherAccountsSkeletons,
  PrimaryCardSkeleton,
  RecentTransactionsSkeleton,
} from "../../components/Skeleton";
import ReccentTransactionSideBar from "../../components/Common/RecentTransactionsSideBar";
import EmptyCrypto from "../../components/Empty/EmptyCrypto";
import CustomTransition from "../../components/CustomTransition";
import { setPageTitle } from "../../helper/metaDataHelper";
import { getCryptoAccounts } from "../../redux/actions/CryptoTransaction/GetCryptoAccounts";
import { getModuleStatus } from "../../redux/actions/lookups/getModuleStatus";
import { getClientAccounts } from "../../redux/actions/home/ClientInfoAction";
import { useRecentTransaction } from "../../hooks/useRecentTransaction";

const Crypto = () => {
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [clientAccounts, setClientAccounts] = useState([]);
  const [ShowRecentTransaction, setShowRecentTransaction] = useState(false);
  const [clientAccountsLoading, setClientAccountsLoading] = useState(true);
  const { isLoading } = useSelector((state) => state.common);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const [recentTransactionsLoading, setRecentTransactionsLoading] =
    useState(false);
  const { lastThreeTransactions } = useRecentTransaction(
    setRecentTransactionsLoading,
    "account"
  );

  useEffect(() => {
    setPageTitle("Crypto");
  }, []);

  useEffect(() => {
    const getCryptoData = async () => {
      try {
        if (!user) {
          navigate("/signin");
        } else {
          setClientAccountsLoading(true);

          // Run all the async actions concurrently
          const [clientCryptoAccounts] =
            await Promise.all([
              dispatch(getCryptoAccounts()),
              dispatch(getModuleStatus({ module: "Bitpace" })),
              dispatch(getModuleStatus({ module: "Crypto" })),
              dispatch(getClientAccounts()),
            ]);

          if (clientCryptoAccounts.length > 0) {
            setClientAccounts(clientCryptoAccounts);
            await dispatch(setActiveAccount(clientCryptoAccounts[0]));
          }
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
      } finally {
        setClientAccountsLoading(false);
      }
    };

    getCryptoData();
  }, [user, dispatch, navigate]);

  return (
    <>
      <main>
        <div className="max-w-[1024px] mx-auto px-4 min-h-screen">
          <div className="flex flex-col sm:flex-row justify-center gap-3">
            {/* First sections */}
            <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
              <HeaderTabs />
              {isLoading || clientAccountsLoading ? (
                <>
                  <PrimaryCardSkeleton count={5} />
                  <RecentTransactionsSkeleton />
                  <CardButtonSkeleton />
                </>
              ) : clientAccounts && clientAccounts.length > 0 ? (
                <>
                  <div className="space-y-4">
                    <PrimaryCard
                      clientAccounts={clientAccounts}
                      activeAccount={activeAccount}
                      type="crypto"
                      crypto={true}
                    />
                    {!recentTransactionsLoading ? (
                      <RecentTransactions
                        activeTransaction={activeTransaction}
                        transactions={lastThreeTransactions}
                        setActiveTransaction={setActiveTransaction}
                        setShowRecentTransaction={setShowRecentTransaction}
                        type="crypto"
                      />
                    ) : (
                      <RecentTransactionsSkeleton />
                    )}

                    <div className="md:hidden block">
                      <AddAccountModal>
                        <CardButton
                          title={"Add new account"}
                          subtitle={"Explore other currency in the world"}
                        />
                      </AddAccountModal>
                    </div>
                  </div>
                </>
              ) : (
                <EmptyCrypto />
              )}
            </div>
            {/* Second sections */}
            {isLoading || clientAccountsLoading ? (
              <div className="xl:w-[404px] w-[350px] hidden md:block">
                <OtherAccountsSkeletons />
                <CardButtonSkeleton />
              </div>
            ) : clientAccounts && clientAccounts.length > 0 ? (
              <div className="xl:w-[404px] w-[350px] hidden md:block mt-2">
                <CustomTransition show={ShowRecentTransaction}>
                  {ShowRecentTransaction && (
                    <>
                      <div className="bg-primarycard-gradient rounded-3xl p-6 mt-2 space-y-4 h-[700px]">
                        <ViewRecentTransaction
                          transaction={activeTransaction}
                          setShowRecentTransaction={setShowRecentTransaction}
                          ShowRecentTransaction={ShowRecentTransaction}
                          setActiveTransaction={setActiveTransaction}
                          type="crypto"
                        />
                      </div>
                    </>
                  )}
                </CustomTransition>
                <ReccentTransactionSideBar
                  ShowRecentTransaction={ShowRecentTransaction}
                  setShowRecentTransaction={setShowRecentTransaction}
                  activeTransaction={activeTransaction}
                  setActiveTransaction={setActiveTransaction}
                  type="crypto"
                />

                <CustomTransition show={!ShowRecentTransaction}>
                  {!ShowRecentTransaction && (
                    <>
                      <OtherAccounts
                        clientAccounts={clientAccounts}
                        activeAccount={activeAccount}
                      />
                      <AddAccountModal crypto={true}>
                        <CardButton
                          title={"Add new account"}
                          subtitle={"Explore other currency in the world"}
                        />
                      </AddAccountModal>
                    </>
                  )}
                </CustomTransition>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Crypto;
