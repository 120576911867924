import React from "react";
import { ArrowIcon } from "../../Icons";
import CustomTransition from "../../CustomTransition";

const ToolTip = ({ text, showTooltip, className }) => {
  return (
    <CustomTransition
      show={showTooltip}
      as={React.Fragment}
      enter="transition ease-out duration-500"
      leave="transition ease-in duration-75"
    >
      <div
        className={`z-50 absolute left-1/2 mt-2 transform -translate-x-1/2 duration-200 p-2.5 rounded-lg shadow-lg text-body-text bg-primarycard-gradient dark:bg-tabs-gradient ${className}`}
      >
        <ArrowIcon className="absolute bottom-[33px] left-1/2 transform -translate-x-1/2 shadow-lg" />
        <div className="text-center text-xs font-medium leading-none">
          {text}
        </div>
      </div>
    </CustomTransition>
  );
};

export default ToolTip;
