import React, { useState } from "react";
import ToolTip from "../ToolTip";
import { DarkModeIcon, LightModeIcon } from "../../Icons";
import { SetThemeClass } from "../../../helper";
import CustomTransition from "../../CustomTransition";

const Mode = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [mode, setMode] = useState(localStorage.getItem("theme") || "light");

  const handleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    localStorage.setItem("theme", newMode);
    SetThemeClass(newMode);
    setMode(newMode);
  };

  return (
    <div className="relative max-[576px]:hidden">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={handleMode}
        className="w-12 h-12 p-2 bg-secondary-icon-bg active:bg-active-state-bg rounded-[100px] justify-center items-center gap-2.5 inline-flex"
      >
        <div className="justify-center items-center gap-2.5 flex">
          <div className={`w-8 h-8 p-2 bg-popup-bg rounded-3xl`}>
            <CustomTransition
              show={mode === "dark"}
              leave="transition ease-out duration-75"
            >
              <LightModeIcon
                className={`h-4 w-4 max-w-max PrimaryCardGradientDark ${
                  mode === "dark" ? "block" : "hidden"
                } `}
              />
            </CustomTransition>
            <CustomTransition show={mode === "light"}>
              <DarkModeIcon
                className={`h-4 w-4 max-w-max PrimaryCardGradientLight ${
                  mode === "light" ? "block" : "hidden"
                } `}
              />
            </CustomTransition>
          </div>
        </div>
      </div>
      <ToolTip
        text={mode === "dark" ? "Switch to Light Mode" : "Switch to Dark Mode"}
        showTooltip={showTooltip}
        className={"min-w-max"}
      />
    </div>
  );
};

export default Mode;
