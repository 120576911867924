import React from "react";
import Skeleton from "../../Common/Skeleton";

const CardButtonSkeleton = () => {
  return (
    <div>
      <Skeleton className="flex rounded-3xl h-[95px] w-full items-center max-[360px]:px-1.5">
        <div className="flex items-center px-3">
          <Skeleton className="rounded-full h-10 w-10 mx-2" />
          <span className="flex flex-col space-y-1">
            <Skeleton className="rounded-full w-[246px] h-[16px]" />
            <Skeleton className="rounded-full w-[155px] h-[16px]" />
          </span>
        </div>
      </Skeleton>
    </div>
  );
};

export default CardButtonSkeleton;
