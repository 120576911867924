const CautionIcon = ({ className, fillColor }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20.8795 18.4725L12.7827 4.45607C12.7042 4.31762 12.5904 4.20246 12.4529 4.12234C12.3154 4.04221 12.1591 4 12 4C11.8409 4 11.6846 4.04221 11.5471 4.12234C11.4096 4.20246 11.2958 4.31762 11.2173 4.45607L3.12053 18.4725C3.04157 18.6093 3 18.7644 3 18.9223C3 19.0802 3.04157 19.2354 3.12053 19.3721C3.19979 19.5094 3.31392 19.6233 3.45137 19.7023C3.58881 19.7813 3.74469 19.8226 3.90322 19.822H20.0968C20.2553 19.8226 20.4112 19.7813 20.5486 19.7023C20.6861 19.6233 20.8002 19.5094 20.8795 19.3721C20.9584 19.2354 21 19.0802 21 18.9223C21 18.7644 20.9584 18.6093 20.8795 18.4725ZM5.4596 18.0227L12 6.70518L18.5404 18.0227H5.4596Z"
          className={fillColor ? fillColor : "fill-highlighter-red"}
        />
        <path
          d="M11.9998 10C11.8142 10 11.6361 10.0737 11.5048 10.205C11.3736 10.3363 11.2998 10.5143 11.2998 10.7V13.4999C11.2998 13.6856 11.3736 13.8636 11.5048 13.9949C11.6361 14.1262 11.8142 14.1999 11.9998 14.1999C12.1854 14.1999 12.3635 14.1262 12.4948 13.9949C12.626 13.8636 12.6998 13.6856 12.6998 13.4999V10.7C12.6998 10.5143 12.626 10.3363 12.4948 10.205C12.3635 10.0737 12.1854 10 11.9998 10ZM12.6438 16.0339C12.6285 15.9893 12.6073 15.9469 12.5808 15.9079L12.4968 15.8029C12.3984 15.7057 12.2734 15.6399 12.1376 15.6138C12.0018 15.5876 11.8613 15.6023 11.7338 15.6559C11.649 15.6913 11.5709 15.741 11.5028 15.8029C11.4379 15.8683 11.3866 15.9458 11.3518 16.0311C11.3169 16.1164 11.2993 16.2077 11.2998 16.2999C11.3009 16.3913 11.3199 16.4817 11.3558 16.5658C11.3873 16.6527 11.4374 16.7316 11.5027 16.7969C11.5681 16.8622 11.6469 16.9124 11.7338 16.9438C11.8176 16.9809 11.9082 17 11.9998 17C12.0914 17 12.182 16.9809 12.2658 16.9438C12.3527 16.9124 12.4315 16.8622 12.4969 16.7969C12.5622 16.7316 12.6123 16.6527 12.6438 16.5658C12.6797 16.4817 12.6987 16.3913 12.6998 16.2999C12.7032 16.2533 12.7032 16.2065 12.6998 16.1599C12.6877 16.1152 12.6688 16.0727 12.6438 16.0339Z"
          className={fillColor ? fillColor : "fill-highlighter-red"}
        />
      </svg>
    </div>
  );
};

export default CautionIcon;
