import commonService from "../../../services/Common/service";
import { CommonActionTypes } from "../../action-types";

export const forgotPassword = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/forgot-password", data);

    return res.data;
  } catch (error) {
    const message = error.response.data.message || error.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
