import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes, ModulesActionTypes } from "../../../action-types";

export const getModuleStatus = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("lookups/module-status", data);
    
    const resData = { module: data.module, status: res.data.data.status };
    let storedModules = JSON.parse(localStorage.getItem("modules")) || [];
    const moduleIndex = storedModules.findIndex((mod) => mod.module === data.module);
    
    if (moduleIndex !== -1) {
      storedModules[moduleIndex] = resData;

      // Dispatch update action
      dispatch({
        type: ModulesActionTypes.UPDATE_MODULES,
        payload: resData,
      });
    } else {
      storedModules.push(resData);

      // Dispatch add action
      dispatch({
        type: ModulesActionTypes.ADD_MODULES,
        payload: storedModules,
      });
    }

    localStorage.setItem("modules", JSON.stringify(storedModules));

    return res.data;
  } catch (err) {
    const message = err.response?.data?.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
