import { clientHelpReasonsActionTypes } from "../../action-types";

const clientHelpReasons = JSON.parse(localStorage.getItem("clientHelpReasons"));

const initialState = {
  clientHelpReasons: clientHelpReasons ? clientHelpReasons : null,
};

export default function clientHelpReasonsReducer(state = initialState, action) {
  switch (action.type) {
    case clientHelpReasonsActionTypes.GET_CLIENT_HELP_REASONS:
      return {
        clientHelpReasons: action.payload,
      };
    default:
      return state;
  }
}
