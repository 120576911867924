import React from "react";
import { ApprovedSign, InitialReviewSign, PendingSign, RejectedSign } from "../../../../../Icons";

const GetTransactionStatus = ({ status }) => {
  switch (status) {
    case "Initial Review":
      return (
        <>
          <InitialReviewSign />
          <span className={"px-1 text-blue-500"}>{status}</span>
        </>
      );
    case "Pending":
      return (
        <>
          <PendingSign />
          <span className={"px-1 text-dark-primary font-medium"}>{status}</span>
        </>
      );
    case "Approved":
      return (
        <>
          <ApprovedSign />
          <span className={"px-1 text-highlighter-green"}>{status}</span>
        </>
      );
    case "Rejected":
      return (
        <div className="flex flex-col items-end max-w-[196px] max-h-16">
          <div className="flex items-center justify-end">
            <RejectedSign className="w-3 h-3"/>
            <span className={"px-1 text-highlighter-red"}>{status}</span>
          </div>
        </div>
      );
    default:
        return status;
  }
};

export default GetTransactionStatus;
