import { AuthActionTypes } from "../../action-types";
import { OnboardingActionTypes } from "../../action-types";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  user: user ? user : null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case AuthActionTypes.LOGIN:
      return {
        user: action.payload,
      };
    case AuthActionTypes.LOGOUT:
      const theme = localStorage.getItem("theme");
      const company = localStorage.getItem("company");
      localStorage.clear();

      if (theme) {
        localStorage.setItem("theme", theme);
        localStorage.setItem("company", company);
      }
      return {
        user: null,
      };
    case AuthActionTypes.CHANGE_PASSWORD:
      return {
        user: { ...state.user, token: action.payload },
      };
    case OnboardingActionTypes.UPDATE_CLIENT_STATUS:
      return {
        user: { ...state.user, status: action.payload.status },
      };
    case AuthActionTypes.UPDATE_REQUESTED_KYC:
      return {
        user: { ...state.user, kyc_requested: action.payload },
      };
    default:
      return state;
  }
}
