import React from "react";
import Skeleton from "../../Common/Skeleton";

const BeneficiarySkeleton = () => {
  return (
    <div className="flex flex-col space-y-3  md:max-w-full max-w-[372px]">
      {[1, 2, 3].map((item) => (
        <Skeleton
          key={item}
          className="rounded-3xl h-[90px] flex justify-between items-center"
        >
          <div className="flex">
            <Skeleton className="rounded-full h-10 w-10 mx-4" />
            <span className="flex flex-col space-y-1">
              <Skeleton className="rounded-full w-[210px] md:max-w-[346px] h-[16px]" />
              <Skeleton className="rounded-full w-[186px] md:max-w-[246px] h-[16px]" />
              <Skeleton className="rounded-full w-[146px] md:max-w-[146px] h-[16px]" />
            </span>
          </div>
          <Skeleton className="rounded-full w-[20px] h-[20px] mr-10" />
        </Skeleton>
      ))}
    </div>
  );
};

export default BeneficiarySkeleton;
