import React from "react";

const RedEllipse = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="red-ellipse"
          cx="4"
          cy="4"
          r="3.5"
          fill="#EC0C00"
          stroke="#FF9B96"
        />
      </svg>
    </div>
  );
};

export default RedEllipse;
