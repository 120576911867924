import ResponseErrorHandling from "../../../helper/ResponseErrorHandling";
import commonService from "../../../services/Common/service";
import { CommonActionTypes, NotificationActionTypes } from "../../action-types";

export const fetchUsersNotification = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING });

  try {
    const res = await commonService.get("/user/notifications");

    if (res.data.data) {
      dispatch({
        type: NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
        payload: [],
      });
    }
  } catch (err) {
    const message = err.response?.data?.message || err.message;
    ResponseErrorHandling(err.response, dispatch);
    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET });
  }
};

export const readAllNotification = () => async (dispatch) => {
  try {
    await commonService.post("/user/notifications/read-all");
    dispatch({ type: NotificationActionTypes.READ_ALL_NOTIFICATIONS });
  } catch (err) {
    const message = err.response?.data?.message || err.message;
    ResponseErrorHandling(err.response, dispatch);
    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};

export const deleteAllNotification = () => async (dispatch) => {
  try {
    await commonService.post("/user/notifications/delete-all");
    dispatch({ type: NotificationActionTypes.DELETE_ALL_NOTIFICATIONS });
  } catch (err) {
    const message = err.response?.data?.message || err.message;
    ResponseErrorHandling(err.response, dispatch);
    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};

export const deleteNotification = (notificationId) => async (dispatch) => {
  try {
    await commonService.post("/user/notification/delete", {
      nid: notificationId,
    });
    dispatch({
      type: NotificationActionTypes.DELETE_NOTIFICATION,
      payload: notificationId,
    });
  } catch (err) {
    const message = err.response?.data?.message || err.message;
    ResponseErrorHandling(err.response, dispatch);
    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};
