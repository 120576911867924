const UploadIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43428 9.43682C9.74849 9.12262 10.2579 9.12262 10.5721 9.43682L13.552 12.4167C13.8662 12.7309 13.8662 13.2403 13.552 13.5545C13.2378 13.8687 12.7284 13.8687 12.4142 13.5545L10.0032 11.1436L7.59223 13.5545C7.27803 13.8687 6.76861 13.8687 6.4544 13.5545C6.1402 13.2403 6.1402 12.7309 6.4544 12.4167L9.43428 9.43682Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0038 9.20117C10.4481 9.20117 10.8084 9.56139 10.8084 10.0057V16.7105C10.8084 17.1548 10.4481 17.515 10.0038 17.515C9.55944 17.515 9.19922 17.1548 9.19922 16.7105V10.0057C9.19922 9.56139 9.55944 9.20117 10.0038 9.20117Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.51528 2.50459C8.53706 2.46694 9.55408 2.66142 10.4899 3.0734C11.4257 3.48539 12.2559 4.10416 12.9181 4.88319C13.4437 5.50146 13.8526 6.20791 14.127 6.96799H14.473C15.4794 6.96866 16.4573 7.30442 17.2516 7.92229C18.0459 8.54017 18.6118 9.40496 18.86 10.3802C19.1083 11.3554 19.0247 12.3855 18.6224 13.3079C18.2202 14.2304 17.5222 14.9926 16.6386 15.4742C16.2485 15.6869 15.7598 15.5431 15.5471 15.1529C15.3344 14.7628 15.4783 14.2741 15.8684 14.0614C16.4381 13.7509 16.8881 13.2594 17.1474 12.6647C17.4068 12.07 17.4607 11.4059 17.3006 10.7771C17.1406 10.1483 16.7757 9.59077 16.2636 9.1924C15.7516 8.79415 15.1217 8.57767 14.473 8.57713C14.4729 8.57713 14.4732 8.57713 14.473 8.57713M14.473 8.57713H13.5344C13.1676 8.57713 12.8472 8.32906 12.7554 7.97395C12.5604 7.21951 12.1968 6.51911 11.6921 5.9254C11.1874 5.33169 10.5547 4.86012 9.84151 4.54614C9.12832 4.23216 8.35324 4.08394 7.57452 4.11263C6.79581 4.14132 6.03373 4.34617 5.34558 4.71178C4.65744 5.07739 4.06112 5.59425 3.60148 6.22349C3.14184 6.85273 2.83082 7.57799 2.69182 8.34473C2.55282 9.11148 2.58944 9.89976 2.79894 10.6503C3.00844 11.4009 3.38536 12.0942 3.90137 12.6781C4.19562 13.011 4.16423 13.5195 3.83126 13.8137C3.49829 14.108 2.98984 14.0766 2.69559 13.7436C2.01852 12.9775 1.52394 12.0678 1.24905 11.0829C0.974157 10.0981 0.926101 9.06377 1.10849 8.05769C1.29088 7.05162 1.69898 6.09998 2.30209 5.27433C2.90521 4.44867 3.68765 3.77049 4.5906 3.29076C5.49354 2.81103 6.4935 2.54223 7.51528 2.50459"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43428 9.43682C9.74849 9.12262 10.2579 9.12262 10.5721 9.43682L13.552 12.4167C13.8662 12.7309 13.8662 13.2403 13.552 13.5545C13.2378 13.8687 12.7284 13.8687 12.4142 13.5545L10.0032 11.1436L7.59223 13.5545C7.27803 13.8687 6.76861 13.8687 6.4544 13.5545C6.1402 13.2403 6.1402 12.7309 6.4544 12.4167L9.43428 9.43682Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default UploadIcon;
