import { HideIcon, ShowIcon } from "../../Icons";

const PasswordToggleIcon = ({ isVisible, onToggle }) => {
  return isVisible ? (
    <ShowIcon
      className={"p-2 hover:bg-option-list-hover rounded-full"}
      onClick={onToggle}
    />
  ) : (
    <HideIcon
      className={"p-2 hover:bg-option-list-hover rounded-full"}
      onClick={onToggle}
    />
  );
};

export default PasswordToggleIcon;
