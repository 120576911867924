import React from "react";
import Modal from "../../Common/Modal";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import { CautionIcon } from "../../Icons";
import HangTightLoading from "../../Common/HangTightLoading";

const ConfirmModal = ({
  isModalOpen,
  setIsModalOpen,
  handleAction,
  message,
  loading,
  children,
}) => {
  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={() => setIsModalOpen(false)}
        headerTitle="Confirm Action"
        loading={loading}
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        {!loading ? (
          <>
            <div className="flex flex-col px-6 py-4 space-y-4 items-center">
              <CautionIcon className="w-20 h-20" fillColor={"fill-icon-btn"} />
              <h3 className="text-sm font-normal text-body-text text-center">
                {message}
              </h3>
            </div>
            <div className="flex items-center justify-center pb-6 pt-3 whitespace-nowrap space-x-4 px-6">
              <PrimaryButton text="Continue" onClick={handleAction} />
              <NavigationButton
                text="Cancel"
                onClick={() => setIsModalOpen(false)}
              />
            </div>
          </>
        ) : (
          <HangTightLoading message="Please wait while we process your request..." />
        )}
      </Modal>
    </>
  );
};

export default ConfirmModal;
