import React from "react";

const ReceiveArrowIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Arrow / Arrow_Up_LG">
          <path
            id="Vector"
            d="M9.30469 6.19531L3.30469 6.19531M3.30469 6.19531L5.80469 3.69531M3.30469 6.19531L5.80469 8.69531"
            stroke="#5F4D21"
            strokeWidth="1.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </div>
  );
};

export default ReceiveArrowIcon;
