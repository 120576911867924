import React from "react";

const RadioButton = ({
  preferenceOptions,
  name,
  onChange,
  value,
  layout,
  error,
}) => {
  const layoutClass =
    layout === "column"
      ? "flex-col space-y-4"
      : "sm:flex sm:items-center sm:space-y-0 sm:space-x-10";
  return (
    <div>
      <fieldset className="mt-4">
        <div className={`space-y-4 ${layoutClass}`}>
          {preferenceOptions.map((option) => (
            <div key={option.id} className="flex items-center">
              <input
                id={option.id}
                name={name}
                type="radio"
                checked={value === option.id}
                className="h-4 w-4 cursor-pointer border-divider-border text-primary-item focus:ring-primary-item"
                value={option.id}
                onChange={() =>
                  onChange({ target: { name, value: option.id } })
                }
              />
              <label
                htmlFor={option.id}
                className="ml-3 block text-sm font-medium text-paragraph-text"
              >
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {error && <span className="text-highlighter-red text-sm">{error}</span>}
    </div>
  );
};

export default RadioButton;
