import React from "react";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import Label from "../../Label";
import useCurrencies from "../../../hooks/useCurrencies";
import CurrencyItem from "./partials/CurrencyItem";
import SelectCurrenciesSkeleton from "../../Skeleton/SelectCurrenciesSkeleton";

const Currency = ({ handleNext, handlePrev, formData, setFormData }) => {
  const currencies = useCurrencies("exclude_fiat");

  const handleCurrencyClick = (currency) => {
    const currencyExists = formData.currency_ids.find(
      (id) => id === currency.id
    );
    const updatedCurrencyIds = currencyExists
      ? formData.currency_ids.filter((id) => id !== currency.id)
      : [...formData.currency_ids, currency.id];

    setFormData({ ...formData, currency_ids: updatedCurrencyIds });
  };

  const isFormValid = () => {
    return formData.currency_ids.length > 0;
  };
  
  return (
    <div className="space-y-4 md:w-[375px] w-full">
      <div className="inline-flex space-x-1 max-[360px]:flex-col max-[360px]:space-x-0">
        <Label
          className="block text-sm font-bold text-body-text"
          htmlFor="currency"
          text="Choose your currencies"
        />
        <span className="text-paragraph-text text-xs leading-5">
          (you can select more than one.)
        </span>
      </div>
      <div className="flex flex-col ">
        {currencies.length ? (
          currencies.map((currency) => (
            <div
              key={currency.id}
              onClick={() => handleCurrencyClick(currency)}
            >
              <CurrencyItem
                currency={currency}
                selectedItem={formData.currency_ids}
              />
            </div>
          ))
        ) : (
          <SelectCurrenciesSkeleton />
        )}
      </div>
      {currencies.length && (
        <>
          <hr className="border border-divider-border" />
          <div className="flex w-full space-x-4">
            <NavigationButton onClick={handlePrev} text="Back" />
            <PrimaryButton
              onClick={handleNext}
              text="Continue"
              disabled={!isFormValid()}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Currency;
