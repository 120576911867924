import React from "react";
import SendArrowIcon from "../SendArrowIcon";

const CryptoDebitIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-transaction-bg rounded-full">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.911 9.52789C22.3917 12.7463 19.5938 15.0196 16.4388 14.9866L16.4483 14.9273C16.7604 11.0475 14.1524 7.43593 10.3417 6.50702L10.3121 6.50225C11.2731 3.49696 14.3111 1.55612 17.5294 2.07544C21.0736 2.64733 23.4829 5.98373 22.911 9.52789Z"
            fill="#FDD671"
          />
          <path
            d="M22.911 9.52789C22.3917 12.7463 19.5938 15.0196 16.4388 14.9866L16.4483 14.9273C16.7604 11.0475 14.1524 7.43593 10.3417 6.50702L10.3121 6.50225C11.2731 3.49696 14.3111 1.55612 17.5294 2.07544C21.0736 2.64733 23.4829 5.98373 22.911 9.52789Z"
            fill="#5F4D21"
          />
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="#FDD671"
          />
          <path
            d="M22.0001 8.5C22.0001 11.76 19.6001 14.45 16.4801 14.92V14.86C16.1701 10.98 13.0201 7.83 9.11008 7.52H9.08008C9.55008 4.4 12.2401 2 15.5001 2C19.0901 2 22.0001 4.91 22.0001 8.5Z"
            fill="url(#paint0_linear_1521_37920)"
          />
          <path
            d="M14.98 14.98C14.73 11.81 12.19 9.27 9.02 9.02C8.85 9.01 8.67 9 8.5 9C4.91 9 2 11.91 2 15.5C2 19.09 4.91 22 8.5 22C12.09 22 15 19.09 15 15.5C15 15.33 14.99 15.15 14.98 14.98Z"
            fill="url(#paint1_linear_1521_37920)"
            stroke="url(#paint2_linear_1521_37920)"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.53815 13.5207C3.80327 13.598 3.95557 13.8755 3.87833 14.1406C3.23031 16.3648 4.37719 18.6859 6.46834 19.5538C6.72338 19.6597 6.87733 19.9347 6.80009 20.1998C6.72285 20.4649 6.44393 20.6193 6.18681 20.5186C3.56581 19.492 2.11565 16.6156 2.91825 13.8609C2.99549 13.5958 3.27303 13.4435 3.53815 13.5207Z"
            fill="#9D8A57"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1521_37920"
              x1="15.5401"
              y1="2"
              x2="23.8553"
              y2="11.9783"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1521_37920"
              x1="8.5"
              y1="9"
              x2="16.8667"
              y2="19.0401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1521_37920"
              x1="8.5"
              y1="9"
              x2="16.8667"
              y2="19.0401"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <SendArrowIcon />
      </div>
    </div>
  );
};

export default CryptoDebitIcon;
