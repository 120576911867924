import React from "react";
import Accounts from "./partials/Accounts";
import { useDispatch } from "react-redux";
import { setActiveAccount } from "../../redux/actions/home/ActiveAccountAction";

const OtherAccounts = ({ clientAccounts, activeAccount }) => {
  const dispatch = useDispatch();

  const handleActiveAccount = async (account) => {
    await dispatch(setActiveAccount(account));
  };
  return (
    <div>
      <h3 className="text-body-text text-sm font-medium leading-tight tracking-tight mb-6">
        Other accounts
      </h3>
      <div className="space-y-4 ">
        {clientAccounts.length &&
          clientAccounts.map((account) => {
            if (activeAccount.id !== account.id)
              return (
                <div
                  onClick={() => handleActiveAccount(account)}
                  key={account.id}
                  className="cursor-pointer space-y-4"
                >
                  <Accounts account={account} />
                </div>
              );
            else return null;
          })}
      </div>
    </div>
  );
};

export default OtherAccounts;
