import React from "react";

const GBPSymbol = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width ? width : 18}
      height={height ? height : 23}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.1875 23V19.7344C1.76042 19.7031 2.54688 18.849 2.54688 17.1719V13.5625H0.15625V10.3906H2.54688V6.8125C2.54688 4.96875 3.19792 3.41146 4.5 2.14062C5.8125 0.859375 7.5625 0.21875 9.75 0.21875C11.9583 0.21875 13.6979 0.869792 14.9688 2.17188C16.25 3.48438 16.8906 5.0625 16.8906 6.90625H13.3438C13.3438 5.94792 13.0312 5.09375 12.4062 4.34375C11.7604 3.63542 10.8542 3.28125 9.6875 3.28125C8.55208 3.28125 7.66667 3.61458 7.03125 4.28125C6.39583 4.94792 6.07812 5.79167 6.07812 6.8125V10.3906H12.9531V13.5625H6.07812V17.2969C6.07812 18.5052 5.72396 19.3073 5.01562 19.7031V19.8281H17.375V23H0.1875Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default GBPSymbol;
