import axios from "axios";
import { commonInterceptor } from "./interceptor";


const API_URL = process.env.REACT_APP_API_BASE_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const commonService = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "api-key": apiKey,
  },
});

commonService.interceptors.request.use(commonInterceptor);

export default commonService;
