import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useForm from "../../../../hooks/useForm";
import Modal from "../../../Common/Modal";
import FormTextAreaField from "../../../FormTextAreaField";
import CommonButton from "../../../Buttons/CommonButton";
import HangTightLoading from "../../../Common/HangTightLoading";
import SuccessMessage from "../../../Common/SuccessMessage";
import { setActiveCard, setClientCards } from "../../../../redux/actions/Cards/setActiveCard";

const BlockUnblockCardModal = ({
  children,
  activeCard,
  contextlabel,
  reasonlabel,
  reduxAction,
}) => {
  const [CardModalOpen, setCardModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const reason = useForm("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const { clientCards } = useSelector((state) => state.clientCards);

  const handleSubmit = async () => {
    setisLoading(true);
    setError("");
    let data = {
      id: activeCard.id,
      reason: reason.value,
    };
    const res = await dispatch(reduxAction(data));
    if (res.success === true) {
      setSuccess(true);
      reason.reset();

      const updateCard = {
        ...activeCard,
        status: activeCard.status === "Blocked" ? "Active" : "Blocked",
      }
      dispatch(setActiveCard(updateCard));

      const updatedCards = clientCards.map((card) => {
        if (card.id === activeCard.id) {
          return updateCard;
        }
        return card;
      });
      dispatch(setClientCards(updatedCards));

      setTimeout(() => {
        handleCloseButton()
      }, 2000);
    } else {
      setError("Something went wrong, please try again later.");
      reason.reset();
    }
    setisLoading(false);
  };

  const handleCloseButton = () => {
    setCardModalOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setError("");
    }, 300);
  };

  return (
    <>
      <div onClick={() => setCardModalOpen(true)}>{children}</div>
      <Modal
        isOpen={CardModalOpen}
        handleOnCloseModal={handleCloseButton}
        header={!isLoading && !success}
        headerTitle={contextlabel}
        loading={isLoading}
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl pt-4"
      >
        {!isLoading && !success && (
          <div className="p-6 max-[360px]:p-4 space-y-4">
            <FormTextAreaField
              label={`Reason for ${reasonlabel} the card`}
              name="reason"
              id="reason"
              rows="5"
              cols="33"
              placeholder="Enter your reason here..."
              value={reason.value}
              onChange={(e) => {
                reason.onChange(e);
                setError("");
              }}
            />
            <div className="mt-3"></div>
            {error && (
              <div className="text-highlighter-red text-sm font-semibold">
                {error}
              </div>
            )}
            <CommonButton onClick={handleSubmit} disabled={!reason.value}>
              {contextlabel}
            </CommonButton>
          </div>
        )}
        {isLoading && !success && (
          <HangTightLoading message="We are placing your request!" />
        )}
        {success && !isLoading && (
          <div className="p-6 space-y-4">
            <SuccessMessage
              title="Success!"
              description={`Card ${reasonlabel} successfully!`}
              handleCloseButton={handleCloseButton}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default BlockUnblockCardModal;
