import React from "react";

const USDTSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 18}
      height={height ? height : 23}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.744 23V17.752L4 20.312L2.848 18.616L7.744 15.256V12.056L4 14.616L2.848 12.92L7.744 9.56V2.68H0.608V0.151999H17.728V2.68H10.624V7.928L14.368 5.336L15.52 7.032L10.624 10.392V13.624L14.368 11.032L15.52 12.728L10.624 16.088V23H7.744Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};
export default USDTSymbol;
