import React, { useState, useEffect } from "react";
import { SmallButton } from "../../components/Buttons";
import ServiceRequestsTable from "../../components/ServiceRequests/ServiceRequestsTable";
import { useDispatch } from "react-redux";
import { getClientServicesRequests } from "../../redux/actions/lookups/getClientServicesRequests";
import ServiceRequestTableSkeleton from "../../components/Skeleton/ServiceRequestTableSkeleton";
import ShowServiceRequestModal from "../../components/Modals/ShowServiceRequestModal";
import { setPageTitle } from "../../helper/metaDataHelper";
import EmptyServiceRequestList from "../../components/Empty/EmptyServiceRequestList";

const ServicesRequestsList = () => {
  const [ServicesRequests, setServicesRequests] = useState([]);
  const [showServiceRequest, setShowServiceRequest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const fetchServicesRequests = async () => {
    setIsLoading(true);
    const res = await dispatch(getClientServicesRequests());
    setServicesRequests(res);
    setIsLoading(false);
  };

  useEffect(() => {
    setPageTitle("Service Requests");
  }, []);

  useEffect(() => {
    fetchServicesRequests();
    // eslint-disable-next-line
  }, []);

  const formateRequestType = (type) => {
    return type
      .split("_")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto">
        <div className="flex flex-col sm:flex-row justify-center gap-3">
          {/* First sections */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <div className="w-10 max-[360px]:px-2">
              <SmallButton back={true} text={"Back"} href={"/"} />
            </div>
            <div className="text-body-text text-3xl font-semibold tracking-tight max-[360px]:px-2">
              Service requests list
            </div>
            {/* Service Request Table */}
            {isLoading ? (
              <ServiceRequestTableSkeleton />
            ) : !ServicesRequests.length && !isLoading ? (
              <EmptyServiceRequestList />
            ) : (
              <ServiceRequestsTable
                ServiceRequests={ServicesRequests}
                setIsModalOpen={setShowModal}
                setShowServiceRequest={setShowServiceRequest}
                formateRequestType={formateRequestType}
              />
            )}
          </div>
        </div>
      </div>

      <ShowServiceRequestModal
        showServiceRequest={showServiceRequest}
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
        formateRequestType={formateRequestType}
        fetchServicesRequests={fetchServicesRequests}
      />
    </main>
  );
};

export default ServicesRequestsList;
