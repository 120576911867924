import { ClientInfoActionTypes } from "../../action-types";

const clientInfo= JSON.parse(localStorage.getItem("clientInfo"));

const initialState = {
  clientInfo: clientInfo ? clientInfo : null
};

export default function clientInfoReducer(state = initialState, action) {
  switch (action.type) {
    case ClientInfoActionTypes.CLIENT_INFO:
      return {
        clientInfo: action.payload,
      };
    case ClientInfoActionTypes.ADD_CLIENT_CRYOTO_ACCOUNTS:
      return {
        clientInfo: {...clientInfo, crypto_accounts: action.payload},
      };
    default:
      return state;
  }
}
