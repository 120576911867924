import React, { useCallback, useEffect, useState } from "react";
import {
  DateSelector,
  FormInputField,
  InputFile,
  Label,
} from "../../components";
import RadioButton from "../../components/RadioButton";
import useAllCountries from "../../hooks/useAllCountries";
import CountriesInput from "../../components/Common/CountriesInput";
import DefaultPreview from "../../assets/images/passport-mock-placeholder.png";
import { PrimaryButton } from "../../components/Buttons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { uploadPassportClientKYC } from "../../redux/actions/ClientKYCGuest";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../../components/Common/ErrorMessage";
import { useForm } from "../../hooks";
import { GetValidationErrors } from "../../helper";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import { UpdateRequestedKYC } from "../../redux/actions/auth";
import { setPageTitle } from "../../helper/metaDataHelper";
import KYCStatus from "../../components/KYC/KYCStatus";

const ClientPassportUpload = () => {
  const allCountries = useAllCountries();
  const [selectedNationality, setSelectedNationality] = useState("");
  const [selectedResidency, setSelectedResidency] = useState("");
  const [previewFile, setPreviewFile] = useState(DefaultPreview);
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const client_id = searchParams.get("client_id");
  const dispatch = useDispatch();
  const document_type = "file-8";
  const [error, setError] = useState("");
  const [requestselfie, setRequestSelfie] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const preferenceOptions = [
    { id: "male", name: "Male" },
    { id: "female", name: "Female" },
  ];

  const passportUpload = useForm(null);
  const passportNumber = useForm("");
  const dob = useForm("");
  const passportExpiry = useForm("");
  const natiaonalityId = useForm("");
  const residencyCountryId = useForm("");
  const gender = useForm("");

  const handleSelectNationality = (option) => {
    natiaonalityId.setValue(option.id);
    setSelectedNationality(option);
  };

  const handleSelectResidency = (option) => {
    residencyCountryId.setValue(option.id);
    setSelectedResidency(option);
  };

  const validateForm = useCallback(() => {
    let isValid = true;

    if (!passportUpload.value) {
      passportUpload.setError(true);
      isValid = false;
    }

    if (!passportNumber.value) {
      passportNumber.setError(true);
      isValid = false;
    }

    if (!dob.value) {
      dob.setError("Date of birth is required");
      isValid = false;
    }

    if (!passportExpiry.value) {
      passportExpiry.setError("Passport expiry date is required");
      isValid = false;
    }

    if (!gender.value) {
      gender.setError("Gender is required");
      isValid = false;
    }

    if (!natiaonalityId.value) {
      natiaonalityId.setError(true);
      isValid = false;
    }

    if (!residencyCountryId.value) {
      residencyCountryId.setError(true);
      isValid = false;
    }

    return isValid;
  }, [
    passportUpload,
    passportNumber,
    dob,
    passportExpiry,
    gender,
    natiaonalityId,
    residencyCountryId,
  ]);

  useEffect(() => {
    const updateNationalityAndResidency = async () => {
      try {
        const nationality_id = natiaonalityId.value
          ? natiaonalityId.value - 1
          : 223;

        const residence_country_id = residencyCountryId.value
          ? residencyCountryId.value - 1
          : 223;

        natiaonalityId.setValue(allCountries[nationality_id]?.id);
        residencyCountryId.setValue(allCountries[residence_country_id]?.id);

        setSelectedNationality(allCountries[nationality_id]);
        setSelectedResidency(allCountries[residence_country_id]);
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };
    if (allCountries.length > 0) {
      updateNationalityAndResidency();
    }
  }, [allCountries, natiaonalityId, residencyCountryId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageTitle("Upload Passport");
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    setError("");
    if (!validateForm()) return;

    setIsLoading(true);

    const form = new FormData();
    form.append("passport_upload", passportUpload.value);
    form.append("passport_number", passportNumber.value);
    form.append("dob", dob.value);
    form.append("passport_expiry", passportExpiry.value);
    form.append("nationality_id", natiaonalityId.value);
    form.append("residence_country_id", residencyCountryId.value);
    form.append("gender", gender.value);
    form.append("hash", hash);
    form.append("document_type", document_type);

    try {
      const res = await dispatch(uploadPassportClientKYC(form));

      if (res.request_selfie === 1) {
        navigate(`/client-liveness-check?hash=${hash}&client_id=${client_id}`);
      } else if (res.request_selfie === 0 || res.request_selfie === 2) {
        if (user && user.token) {
          const updatedUser = { ...user, kyc_requested: 0 };
          await dispatch(UpdateRequestedKYC(updatedUser));
        }
        setRequestSelfie(true);
      }
      if (res.errors) {
        setError(GetValidationErrors(res.errors));
      } else {
        setError(res.message);
      }
    } catch {
      setError("Something went wrong please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleClearFile = () => {
    passportUpload.setValue(null);
    setPreviewFile(DefaultPreview);
  };

  return (
    <>
      <div className="md:max-w-[992px] mx-auto min-h-[80vh] flex-1 flex flex-col">
        {requestselfie ? (
          <KYCStatus status="success" />
        ) : (
          <div className="flex flex-col md:items-center w-full px-3 justify-center pt-1">
            <div className="w-full md:w-[750px]">
              <Label
                className="block text-3xl font-bold text-body-text mb-3"
                htmlFor="personal-info"
                text="Upload Passport"
              />

              {error && (
                <ErrorMessage message={error} onClose={() => setError("")} />
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="col-span-1">
                  <InputFile
                    name="passport_upload"
                    id="passport_upload"
                    onClear={handleClearFile}
                    onChange={(e) => {
                      passportUpload.setValue(e.target.files[0]);
                      if (e.target.files && e.target.files[0]) {
                        const file = e.target.files[0];
                        if (
                          file.type === "application/pdf" ||
                          file.type.startsWith("image/")
                        ) {
                          setPreviewFile(URL.createObjectURL(file));
                        }
                      }
                      passportUpload.error && passportUpload.setError("");
                    }}
                    text="Upload Passport"
                    supportingDocument={passportUpload.value}
                    placeholder="Upload passport front page, which includes your photo."
                    error={passportUpload.error}
                  />

                  <div className="mt-6">
                    <h3 className="text-lg font-medium">Passport Preview</h3>
                    <div className="mt-4">
                      {passportUpload.value?.type === "application/pdf" ? (
                        <iframe
                          src={previewFile}
                          title="PDF Preview"
                          className="w-full h-[500px] border rounded-2xl"
                        />
                      ) : (
                        <img
                          src={previewFile}
                          alt="Passport Preview"
                          className="md:w-[300px] w-[200px] h-auto rounded-2xl"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-span-1 space-y-4 mb-2">
                  <FormInputField
                    label="Passport Number"
                    name="passport_number"
                    id="passport_number"
                    type="text"
                    placeholder="Enter passport number"
                    {...passportNumber}
                  />
                  <DateSelector
                    text="Date of Birth"
                    name="dob"
                    id="dob"
                    placeholder="Enter date of birth"
                    selectedDate={dob.value}
                    {...dob}
                  />

                  <DateSelector
                    text="Passport Expiry Date"
                    name="passport_expiry"
                    id="passport_expiry"
                    placeholder="Enter passport expiry date"
                    selectedDate={passportExpiry.value}
                    disableFutureDates={false}
                    {...passportExpiry}
                  />
                  <CountriesInput
                    name="nationality_id"
                    label="Nationality"
                    placeholder="Choose your nationality"
                    countries={allCountries}
                    SelectedCountry={selectedNationality}
                    onChange={handleSelectNationality}
                    phonecode={false}
                  >
                    <div>
                      <Label
                        htmlFor={"nationality_id"}
                        className="block text-xs font-medium text-body-text pb-1"
                        text="Nationality"
                      />
                      <div className="flex items-center justify-between space-x-3">
                        <img
                          className="rounded-full w-8 h-8"
                          src={selectedNationality?.flag}
                          alt="flag"
                        />
                        <Label
                          htmlFor={"nationality_id"}
                          className="text-sm font-medium text-body-text"
                          text={selectedNationality?.name}
                        />
                      </div>
                    </div>
                  </CountriesInput>

                  <CountriesInput
                    name="residence_country_id"
                    label="Country of Residency"
                    placeholder="Choose your Residency"
                    countries={allCountries}
                    SelectedCountry={selectedResidency}
                    onChange={handleSelectResidency}
                    phonecode={false}
                  >
                    <div>
                      <Label
                        htmlFor={"residence_country_id"}
                        className="block text-xs font-medium text-body-text pb-1"
                        text="Country of Residence"
                      />
                      <div className="flex items-center space-x-3">
                        <img
                          className="rounded-full w-8 h-8"
                          src={selectedResidency?.flag}
                          alt="flag"
                        />
                        <Label
                          htmlFor={"residency"}
                          className="text-sm font-medium text-body-text"
                          text={selectedResidency?.name}
                        />
                      </div>
                    </div>
                  </CountriesInput>
                  <RadioButton
                    name="gender"
                    preferenceOptions={preferenceOptions}
                    {...gender}
                  />
                  <PrimaryButton onClick={handleSubmit} text={"Submit"} />
                </div>
                {isLoading && <LoadingSpinner />}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ClientPassportUpload;
