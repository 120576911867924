import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Modal from "../../Common/Modal";
import { keepThisSession } from "../../../redux/actions/Session";
import { NavigationButton } from "../../Buttons";
import ActiveSessionIcon from "../../Icons/ActiveSessionIcon";
import { SessionActionTypes } from "../../../redux/action-types";
import { logout } from "../../../redux/actions/auth";

const ActiveSessionExpireModal = () => {
  const dispatch = useDispatch();
  const { showModal, showModalDuration } = useSelector(
    (state) => state.session
  );
  const [counter, setCounter] = useState(showModalDuration);

  useEffect(() => {
    if (showModal) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter <= 1000) {
            clearInterval(timer);
            return 0;
          }
          const counter = prevCounter - 1000;
          let session = JSON.parse(localStorage.getItem("session"));
          session = { ...session, showModalDuration: counter };
          localStorage.setItem("session", JSON.stringify(session));
          return counter;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [showModal]);

  // Function to keep the current session alive
  const handleKeepSession = () => {
    dispatch(keepThisSession());
    setCounter(showModalDuration);
    dispatch({ type: SessionActionTypes.CLOSE_SESSION_MODAL });
  };

  // Function to close the modal
  const closeModal = () => {
    dispatch({ type: SessionActionTypes.CLOSE_SESSION_MODAL });
    dispatch(logout());
  };

  const convertMillisecondsToMinutesSeconds = (milliseconds) => {
    let minutes = Math.floor(milliseconds / 60000);
    let seconds = Math.floor((milliseconds % 60000) / 1000);

    // Pad minutes and seconds with leading zero if they are less than 10
    let minutesStr = minutes < 10 ? "0" + minutes : minutes;
    let secondsStr = seconds < 10 ? "0" + seconds : seconds;

    return `${minutesStr}:${secondsStr}`;
  };

  return (
    <Modal
      isOpen={showModal}
      handleOnCloseModal={closeModal}
      header={false}
      loading={false}
      className="bg-popup-bg w-full max-w-[432px] pb-4 overflow-scroll hide-scrollbar shadow-box-shadow rounded-3xl mx-auto"
    >
      <div className="px-6 flex flex-col items-center">
        <ActiveSessionIcon className="w-52 h-52 mt-5 dark:mt-0" />
        <p className="text-center text-lg">
          Your session will expire in{" "}
          {convertMillisecondsToMinutesSeconds(counter)}. Do you want to keep
          your session active?
        </p>
        <div className="flex items-center justify-between mt-6 space-x-4 w-full">
          <PrimaryButton
            text="Keep Current Session"
            onClick={handleKeepSession}
          />
          <NavigationButton text="Logout" onClick={closeModal} />
        </div>
      </div>
    </Modal>
  );
};

export default ActiveSessionExpireModal;
