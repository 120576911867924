const GetValidationErrors = (errors) => {
    let keys = Object.keys(errors);
    if (keys.length === 1) {
      return errors[keys];
    } else {

      let error = keys.map((key) => errors[key]);
      return error;
    }
  };

  export default GetValidationErrors;