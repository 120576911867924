import { SessionActionTypes } from "../../action-types";

const session = localStorage.getItem("session")

const initialState = session ? JSON.parse(session) : {
  sessionTimeLeft: process.env.REACT_APP_SESSION_LIFE_TIME || 3600000,
  showModalDuration: process.env.REACT_APP_ALERT_SESSION_LIFE_EXPIRE || 300000,
  showModal: false,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SessionActionTypes.SHOW_SESSION_MODAL:
      return {
        ...state,
        showModal: true,
      };
    case SessionActionTypes.CLOSE_SESSION_MODAL:
      return {
        ...state,
        showModal: false,
      };
    case SessionActionTypes.KEEP_SESSION:
      return {
        ...state,
        showModal: false,
        sessionTimeLeft: action.payload.sessionTimeLeft,
        showModalDuration: action.payload.showModalDuration,
      };
    case SessionActionTypes.UPDATE_SESSION_TIMER:
      return {
        ...state,
        sessionTimeLeft: action.payload.sessionTimeLeft,
        showModalDuration: action.payload.showModalDuration,
      };
    case SessionActionTypes.SESSION_EXPIRED:
      return {
        sessionTimeLeft: 0,
        showModalDuration: 0,
        showModal: false,
      };
    default:
      return state;
  }
};

export default sessionReducer;
