import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";
import { Header } from "../../components";
import Footer from "../../components/Footer";
import HeaderLogo from "../../components/HeaderLogo";
import RequiredDocumentsPage from "../Dashboard/RequiredDocumentsPage";
import RejectedClient from "../Dashboard/rejectedClient";
import DraftClient from "../Dashboard/draftClient";
import PendingClient from "../Dashboard/PendingClient";
import KYCRequired from "../KYCRequired";

const PrivateRoutes = () => {
  const { user } = useSelector((state) => state.user);

  if (!user || !user.token) {
    return <Navigate to="/signin" />;
  }

  const registrationFlow = [
    "Draft",
    "Liveness Done",
    "Pending",
    "Rejected",
    "Update",
  ].includes(user.status);

  if ((user.status === "Approved" || "Pending") && user.kyc_requested === 1) {
    return <KYCRequired />;
  }

  return (
    <>
      {registrationFlow ? <HeaderLogo /> : <Header />}
      {user.token && (
        <>
          {(() => {
            switch (user.status) {
              case "Draft":
              case "Update":
                return <DraftClient />;
              case "Liveness Done":
                return <RequiredDocumentsPage user={user} />;
             case "Pending":
                return <PendingClient />;
              case "Rejected":
                return <RejectedClient rejectedReason={user.message} />;
              default:
                return <Outlet />;
            }
          })()}
        </>
      )}
      <Footer />
    </>
  );
};

export default PrivateRoutes;
