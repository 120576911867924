import commonService from "../../../../services/Common/service";
import {
  CommonActionTypes,
  ElgibleCurrenciesActions,
} from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const getClientEligibleCurrency = () => async (dispatch) => {

  try {
    const res = await commonService.get(
      "/client/mobile-requests/apply-currency"
    );

    dispatch({
      type: ElgibleCurrenciesActions.GET_ELIGIBLE_CURRENCIES,
      payload: res.data.data,
    });

    localStorage.setItem("eligibleCurrencies", JSON.stringify(res.data.data));

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  }
};
