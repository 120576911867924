const TransferIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M21.7101 9.29019L17.7101 5.29019C17.6169 5.19695 17.5062 5.12299 17.3844 5.07253C17.2626 5.02207 17.132 4.99609 17.0001 4.99609C16.7338 4.99609 16.4784 5.10188 16.2901 5.29019C16.1018 5.47849 15.996 5.73388 15.996 6.00019C15.996 6.26649 16.1018 6.52188 16.2901 6.71019L18.5901 9.00019H7.00014C6.73492 9.00019 6.48057 9.10554 6.29303 9.29308C6.1055 9.48062 6.00014 9.73497 6.00014 10.0002C6.00014 10.2654 6.1055 10.5198 6.29303 10.7073C6.48057 10.8948 6.73492 11.0002 7.00014 11.0002H21.0001C21.1976 10.9992 21.3903 10.9398 21.554 10.8295C21.7177 10.7191 21.8451 10.5628 21.9201 10.3802C21.9967 10.1981 22.0176 9.99736 21.9803 9.80337C21.9429 9.60938 21.8489 9.43081 21.7101 9.29019ZM17.0001 13.0002H3.00014C2.80271 13.0012 2.61 13.0606 2.44628 13.1709C2.28256 13.2812 2.15516 13.4376 2.08014 13.6202C2.00356 13.8023 1.98264 14.003 2.02001 14.197C2.05739 14.391 2.15138 14.5696 2.29014 14.7102L6.29014 18.7102C6.3831 18.8039 6.4937 18.8783 6.61556 18.9291C6.73742 18.9798 6.86813 19.006 7.00014 19.006C7.13215 19.006 7.26286 18.9798 7.38472 18.9291C7.50658 18.8783 7.61718 18.8039 7.71014 18.7102C7.80387 18.6172 7.87826 18.5066 7.92903 18.3848C7.9798 18.2629 8.00594 18.1322 8.00594 18.0002C8.00594 17.8682 7.9798 17.7375 7.92903 17.6156C7.87826 17.4937 7.80387 17.3831 7.71014 17.2902L5.41014 15.0002H17.0001C17.2654 15.0002 17.5197 14.8948 17.7072 14.7073C17.8948 14.5198 18.0001 14.2654 18.0001 14.0002C18.0001 13.735 17.8948 13.4806 17.7072 13.2931C17.5197 13.1055 17.2654 13.0002 17.0001 13.0002Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default TransferIcon;