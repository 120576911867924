const YellowEllipse = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="Ellipse 4961"
          cx="8.75"
          cy="8"
          r="7"
          className={className}
          strokeWidth="2"
        />
      </svg>
    </div>
  );
};

export default YellowEllipse;
