const CloseAccountIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20.739 8.004L15.996 3.261C15.8235 3.09654 15.5953 3.00332 15.357 3H8.643C8.40471 3.00332 8.17647 3.09654 8.004 3.261L3.261 8.004C3.09654 8.17647 3.00332 8.40471 3 8.643V15.357C3.00332 15.5953 3.09654 15.8235 3.261 15.996L8.004 20.739C8.17647 20.9035 8.40471 20.9967 8.643 21H15.357C15.5953 20.9967 15.8235 20.9035 15.996 20.739L20.739 15.996C20.9035 15.8235 20.9967 15.5953 21 15.357V8.643C20.9967 8.40471 20.9035 8.17647 20.739 8.004ZM19.2 14.979L14.979 19.2H9.021L4.8 14.979V9.021L9.021 4.8H14.979L19.2 9.021V14.979Z"
          className="fill-primary-item"
        />
        <path
          d="M13.1314 12L15.7657 9.36569C16.0781 9.05327 16.0781 8.54673 15.7657 8.23431C15.4533 7.9219 14.9467 7.9219 14.6343 8.23431L12 10.8686L9.36569 8.23432C9.05327 7.9219 8.54673 7.9219 8.23431 8.23432C7.9219 8.54673 7.9219 9.05327 8.23431 9.36569L10.8686 12L8.23431 14.6343C7.9219 14.9467 7.9219 15.4533 8.23431 15.7657C8.54673 16.0781 9.05327 16.0781 9.36569 15.7657L12 13.1314L14.6343 15.7657C14.9467 16.0781 15.4533 16.0781 15.7657 15.7657C16.0781 15.4533 16.0781 14.9467 15.7657 14.6343L13.1314 12Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default CloseAccountIcon;
