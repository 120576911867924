import React from "react";

const Skeleton = ({ className, children }) => (
  <>
    {children ? (
      <div
        className={`bg-skeleton-end ${className}`}
      >
        {children}
      </div>
    ) : (
      <div
        className={`skeleton-animation ${className}`}
      />
    )}
  </>
);

export default Skeleton;
