import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import {
  ClientInfoActionTypes,
  CommonActionTypes,
} from "../../../action-types";

export const getClientAccounts = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.get("/client/mobile-home");

    if (res.data.success && res.data.data) {
      localStorage.setItem("clientInfo", JSON.stringify(res.data.data));
    }
    
    const { data } = res;

    dispatch({
      type: ClientInfoActionTypes.CLIENT_INFO,
      payload: data.data,
    });
    return data.data;
  } catch (err) {
    const message = err.response.data.message || err.message;
    
    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
