import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6667 5H9.16667C8.24619 5 7.5 5.91868 7.5 7.05192V16.2856C7.5 17.4188 8.24619 18.3375 9.16667 18.3375H16.6667C17.5871 18.3375 18.3333 17.4188 18.3333 16.2856V7.05192C18.3333 5.91868 17.5871 5 16.6667 5Z"
        className="stroke-current text-primary-item"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
        />
      <path
        d="M6.66406 14.9996H5.83073C5.3887 14.9996 4.96478 14.824 4.65222 14.5114C4.33966 14.1989 4.16406 13.775 4.16406 13.3329V3.33293C4.16406 2.8909 4.33966 2.46698 4.65222 2.15442C4.96478 1.84185 5.3887 1.66626 5.83073 1.66626H13.3307C13.7728 1.66626 14.1967 1.84185 14.5092 2.15442C14.8218 2.46698 14.9974 2.8909 14.9974 3.33293V4.16626"
        className="stroke-current text-primary-item"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
