import { useDispatch } from "react-redux";
import { useState } from "react";
import { deleteSupportingDoc } from "../../redux/actions/TRrequest/SupportingDocument";

const useDeleteDocument = (initialDocuments) => {
  
  const [documents, setDocuments] = useState(initialDocuments);
  const dispatch = useDispatch();

  const handleDeleteDocument = async (doc) => {
    
    const data = {
      document_id: doc.id,
      trn_id: doc.transaction_id,
    };

    const res = await dispatch(deleteSupportingDoc(data));

    if (res.success) {
      setDocuments(documents.filter((document) => document.id !== doc.id));
    }
  };

  return { documents, setDocuments, handleDeleteDocument };
};

export default useDeleteDocument;
