const HideIcon = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="11"
        viewBox="0 0 18 11"
        fill="none"
      >
        <path
          d="M16.7067 1C15.0725 4.44007 12.17 6.50381 8.99671 6.50381H9.00004C5.82671 6.50381 2.92421 4.44007 1.29004 1"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.07663 4.07723C3.41397 3.73988 3.96092 3.73988 4.29826 4.07723C4.6356 4.41457 4.6356 4.96151 4.29826 5.29886L2.47464 7.12248C2.13729 7.45982 1.59035 7.45982 1.25301 7.12248C0.915664 6.78513 0.915664 6.23819 1.25301 5.90085L3.07663 4.07723ZM15.7515 4.07723C15.4141 3.73988 14.8672 3.73988 14.5299 4.07723C14.1925 4.41457 14.1925 4.96151 14.5299 5.29886L16.3535 7.12248C16.6908 7.45982 17.2378 7.45982 17.5751 7.12248C17.9125 6.78513 17.9125 6.23819 17.5751 5.90085L15.7515 4.07723ZM11.2214 5.93681C11.6796 5.80382 12.1588 6.06743 12.2918 6.5256L13.0213 9.03893C13.1543 9.4971 12.8907 9.97632 12.4325 10.1093C11.9743 10.2423 11.4951 9.97868 11.3621 9.52052L10.6326 7.00718C10.4996 6.54902 10.7632 6.06979 11.2214 5.93681ZM6.53439 6.5256C6.66738 6.06743 7.1466 5.80382 7.60477 5.93681C8.06294 6.06979 8.32655 6.54902 8.19356 7.00718L7.46405 9.52052C7.33107 9.97868 6.85184 10.2423 6.39368 10.1093C5.93551 9.97632 5.6719 9.4971 5.80489 9.03893L6.53439 6.5256Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default HideIcon;
