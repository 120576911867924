import React from "react";
import { DropdownCrossIcon, RightArrowIcon } from "../../Icons";
import { Link } from "react-router-dom";

const SmallButton = ({ text, cancel, back, href, ...props }) => {
  return (
    <>
      {href ? (
        <Link to={href}>
          <div className="flex items-center">
            <button
              className="flex items-center bg-transparent underline px-3 py-2 rounded-full hover:bg-hover-state-bg disabled:bg-[#DFDBD3] disabled:bg-opacity-50 text-primary-item text-sm font-medium"
              {...props}
            >
              {cancel && <DropdownCrossIcon />}
              {back && <RightArrowIcon className={"rotate-180"} />}
              {text}
            </button>
          </div>
        </Link>
      ) : (
        <div className="flex items-center">
          <button
            className="flex items-center bg-transparent underline px-3 py-2 rounded-full hover:bg-hover-state-bg disabled:bg-[#DFDBD3] disabled:bg-opacity-50 text-primary-item text-sm font-medium"
            {...props}
          >
            {cancel && <DropdownCrossIcon />}
            {back && <RightArrowIcon className={"rotate-180"} />}
            {text}
          </button>
        </div>
      )}
    </>
  );
};

export default SmallButton;
