import React from "react";
import Skeleton from "../../Common/Skeleton";

const AddNewAccountSkeleton = () => {
  return (
    <>
      <div className="space-y-3">
        <Skeleton className="rounded-full h-6 w-[150px]" />
        <Skeleton className="rounded-full h-10 " />
      </div>
      {[1, 2].map((item) => (
        <div key={item} className="flex flex-col space-y-1">
          <Skeleton className="rounded-3xl h-[76px] flex items-center justify-between">
            <div className="flex space-x-3">
              <Skeleton className="rounded-full h-10 w-10 mx-2" />
              <span className="flex flex-col space-y-1">
                <Skeleton className="rounded-full w-[146px] h-[16px]" />
                <Skeleton className="rounded-full w-[135px] h-[16px]" />
              </span>
            </div>
          </Skeleton>
        </div>
      ))}
      <Skeleton className="rounded-full h-12" />
    </>
  );
};

export default AddNewAccountSkeleton;
