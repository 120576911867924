import React, { useState } from "react";
import Modal from "../../../Common/Modal";
import Card from "./partials/CardItem";
import { useDispatch } from "react-redux";
import { setActiveCard } from "../../../../redux/actions/Cards/setActiveCard";

const SelectCardModal = ({
  clientCards,
  activeCard,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeAccount = async (card) => {
    await dispatch(setActiveCard(card));
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={true}
        headerTitle={"Select Card"}
        className="w-[432px] max-h-[578px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl"
      >
        <div className="flex flex-col px-6 pb-6 space-y-4 pt-3">
          {clientCards.length ? (
            clientCards.map((card) => (
              <div
                key={card.id}
                onClick={() => handleChangeAccount(card)}
              >
                <Card
                  card={card}
                  activeCardId={activeCard.id}
                />
              </div>
            ))
          ) : (
            <div className="text-body-text p-16 font-medium">
              <p>No Card Found</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SelectCardModal;
