const FormatBalance = (balance, isCrypto = false) => {
  balance = Number(balance);

  if (isNaN(balance)) {
    return "N/A";
  }

  // Use the string version of the balance and add commas for thousands, keeping all decimals
  if (isCrypto) {
    return balance.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 20, // To preserve all decimal values without unnecessary truncation
      useGrouping: true,
    });
  } else {
    return balance.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2, // To preserve all decimal values without unnecessary truncation
      useGrouping: true,
    });
  }
};

export default FormatBalance;
