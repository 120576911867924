import React, { useEffect, useState } from "react";
import ProgressBar from "../../../components/ProgessBar";
import Label from "../../../components/Label";
import { useDispatch, useSelector } from "react-redux";
import SelectedAccountModal from "../../../components/Modals/SelectAccount/SelectAccountModal";
import {
  FormatBalance,
  FormatTRDate,
  GetCurrenySymbol,
  GetValidationErrors,
} from "../../../helper";
import { FormInputField } from "../../../components";
import { useDeleteDocument, useForm } from "../../../hooks";
import {
  depositTR,
  updateDepositTR,
} from "../../../redux/actions/TRrequest/Deposit";
import CancelTransactionModal from "../../../components/Modals/CancelTransactionModal";
import { PrimaryButton, SmallButton } from "../../../components/Buttons";
import TransactionOTPModal from "../../../components/Modals/TransactionsOTPModal";
import { Review, TransactionDetail } from "../../../components/Transactions";
import Steps from "../../../components/Common/Steps";
import AccountDropdown from "../../../components/Common/AccountDropdown";
import { verifyTROTP } from "../../../redux/actions/TRrequest/OTPVerification";
import { setPageTitle } from "../../../helper/metaDataHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactionDetails } from "../../../redux/actions/Transaction/TransactionHistory";
import { setActiveAccount } from "../../../redux/actions/home/ActiveAccountAction";
import ErrorMessage from "../../../components/Common/ErrorMessage";
import PreviousDocs from "../../../components/Common/PreviousDocs";
import MultipleFileUpload from "../../../components/MultipleFileUpload";

const Deposit = () => {
  const [step, setStep] = useState(1);
  const labels = ["Deposit Amount", "Review"];
  const location = useLocation();
  const { documents, setDocuments, handleDeleteDocument } = useDeleteDocument(
    []
  );
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);

  // this need to be fixed from api side to get only the fiat accounts
  const fiatAccounts = useSelector((state) =>
    state.client.clientInfo.accounts.filter(
      (account) => account.is_crypto === 0 || account.is_crypto === null
    )
  );
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const [loading, setLoading] = useState(false);
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [trn_id, setTrnId] = useState("");
  const [error, setError] = useState([]);

  const amountInput = useForm("", "amount");
  const remarksInput = useForm("");
  const [supportingDocument, setSupportingDocument] = useState("");
  const transactionDate = FormatTRDate(new Date());
  const [isDepositConfirmed, setIsDepositConfirmed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle("Deposit");
  }, []);

  const handleNext = () => {
    if (!validateDeposit()) return;
    if (step < labels.length + 1) setStep(step + 1);
  };

  const handlePrev = () => {
    if (step > 1 && !isDepositConfirmed) setStep(step - 1);
  };

  const handleDeposit = async () => {
    setIsOTPModalOpen(true);
    setLoading(true);

    const formData = new FormData();

    formData.append("account_id", activeAccount.id);
    formData.append("amount", amountInput.value);
    formData.append("remarks", remarksInput.value);
    formData.append("transaction_date", transactionDate);

    if (supportingDocument) {
      Object.keys(supportingDocument).forEach((key) => {
        supportingDocument[key].forEach((file) => {
          formData.append("supporting_documents[]", file);
        });
      });
    }

    let res;

    try {
      if (location.state) {
        formData.append("trn_id", location.state);
        res = await dispatch(updateDepositTR(formData));
      } else {
        res = await dispatch(depositTR(formData));
      }

      setLoading(false);

      if (res.success) {
        setIsOTPModalOpen(true);
        setTrnId(res.data.trn_id);
        setIsDepositConfirmed(true);
        location.state = null;
      } else if (!res.success) {
        if (res.errors) {
          setError(GetValidationErrors(res.errors));
        } else {
          setError(res.error);
        }
        setStep(1);
        setIsOTPModalOpen(false);
        setTimeout(() => {
          setLoading(false);
        }, 500);
        return;
      }
    } catch (error) {
      setLoading(false);
      setIsOTPModalOpen(false);
      setStep(1);
    }
  };

  useEffect(() => {
    if (location.state) {
      // get transaction details
      const fetchTransactionDetails = async () => {
        const data = {
          trn_id: location.state,
          transaction_nature: "TT",
        };
        setLoading(true);
        const res = await dispatch(getTransactionDetails(data));

        if (res.success) {
          amountInput.setValue(res.data.amount);
          remarksInput.setValue(res.data.remarks);
          const account = fiatAccounts.find(
            (account) => account.id === res.data.account_id
          );
          if (account) {
            dispatch(setActiveAccount(account));
          }

          if (res.data.docs && res.data.docs.length > 0) {
            setDocuments(res.data.docs);
          }
        }
        setLoading(false);
      };
      fetchTransactionDetails();
    }
  }, [location.state, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  const validateDeposit = () => {
    let validate = true;
    setError("");

    if (amountInput.validate()) {
      validate = false;
      amountInput.setError("Please enter a valid amount.");
    }
    if (!remarksInput.value) {
      validate = false;
      remarksInput.setError("Please enter remarks.");
    }

    setStep(1);

    return validate;
  };

  const handleViewTransaction = async () => {
    setIsOTPModalOpen(false);
    setStep(1);
    navigate("/all-transactions");
  };

  const handleFileChange = (name, selectedFiles) => {
    setSupportingDocument((prevFiles) => {
      const updatedFiles = { ...prevFiles, [name]: selectedFiles };
      if (!selectedFiles.length) {
        delete updatedFiles[name];
        return "";
      }
      return updatedFiles;
    });
    if (error) {
      setError([]);
    }
  };

  const isContinueEnabled =
    amountInput.value &&
    remarksInput.value &&
    (supportingDocument || documents.length > 0);

  return (
    <div className="max-w-[992px] mx-auto">
      <div className="inline-flex space-x-2 px-2 pb-8">
        <CancelTransactionModal contextLabel="Cancel Deposit">
          <SmallButton text={"Cancel Deposit"} cancel={true} />
        </CancelTransactionModal>
      </div>
      <div className="flex flex-col md:items-center md:justify-center">
        <Steps step={step} labels={labels} />
        <ProgressBar step={step} labels={labels} />
      </div>
      <div className="flex flex-col items-center justify-center md:p-6">
        <div className="py-4">
          {step === 1 && (
            <div className="space-y-4 md:max-w-[375px] w-[375px] max-[360px]:w-[340px]">
              {error.length > 0 && (
                <ErrorMessage message={error} onClose={() => setError("")} />
              )}

              <h3 className="text-sm font-normal text-paragraph-text mb-4">
                You're Balance:{" "}
                <span
                  className={`text-body-text text-sm font-bold tracking-tight
                ${activeAccount.balance <= 0 && "text-highlighter-red"}
                `}
                >
                  {FormatBalance(activeAccount.balance)}{" "}
                  {activeAccount.currency_code}
                </span>
              </h3>
              <div className="mb-4">
                <Label
                  className="block text-sm font-medium text-body-text mb-3"
                  htmlFor="account"
                  text="Select account"
                />
                <SelectedAccountModal
                  activeAccount={activeAccount}
                  clientAccounts={fiatAccounts}
                >
                  <AccountDropdown activeAccount={activeAccount} />
                </SelectedAccountModal>
                {error.accountError && (
                  <span className="text-highlighter-red text-sm px-2">
                    {error.accountError}
                  </span>
                )}
              </div>
              <div>
                <FormInputField
                  label="Amount"
                  name="amount"
                  id="amount"
                  type="number"
                  placeholder="Enter amount"
                  required={true}
                  currency={GetCurrenySymbol(
                    activeAccount.currency_code,
                    "13",
                    "13"
                  )}
                  {...amountInput}
                />
              </div>
              <div>
                <FormInputField
                  label="Remarks"
                  name="remarks"
                  id="remarks"
                  type="text"
                  placeholder="Add note"
                  {...remarksInput}
                />
              </div>
              <div>
                <FormInputField
                  label="Transaction Date"
                  name="transaction_date"
                  id="transaction_date"
                  type="text"
                  value={transactionDate}
                  onChange={() => {}}
                  readOnly
                />
              </div>
              <div>
                <MultipleFileUpload
                  name="supporting_documents"
                  id="supporting_documents"
                  title={"Supporting document"}
                  onChange={handleFileChange}
                  isMultiple={true}
                  maxCount={5}
                  mandatory={true}
                  files={files}
                  setFiles={setFiles}
                />
              </div>

              <PreviousDocs
                documents={documents}
                handleDeleteDocument={handleDeleteDocument}
              />

              <hr className="flex-grow w-full md:w-96 border-divider-border" />
              <div className="flex md:w-96 w-full">
                <PrimaryButton
                  onClick={handleNext}
                  disabled={!isContinueEnabled}
                  text="Continue"
                />
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="md:w-[568px] px-2">
              <Review
                handlePrev={handlePrev}
                handleTransaction={handleDeposit}
                amount={`${amountInput.value} ${activeAccount.currency_code}`}
                supportingDocument={supportingDocument}
                message=" will be added to
              the below account details."
                isTransactionConfirmed={isDepositConfirmed}
              >
                <TransactionDetail
                  label="You're adding"
                  value={`${amountInput.value} ${activeAccount.currency_code}`}
                />
                <TransactionDetail label="Nature" value="Deposit" />
                <hr className="flex w-full border-divider-border" />
                <TransactionDetail
                  label="Account name"
                  value={activeAccount.title}
                />
                <TransactionDetail
                  label="Recipient account number"
                  value={activeAccount.account_number}
                />
                <hr className="w-full border-divider-border" />
                <TransactionDetail label="Remarks" value={remarksInput.value} />
              </Review>
            </div>
          )}
        </div>
      </div>
      <TransactionOTPModal
        isLoading={loading}
        setisLoading={setLoading}
        TrnId={trn_id}
        setTrnId={setTrnId}
        isModalOpen={isOTPModalOpen}
        setIsModalOpen={setIsOTPModalOpen}
        handleTransaction={handleDeposit}
        reduxaction={verifyTROTP}
        navigateUrl="/"
        handleViewTransaction={handleViewTransaction}
      />
    </div>
  );
};

export default Deposit;
