import React from "react";
import PrimaryCardButton from "../PrimaryCardButton";
import {
  AddIcon,
  BankDetailsIcon,
  BlockCardIcon,
  ChangeCardPinIcon,
  CryptoBuyIcon,
  CryptoDepositIcon,
  CryptoSellIcon,
  CryptoWithdrawIcon,
  StatementIcon,
  TopUpIcon,
  TopupListIcon,
  TransferIcon,
  UnblockCardIcon,
} from "../../../Icons";
import BankDetailsModal from "../../../Modals/BankDetailsModal";
import AccountStatementModal from "../../../Modals/AccountStatementModal";
import BlockUnblockCardModal from "../../../Modals/Card/BlockUnblockCardModal";
import { BlockedCard } from "../../../../redux/actions/Cards/BlockedCard";
import { UnblockCard } from "../../../../redux/actions/Cards/UnblockCard";
import ChangePinModal from "../../../Modals/Card/ChangePinModal";
import TopupCardModal from "../../../Modals/Card/ToupCardModal";
import CardStatmentModal from "../../../Modals/Card/CardStatmentModal";
import { useModules } from "../../../../hooks";
import AddAccountModal from "../../../Modals/AddAccount/AddAccountModal";
import { useSelector } from "react-redux";

const ButtonCardType = (type, activeCard) => {
  const isBitpaceEnabled = useModules("Bitpace");
  const isCryptoEnabled = useModules("Crypto");
  const { user } = useSelector((state) => state.user);

  switch (type) {
    case "account":
      return (
        <>
          {user && !user.is_basic_client && (
            <>
              <PrimaryCardButton
                label="Deposit"
                icon={<AddIcon className={"h-6 w-6"} />}
                link="/deposit"
              />
              <PrimaryCardButton
                label="Transfer"
                icon={<TransferIcon className={"h-6 w-6"} />}
                link="/transfer"
              />{" "}
            </>
          )}
          <BankDetailsModal>
            <PrimaryCardButton
              label="Details"
              icon={<BankDetailsIcon className={"h-6 w-6"} />}
            />
          </BankDetailsModal>
          <AccountStatementModal>
            <PrimaryCardButton
              label="Statement"
              icon={<StatementIcon className={"h-6 w-6"} />}
            />
          </AccountStatementModal>
        </>
      );
    case "crypto":
      return (
        <>
          {isBitpaceEnabled || isCryptoEnabled ? (
            <>
              {isBitpaceEnabled && (
                <PrimaryCardButton
                  label="Deposit"
                  icon={<CryptoDepositIcon className={"h-6 w-6"} />}
                  link="/crypto-deposit"
                />
              )}
              <PrimaryCardButton
                label="Buy"
                icon={<CryptoBuyIcon />}
                link="/crypto-buy"
              />
              <PrimaryCardButton
                label="Sell"
                icon={<CryptoSellIcon />}
                link="/crypto-sell"
              />
              <PrimaryCardButton
                label="Withdraw"
                icon={<CryptoWithdrawIcon className={"h-6 w-6"} />}
                link="/crypto-withdraw"
              />
              <AccountStatementModal type="crypto">
                <PrimaryCardButton
                  label="Statement"
                  icon={<StatementIcon className={"h-6 w-6"} />}
                />
              </AccountStatementModal>
            </>
          ) : (
            <>
              <AddAccountModal crypto={true}>
                <PrimaryCardButton
                  label="Add Crypto"
                  icon={<AddIcon className={"h-6 w-6"} />}
                  // link="/crypto-deposit"
                />
              </AddAccountModal>

              <AccountStatementModal type="crypto">
                <PrimaryCardButton
                  label="Statement"
                  icon={<StatementIcon className={"h-6 w-6"} />}
                />
              </AccountStatementModal>
            </>
          )}
        </>
      );
    case "card":
      return (
        <>
          {activeCard.status === "Active" ? (
            <>
              <TopupCardModal activeCard={activeCard}>
                <PrimaryCardButton
                  label="Top-up"
                  icon={<TopUpIcon className={"h-6 w-6"} />}
                />
              </TopupCardModal>
              <BlockUnblockCardModal
                activeCard={activeCard}
                contextlabel="Block card"
                reasonlabel="block"
                reduxAction={BlockedCard}
              >
                <PrimaryCardButton
                  label="Block card"
                  icon={<BlockCardIcon className={"h-6 w-6"} />}
                />
              </BlockUnblockCardModal>
              <ChangePinModal activeCard={activeCard}>
                <PrimaryCardButton
                  label="Change Pin"
                  icon={<ChangeCardPinIcon className={"h-6 w-6"} />}
                />
              </ChangePinModal>
              <CardStatmentModal activeCard={activeCard}>
                <PrimaryCardButton
                  label="Statement"
                  icon={<StatementIcon className={"h-6 w-6"} />}
                />
              </CardStatmentModal>

              <PrimaryCardButton
                link={"/card-topup"}
                label="Top-up list"
                icon={<TopupListIcon />}
              />
            </>
          ) : (
            <BlockUnblockCardModal
              activeCard={activeCard}
              contextlabel="Unblock card"
              reasonlabel="unblock"
              reduxAction={UnblockCard}
            >
              <PrimaryCardButton
                label="Unblock card"
                icon={<UnblockCardIcon className={"h-6 w-6"} />}
              />
            </BlockUnblockCardModal>
          )}
        </>
      );
    default:
      return null;
  }
};

export default ButtonCardType;
