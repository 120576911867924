import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const applyForNewCard = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.from_web = true;
    const res = await commonService.post(
      "/client/mobile-requests/new-card",
      data
    );

    return res.data;
  } catch (error) {
    const message = error.response.data.message || error.message;

    ResponseErrorHandling(error.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
