import React from "react";
import Skeleton from "../../Common/Skeleton";

const ServiceRequestTableSkeleton = () => {
  return (
    <div className="flex flex-col space-y-3 md:max-w-full w-full">
      <Skeleton className="rounded-3xl flex flex-col space-y-2 pb-5">
        <div className="flex items-start justify-between max-md:hidden">
          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
            <Skeleton key={item} className="rounded-md h-4 w-20 mx-8 my-3" />
          ))}
        </div>

        <div className="flex items-center justify-between md:hidden">
          {[1, 2, 3].map((item) => (
            <Skeleton key={item} className="rounded-md h-5 w-20 mx-8" />
          ))}
        </div>

        <Skeleton className="rounded-full h-1 w-full" />

        <div className="md:block hidden">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <div
              key={item}
              className="flex items-center justify-between w-full px-7 pb-2 py-2"
            >
              <Skeleton className="rounded-md h-5 w-20" />

              <span className="flex items-center justify-between space-x-2">
                <Skeleton className="rounded-full h-10 w-10" />
                <Skeleton className="rounded-md h-7 w-20" />
              </span>

              <Skeleton className="rounded-md h-5 w-20 mx-2" />
              <Skeleton className="rounded-md h-5 w-20 mx-2" />
              <Skeleton className="rounded-md h-5 w-20 mx-2" />
              <Skeleton className="rounded-md h-5 w-20 mx-2" />
              <Skeleton className="rounded-md h-5 w-20 mx-2" />
            </div>
          ))}
        </div>

        <div className="block md:hidden">
          {[1, 2, 3, 4, 5, 6, 7].map((item) => (
            <div
              key={item}
              className="flex items-center justify-between w-full px-5 pb-2"
            >
              <div className="flex flex-col space-y-2">
                <Skeleton className="rounded-md h-5 w-20" />
                <Skeleton className="rounded-md h-3 w-14" />
              </div>
              <Skeleton className="rounded-md h-5 w-20" />

              <Skeleton className="rounded-md h-5 w-20 mx-2" />
            </div>
          ))}
        </div>
      </Skeleton>
    </div>
  );
};

export default ServiceRequestTableSkeleton;
