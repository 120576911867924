import React from "react";
import Modal from "../../Common/Modal";
import { NavigationButton, PrimaryButton } from "../../Buttons";
import { CautionIcon } from "../../Icons";

const ConfirmWithdrawCrypto = ({
  wallteAddress,
  isModalOpen,
  setIsModalOpen,
  handleWithDraw,
  children,
}) => {
  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={() => setIsModalOpen(false)}
        // header={true}
        headerTitle="Confirm wallet address"
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        <div className="flex flex-col px-6 py-4 space-y-4 items-center">
          <CautionIcon className="w-20 h-20" fillColor={"fill-icon-btn"} />
          <h3 className="text-sm font-normal text-body-text text-center">
            Please double-check the wallet address.
            <span>
              <br />(<b>{wallteAddress}</b>)
              <br />
            </span>
            Once the amount is deposited to a wrong wallet address, it can never
            be reversed.
          </h3>
        </div>
        <div className="flex items-center justify-center pb-6 pt-3 whitespace-nowrap space-x-4 px-6">
          <PrimaryButton text="Continue" onClick={handleWithDraw} />
          <NavigationButton
            text="Cancel"
            onClick={() => setIsModalOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
};

export default ConfirmWithdrawCrypto;
