import React from "react";

const INRSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 14}
      height={height ? height : 23}
      viewBox="0 0 14 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.576 23L0.528 12.44V11.032H1.232C2.36267 11.032 3.312 10.9253 4.08 10.712C4.86933 10.4987 5.488 10.1467 5.936 9.656C6.384 9.16533 6.65067 8.49333 6.736 7.64H0.528V5.592H6.704C6.55467 4.80267 6.256 4.16267 5.808 3.672C5.36 3.16 4.752 2.78667 3.984 2.552C3.23733 2.31733 2.32 2.2 1.232 2.2H0.528V0.151999H13.776V2.2H7.728C8.21867 2.60533 8.624 3.08533 8.944 3.64C9.264 4.19467 9.46667 4.84533 9.552 5.592H13.776V7.64H9.616C9.46667 9.19733 8.86933 10.4133 7.824 11.288C6.8 12.1413 5.43467 12.6853 3.728 12.92L9.84 23H6.576Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default INRSymbol;
