import React from "react";
import Skeleton from "../../Common/Skeleton";

const PriceSummarySkeleton = () => {
  return (
    <div className="p-6 bg-primarycard-gradient rounded-3xl shadow-box-shadow">
      <div className="text-body-text">
        <h2 className="text-xl font-bold mb-2">Price Summary</h2>
        <div className="flex flex-col space-y-2">
          {[1, 2, 3, 4].map((item) => (
            <Skeleton key={item} className="rounded-full w-[210px] h-[16px]" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PriceSummarySkeleton;
