import { ClientProfilesActionTypes } from "../../action-types";

const clientProfiles = JSON.parse(localStorage.getItem("clientProfiles"));

const initialState = {
  clientProfiles: clientProfiles ? clientProfiles : null,
};

export default function clientProfilesReducer(state = initialState, action) {
  switch (action.type) {
    case ClientProfilesActionTypes.GET_ALL_CLIENT_PROFILES:
      return {
        clientProfiles: action.payload,
      };
    default:
      return state;
  }
}
