import React from "react";
import { GetThemeClass } from "../../../helper";

const HangTightLoading = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center md:max-w-[432px] h-[252px] max-[360px]:h-[210px] bg-popup-bg">
      {GetThemeClass("light", "dark") === "dark" ? (
        <img src="assets/gifs/hangTightDark.gif" alt="loader" />
      ) : (
        <img src="assets/gifs/hangTightLight.gif" alt="loader" />
      )}

      <div className="text-center text-body-text text-2xl font-medium tracking-tight">
        Hang tight!
      </div>
      <div className="text-center text-body-text text-sm font-normal tracking-tight pt-3">
        {message ? message : "We are placing your transaction"}
        <br />
        Don’t refresh / close the browser.
      </div>
    </div>
  );
};

export default HangTightLoading;
