import {
  CommonActionTypes,
  RecentCardTransactionsAction,
} from "../../../action-types";
import { ResponseErrorHandling } from "../../../../helper";
import commonService from "../../../../services/Common/service";

export const getCardRecentTransaction = (data) => async (dispatch) => {
  try {
    const res = await commonService.post("/client/card/chart-data-dc", data);

    dispatch({
      type: RecentCardTransactionsAction.UPDATE_RECENT_CARD_TRANSACTIONS,
      payload: { last_10: res.data.data.last_10, card_id: data.card_id },
    });

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;
    ResponseErrorHandling(err.response, dispatch);
    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message: message, isError: true },
    });
    return err.response;
  }
};
