import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { HeaderTabs, RecentTranactions } from "../../components";
import PrimaryCardBG from "../../components/Card/PrimaryCardBG";
import { GetCards } from "../../redux/actions/Cards/GetCards";
import { useDispatch, useSelector } from "react-redux";
import OtherCards from "../../components/OthersCards";
import ViewRecentTransaction from "../../components/RecentTranactions/partials/ViewRecentTransaction";
import EmptyCard from "../../components/Empty/EmptyCard";
import {
  CardButtonSkeleton,
  OtherCardsSkeletons,
  PrimaryActiveCardSkeleton,
  RecentTransactionsSkeleton,
} from "../../components/Skeleton";
import CustomTransition from "../../components/CustomTransition";
import { setPageTitle } from "../../helper/metaDataHelper";
import CardButton from "../../components/Card/CardButton";
import AddNewCardModal from "../../components/Modals/Card/AddNewCardModal";
import { setActiveCard } from "../../redux/actions/Cards/setActiveCard";
import { useRecentTransaction } from "../../hooks/useRecentTransaction";
import ReccentTransactionSideBar from "../../components/Common/RecentTransactionsSideBar";

const Card = () => {
  const [activeTransaction, setActiveTransaction] = useState({});
  const [ShowRecentTransaction, setShowRecentTransaction] = useState(false);
  const [recentTransactionsLoading, setRecentTransactionsLoading] =
    useState(false);
  const dispatch = useDispatch();
  const { lastThreeTransactions } = useRecentTransaction(
    setRecentTransactionsLoading,
    "card"
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasCard = useSelector((state) => state.client.clientInfo.has_card);
  const { activeCard } = useSelector((state) => state.activeCard);
  const { clientCards } = useSelector((state) => state.clientCards);

  useEffect(() => {
    setPageTitle("Cards");
  }, []);

  useEffect(() => {
    const fetchCards = async () => {
      try {
        setLoading(true);
        const res = await dispatch(GetCards());
        if (!res.data) {
          setError("Failed to fetch cards. Please try again later.");
        }
      } catch (error) {
        setError(
          "An error occurred while fetching cards. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };
    if (hasCard) {
      fetchCards();
    }
  }, [dispatch, hasCard]);

  const handleCardChange = (index) => {
    const selectedCard = clientCards[index];
    dispatch(setActiveCard(selectedCard));
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: handleCardChange,
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto md:px-4 px-1 max-[360px]:px-1 min-h-screen">
        <div className="flex flex-col sm:flex-row justify-center gap-3">
          {/* First section */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-3">
            <HeaderTabs />
            {hasCard ? (
              loading ? (
                <>
                  <PrimaryActiveCardSkeleton />
                  <RecentTransactionsSkeleton />
                </>
              ) : error ? (
                <EmptyCard />
              ) : (
                <>
                  {activeCard && (
                    <div className="space-y-4">
                      <div className="block md:hidden pb-3">
                        <Slider {...settings}>
                          {clientCards.map((card) => (
                            <PrimaryCardBG type={"card"} activeCard={card} />
                          ))}
                        </Slider>
                      </div>
                      <div className="hidden md:block">
                        <PrimaryCardBG type={"card"} activeCard={activeCard} />
                      </div>
                      {!recentTransactionsLoading && lastThreeTransactions ? (
                        <div className="max-[360px]:px-2">
                          <RecentTranactions
                            activeTransaction={activeTransaction}
                            transactions={lastThreeTransactions}
                            setActiveTransaction={setActiveTransaction}
                            setShowRecentTransaction={setShowRecentTransaction}
                            activeCard={activeCard}
                            type="card"
                          />
                        </div>
                      ) : (
                        <RecentTransactionsSkeleton />
                      )}
                      <div className="md:hidden block max-[360px]:px-1.5">
                        <AddNewCardModal>
                          <CardButton
                            title="Add New Card"
                            subtitle="Let's get your card ready!"
                          />
                        </AddNewCardModal>
                      </div>
                    </div>
                  )}
                </>
              )
            ) : (
              <EmptyCard />
            )}
          </div>
          {/* Second section */}
          {hasCard && loading ? (
            <div className="xl:w-[404px] w-[350px] hidden md:block">
              <OtherCardsSkeletons />
              <CardButtonSkeleton />
            </div>
          ) : clientCards && clientCards.length > 0 ? (
            <div className="xl:w-[404px] w-[350px] hidden md:block mt-2">
              <>
                <CustomTransition show={ShowRecentTransaction}>
                  {ShowRecentTransaction && (
                    <>
                      <div className="bg-primarycard-gradient rounded-3xl p-6 mt-2 space-y-4 h-[700px]">
                        <ViewRecentTransaction
                          transaction={activeTransaction}
                          setShowRecentTransaction={setShowRecentTransaction}
                          ShowRecentTransaction={ShowRecentTransaction}
                          setActiveTransaction={setActiveTransaction}
                          type="card"
                        />
                      </div>
                    </>
                  )}
                </CustomTransition>

                <CustomTransition show={!ShowRecentTransaction}>
                  {!ShowRecentTransaction && (
                    <OtherCards cards={clientCards} activeCard={activeCard} />
                  )}
                </CustomTransition>
              </>
            </div>
          ) : null}
          <ReccentTransactionSideBar
            ShowRecentTransaction={ShowRecentTransaction}
            setShowRecentTransaction={setShowRecentTransaction}
            activeTransaction={activeTransaction}
            setActiveTransaction={setActiveTransaction}
            type="card"
          />
        </div>
      </div>
    </main>
  );
};

export default Card;
