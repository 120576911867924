import React, { useEffect, useRef, useState } from "react";
import { SmallButton } from "../../components/Buttons";
import { CardTopupNav, CardTopupTable } from "../../components/Cards/CardTopup";
import CustomTransition from "../../components/CustomTransition";
import ViewRecentTransaction from "../../components/RecentTranactions/partials/ViewRecentTransaction";
import { useDispatch, useSelector } from "react-redux";
import { GetTopupLists } from "../../redux/actions/Cards/GetTopupLists";
import TransactionsFiltersSidebar from "../../components/Modals/TransactionsFiltersSidebar";
import ReccentTransactionSideBar from "../../components/Common/RecentTransactionsSideBar";
import CardDropdown from "../../components/Common/CardDropdown";
import SelectCardSidebar from "../../components/Modals/Card/SelectCardSidebar";
import TopupCardModal from "../../components/Modals/Card/ToupCardModal";
import { setPageTitle } from "../../helper/metaDataHelper";

const CardTopup = () => {
  const [filteredTopups, setFilteredTopups] = useState([]);
  const { clientCards } = useSelector((state) => state.clientCards);
  const { activeCard } = useSelector((state) => state.activeCard);
  const [showTransaction, setShowTransaction] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState(2);
  const [period, setPeriod] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [limit] = useState(20);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const [showTopupModal, setShowTopupModal] = useState(false);

  const loadMoreTransactions = async (nextPage) => {
    try {
      setLoadMore(true);
      let response = {};
      const payload = {
        card_id: activeCard.id,
        page: nextPage,
        from_date: fromDate ? fromDate : null,
        to_date: toDate ? toDate : null,
        period: period ? period : 0,
        limit,
      };
      response = await dispatch(GetTopupLists(payload));
      const allTransactions = response.data.data;
      const currentPage = response.data.current_page;
      const lastPage = response.data.last_page;

      if (allTransactions.length === 0 || currentPage >= lastPage) {
        setHasMore(false);
      } else if (currentPage === nextPage) {
        setFilteredTopups((prev) => [...prev, ...allTransactions]);
      }
      setLoadMore(false);
    } catch (error) {
      console.error(error);
      setLoadMore(false);
    }
  };

  const fetchTopups = async () => {
    const payload = {
      card_id: activeCard.id,
      page: 1,
      from_date: fromDate,
      to_date: toDate,
      limit,
      ...(period > 0 && { period }), // Add period only if it's greater than 0
    };

    try {
      setLoading(true);
      setShowTransaction(false);
      const { data } = await dispatch(GetTopupLists(payload));

      if (data?.data?.length > 0) {
        setFilteredTopups(data.data);
        scrollRef.current.scrollTop = 0;
        if (data.last_page > 1) {
          setHasMore(true);
        }
        setPage(2);
      } else {
        setHasMore(false);
        setFilteredTopups([]);
      }
    } catch (error) {
      console.error("Error fetching top-ups:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetFilters = () => {
    setFromDate("");
    setToDate("");
    setPeriod(0);
    setReset(true);
  };

  useEffect(() => {
    setPageTitle("Card Topups");
  }, []);

  useEffect(() => {
    if (period) {
      fetchTopups();
    }
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reset) {
      fetchTopups();
      scrollRef.current.scrollTop = 0;
      setReset(false);
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetFilters();
    scrollRef.current.scrollTop = 0;
  }, [activeCard.id]);

  const handleSelectTransaction = (transaction) => {
    setActiveTransaction(transaction);
    setShowTransaction(true);
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4">
        <div className="flex flex-col w-full">
          <SmallButton href={"/card"} text="Back" back={true} />
          <div className="flex md:flex-row flex-col md:items-center md:w-[572px] w-full justify-between py-2 md:py-4">
            <h1 className="text-2xl font-semibold text-body-text">
              Card Topups
            </h1>
            <div className="space-x-3 py-3 md:hidden block">
              <TransactionsFiltersSidebar
                activeAccount={activeCard}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                fetchTransactions={fetchTopups}
                period={period}
                setPeriod={setPeriod}
                resetFilters={resetFilters}
              >
                <div className="space-y-4">
                  <p className="text-body-text text-sm font-medium tracking-tight">
                    Select card
                  </p>

                  <div className="gap-4">
                    <SelectCardSidebar
                      activeCard={activeCard}
                      clientCards={clientCards}
                    >
                      <CardDropdown activeCard={activeCard} />
                    </SelectCardSidebar>
                  </div>
                </div>
              </TransactionsFiltersSidebar>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-3">
          {/* First sections */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <CardTopupTable
              topups={filteredTopups}
              handleSelectTransaction={handleSelectTransaction}
              activeTransaction={activeTransaction}
              loadMoreTransactions={loadMoreTransactions}
              isLoading={loading}
              hasMore={hasMore}
              setPage={setPage}
              page={page}
              loadMore={loadMore}
              scrollRef={scrollRef}
            />
          </div>

          {/* Second sections */}
          <div className="xl:w-[404px] w-[350px] hidden md:block">
            <>
              <CustomTransition show={showTransaction}>
                {showTransaction && (
                  <>
                    <div className="bg-primarycard-gradient rounded-3xl p-6 space-y-4 min-h-[644px]">
                      <ViewRecentTransaction
                        transaction={activeTransaction}
                        setShowRecentTransaction={setShowTransaction}
                        showTransaction={showTransaction}
                        setActiveTransaction={setActiveTransaction}
                        type="card"
                        fetchTopups={fetchTopups}
                      />
                    </div>
                  </>
                )}
              </CustomTransition>

              {/* SideBar */}
              <CustomTransition show={!showTransaction}>
                {!showTransaction && (
                  <CardTopupNav
                    fetchTopups={fetchTopups}
                    clientCards={clientCards}
                    // setTopupStatus={setTopupsStatus}
                    // topupStatus={topupsStatus}
                    fromDate={fromDate}
                    setFromDate={setFromDate}
                    toDate={toDate}
                    setToDate={setToDate}
                    period={period}
                    setPeriod={setPeriod}
                    resetFilters={resetFilters}
                    loading={loading}
                    
                  />
                )}
              </CustomTransition>
            </>

            <ReccentTransactionSideBar
              activeTransaction={activeTransaction}
              ShowRecentTransaction={showTransaction}
              setShowRecentTransaction={setShowTransaction}
              setActiveTransaction={setActiveTransaction}
            />
            <TopupCardModal CardModalOpen={showTopupModal} setCardModalOpen={setShowTopupModal}/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default CardTopup;
