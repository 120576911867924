import React from "react";
import CommonButton from "../../Buttons/CommonButton";

const SuccessMessage = ({ title, description, handleCloseButton }) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center space-y-4">
        <img src="assets/gifs/done.gif" alt="done" className="w-36 h-36" />

        <h2 className="text-center text-body-text text-2xl mt-6 font-medium leading-loose tracking-tight">
          {title}
        </h2>
        <h3 className="text-center text-paragraph-text text-sm font-normal leading-tight tracking-tight">
          {description}
        </h3>
      </div>
      <div className="pt-4">
        <CommonButton
          onClick={handleCloseButton}
          className="w-full rounded-full bg-secondary-icon-bg text-primary-item font-medium text-sm p-3"
        >
          Close
        </CommonButton>
      </div>
    </>
  );
};

export default SuccessMessage;
