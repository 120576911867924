import React from "react";
import ReceiveArrowIcon from "../ReceiveArrowIcon";

const CardDepositIcon = () => {
  return (
    <div>
      <div className="w-[42px] h-[42px] relative">
        <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-button-text rounded-full">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 8.5H13.5"
              stroke="url(#paint0_linear_1027_29225)"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 13.5H10"
              stroke="url(#paint1_linear_1027_29225)"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 12.03V14.11C22 17.62 21.11 18.5 17.56 18.5H6.44C2.89 18.5 2 17.62 2 14.11V7.89C2 5.38 2.89 4.5 6.44 4.5H13.5"
              stroke="url(#paint2_linear_1027_29225)"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.5 7.25H22"
              stroke="url(#paint3_linear_1027_29225)"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M19.25 10V4.5"
              stroke="url(#paint4_linear_1027_29225)"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1027_29225"
                x1="7.75"
                y1="8.5"
                x2="7.84433"
                y2="9.80181"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD876" />
                <stop offset="1" stopColor="#CD9F00" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1027_29225"
                x1="8"
                y1="13.5"
                x2="8.26129"
                y2="14.7542"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD876" />
                <stop offset="1" stopColor="#CD9F00" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1027_29225"
                x1="12"
                y1="4.5"
                x2="19.9739"
                y2="18.1695"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD876" />
                <stop offset="1" stopColor="#CD9F00" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1027_29225"
                x1="19.25"
                y1="7.25"
                x2="19.4438"
                y2="8.52927"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD876" />
                <stop offset="1" stopColor="#CD9F00" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1027_29225"
                x1="19.75"
                y1="4.5"
                x2="21.249"
                y2="4.82706"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFD876" />
                <stop offset="1" stopColor="#CD9F00" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="w-4 h-4 right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
          <ReceiveArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default CardDepositIcon;
