import { CopyIcon, DropdownCheckIcon } from "../../../Icons";
import Input from "../../../Input";
import Label from "../../../Label";

const BankDetailsInput = ({ label, value, name, onCopy, isCopied }) => {
  return (
    <div className="w-full md:w-96 h-auto md:h-[68px] px-4 py-3 relative rounded-3xl bg-option-list-hover shadow-sm">
      <Label
        htmlFor={name}
        className="block text-sm font-normal text-paragraph-text tracking-[0.07px]"
        text={label}
      />
      <Input
        type="text"
        name={name}
        id={name}
        value={value}
        className="block w-full bg-transparent border-0 p-0 text-sm font-medium tracking-[0.07px] text-body-text focus:ring-0"
        isDisabled={true}
      />
      <div
        onClick={onCopy}
        className="absolute top-3 right-0 flex items-center pr-3 hover:cursor-pointer"
      >
        {isCopied ? (
          <DropdownCheckIcon className={"h-6 w-6"} />
        ) : (
          <CopyIcon className={"h-6 w-6"} />
        )}
      </div>
    </div>
  );
};
export default BankDetailsInput;
