import React from "react";
import Modal from "../../../Common/Modal";
import ListItem from "../partails/ListItem";
import CommonButton from "../../../Buttons/CommonButton";
import InitialsAvatar from "../../../Common/InitialsAvatar";
import { useNavigate } from "react-router-dom";

const ShowBeneficiaryModal = ({ isModalOpen, setIsModalOpen, beneficiary }) => {
  const navigate = useNavigate();
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={() => setIsModalOpen(false)}
        header={true}
        headerTitle={"Beneficiary Details"}
        className="bg-popup-bg w-[432px] max-h-[640px] overflow-scroll hide-scrollbar rounded-3xl"
      >
        <div className="px-6">
          <div className="flex items-center space-x-3 py-4">
            <InitialsAvatar name={beneficiary.name} />
            <div className="text-body-text text-base font-medium tracking-tight">
              {beneficiary.name}
            </div>
          </div>

          <hr className="mb-4 border border-secondary-icon-bg dark:border-divider-border" />
          <div className="flex flex-col pb-10">
            <ListItem
              title={"Account Number"}
              value={beneficiary.account_number}
            />
            <ListItem title={"IBAN"} value={beneficiary.iban} />
            <ListItem title={"Full Name"} value={beneficiary.name} />
            <ListItem
              title={"Beneficiary Address"}
              value={beneficiary.address}
            />
            <ListItem title={"Bank Name"} value={beneficiary.bank_name} />
            <ListItem title={"Swift Code"} value={beneficiary.swift_code} />
            <ListItem
              title={"Bank Address "}
              value={beneficiary.bank_address}
            />
          </div>
          <div className="pb-6">
            <CommonButton
              onClick={() =>
                navigate("/transfer", {
                  state: { beneficiary: beneficiary, type: "transfer" },
                })
              }
            >
              Transfer amount
            </CommonButton>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShowBeneficiaryModal;
