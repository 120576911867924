import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "../../../Common/Modal";
import FormInputField from "../../../FormInputField";
import CommonButton from "../../../Buttons/CommonButton";
import useForm from "../../../../hooks/useForm";
import HangTightLoading from "../../../Common/HangTightLoading";
import SuccessMessage from "../../../Common/SuccessMessage";
import { addBeneficiary } from "../../../../redux/actions/TRrequest/Beneficiary";
import ErrorMessage from "../../../Common/ErrorMessage";

const AddBeneficiaryModal = ({
  addBeneficiaryModalOpen,
  setaddBeneficiaryModalOpen,
  children,
}) => {
  const [isloading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const account_number = useForm("");
  const iban = useForm("");
  const full_name = useForm("");
  const bank_name = useForm("");
  const swift_code = useForm("");
  const bank_address = useForm("");
  const beneficiary_address = useForm("");

  const dispatch = useDispatch();
  const handleOnCloseModal = () => {
    setaddBeneficiaryModalOpen(false);
    setTimeout(() => {
      setSuccess(false);
    }, 500);
  };

  const handleOnSubmit = async () => {
    setIsloading(true);
    if (
      account_number.value ||
      // iban.value ||
      full_name.value ||
      bank_name.value ||
      swift_code.value ||
      beneficiary_address.value ||
      bank_address.value
    ) {
      const data = {
        account_number: account_number.value,
        iban: iban.value,
        name: full_name.value,
        bank_name: bank_name.value,
        swift_code: swift_code.value,
        bank_address: bank_address.value,
        address: beneficiary_address.value,
      };
      let res = await dispatch(addBeneficiary(data));
      if (res.success) {
        setSuccess(true);
        account_number.reset();
        iban.reset();
        full_name.reset();
        bank_name.reset();
        swift_code.reset();
        bank_address.reset();
        beneficiary_address.reset();
      } else {
        console.error(res);
        setError(res.message);
        if (res.errors.account_number) {
          account_number.setError(res.errors.account_number);
        }
        if (res.errors.iban) {
          iban.setError(res.errors.iban);
        }
      }
    }
    setIsloading(false);
  };

  return (
    <>
      <div onClick={() => setaddBeneficiaryModalOpen(true)}>{children}</div>
      <Modal
        isOpen={addBeneficiaryModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={isloading || success ? false : true}
        headerTitle={"Add new beneficiary"}
        className="bg-popup-bg w-[432px] max-h-[613px] overflow-scroll hide-scrollbar bg-PopupBG shadow-xl rounded-3xl"
      >
        {error && (
          <div className="mx-6">
            <ErrorMessage message={error} />
          </div>
        )}
        {!isloading && !success && (
          <div className="px-6 pt-2">
            <div className="text-body-text text-sm font-medium leading-tight tracking-tight">
              Beneficiary details
            </div>
            <div className="my-2 space-y-3">
              <FormInputField
                label={"Account Number"}
                name={"account_number"}
                id={"account_number"}
                type={"number"}
                placeholder={"########"}
                value={account_number.value}
                onChange={account_number.onChange}
                {...account_number}
              />
              <FormInputField
                label={"IBAN"}
                name={"iban"}
                id={"iban"}
                type={"text"}
                placeholder={"#### #### #### #### ##"}
                value={iban.value}
                onChange={iban.onChange}
                {...iban}
              />
              <FormInputField
                label={"Full Name"}
                name={"full_name"}
                id={"full_name"}
                type={"text"}
                placeholder={"Full name of the account holder"}
                value={full_name.value}
                onChange={full_name.onChange}
              />
              <FormInputField
                label={"Beneficiary Address"}
                name={"beneficiary_address"}
                id={"beneficiary_address"}
                type={"text"}
                placeholder={"Address of the beneficiary"}
                value={beneficiary_address.value}
                onChange={beneficiary_address.onChange}
              />
              <FormInputField
                label={"Bank Name"}
                name={"bank_name"}
                id={"bank_name"}
                type={"text"}
                placeholder={"Bank name of the beneficiary account"}
                value={bank_name.value}
                onChange={bank_name.onChange}
              />
              <FormInputField
                label={"Swift Code"}
                name={"swift_code"}
                id={"swift_code"}
                type={"text"}
                placeholder={"Swift code of the beneficiary bank"}
                value={swift_code.value}
                onChange={swift_code.onChange}
              />
              <FormInputField
                label={"Bank Address"}
                name={"bank_address"}
                id={"bank_address"}
                type={"text"}
                placeholder={"Bank address of the beneficiary account"}
                value={bank_address.value}
                onChange={bank_address.onChange}
              />
            </div>
            <div className="flex justify-end py-6">
              <CommonButton
                onClick={handleOnSubmit}
                disabled={
                  !account_number.value ||
                  // !iban.value ||
                  !full_name.value ||
                  !bank_name.value ||
                  !swift_code.value ||
                  !bank_address.value
                }
              >
                Confirm beneficiary
              </CommonButton>
            </div>
          </div>
        )}

        {success && (
          <div className="p-6">
            <SuccessMessage
              title="Beneficiary added"
              description={`You've successfully added ${full_name.value} to your list of beneficiaries.`}
              handleCloseButton={handleOnCloseModal}
            />
          </div>
        )}

        {isloading && <HangTightLoading />}
      </Modal>
    </>
  );
};

export default AddBeneficiaryModal;
