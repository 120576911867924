import React, { useState } from "react";
import FormInputField from "../../../FormInputField";
import { CautionIcon, DownArrowIcon } from "../../../Icons";
import FormTextAreaField from "../../../FormTextAreaField";
import useForm from "../../../../hooks/useForm";
import { PrimaryButton } from "../../../Buttons";
import SelectCloseAccountModal from "../../../Modals/CloseAccountModal/SelectCloseAccountModal";
import ConfirmAccountClosure from "../../../Modals/CloseAccountModal/ConfirmAccountClosure";

const CloseAccount = () => {
  const [account, setAccount] = useState("");
  const reason = useForm("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isFormValid = () => {
    return account && reason.value;
  };

  const handleReset = () => {
    setAccount("");
    reason.reset();
    setIsModalOpen(false);
    setIsLoading(false);
    setErrorMessage("");
  };

  return (
    <div className="flex flex-col space-y-4 mx-2 max-[360px]:mx-0">
      <div className="inline-flex items-center">
        <h3 className="text-base text-body-text font-medium">
          We're sorry to see you go.
        </h3>
      </div>
        {errorMessage && (
          <div className="text-highlighter-red inline-flex items-center text-sm">
            <CautionIcon className="w-6 h-6"/>
            {errorMessage}
          </div>
        )}
      <div className="flex flex-col md:w-[384px] w-full space-y-4">
        <SelectCloseAccountModal
          account={account}
          setAccount={setAccount}
          setErrorMessage={setErrorMessage}
        >
          <FormInputField
            type="text"
            id="client_id"
            name="client_id"
            placeholder="Select account"
            label="Select account"
            readOnly
            icon={<DownArrowIcon />}
            value={account ? account.name : ""}
          />
        </SelectCloseAccountModal>

        <FormTextAreaField
          label="Reason"
          name="reason"
          id="reason"
          rows="5"
          cols="33"
          placeholder="Enter a reason for closing your account..."
          value={reason.value}
          onChange={reason.onChange}
        />
        <div className="mt-6"></div>
        <ConfirmAccountClosure
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setErrorMessage={setErrorMessage}
          errorMessage={errorMessage}
          handleReset={handleReset}
          account={account}
          reason={reason}
        >
          <PrimaryButton text="Close account" disabled={!isFormValid()} />
        </ConfirmAccountClosure>
      </div>
    </div>
  );
};

export default CloseAccount;
