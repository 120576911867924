import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ImpersonateUser } from "../../redux/actions/auth";
import { Outlet, useSearchParams } from "react-router-dom";
import LoadingSpinner from "../../components/Common/LoadingSpinner";

const CheckImperonate = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key");
  const impersonator = searchParams.get("impersonator");
  const impersonated = searchParams.get("impersonated");

  useEffect(() => {
    const handleImpersonate = async () => {
      const data = {
        key,
        impersonator,
        impersonated,
      };
      setLoading(true);
      const res = await dispatch(ImpersonateUser(data));
      if (res.success) {
        window.location.href = "/";
      } else {
        console.error("Error in impersonating user");
      }
      setLoading(false);
    };
    if (impersonator && impersonated && key) {
      handleImpersonate();
    }
    // eslint-disable-next-line
  }, [impersonator, impersonated, key]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <Outlet />;
    </>
  );
};

export default CheckImperonate;
