import React, { useState, useEffect, useRef } from "react";
import SmallButton from "../../components/Buttons/SmallButton";
import Table from "../../components/AllTransactions/Table";
import SideNav from "../../components/AllTransactions/SideNav";
import { useDispatch, useSelector } from "react-redux";
import { getAllTransactions } from "../../redux/actions/Transaction/TransactionHistory";
import ViewRecentTransaction from "../../components/RecentTranactions/partials/ViewRecentTransaction";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { DownloadIcon } from "../../components/Icons";
import AccountStatementModal from "../../components/Modals/AccountStatementModal";
import ReccentTransactionSideBar from "../../components/Common/RecentTransactionsSideBar";
import CustomTransition from "../../components/CustomTransition";
import TransactionsFiltersSidebar from "../../components/Modals/TransactionsFiltersSidebar";
import SelectedAccountSidebar from "../../components/Modals/SelectAccount/SelectAccountSidebar";
import AccountDropdown from "../../components/Common/AccountDropdown";
import { setPageTitle } from "../../helper/metaDataHelper";

const AllTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState("");
  const [showTransaction, setShowTransaction] = useState(false);
  const [activeTransaction, setActiveTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [reset, setReset] = useState(false);
  const [page, setPage] = useState(2);
  const [period, setPeriod] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [limit] = useState(20);
  const dispatch = useDispatch();
  const scrollRef = useRef(null);
  const { activeAccount } = useSelector((state) => state.activeAccount);
  const clientAccounts = useSelector((state) => state.client.clientInfo);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [accountType, setAccountType] = useState("");

  useEffect(() => {
    const accounts = clientAccounts.accounts.filter(
      (account) => Boolean(account.is_crypto) === Boolean(activeAccount.is_crypto)
    );
    setFilteredAccounts(accounts);
    setAccountType(activeAccount.is_crypto ? "crypto" : "fiat");
    // eslint-disable-next-line
  }, []);

  const loadMoreTransactions = async (nextPage) => {
    try {
      setLoadMore(true);
      let response = {};
      const payload = {
        account_id: activeAccount.id,
        page: nextPage,
        from_date: fromDate ? fromDate : null,
        to_date: toDate ? toDate : null,
        period: period ? period : 0,
        limit,
        status: transactionStatus === "" ? null : transactionStatus,
      };
      response = await dispatch(getAllTransactions(payload));

      const allTransactions = response.data;
      const currentPage = response.current_page;
      const lastPage = response.last_page;

      if (allTransactions.length === 0 || currentPage >= lastPage) {
        setHasMore(false);
      } else if (currentPage === nextPage) {
        setTransactions((prev) => [...prev, ...allTransactions]);
        setFilteredTransactions((prev) => [...prev, ...allTransactions]);
      }
      setLoadMore(false);
    } catch (error) {
      console.error(error);
      setLoadMore(false);
    }
  };

  const fetchTransactions = async () => {
    try {
      const payload = {
        account_id: activeAccount.id,
        page: 1,
        from_date: fromDate,
        to_date: toDate,
        limit,
        status: transactionStatus === "" ? null : transactionStatus,
      };
      if (period > 0) {
        payload.period = period;
      }
      setLoading(true);
      const allTransactions = await dispatch(getAllTransactions(payload));

      setTransactions(allTransactions.data);
      setFilteredTransactions(allTransactions.data);
      // setTransactionStatus("");
      scrollRef.current.scrollTop = 0;
      setHasMore(true);
      setPage(2);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const resetFilters = () => {
    setTransactionStatus("");
    setFromDate("");
    setToDate("");
    setPeriod(0);
    setReset(true);
  };

  useEffect(() => {
    setPageTitle(`${accountType === "crypto" ? "Crypto" : "Fait"} Transactions`);
  }, [accountType]);

  useEffect(() => {
    if (transactionStatus !== "") {
      fetchTransactions();
    }
  }, [transactionStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (period) {
      fetchTransactions();
    }
  }, [period]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reset) {
      fetchTransactions();
      scrollRef.current.scrollTop = 0;
      setReset(false);
    }
  }, [reset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    resetFilters();
    scrollRef.current.scrollTop = 0;
  }, [activeAccount.id]);

  useEffect(() => {
    let filtered = transactions;
    switch (transactionStatus) {
      case "pending":
        filtered = transactions.filter(
          (transaction) => transaction.status === "Pending"
        );
        break;
      case "approved":
        filtered = transactions.filter(
          (transaction) => transaction.status === "Approved"
        );
        break;
      case "declined":
        filtered = transactions.filter(
          (transaction) => transaction.status === "Rejected"
        );
        break;
      case "initial review":
        filtered = transactions.filter(
          (transaction) => transaction.status === "Initial Review"
        );
        break;
      default:
        filtered = transactions;
    }
    setFilteredTransactions(filtered);
  }, [transactionStatus, transactions]);

  const handleSelectTransaction = (transaction) => {
    setActiveTransaction(transaction);
    setShowTransaction(true);
  };

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4 min-h-screen">
        <div className="flex flex-col md:w-[82px]">
          <SmallButton
            href={accountType === "crypto" ? "/crypto" : "/"}
            text="Back"
            back={true}
          />
        </div>

        <div className="flex md:flex-row flex-col md:items-center md:w-[572px] w-full justify-between py-2 md:py-4">
          <h1 className="text-2xl font-semibold text-body-text">
            All Transactions
          </h1>
          <div className="flex space-x-3 py-3">
            <AccountStatementModal>
              <div className="md:block hidden">
                <PrimaryButton
                  text="Statement Download"
                  icon={<DownloadIcon />}
                />
              </div>
              <div className="md:hidden block">
                <PrimaryButton text="Statement" icon={<DownloadIcon />} />
              </div>
            </AccountStatementModal>

            <div className="md:hidden block">
              <TransactionsFiltersSidebar
                transactionStatus={transactionStatus}
                setTransactionStatus={setTransactionStatus}
                activeAccount={activeAccount}
                fromDate={fromDate}
                setFromDate={setFromDate}
                toDate={toDate}
                setToDate={setToDate}
                fetchTransactions={fetchTransactions}
                period={period}
                setPeriod={setPeriod}
                resetFilters={resetFilters}
                accounts={filteredAccounts}
              >
                <div className="space-y-4">
                  <p className="text-body-text text-sm font-medium tracking-tight">
                    Select account
                  </p>

                  <div className="gap-4">
                    <SelectedAccountSidebar
                      activeAccount={activeAccount}
                      clientAccounts={filteredAccounts}
                    >
                      <AccountDropdown activeAccount={activeAccount} />
                    </SelectedAccountSidebar>
                  </div>
                </div>
              </TransactionsFiltersSidebar>
            </div>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center gap-3">
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4 md:pb-5 ">
            <Table
              transactions={filteredTransactions}
              handleSelectTransaction={handleSelectTransaction}
              activeTransaction={activeTransaction}
              loadMoreTransactions={loadMoreTransactions}
              loading={loading}
              hasMore={hasMore}
              setPage={setPage}
              page={page}
              loadMore={loadMore}
              scrollRef={scrollRef}
              currencyCode={activeAccount.currency_code}
              type={accountType}
            />
          </div>

          <div className="xl:w-[404px] w-[350px] hidden md:block">
            <>
              <CustomTransition show={showTransaction}>
                {showTransaction && (
                  <div className="bg-primarycard-gradient rounded-3xl p-6 space-y-4 min-h-[644px]">
                    <ViewRecentTransaction
                      transaction={activeTransaction}
                      setShowRecentTransaction={setShowTransaction}
                      showTransaction={showTransaction}
                      setActiveTransaction={setActiveTransaction}
                      type={accountType}
                    />
                  </div>
                )}
              </CustomTransition>

              <div className="md:block hidden">
                <CustomTransition show={!showTransaction}>
                  {!showTransaction && (
                    <SideNav
                      transactionStatus={transactionStatus}
                      setTransactionStatus={setTransactionStatus}
                      activeAccount={activeAccount}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      fetchTransactions={fetchTransactions}
                      period={period}
                      setPeriod={setPeriod}
                      resetFilters={resetFilters}
                      loading={loading}
                      accounts={filteredAccounts}
                      type={accountType}
                    />
                  )}
                </CustomTransition>
              </div>
            </>
          </div>

          <ReccentTransactionSideBar
            activeTransaction={activeTransaction}
            ShowRecentTransaction={showTransaction}
            setShowRecentTransaction={setShowTransaction}
            setActiveTransaction={setActiveTransaction}
            type={accountType}
          />
        </div>
      </div>
    </main>
  );
};

export default AllTransactions;
