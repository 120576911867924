const ExternalTransferIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2134 11.999C7.4705 12.5132 7.86619 12.9453 8.35582 13.2466L12.7201 15.932C12.5791 16.1513 12.2871 16.2148 12.0678 16.0738L7.22307 12.9594L2.36865 16.3201C2.15432 16.4685 1.86028 16.415 1.7119 16.2007C1.56352 15.9864 1.61698 15.6923 1.83131 15.544L7.2134 11.999Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.21381 11.999C6.95202 12.2608 6.66927 12.5008 6.36839 12.7165L1.88342 15.932C2.02828 16.1574 2.3204 16.2361 2.5589 16.1141L3.67377 15.544L7.21381 13.179L12.2349 16.3201C12.4492 16.4685 12.7432 16.415 12.8916 16.2007C13.04 15.9864 12.9865 15.6923 12.7722 15.544L7.21381 11.999Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.13262 22.6197C2.13262 22.3481 2.35274 22.128 2.62429 22.128H3.44374L3.44374 16.2935H2.13246C1.39329 16.2935 2.01978 15.7513 2.65318 15.3062H2.9591C2.95691 15.3075 2.95471 15.3088 2.95252 15.3101H11.4739C11.4717 15.3088 11.4695 15.3075 11.4673 15.3062H12.3887C12.6139 15.5545 12.6854 15.8672 12.4324 16.2935H10.9827V22.128H11.8022C12.0737 22.128 12.2938 22.3481 12.2938 22.6197C12.2938 22.8912 12.0737 23.1113 11.8022 23.1113H2.62429C2.35274 23.1113 2.13262 22.8912 2.13262 22.6197ZM9.99937 22.128H7.7049V16.2935H9.99937V22.128ZM6.72155 22.128L6.72155 16.2935H4.42708L4.42708 22.128H6.72155Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2134 0.887695C17.4705 1.4019 17.8662 1.83402 18.3558 2.13529L22.7201 4.82071C22.5791 5.03999 22.2871 5.10347 22.0678 4.96251L17.2231 1.84803L12.3686 5.20878C12.1543 5.35717 11.8603 5.30371 11.7119 5.08938C11.5635 4.87505 11.617 4.58101 11.8313 4.43263L17.2134 0.887695Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.2138 0.887695C16.952 1.14948 16.6693 1.38943 16.3684 1.60515L11.8834 4.82071C12.0283 5.04604 12.3204 5.1248 12.5589 5.00282L13.6738 4.43263L17.2138 2.06771L22.2349 5.20878C22.4492 5.35717 22.7432 5.30371 22.8916 5.08938C23.04 4.87505 22.9865 4.58101 22.7722 4.43263L17.2138 0.887695Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.1326 11.5083C12.1326 11.2368 12.3527 11.0167 12.6243 11.0167H13.4437L13.4437 5.18215H12.1325C11.3933 5.18215 12.0198 4.64002 12.6532 4.19485H12.9591C12.9569 4.19617 12.9547 4.19748 12.9525 4.1988H21.4739C21.4717 4.19748 21.4695 4.19617 21.4673 4.19485H22.3887C22.6139 4.44321 22.6854 4.75588 22.4324 5.18215H20.9827V11.0167H21.8022C22.0737 11.0167 22.2938 11.2368 22.2938 11.5083C22.2938 11.7799 22.0737 12 21.8022 12H12.6243C12.3527 12 12.1326 11.7799 12.1326 11.5083ZM19.9994 11.0167H17.7049V5.18215H19.9994V11.0167ZM16.7216 11.0167L16.7216 5.18215H14.4271L14.4271 11.0167H16.7216Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.178 18.1995C13.2694 17.9311 13.5485 17.7805 13.8213 17.8413L13.884 17.8591L14.0021 17.9001C15.7151 18.4616 17.5584 17.575 18.1638 15.8915C18.1987 15.7943 18.229 15.696 18.2545 15.5971L18.0648 15.683C17.7869 15.8113 17.4546 15.6899 17.3227 15.412C17.1908 15.134 17.3092 14.8047 17.5872 14.6764L19.0108 14.0195C19.2887 13.8912 19.621 14.0126 19.7529 14.2906L20.4285 15.7142C20.5604 15.9921 20.4419 16.3215 20.164 16.4497C19.886 16.578 19.5537 16.4566 19.4218 16.1787L19.3155 15.9526C19.2857 16.0609 19.2516 16.1684 19.2133 16.2749C18.422 18.4757 16.0443 19.6549 13.7899 18.9917L13.6548 18.9496L13.5278 18.9056C13.2362 18.8046 13.0796 18.4885 13.178 18.1995ZM3.00675 8.81076C3.21793 8.6239 3.53509 8.62773 3.74533 8.80936L3.79554 8.85847L3.96282 9.04466C3.95996 8.93237 3.96138 8.81964 3.96713 8.70664C4.09118 6.26737 6.18949 4.41077 8.65384 4.55981C8.96188 4.57844 9.19903 4.84072 9.18353 5.14563C9.16802 5.45054 8.90573 5.68261 8.59769 5.66398C6.74943 5.5522 5.17569 6.94465 5.08265 8.77411C5.0774 8.87739 5.07694 8.98026 5.08121 9.08251L5.2378 8.94569C5.46658 8.74326 5.81974 8.76462 6.02659 8.9934C6.21753 9.20458 6.21708 9.51867 6.03593 9.72496L5.98689 9.77417L4.81521 10.8109C4.60402 10.9977 4.28686 10.9939 4.07662 10.8123L4.02641 10.7632L2.96704 9.59153C2.76019 9.36275 2.77796 9.01319 3.00675 8.81076Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default ExternalTransferIcon;
