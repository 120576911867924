const RightArrowIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.752 11.75C15.752 11.4185 15.6203 11.1005 15.3858 10.8661L10.3858 5.86612C9.89768 5.37796 9.10622 5.37796 8.61807 5.86612C8.12991 6.35427 8.12991 7.14573 8.61807 7.63388L12.7342 11.75L8.61807 15.8661C8.12991 16.3543 8.12991 17.1457 8.61807 17.6339C9.10623 18.122 9.89768 18.122 10.3858 17.6339L15.3858 12.6339C15.6203 12.3995 15.752 12.0815 15.752 11.75Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default RightArrowIcon;