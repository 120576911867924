import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const GetCurrencyNetwork = (data) => async (dispatch) => {
  try {
    const res = await commonService.post(
      "/client/crypto-transaction-deposit/bitpace/get-currency-networks",
      data
    );
    const network = res.data.data;

    return network;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } 
};
