import React, { useEffect } from "react";
import BitpaceBuy from "../../../components/Crypto/CryptoBitpaceTransactions/BitpaceBuy";
import ManualBuy from "../../../components/Crypto/CryptoManualTransactions/ManualBuy";
import { useModules } from "../../../hooks";
import { setPageTitle } from "../../../helper/metaDataHelper";

const Buy = () => {
  const isModuleEnabled = useModules("Bitpace");
  
  useEffect(() => {
    setPageTitle("Crypto Buy");
  }, []);

  return <>{isModuleEnabled ? <BitpaceBuy /> : <ManualBuy />}</>;
};

export default Buy;
