import React from "react";
import Skeleton from "../../Common/Skeleton";

const HeaderSkeletons = () => {
  return (
    <div className="flex space-x-6">
      <div className="flex space-x-6">
        <Skeleton className="rounded-full hidden md:block w-12 h-12" />
        <Skeleton className="rounded-full hidden md:block w-12 h-12" />
        <Skeleton className="rounded-full w-12 h-12" />
      </div>
      <Skeleton className="rounded-full md:w-[185px] w-[100px] h-12" />
    </div>
  );
};

export default HeaderSkeletons;
