import React from "react";
import CommonButton from "../../../../../Buttons/CommonButton";
import { EditIcon } from "../../../../../Icons";

const EditTransactionButton = ({handleTransactionClick, transaction}) => {
  return (
    <CommonButton
      onClick={(e) => {
        e.stopPropagation();
        handleTransactionClick(transaction);
      }}
    >
      <div className="flex items-center justify-center">
        <EditIcon />
        Edit Transaction
      </div>
    </CommonButton>
  );
};

export default EditTransactionButton;
