import React, { useState } from "react";
import HangTightLoading from "../../../Common/HangTightLoading";
import SuccessMessage from "../../../Common/SuccessMessage";
import CommonButton from "../../../Buttons/CommonButton";
import Modal from "../../../Common/Modal";
import FormInputField from "../../../FormInputField";
import useForm from "../../../../hooks/useForm";
import InputValidation from "../../../../middlewares/InputValidation";
import { useDispatch } from "react-redux";
import { SetPin } from "../../../../redux/actions/Cards/SetPin";
import PasswordToggleIcon from "../../../Common/PasswordToggleIcon";

const ChangePinModal = ({ children, activeCard }) => {
  const [CardModalOpen, setCardModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const newPin = useForm("");
  const confirmPin = useForm("");
  const dispatch = useDispatch();

  const [showPin, setShowPin] = useState({
    new_pin: false,
    confirm_Pin: false,
  });

  const togglePinVisibility = (field) => {
    setShowPin({
      ...showPin,
      [field]: !showPin[field],
    });
  };

  const handleSubmit = async () => {
    if (newPin.value !== confirmPin.value) {
      setError("Pins do not match");
      return;
    }
    if (
      !InputValidation(newPin.value, "pin") ||
      !InputValidation(confirmPin.value, "pin")
    ) {
      setError("Invalid pin");
      return;
    }
    setisLoading(true);
    let data = {
      id: activeCard.id,
      new_pin: newPin.value,
      new_pin_confirmation: confirmPin.value,
    };
    const res = await dispatch(SetPin(data));
    if (res.success) {
      setSuccess(true);
      newPin.reset();
      confirmPin.reset();
    } else {
      setError(res.error);
    }
    setisLoading(false);
  };

  const isFormValid = () => {
    return newPin.value && confirmPin.value;
  };

  const handleCloseButton = () => {
    setCardModalOpen(false);
    setTimeout(() => {
      setSuccess(false);
    }, 300);
  };
  return (
    <>
      <div onClick={() => setCardModalOpen(true)}>{children}</div>
      <Modal
        isOpen={CardModalOpen}
        handleOnCloseModal={() => setCardModalOpen(false)}
        header={!isLoading && !success}
        headerTitle="Change pin"
        loading={isLoading}
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl pt-4"
      >
        {!isLoading && !success && (
          <div className="p-6 max-[360px]:p-4 space-y-4">
            <FormInputField
              type={showPin.new_pin ? "text" : "password"}
              placeholder="Set card new pin"
              label="New pin"
              name="new_pin"
              id="new_pin"
              {...newPin}
              icon={
                <PasswordToggleIcon
                  isVisible={showPin.new_pin}
                  onToggle={() => togglePinVisibility("new_pin")}
                />
              }
              maxLength={4}
            />
            <FormInputField
              type={showPin.confirm_pin ? "text" : "password"}
              placeholder="Confirm card pin"
              label="Confirm pin"
              name="confirm_pin"
              id="confirm_pin"
              {...confirmPin}
              icon={
                <PasswordToggleIcon
                  isVisible={showPin.confirm_pin}
                  onToggle={() => togglePinVisibility("confirm_pin")}
                />
              }
              maxLength={4}
            />
            <div className="mt-3"></div>
            {error && (
              <div className="text-highlighter-red text-sm font-semibold">
                {error}
              </div>
            )}
            <CommonButton onClick={handleSubmit} disabled={!isFormValid()}>
              Change card Pin
            </CommonButton>
          </div>
        )}
        {isLoading && !success && (
          <HangTightLoading message="We are placing your request!" />
        )}
        {success && !isLoading && (
          <div className="p-6 space-y-4">
            <SuccessMessage
              title="Card pin changed"
              description="Your card pin changed successfully"
              handleCloseButton={handleCloseButton}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ChangePinModal;
