import React from "react";

const AEDSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 29}
      height={height ? height : 33}
      viewBox="0 0 29 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.944 23L1.304 0.151999H3.896L4.536 23H1.944ZM1.08 32.696L0.696 30.968C1.25067 30.84 1.69867 30.7333 2.04 30.648C2.38133 30.5627 2.76533 30.4667 3.192 30.36L3.032 31.032C2.47733 30.8613 1.944 30.4987 1.432 29.944C0.941333 29.3893 0.696 28.76 0.696 28.056C0.696 27.416 0.845333 26.872 1.144 26.424C1.464 25.9973 1.86933 25.6773 2.36 25.464C2.872 25.2507 3.416 25.144 3.992 25.144C4.22667 25.144 4.472 25.1653 4.728 25.208C5.00533 25.2507 5.26133 25.3147 5.496 25.4L5.24 27C5.02667 26.9573 4.824 26.9147 4.632 26.872C4.44 26.8293 4.22667 26.808 3.992 26.808C3.50133 26.808 3.11733 26.904 2.84 27.096C2.56267 27.3093 2.424 27.5973 2.424 27.96C2.424 28.408 2.56267 28.792 2.84 29.112C3.13867 29.432 3.46933 29.6773 3.832 29.848C4.216 30.04 4.52533 30.136 4.76 30.136L3.448 30.264C4.024 30.1147 4.55733 29.9547 5.048 29.784C5.56 29.6347 6.01867 29.4853 6.424 29.336L6.936 30.968C6.424 31.16 5.816 31.3627 5.112 31.576C4.42933 31.7893 3.736 31.992 3.032 32.184C2.328 32.376 1.67733 32.5467 1.08 32.696ZM8.51937 23V18.8438H12.7381V23H8.51937ZM15.7837 21.56L17.0637 19.352C17.4904 19.6293 18.0877 19.896 18.8558 20.152C19.6238 20.408 20.5197 20.536 21.5437 20.536C22.6104 20.536 23.4531 20.4293 24.0717 20.216C24.7117 20.0027 25.1704 19.6933 25.4478 19.288C25.7464 18.8613 25.8958 18.3707 25.8958 17.816C25.8958 17.3893 25.7998 16.8667 25.6077 16.248C25.4371 15.608 25.0531 14.7547 24.4558 13.688C23.8584 12.6 22.9411 11.1813 21.7038 9.432L23.9117 7.992C25.0424 9.54933 25.9384 10.9573 26.5998 12.216C27.2824 13.4747 27.7624 14.584 28.0398 15.544C28.3384 16.4827 28.4878 17.2933 28.4878 17.976C28.4878 18.8293 28.2744 19.6613 27.8478 20.472C27.4211 21.2613 26.7064 21.912 25.7038 22.424C24.7011 22.936 23.3144 23.192 21.5437 23.192C20.7757 23.192 20.0291 23.128 19.3038 23C18.5997 22.872 17.9491 22.6907 17.3517 22.456C16.7544 22.2 16.2317 21.9013 15.7837 21.56Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default AEDSymbol;
