import React, { useEffect, useState } from "react";
import { SmallButton } from "../../components/Buttons";
import {
  ChangePasswordIcon,
  CloseAccountIcon,
  HelpIcon,
  ProfileIcon,
} from "../../components/Icons";
import {
  ChangePassword,
  CloseAccount,
  ProfileDetails,
} from "../../components/ProfileAccordion/partials";
import { useSelector } from "react-redux";
import HelpModal from "../../components/Modals/HelpModal";
import ProfileAccordion from "../../components/ProfileAccordion";
import { setPageTitle } from "../../helper/metaDataHelper";

const Settings = () => {
  const { userInfo } = useSelector((state) => state.userInfo);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [HelpModalOpen, setHelpModalOpen] = useState(false);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    setPageTitle("Settings");
  }, []);

  return (
    <main>
      <div className="max-w-[1024px] mx-auto px-4">
        <div className="flex flex-col sm:flex-row justify-center">
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <SmallButton cancel={true} text={"Close"} href={"/"} />
            <div className="text-body-text px-4 text-3xl font-semibold tracking-tight">
              Settings
            </div>
            <div className="flex items-center justify-center py-10">
              <div className="md:w-[572px] w-[360px] space-y-5 md:px-4 px-2">
                <ProfileAccordion
                  startIcon={<ProfileIcon />}
                  title="Profile details"
                  placeholder="View or update profile information"
                >
                  <ProfileDetails
                    loading={loading}
                    setLoading={setLoading}
                    success={success}
                    setSuccess={setSuccess}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    userInfo={userInfo}
                  />
                </ProfileAccordion>
                <ProfileAccordion
                  startIcon={<ChangePasswordIcon />}
                  title="Change password"
                  placeholder="Change your account password"
                >
                  <ChangePassword
                    loading={loading}
                    setLoading={setLoading}
                    success={success}
                    setSuccess={setSuccess}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />
                </ProfileAccordion>
                {user && !user.is_basic_client && (
                  <ProfileAccordion
                    startIcon={<CloseAccountIcon />}
                    title="Close account"
                    placeholder="Permanently close the account"
                  >
                    <CloseAccount />
                  </ProfileAccordion>
                )}
                <HelpModal
                  HelpModalOpen={HelpModalOpen}
                  setHelpModalOpen={setHelpModalOpen}
                >
                  <ProfileAccordion
                    startIcon={<HelpIcon />}
                    title="Help"
                    placeholder="Assistance and support queries"
                    icon={false}
                    openModal={true}
                    setModalOpen={setHelpModalOpen}
                  />
                </HelpModal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Settings;
