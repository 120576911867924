import React from "react";
import SendArrowIcon from "../SendArrowIcon";

const DebitMoneyIcon = () => {
  return (
    <div className="w-[42px] h-[42px] relative">
      <div className="flex items-center justify-center w-10 h-10 left-0 top-0 absolute bg-button-text rounded-full">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="money-stack">
            <g id="Group 12648">
              <g id="Rectangle (Stroke)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1883 5.17689C11.7491 5.66428 10.1747 6.19742 8.5 6.19742C7.41865 6.19742 6.09623 5.87882 5.15745 5.60868C5.05838 5.58017 4.95361 5.60076 4.87058 5.66069C4.78972 5.71905 4.75 5.79842 4.75 5.88342V15.7018C4.75 15.8132 4.8197 15.8959 4.90598 15.9207C5.83214 16.1865 7.41876 16.5987 8.55533 16.6828C10.1449 16.8004 11.349 16.308 12.7113 15.7399L12.7511 15.7233C14.0905 15.1647 15.5881 14.54 17.5553 14.6856C18.6147 14.7639 19.9365 15.0755 20.8791 15.3275C21.0778 15.3807 21.25 15.2261 21.25 15.0629V5.14585C21.25 5.03744 21.1854 4.96262 21.1111 4.93871C20.1908 4.64266 18.611 4.2002 17.5 4.2002C16.0937 4.2002 14.7365 4.65267 13.2405 5.15919L13.1883 5.17689ZM12.8117 3.72072C14.2509 3.23334 15.8253 2.7002 17.5 2.7002C18.8744 2.7002 20.6604 3.21799 21.5705 3.51078C22.2936 3.74341 22.75 4.41658 22.75 5.14585V15.0629C22.75 16.2542 21.6017 17.0734 20.4917 16.7766C19.5591 16.5273 18.3534 16.2487 17.4447 16.1815C15.8551 16.0639 14.651 16.5563 13.2887 17.1244L13.2489 17.141C11.9095 17.6996 10.4119 18.3242 8.44467 18.1787C7.13627 18.0819 5.41459 17.6272 4.49223 17.3625C3.74217 17.1472 3.25 16.4605 3.25 15.7018V5.88342C3.25 4.65548 4.45645 3.84609 5.57225 4.16717C6.49622 4.43305 7.64485 4.69742 8.5 4.69742C9.90634 4.69742 11.2635 4.24495 12.7595 3.73842L12.8117 3.72072Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.1883 5.17689C11.7491 5.66428 10.1747 6.19742 8.5 6.19742C7.41865 6.19742 6.09623 5.87882 5.15745 5.60868C5.05838 5.58017 4.95361 5.60076 4.87058 5.66069C4.78972 5.71905 4.75 5.79842 4.75 5.88342V15.7018C4.75 15.8132 4.8197 15.8959 4.90598 15.9207C5.83214 16.1865 7.41876 16.5987 8.55533 16.6828C10.1449 16.8004 11.349 16.308 12.7113 15.7399L12.7511 15.7233C14.0905 15.1647 15.5881 14.54 17.5553 14.6856C18.6147 14.7639 19.9365 15.0755 20.8791 15.3275C21.0778 15.3807 21.25 15.2261 21.25 15.0629V5.14585C21.25 5.03744 21.1854 4.96262 21.1111 4.93871C20.1908 4.64266 18.611 4.2002 17.5 4.2002C16.0937 4.2002 14.7365 4.65267 13.2405 5.15919L13.1883 5.17689ZM12.8117 3.72072C14.2509 3.23334 15.8253 2.7002 17.5 2.7002C18.8744 2.7002 20.6604 3.21799 21.5705 3.51078C22.2936 3.74341 22.75 4.41658 22.75 5.14585V15.0629C22.75 16.2542 21.6017 17.0734 20.4917 16.7766C19.5591 16.5273 18.3534 16.2487 17.4447 16.1815C15.8551 16.0639 14.651 16.5563 13.2887 17.1244L13.2489 17.141C11.9095 17.6996 10.4119 18.3242 8.44467 18.1787C7.13627 18.0819 5.41459 17.6272 4.49223 17.3625C3.74217 17.1472 3.25 16.4605 3.25 15.7018V5.88342C3.25 4.65548 4.45645 3.84609 5.57225 4.16717C6.49622 4.43305 7.64485 4.69742 8.5 4.69742C9.90634 4.69742 11.2635 4.24495 12.7595 3.73842L12.8117 3.72072Z"
                  fill="url(#paint0_linear_2996_15511)"
                />
              </g>
              <g id="Rectangle (Stroke)_2">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 8.2002C1.86193 8.2002 1.75 8.31212 1.75 8.4502V17.8288C1.75 17.9286 1.80553 18.0044 1.8736 18.0356C4.00979 19.0143 6.07074 19.499 8.55532 19.6828C10.1449 19.8004 11.3491 19.3081 12.7114 18.74L12.7512 18.7234C14.0905 18.1648 15.5881 17.5403 17.5553 17.6858C17.6249 17.6909 17.744 17.6987 17.891 17.7084C18.16 17.726 18.5225 17.7497 18.8456 17.7745C18.9424 17.7819 19.0483 17.7467 19.1322 17.6722C19.2147 17.599 19.25 17.512 19.25 17.4346V16.6217C19.25 16.5697 19.2342 16.5286 19.2153 16.5019C19.1978 16.4771 19.1776 16.4634 19.1537 16.4564C18.4397 16.2482 17.2517 16.0019 16.1233 16.19C15.0373 16.371 14.1133 16.7417 13.1381 17.1329C12.8536 17.247 12.5648 17.3629 12.2664 17.4763C10.9753 17.9669 9.57398 18.3729 7.8767 18.09C6.35292 17.836 5.19712 17.5592 4.50591 17.3751C3.74238 17.1717 3.25 16.4793 3.25 15.7229V8.4502C3.25 8.31212 3.13807 8.2002 3 8.2002H2ZM0.25 8.4502C0.25 7.4837 1.0335 6.7002 2 6.7002H3C3.9665 6.7002 4.75 7.4837 4.75 8.4502V15.7229C4.75 15.8336 4.81759 15.9058 4.89205 15.9256C5.54309 16.0991 6.65209 16.3652 8.1233 16.6104C9.42602 16.8275 10.5246 16.5335 11.7336 16.0741C11.98 15.9805 12.2347 15.878 12.4981 15.772C13.494 15.3713 14.6134 14.9209 15.8767 14.7104C17.3351 14.4673 18.7806 14.7851 19.5737 15.0164C20.3177 15.2334 20.75 15.9191 20.75 16.6217V17.4346C20.75 18.5332 19.7641 19.3493 18.731 19.2701C18.3957 19.2444 18.0935 19.2248 17.845 19.2087C17.6885 19.1985 17.5534 19.1897 17.4447 19.1817C15.8551 19.0641 14.6509 19.5564 13.2886 20.1245L13.2488 20.1411C11.9095 20.6997 10.4119 21.3242 8.44468 21.1787C5.80852 20.9837 3.56952 20.4625 1.24882 19.3993C0.625035 19.1135 0.25 18.4901 0.25 17.8288V8.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2 8.2002C1.86193 8.2002 1.75 8.31212 1.75 8.4502V17.8288C1.75 17.9286 1.80553 18.0044 1.8736 18.0356C4.00979 19.0143 6.07074 19.499 8.55532 19.6828C10.1449 19.8004 11.3491 19.3081 12.7114 18.74L12.7512 18.7234C14.0905 18.1648 15.5881 17.5403 17.5553 17.6858C17.6249 17.6909 17.744 17.6987 17.891 17.7084C18.16 17.726 18.5225 17.7497 18.8456 17.7745C18.9424 17.7819 19.0483 17.7467 19.1322 17.6722C19.2147 17.599 19.25 17.512 19.25 17.4346V16.6217C19.25 16.5697 19.2342 16.5286 19.2153 16.5019C19.1978 16.4771 19.1776 16.4634 19.1537 16.4564C18.4397 16.2482 17.2517 16.0019 16.1233 16.19C15.0373 16.371 14.1133 16.7417 13.1381 17.1329C12.8536 17.247 12.5648 17.3629 12.2664 17.4763C10.9753 17.9669 9.57398 18.3729 7.8767 18.09C6.35292 17.836 5.19712 17.5592 4.50591 17.3751C3.74238 17.1717 3.25 16.4793 3.25 15.7229V8.4502C3.25 8.31212 3.13807 8.2002 3 8.2002H2ZM0.25 8.4502C0.25 7.4837 1.0335 6.7002 2 6.7002H3C3.9665 6.7002 4.75 7.4837 4.75 8.4502V15.7229C4.75 15.8336 4.81759 15.9058 4.89205 15.9256C5.54309 16.0991 6.65209 16.3652 8.1233 16.6104C9.42602 16.8275 10.5246 16.5335 11.7336 16.0741C11.98 15.9805 12.2347 15.878 12.4981 15.772C13.494 15.3713 14.6134 14.9209 15.8767 14.7104C17.3351 14.4673 18.7806 14.7851 19.5737 15.0164C20.3177 15.2334 20.75 15.9191 20.75 16.6217V17.4346C20.75 18.5332 19.7641 19.3493 18.731 19.2701C18.3957 19.2444 18.0935 19.2248 17.845 19.2087C17.6885 19.1985 17.5534 19.1897 17.4447 19.1817C15.8551 19.0641 14.6509 19.5564 13.2886 20.1245L13.2488 20.1411C11.9095 20.6997 10.4119 21.3242 8.44468 21.1787C5.80852 20.9837 3.56952 20.4625 1.24882 19.3993C0.625035 19.1135 0.25 18.4901 0.25 17.8288V8.4502Z"
                  fill="url(#paint1_linear_2996_15511)"
                />
              </g>
              <g id="Ellipse (Stroke)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 8.2002C11.7574 8.2002 10.75 9.20755 10.75 10.4502C10.75 11.6928 11.7574 12.7002 13 12.7002C14.2426 12.7002 15.25 11.6928 15.25 10.4502C15.25 9.20755 14.2426 8.2002 13 8.2002ZM9.25 10.4502C9.25 8.37913 10.9289 6.7002 13 6.7002C15.0711 6.7002 16.75 8.37913 16.75 10.4502C16.75 12.5213 15.0711 14.2002 13 14.2002C10.9289 14.2002 9.25 12.5213 9.25 10.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13 8.2002C11.7574 8.2002 10.75 9.20755 10.75 10.4502C10.75 11.6928 11.7574 12.7002 13 12.7002C14.2426 12.7002 15.25 11.6928 15.25 10.4502C15.25 9.20755 14.2426 8.2002 13 8.2002ZM9.25 10.4502C9.25 8.37913 10.9289 6.7002 13 6.7002C15.0711 6.7002 16.75 8.37913 16.75 10.4502C16.75 12.5213 15.0711 14.2002 13 14.2002C10.9289 14.2002 9.25 12.5213 9.25 10.4502Z"
                  fill="url(#paint2_linear_2996_15511)"
                />
              </g>
              <g id="Vector (Stroke)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.25 11.4502C6.25 11.036 6.58579 10.7002 7 10.7002H7.01C7.42421 10.7002 7.76 11.036 7.76 11.4502C7.76 11.8644 7.42421 12.2002 7.01 12.2002H7C6.58579 12.2002 6.25 11.8644 6.25 11.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.25 11.4502C6.25 11.036 6.58579 10.7002 7 10.7002H7.01C7.42421 10.7002 7.76 11.036 7.76 11.4502C7.76 11.8644 7.42421 12.2002 7.01 12.2002H7C6.58579 12.2002 6.25 11.8644 6.25 11.4502Z"
                  fill="url(#paint3_linear_2996_15511)"
                />
              </g>
              <g id="Vector (Stroke)_2">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.25 9.4502C18.25 9.03598 18.5858 8.7002 19 8.7002H19.01C19.4242 8.7002 19.76 9.03598 19.76 9.4502C19.76 9.86441 19.4242 10.2002 19.01 10.2002H19C18.5858 10.2002 18.25 9.86441 18.25 9.4502Z"
                  fill="#FDD671"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M18.25 9.4502C18.25 9.03598 18.5858 8.7002 19 8.7002H19.01C19.4242 8.7002 19.76 9.03598 19.76 9.4502C19.76 9.86441 19.4242 10.2002 19.01 10.2002H19C18.5858 10.2002 18.25 9.86441 18.25 9.4502Z"
                  fill="url(#paint4_linear_2996_15511)"
                />
              </g>
            </g>
            <circle id="Ellipse 4958" cx="17.5" cy="6" r="6" fill="#5F4D21" />
            <g id="Group 12644">
              <path
                id="Vector"
                d="M15 5.75H20.5"
                stroke="url(#paint5_linear_2996_15511)"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                id="Vector_2"
                d="M17.75 8.5V3"
                stroke="url(#paint6_linear_2996_15511)"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </g>
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_2996_15511"
              x1="13"
              y1="2.70019"
              x2="22.3384"
              y2="16.7983"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_2996_15511"
              x1="10.5"
              y1="6.70019"
              x2="18.8006"
              y2="20.7828"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_2996_15511"
              x1="13"
              y1="6.7002"
              x2="17.827"
              y2="12.4925"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_2996_15511"
              x1="7.005"
              y1="10.7002"
              x2="7.96922"
              y2="11.865"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_2996_15511"
              x1="19.005"
              y1="8.7002"
              x2="19.9692"
              y2="9.86497"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_2996_15511"
              x1="17.75"
              y1="5.75"
              x2="17.9438"
              y2="7.02927"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_2996_15511"
              x1="18.25"
              y1="3"
              x2="19.749"
              y2="3.32706"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFD876" />
              <stop offset="1" stopColor="#CD9F00" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className="w-4 h-4 -right-0.5 top-6 absolute bg-icon-btn rounded-full border-2 border-icon-btn">
        <SendArrowIcon />
      </div>
    </div>
  );
};

export default DebitMoneyIcon;
