import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/actions/auth";
import Steps from "../../../components/Common/Steps";
import ProgressBar from "../../../components/ProgessBar";
import {
  CompanyInformation,
  Currency,
  EmployerInfo,
  IdentityVerification,
  PersonalInfo,
  Signature,
} from "../../../components/DraftClient";
import {
  createCompanyClient,
  createIndividualClient,
  getRejectedDraftClient,
  updateCompanyClient,
  updateIndividualClient,
} from "../../../redux/actions/register/draftClient";
import LoadingModal from "../../../components/Modals/LoadingModal";
import { SmallButton } from "../../../components/Buttons";
import { updateUserStatus } from "../../../redux/actions/register/updateUserStatus";
import { useNavigate } from "react-router-dom";
import CompanyPersonalInfo from "../../../components/DraftClient/Company/CompanyPersonalInfo";
import { setPageTitle } from "../../../helper/metaDataHelper";
import ErrorMessage from "../../../components/Common/ErrorMessage";

const DraftClient = () => {
  const { user } = useSelector((state) => state.user);
  const [step, setStep] = useState(1);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const labels = [
    "Personal info",
    user.account_type === "individual" ? "Employer info" : "Company",
    "Identity verification",
    "Currency",
    "Signature",
    "Required documents",
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNext = () => {
    if (submissionSuccess) return;

    if (formData.selfie_photo !== "" && step === 2) {
      setStep(step + 2); // Skip step 3
      return;
    }
    if (step < labels.length) setStep(step + 1);
  };

  const handlePrev = () => {
    if (submissionSuccess) return;

    if (formData.selfie_photo !== "" && step === 4) {
      setStep(step - 2); // Skip step 3
      return;
    }

    if (step > 1) setStep(step - 1);
  };

  const isloadingModal = (status) => {
    setisLoading(status);
    setIsModalOpen(status);
  };

  const handleLogout = async () => {
    isloadingModal(true);
    await dispatch(logout());
    isloadingModal(false);
  };

  // this already been created in a helper folder in another branch wich will be merged
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  const initialIndividualFormData = {
    passport_copy: null,
    mobile_number: "",
    date_of_birth: "",
    passport_number: "",
    passport_expiry_date: "",
    nationality_id: "",
    residence_country_id: "",
    gender: "",
    mailing_address: "",
    occupation_business: "",
    job_title: "",
    employer_business_name: "",
    applicant_annual_income_usd: "",
    employer_address: "",
    selfie_photo: "",
    currency_ids: [],
    signature_photo: null,
  };

  const initialCompanyFormData = {
    name: "",
    passport_copy: null,
    passport_number: "",
    dob: "",
    nationality_id: "",
    residence_country_id: "",
    passport_expiry_date: "",
    gender: "",
    contact_person_name: "",
    contact_person_designation: "",
    company_place: "",
    registration_date: "",
    business_reg_number: "",
    expiry_date: "",
    business_reg_address: "",
    nature_of_business: "",
    company_type: "",
    selfie_photo: "",
    currency_ids: [],
    directors: [],
    signature_photo: null,
  };

  const [formData, setFormData] = useState(
    user?.account_type === "company"
      ? initialCompanyFormData
      : initialIndividualFormData
  );

  useEffect(() => {
    setPageTitle(`${user.account_type} Client`);
  }, [user.account_type]);

  useEffect(() => {
    const fetchRejectedDraftClient = async () => {
      const res = await dispatch(getRejectedDraftClient());
      if (!res.success && res.status === "Rejected") {
        const getClientDetails = {
          individual: (res) => ({
            ...initialIndividualFormData,
            mobile_number: res.individual.mobile_number,
            date_of_birth: formatDate(res.individual.dob),
            passport_number: res.individual.passport_number,
            passport_expiry_date: formatDate(
              res.individual.passport_expiry_date
            ),
            residence_country_id: res.individual.residence_country_id,
            nationality_id: res.individual.nationality_id,
            gender: res.individual.gender,
            occupation_business: res.individual.employer.occupation_business,
            job_title: res.individual.employer.job_title,
            employer_business_name:
              res.individual.employer.employer_business_name,
            applicant_annual_income_usd:
              res.individual.employer.applicant_annual_income_usd,
            employer_address: res.individual.employer.employer_address,
            currency_ids: res.draftCurrencies,
          }),
          company: (res) => ({
            ...initialCompanyFormData,
            contact_person_name: res.company.contact.name,
            // name: res.clientName,
            passport_number: res.company.contact.passport_number,
            dob: formatDate(res.company.contact.dob),
            nationality_id: res.company.contact.nationality_id,
            mobile_number: res.company.contact.mobile_number,
            contact_person_designation: res.company.contact.designation,
            passport_expiry_date: formatDate(
              res.company.contact.passport_expiry
            ),
            residence_country_id: res.company.contact.residence_country_id,
            gender: res.company.contact.gender,
            company_place: res.company.company_place,
            registration_date: formatDate(res.company.registration_date),
            business_reg_number: res.company.business_reg_number,
            expiry_date: formatDate(res.company.expiry_date),
            business_reg_address: res.company.business_reg_address,
            nature_of_business: res.company.nature_of_business,
            company_type: res.company.company_type,
            currency_ids: res.draftCurrencies,
            directors: res.directors.map((director,index) => ({
              id: index + 1,
              name: director.name,
              email: director.email
            })),
          }),
        };

        setFormData(getClientDetails[user.account_type](res.data));
      }
    };

    if (user.status === "Rejected" || user.status === "Update") {
      fetchRejectedDraftClient();
    }
  }, [dispatch, user]); //eslint-disable-line react-hooks/exhaustive-deps

  const onChange = (e) => {
    const { name, value, files } = e.target;

    if (files && files[0]) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: files[0],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleLiveness = async () => {
    try {
      const form = new FormData();
      for (const key in formData) {
        if (key === "directors") {
          form.append(key, JSON.stringify(formData[key]));
        } else {
          form.append(key, formData[key]);
        }
      }
      isloadingModal(true);

      const dispatchable = {
        Update: {
          individual: (form) => updateIndividualClient(form),
          company: (form) => updateCompanyClient(form),
        },
        Draft: {
          individual: (form) => createIndividualClient(form),
          company: (form) => createCompanyClient(form),
        },
      };
      const response = await dispatch(
        dispatchable[user.status][user.account_type](form)
      );

      if (response.success) {
        setisLoading(false);
        setSuccess(true);
        setTimeout(() => {
          dispatch(updateUserStatus("Liveness Done"));
          setIsModalOpen(false);
          setSuccess(false);
          setStep(6);
          setSubmissionSuccess(true);
        }, 2000);
      } else {
        setError("Internal server error, Please try again later.");
      }
    } catch (error) {
      setError("Internal server error, Please try again later.");
      console.error("Error in handleLiveness:", error);
      isloadingModal(false);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return user.account_type === "individual" ? (
          <PersonalInfo
            handleNext={handleNext}
            formData={formData}
            onChange={onChange}
          />
        ) : (
          <CompanyPersonalInfo
            handleNext={handleNext}
            formData={formData}
            onChange={onChange}
          />
        );
      case 2:
        return user.account_type === "individual" ? (
          <EmployerInfo
            handleNext={handleNext}
            handlePrev={handlePrev}
            formData={formData}
            onChange={onChange}
          />
        ) : (
          <CompanyInformation
            handleNext={handleNext}
            handlePrev={handlePrev}
            formData={formData}
            onChange={onChange}
          />
        );
      case 3:
        return (
          <IdentityVerification
            handleNext={handleNext}
            handlePrev={handlePrev}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 4:
        return (
          <Currency
            handleNext={handleNext}
            handlePrev={handlePrev}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 5:
        return (
          <Signature
            handleNext={handleNext}
            handlePrev={handlePrev}
            setFormData={setFormData}
            formData={formData}
            onChange={onChange}
            handleLiveness={handleLiveness}
          />
        );
      case 6:
        return navigate("/");
      default:
        return null;
    }
  };

  return (
    <>
      <div className="md:max-w-[992px] mx-auto min-h-dvh flex-1 flex flex-col">
        <div className="inline-flex space-x-2 px-2 pb-8">
          <SmallButton onClick={handleLogout} text={"Logout"} cancel={true} />
        </div>
        <div className="flex flex-col md:items-center md:justify-center">
          <Steps step={step} labels={labels} />
          <ProgressBar step={step} labels={labels} />
        </div>

        <div className="flex flex-col md:items-center w-full px-3 justify-center md:p-6">
          {error && (
            <ErrorMessage message={error} onClose={() => setError("")} />
          )}
          <div className="py-4">{renderStepContent(step)}</div>
        </div>
      </div>
      <LoadingModal
        isLoading={isLoading}
        setisLoading={setisLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isSuccess={isSuccess}
        setSuccess={setSuccess}
        title={"Request Submitted"}
      />
    </>
  );
};

export default DraftClient;
