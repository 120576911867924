import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { registerKYCForOldClients } from "../../redux/actions/ClientKYCGuest";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "../../hooks";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import { UpdateRequestedKYC } from "../../redux/actions/auth";
import { setPageTitle } from "../../helper/metaDataHelper";
import KYCStatus from "../../components/KYC/KYCStatus";
import LivenessError from "../../components/KYC/LivenessError";
import CommonButton from "../../components/Buttons/CommonButton";

const ClientLivenessCheck = () => {
  const [liveness, setLiveness] = useState("Pending");
  const iframeRef = useRef(null);
  const [searchParams] = useSearchParams();
  const hash = searchParams.get("hash");
  const client_id = searchParams.get("client_id");
  const dispatch = useDispatch();
  const [selfieUrl, setSelfieUrl] = useState(false);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const selfiePhoto = useForm("");
  const { user } = useSelector((state) => state.user);

  const reloadIframe = () => {
    if (iframeRef.current) {
      const currentSrc = iframeRef.current.src;
      iframeRef.current.src = "";
      iframeRef.current.src = currentSrc;
    }
    setLiveness("Pending");
    setStatus("retry");
    setSelfieUrl(false);
  };

  const handleKycResponse = async () => {
    setLoading(true);
    try {
      const form = new FormData();
      form.append("client_id", client_id);
      form.append("selfie_photo", selfiePhoto.value);
      form.append("hash", hash);

      const res = await dispatch(registerKYCForOldClients(form));
      if (res.success === true) {
        if (user && user.token) {
          const updatedUser = { ...user, kyc_requested: 0 };
          await dispatch(UpdateRequestedKYC(updatedUser));
        }
        setStatus("success");
      } else {
        setStatus("failed");
      }
    } catch (error) {
      console.error("Error in registerKYC:", error);
      setStatus("failed");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setPageTitle("Liveness Check");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleKyc = (e) => {
      try {
        if (e.origin !== process.env.REACT_APP_LIVENESS_URL) return;
        if (e.data.liveness_status === true) {
          setLiveness(true);
          selfiePhoto.setValue(e.data.selfie_image);
          if (e.data.selfie_image) {
            setSelfieUrl(true);
          }
        } else {
          setLiveness(false);
        }
      } catch (error) {
        console.error("Error in handleKyc:", error);
      }
    };

    window.addEventListener("message", handleKyc);
    return () => {
      window.removeEventListener("message", handleKyc);
    };
  }, [selfiePhoto]);

  useEffect(() => {
    if (selfieUrl) {
      handleKycResponse();
    }
  }, [selfieUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="md:max-w-[992px] mx-auto md:min-h-[80vh] flex-1 flex flex-col">
        {status === "success" || status === "failed" ? (
          <KYCStatus status={status} onRetry={reloadIframe} />
        ) : loading ? (
          <LoadingSpinner />
        ) : (
          <div className="flex flex-col space-y-4 items-center justify-center">
            {liveness === "Pending" || status === "retry" ? (
              <iframe
                ref={iframeRef}
                id="liveness"
                title="Facial Recognition"
                className="w-full max-[375px]:h-[108vh] h-[87vh] md:h-[88vh] border-none rounded-xl"
                src={`${process.env.REACT_APP_LIVENESS_URL}?platform=web&baseUrl=${process.env.REACT_APP_BASE_URL}`}
                allow="camera"
              ></iframe>
            ) : (
              ""
            )}
            {liveness === false && (
              <>
                <LivenessError />
                <hr className="border border-divider-border w-full" />
                <CommonButton
                  onClick={reloadIframe}
                >
                  Retry
                </CommonButton>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ClientLivenessCheck;
