export const AuthActionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  UPDATE_REQUESTED_KYC: "UPDATE_REQUESTED_KYC",
};

export const CommonActionTypes = {
  PENDING: "PENDING",
  REJECTED: "REJECTED",
  RESET: "RESET",
};

export const ClientInfoActionTypes = {
  CLIENT_INFO: "CLIENT_INFO",
  ADD_CLIENT_CRYOTO_ACCOUNTS: "ADD_CLIENT_CRYOTO_ACCOUNTS",
};

export const ModulesActionTypes = {
  ADD_MODULES: "ADD_MODULES",
  UPDATE_MODULES: "UPDATE_MODULES",
};

export const ActiveAccountActionTypes = {
  SET_ACTIVE_ACCOUNT: "SET_ACTIVE_ACCOUNT",
  CHANGE_ACTIVE_ACCOUNT: "CHANGE_ACTIVE_ACCOUNT",
};

export const UserInfoActionTypes = {
  USER_INFO: "USER_INFO",
};

export const OnboardingActionTypes = {
  UPDATE_CLIENT_STATUS: "CLIENT_STATUS",
};
export const NotificationActionTypes = {
  FETCH_NOTIFICATIONS_SUCCESS: "FETCH_NOTIFICATIONS_SUCCESS",
  READ_ALL_NOTIFICATIONS: "READ_ALL_NOTIFICATIONS",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_ALL_NOTIFICATIONS: "DELETE_ALL_NOTIFICATIONS",
};

export const CardActionTypes = {
  FETCH_CLIENT_CARDS: "FETCH_CLIENT_CARDS",
  SET_ACTIVE_CARD: "SET_ACTIVE_CARD",
};
export const OwnCompanyActions = {
  GET_OWN_COMPANY_DETAILS: "GET_OWN_COMPANY_DETAILS",
};
export const AllCountriesActions = {
  GET_ALL_COUNTRIES_DATA: "GET_ALL_COUNTRIES_DATA",
};

export const SessionActionTypes = {
  SHOW_SESSION_MODAL: "SHOW_SESSION_MODAL",
  CLOSE_SESSION_MODAL: "CLOSE_SESSION_MODAL",
  KEEP_SESSION: "KEEP_SESSION",
  SESSION_EXPIRED: "SESSION_EXPIRED",
  UPDATE_SESSION_TIMER: "UPDATE_SESSION_TIMER",
};
export const ElgibleCurrenciesActions = {
  ADD_ELIGIBLE_CURRENCIES: "ADD_ELIGIBLE_CURRENCIES",
  REMOVE_ELIGIBLE_CURRENCIE: "REMOVE_ELIGIBLE_CURRENCIES",
  GET_ELIGIBLE_CURRENCIES: "GET_ELIGIBLE_CURRENCIES",
};

export const ClientProfilesActionTypes = {
  GET_ALL_CLIENT_PROFILES: "GET_ALL_CLIENT_PROFILES",
};

export const RecentCardTransactionsAction = {
  GET_RECENT_CARD_TRANSACTIONS: "GET_RECENT_CARD_TRANSACTIONS",
  UPDATE_RECENT_CARD_TRANSACTIONS: "UPDATE_RECENT_CARD_TRANSACTIONS",
};

export const clientHelpReasonsActionTypes = {
  GET_CLIENT_HELP_REASONS: "GET_CLIENT_HELP_REASONS",
};
