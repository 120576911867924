import React from "react";

const Steps = ({ step, labels }) => {
  return (
    <div className="md:hidden inline-flex items-center mx-auto justify-between w-full px-2">
      <h2 className="text-body-text text-2xl font-medium ">
        {labels[step - 1]}
      </h2>
      <span className="text-paragraph-text text-sm font-normal">
        Steps {step} of {labels.length}
      </span>
    </div>
  );
};

export default Steps;
