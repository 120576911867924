import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const verifyEmail = (email) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/verify-email", { email });

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const sendGuestOtp = (email) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/email/otp", { email });

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const verifyGuestOtp = (email, otp) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post(
      `/user/email/otp/verify?email=${email}&otp=${otp}`
    );

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const verifyLoginOtp = (data) => async (dispatch) => {
  try {
    const res = await commonService.post(`/user/verify/login-otp`, data);

    return res.data;
  } catch (err) {
    return err.response.data;
  }
};
