import React, { useEffect, useState } from "react";
import { checkAccountNumber } from "../../../../../../../redux/actions/TRrequest/AccountCheck";
import { useDispatch } from "react-redux";
import { CheckIcon } from "../../../../../../Icons";
import { GetValidationErrors } from "../../../../../../../helper";

const VerifyAccount = ({
  fromAccount,
  toAccountNumber,
  setBeneficiaryAccount,
  beneficiaryAccount,
  setError,
  error,
  location,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();

  const verifyAccountNumber = async () => {
    if (toAccountNumber.value.length < 1)
      return toAccountNumber.setError("Account number must be 10 digits");
    if (fromAccount === toAccountNumber.value)
      return toAccountNumber.setError("You can't transfer to the same account");

    setisLoading(true);
    const verify = await dispatch(
      checkAccountNumber({ account_number: toAccountNumber.value })
    );
    if (verify.success) {
      setBeneficiaryAccount(verify.data);
    } else if (!verify.success) {
      if(verify.errors){
        setError(GetValidationErrors(verify.errors));
      }else {
        setError(verify.error);
        toAccountNumber.setError(true);
      }
    }
    setisLoading(false);
  };

  useEffect(() => {
    if (toAccountNumber.value.length === 12 && location.state) {
      verifyAccountNumber();
    }
    // eslint-disable-next-line
  }, [toAccountNumber.value, location.state]);

  return (
    <>
      {!isLoading ? (
        <>
          {!beneficiaryAccount ? (
            <div
              onClick={verifyAccountNumber}
              className="px-3 py-1.5 cursor-pointer bg-secondary-icon-bg text-primary-item font-medium rounded-full hover:bg-hover-state-bg"
            >
              Verify
            </div>
          ) : (
            <div className="p-2 py-2.5 cursor-pointer bg-active-state-bg rounded-full">
              <CheckIcon />
            </div>
          )}
        </>
      ) : (
        <div className="p-1 bg-primary-shade-color rounded-full">
          <img
            src="assets/gifs/loader.gif"
            className="w-6 h-6 rounded-full"
            alt="loader"
          />
        </div>
      )}
    </>
  );
};

export default VerifyAccount;
