import React from "react";
import { Transition } from "@headlessui/react";

const CustomTransition = ({
  as,
  show,
  enter = "transition ease-in duration-500",
  enterFrom = "transform opacity-0 scale-95",
  enterTo = "transform opacity-100 scale-100",
  leave = "transition ease-out duration-1000",
  leaveFrom = "transform opacity-100 scale-500",
  leaveTo = "transform opacity-0 scale-95",
  children,
}) => {
  return (
    <Transition
      as={as && as}
      show={show && show}
      enter={enter}
      enterFrom={enterFrom}
      enterTo={enterTo}
      leave={leave}
      leaveFrom={leaveFrom}
      leaveTo={leaveTo}
    >
      {children}
    </Transition>
  );
};

export default CustomTransition;
