const InputValidation = (inputValue, inputName) => {
  const patterns = {
    email: /^[a-zA-Z0-9-_.+]{2,35}(@){1}(?:[a-zA-Z0-9._-]{2,15}\.){1}[A-Za-z]{2,5}$/,
    password: /^[a-zA-Z0-9.\-_!@#$%^&*\s]{6,20}$/,
    pin: /^[0-9]{4}$/,
    amount: /^(?!0+(\.0+)?$)(\d+(\.\d{1,2})?)$/,
    account_number: /^[a-zA-Z]{0,8}(\d){10,15}$/,
  };
  
  var isValid = patterns[inputName]?.test(inputValue);      

  if (inputName === "email") {
    isValid = true
  }

  if(inputName === "password"){
    return {
      hasError: !isValid,
      message: isValid ? '' : `Password must be at least 6 characters long.`,
    };
  }

  return {
    hasError: !isValid,
    message: isValid ? '' : `${inputName.charAt(0).toUpperCase() + inputName.slice(1)} is invalid`,
  };
};

export default InputValidation;
