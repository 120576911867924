import React, { useState } from "react";
import Modal from "../../../Common/Modal";
import Account from "../partials/account";
import { useDispatch } from "react-redux";
import { setActiveAccount } from "../../../../redux/actions/home/ActiveAccountAction";

const SelectedAccountModal = ({
  clientAccounts,
  activeAccount,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChangeAccount = async (account) => {
    await dispatch(setActiveAccount(account));
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={true}
        headerTitle={"Select Account"}
        className="w-[432px] max-h-[578px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl"
      >
        <div className="flex flex-col px-6 max-[360px]:px-3 pb-6 ">
          {clientAccounts.length ? (
            clientAccounts.map((account) => (
              <div
                key={account.id}
                onClick={() => handleChangeAccount(account)}
              >
                <Account
                  key={account.id}
                  {...account}
                  activeAccount={activeAccount}
                />
              </div>
            ))
          ) : (
            <div className="text-body-text p-16 font-medium">
              <p>No Account Found</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SelectedAccountModal;
