const CheckIcon = ({ className }) => {
    return (
      <div className={className}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="18"
          viewBox="0 0 24 25"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.621 6.44679C20.2167 7.0425 20.2167 8.00835 19.621 8.60406L10.6719 17.5532C10.0761 18.1489 9.1103 18.1489 8.51458 17.5532L4.44679 13.4854C3.85107 12.8897 3.85107 11.9239 4.44679 11.3281C5.0425 10.7324 6.00835 10.7324 6.60406 11.3281L9.59322 14.3173L17.4637 6.44679C18.0594 5.85107 19.0253 5.85107 19.621 6.44679Z"
            className="fill-primary-item"
          />
        </svg>
      </div>
    );
  };
  
  export default CheckIcon;