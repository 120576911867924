import { ResponseErrorHandling } from "../../../../helper";
import commonService from "../../../../services/Common/service";
import FormDataService from "../../../../services/FormDataService";
import { CommonActionTypes } from "../../../action-types";

export const registerDraftClient = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.post("/user/register/draft-client", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const createIndividualClient = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {

    const res = await FormDataService.post("/user/register/individual", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const updateIndividualClient = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {

    const res = await FormDataService.post("/user/update/individual", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const createCompanyClient = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await FormDataService.post("/user/register/company", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const updateCompanyClient = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await FormDataService.post("/user/update/company", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const getRejectedDraftClient = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.get("/user/get-client-details", data);
    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;
    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
