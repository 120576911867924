import React, { useEffect, useState } from "react";
import { NavigationButton, PrimaryButton } from "../../../Buttons";
import Modal from "../../../Common/Modal";
import HangTightLoading from "../../../Common/HangTightLoading";
import SuccessMessage from "../../../Common/SuccessMessage";
import { useDispatch } from "react-redux";
import { serviceRequestVerifyOTP } from "../../../../redux/actions/ServiceRequests/serviceRequestVerifyOTP";
import { CloseClientAccount } from "../../../../redux/actions/ServiceRequests/closeAccount";
import EnterOTP from "../../../Common/EnterOTP";

const ConfirmAccountClosure = ({
  children,
  isModalOpen,
  setIsModalOpen,
  isLoading,
  setIsLoading,
  setErrorMessage,
  errorMessage,
  handleReset,
  account,
  reason,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [requestId, setRequestId] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = useState(60);

  const handleOTP = async () => {
    const data = {
      request_id: requestId,
      otp: otp.join(""),
    };
    setIsLoading(true);
    const res = await dispatch(serviceRequestVerifyOTP(data));
    if (res.success) {
      setRequestId("");
      setPage(3);
    } else {
      setErrorMessage(res.error);
    }
    setIsLoading(false);
  };

  const handleResendCode = () => {
    setCounter(60);
    setResendDisabled(true);
    handleCloseAccount();
  };

  useEffect(() => {
    if (!isLoading && resendDisabled) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            setResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLoading, resendDisabled]);

  const handleCloseAccount = async () => {
    setIsLoading(true);
    const data = {
      client_id: account.id,
      reason: reason.value,
    };
    const res = await dispatch(CloseClientAccount(data));
    if (res.success) {
      setRequestId(res.data.request_id);
      // handleReset();
      setPage(2);
      setCounter(60);
    } else {
      setIsModalOpen(false);
      handleReset()
      setErrorMessage(res.error);
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsLoading(false);
  };

  const reset = () => {
    handleReset();
    setPage(1);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={closeModal}
        loading={isLoading}
        header={!isLoading && page !== 3}
        headerTitle={page === 2 ? "Enter OTP" : "Confirm Account Closure"}
        className="bg-popup-bg w-[432px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl pt-4"
      >
        {!isLoading && page === 1 && (
          <div className="flex flex-col px-6 py-4 space-y-4 ">
            <h3 className="text-sm font-normal text-body-text">
              Are you sure you want to close your account? This action cannot be
              undone.
            </h3>
            <div className="flex items-center justify-center py-6 whitespace-nowrap space-x-4">
              <PrimaryButton text="Keep account open" onClick={reset} />
              <NavigationButton
                text="Confirm closure"
                onClick={handleCloseAccount}
              />
            </div>
          </div>
        )}
        {!isLoading && page === 2 && (
          <div className="px-6 pt-3">
            <EnterOTP
              handleOTP={handleOTP}
              otp={otp}
              setOtp={setOtp}
              error={errorMessage}
              setError={setErrorMessage}
              resendDisabled={resendDisabled}
              counter={counter}
              handleResendCode={handleResendCode}
            />
          </div>
        )}
        {isLoading && (
          <HangTightLoading message="Please wait while we process your request..." />
        )}
        {page === 3 && !isLoading && (
          <div className="p-6 space-y-4">
            <SuccessMessage
              title={"Close account request sent"}
              description={
                "You will receive an update within 2-3 working days."
              }
              handleCloseButton={reset}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConfirmAccountClosure;
