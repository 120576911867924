import { NotificationActionTypes } from "../../action-types";

const initialState = {
  notifications: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NotificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        notifications: action.payload,
      };
    case NotificationActionTypes.READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          status: "read",
        })),
      };
    case NotificationActionTypes.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload
        ),
      };
    case NotificationActionTypes.DELETE_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: [],
      };
    default:
      return state;
  }
};

export default notificationReducer;
