import React, { useState } from "react";
import { EmptyCurrencyListIcons, SearchIcons } from "../../../../Icons";
import CommonButton from "../../../../Buttons/CommonButton";
import ListItem from "../../partials/ListItem";

const EligibleCurrenciesList = ({
  eligibleCurrencies,
  newCurrency,
  setNewCurrency,
  error,
  handleRequestForCurrency,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredCurrencies = eligibleCurrencies.filter(
    (currency) =>
      currency.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      currency.short.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="sticky -top-1 bg-popup-bg z-10">
        <h2 className="pb-3 text-sm font-medium text-body-text">
          Choose your currency
        </h2>

        <div className="w-full px-3 py-1 bg-secondary-icon-bg dark:bg-[#35322A] rounded-full flex justify-start items-center">
          <SearchIcons className="w-6 h-6 relative" />
          <input
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            className="w-full text-paragraph-text text-sm font-normal bg-transparent border-none focus:ring-0 leading-tight tracking-tight"
          />
        </div>
      </div>

      {filteredCurrencies.length ? (
        <>
          {filteredCurrencies.map((currency) => (
            <div
              key={currency.currency_id}
              onClick={() => setNewCurrency({ id: currency.currency_id })}
            >
              <ListItem
                logo={currency.url}
                title={currency.short}
                subTitle={currency.name}
                selectedItem={newCurrency}
                itemId={currency.currency_id}
              />
            </div>
          ))}
          <div className={`mt-8 sticky z-50 bottom-9`}>
            <CommonButton
              disabled={newCurrency.id ? false : true}
              onClick={handleRequestForCurrency}
            >
              Continue
            </CommonButton>
          </div>
        </>
      ) : (
        <div className="flex flex-col text-body-text items-center justify-center py-6">
          <EmptyCurrencyListIcons className="w-40 h-40" />
          <p>No currency found!</p>
        </div>
      )}

      {error && (
        <div className="flex text-highlighter-red items-center justify-center pt-6">
          <p>{error}</p>
        </div>
      )}

    </>
  );
};

export default EligibleCurrenciesList;
