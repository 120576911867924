import {
  // AEDSymbol,
  BTCSymbol,
  CNYSymbol,
  ETHSymbol,
  EURSymbol,
  GBPSymbol,
  // HKDSymbol,
  INRSymbol,
  // MYRSymbol,
  RUBSymbol,
  // SGDSymbol,
  TRYSymbol,
  USDCSymbol,
  USDSymbol,
  USDTSymbol,
} from "../../components/Icons/CurrenySymbol";

const GetCurrenySymbol = (currencyCode, width, height, fillColor) => {
  switch (currencyCode) {
    case "USD":
      return <USDSymbol width={width} height={height} fillColor={fillColor} />;
    case "EUR":
      return <EURSymbol width={width} height={height} fillColor={fillColor} />;
    case "GBP":
      return <GBPSymbol width={width} height={height} fillColor={fillColor} />;
    case "HKD":
      // return <HKDSymbol width={width} height={height} fillColor={fillColor} />;
      return "HKD";
    case "CNY":
      return <CNYSymbol width={width} height={height} fillColor={fillColor} />;
    case "SGD":
      // return <SGDSymbol width={width} height={height} fillColor={fillColor} />;
      return "SGD";
    case "AED":
      // return <AEDSymbol width={width} height={height} fillColor={fillColor} />;
      return "AED";
    case "MYR":
      // return <MYRSymbol width={width} height={height} fillColor={fillColor} />;
      return "MYR";
    case "RUB":
      return <RUBSymbol width={width} height={height} fillColor={fillColor} />;
    case "INR":
      return <INRSymbol width={width} height={height} fillColor={fillColor} />;
    case "TRY":
      return <TRYSymbol width={width} height={height} fillColor={fillColor} />;
    case "BTC":
      return <BTCSymbol width={width} height={height} fillColor={fillColor} />;
    case "ETH":
      return <ETHSymbol width={width} height={height} fillColor={fillColor} />;
    case "USDT":
      return <USDTSymbol width={width} height={height} fillColor={fillColor} />;
    case "USDC":
      return <USDCSymbol width={width} height={height} fillColor={fillColor} />;
    default:
      return currencyCode;
  }
};

export default GetCurrenySymbol;
