import commonService from "../../../services/Common/service";
import { SessionActionTypes } from "../../action-types";
import { logout } from "../auth";

let sessionModalTimeout, sessionExpirationTimeout;

export const startSessionExpirationTimers = () => (dispatch) => {
  clearTimeout(sessionModalTimeout);
  clearTimeout(sessionExpirationTimeout);
  const sessionExpiryFromAPi = JSON.parse(localStorage.getItem("company"));

  const sessionDuration =
    sessionExpiryFromAPi.session_lifetime ||
    process.env.REACT_APP_SESSION_LIFE_TIME ||
    7200000;
  const modalBeforeExpiry =
    process.env.REACT_APP_ALERT_SESSION_LIFE_EXPIRE || 300000;

  localStorage.setItem(
    "session",
    JSON.stringify({
      sessionTimeLeft: sessionDuration,
      showModalDuration: modalBeforeExpiry,
      showModal: false,
    })
  );

  sessionModalTimeout = setTimeout(() => {
    dispatch({ type: SessionActionTypes.SHOW_SESSION_MODAL });
    let session = JSON.parse(localStorage.getItem("session"));
    session = { ...session, showModal: true };
    localStorage.setItem("session", JSON.stringify(session));
  }, sessionDuration - modalBeforeExpiry);

  sessionExpirationTimeout = setTimeout(() => {
    dispatch(expireSession());
  }, sessionDuration);
};

export const expireSession = () => async (dispatch) => {
  dispatch({ type: SessionActionTypes.SESSION_EXPIRED });
  const session = {
    sessionTimeLeft: 0,
    showModalDuration: 0,
    showModal: false,
  };
  localStorage.setItem("session", JSON.stringify(session));
  await dispatch(logout());
};

export const keepThisSession = () => async (dispatch) => {
  clearTimeout(sessionModalTimeout);
  clearTimeout(sessionExpirationTimeout);

  dispatch(startSessionExpirationTimers());
  dispatch({
    type: SessionActionTypes.KEEP_SESSION,
    payload: {
      sessionTimeLeft: sessionExpirationTimeout,
      showModalDuration: sessionModalTimeout,
    },
  });
  // To do: Add API call to extend the session
  await dispatch(updateSession());
};

export const updateSession = () => async (dispatch) => {
  try {
    const res = await commonService.get("client/refresh-token");

    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      localStorage.setItem(
        "user",
        JSON.stringify({ ...user, token: res.data.token })
      );
    } else {
      console.warn("No user found in localStorage.");
    }

    dispatch(startSessionExpirationTimers());
  } catch (error) {
    console.error("Error in updateSession:", error);
  }
};
