import React from "react";
import Skeleton from "../../Common/Skeleton";

const PrimaryActiveCardSkeleton = () => {
  return (
    <div className="flex flex-col space-y-7 items-center pb-10">
      <Skeleton className="rounded-3xl flex flex-col justify-evenly h-[196px] w-[314px] px-3 mt-7">
        <div className="flex items-start justify-between w-full">
          <Skeleton className="rounded-full w-[103px] h-[25px]" />
          <Skeleton className="rounded-full w-[103px] h-[25px]" />
        </div>
        <div className="flex justify-between w-full">
          <div className="flex flex-col space-y-1">
            <Skeleton className="rounded-full w-[103px] h-[10px]" />
            <Skeleton className="rounded-full w-[103px] h-[25px]" />
          </div>
          <Skeleton className="rounded-xl w-[60px] h-[40px]" />
        </div>
        <div className="flex justify-between">
          <Skeleton className="rounded-xl w-[130px] h-[30px]" />
          <Skeleton className="rounded-xl w-[80px] h-[30px]" />
        </div>
      </Skeleton>

      <div className="flex space-x-6">
        {[1, 2, 3, 4].map((item) => (
          <div
            key={item}
            className="flex flex-col space-y-2 items-center justify-center"
          >
            <Skeleton className="rounded-full w-12 h-12" />
            <Skeleton className="rounded-full w-14 h-[17px]" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrimaryActiveCardSkeleton;
