import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage";

const RestrictRoute = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <>
      {user && !user.is_basic_client ? (
        <>
          <Outlet />
        </>
      ) : (
        <ErrorPage errorCode="403" message="Forbidden" description="You don't have permission to access this resource" />
      )}
    </>
  );
};

export default RestrictRoute;
