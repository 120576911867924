import React from "react";

const TRYSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 18}
      height={height ? height : 24}
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.72 23.16C6.16533 23.16 5.664 23.1387 5.216 23.096C4.768 23.0747 4.384 23.0427 4.064 23V12.568L0.64 13.752V11.672L4.064 10.52V7.992L0.64 9.144V7.064L4.064 5.912V0.151999H6.816V4.952L13.408 2.68V4.76L6.816 7.032V9.56L13.408 7.288V9.336L6.816 11.64V20.728C8.75733 20.728 10.2933 20.3333 11.424 19.544C12.5547 18.7333 13.3653 17.656 13.856 16.312C14.3467 14.968 14.592 13.464 14.592 11.8H17.344C17.344 13.272 17.152 14.6907 16.768 16.056C16.4053 17.4213 15.7973 18.6373 14.944 19.704C14.112 20.7707 13.024 21.6133 11.68 22.232C10.336 22.8507 8.68267 23.16 6.72 23.16Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default TRYSymbol;
