import React from "react";
import Skeleton from "../../Common/Skeleton";

const SelectCurrenciesSkeleton = () => {
  return (
    <div className="space-y-3 items-center justify-center">
      {[1, 2, 3, 4].map((item) => (
        <div key={item} className="flex flex-col space-y-1">
          <Skeleton className="rounded-3xl h-[76px] flex items-center justify-between">
            <div className="flex space-x-3">
              <img
                src="assets/gifs/loader.gif"
                className="w-10 h-10 rounded-full ml-4"
                alt="loader"
              />
              <span className="flex flex-col space-y-1">
                <Skeleton className="rounded-full w-[100px] h-[16px]" />
                <Skeleton className="rounded-full w-[176px] h-[16px]" />
              </span>
            </div>
          </Skeleton>
        </div>
      ))}
        <Skeleton className="rounded-full w-full h-1" />
      <span className="flex justify-between">
        <Skeleton className="rounded-full w-[120px] h-[45px]" />
        <Skeleton className="rounded-full w-[230px] h-[45px]" />
      </span>
    </div>
  );
};

export default SelectCurrenciesSkeleton;
