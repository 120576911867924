import React from "react";
import { DropdownCheckIcon } from "../../../../../Icons";

const TransferTypeButton = ({ icon, title, subTitle, type, selectedType }) => {
  let selected = type === selectedType;
  return (
    <div className="relative cursor-pointer">
      {selected && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 top-0 bg-[#EFC660] rounded-full shadow border-2 border-white">
          <DropdownCheckIcon />
        </div>
      )}
      <div
        className={`flex justify-start items-center space-x-3 border border-divider-border px-6 py-5 rounded-3xl border-2 ${
          selected
            ? "border-icon-btn bg-option-list-selected"
            : "bg-popup-bg border-divider-border hover:bg-option-list-hover"
        }
      `}
      >
        <div
          className={`p-2 ${
            selected ? "bg-primary-Icon-bg" : "bg-secondary-icon-bg"
          } rounded-full`}
        >
          {icon}
        </div>
        <div className="space-y-1">
          <div className="text-body-text text-sm font-medium leading-tight tracking-tight">
            {title}
          </div>
          <p className="text-sm text-paragraph-text leading-tight tracking-tight">
            {subTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransferTypeButton;
