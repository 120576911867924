import React from "react";

const SearchIcons = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Frame 3">
          <path
            id="Vector"
            d="M21.7075 20.2899L17.9975 16.6099C19.4376 14.8143 20.135 12.5352 19.9463 10.2412C19.7576 7.94721 18.6971 5.81269 16.983 4.27655C15.2688 2.74041 13.0313 1.91941 10.7304 1.98237C8.4295 2.04534 6.24018 2.98747 4.6126 4.61505C2.98502 6.24263 2.04289 8.43194 1.97993 10.7328C1.91697 13.0337 2.73797 15.2713 4.27411 16.9854C5.81024 18.6996 7.94476 19.76 10.2388 19.9487C12.5328 20.1374 14.8119 19.44 16.6075 17.9999L20.2875 21.6799C20.3804 21.7736 20.491 21.848 20.6129 21.8988C20.7347 21.9496 20.8654 21.9757 20.9975 21.9757C21.1295 21.9757 21.2602 21.9496 21.382 21.8988C21.5039 21.848 21.6145 21.7736 21.7075 21.6799C21.8877 21.4934 21.9884 21.2442 21.9884 20.9849C21.9884 20.7256 21.8877 20.4764 21.7075 20.2899ZM10.9975 17.9999C9.61299 17.9999 8.25961 17.5894 7.10847 16.8202C5.95733 16.051 5.06012 14.9578 4.53031 13.6787C4.00049 12.3996 3.86187 10.9921 4.13197 9.63427C4.40206 8.27641 5.06875 7.02913 6.04771 6.05016C7.02668 5.07119 8.27396 4.4045 9.63183 4.13441C10.9897 3.86431 12.3972 4.00293 13.6762 4.53275C14.9553 5.06256 16.0486 5.95977 16.8177 7.11091C17.5869 8.26206 17.9975 9.61544 17.9975 10.9999C17.9975 12.8564 17.26 14.6369 15.9472 15.9497C14.6345 17.2624 12.854 17.9999 10.9975 17.9999Z"
            fill="#AEACA4"
          />
        </g>
      </svg>
    </div>
  );
};

export default SearchIcons;
