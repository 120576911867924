import React, { useEffect } from "react";
import { useModules } from "../../../hooks";
import BitpaceDeposit from "../../../components/Crypto/CryptoBitpaceTransactions/BitpaceDeposit";
import { setPageTitle } from "../../../helper/metaDataHelper";
import ErrorPage from "../../../components/ErrorPage";

const CryptoDeposit = () => {
  const isModuleEnabled = useModules("Bitpace");

  useEffect(() => {
    setPageTitle("Crypto Deposit");
  }, []);

  return (
    <main>
      {isModuleEnabled ? (
            <BitpaceDeposit />
          ) : (
            <ErrorPage />
          )
      }
    </main>
  );
};

export default CryptoDeposit;
