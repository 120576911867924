import React, { useEffect, useState } from "react";
import HeaderLogo from "../../components/HeaderLogo";
import Footer from "../../components/Footer";
import { useDispatch } from "react-redux";
import { getClientKYCLink } from "../../redux/actions/lookups/getClientKYCLink";
import LoadingSpinner from "../../components/Common/LoadingSpinner";
import KYCRequiredCard from "../../components/KYC/KYCRequiredCard";
import { SmallButton } from "../../components/Buttons";
import { setPageTitle } from "../../helper/metaDataHelper";
import { logout } from "../../redux/actions/auth";
import KYCError from "../../components/KYC/KYCError";

const KYCRequired = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [kycLink, setKycLink] = useState("");
  const dispatch = useDispatch(); // useDispatch is a hook provided by react-redux to dispatch actions

  const getKycLink = async () => {
    setLoading(true);
    const res = await dispatch(getClientKYCLink());

    if (res.success === true) {
      setKycLink(res.kyc_link);
      setError(false);
    } else {
      setError(res.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!kycLink) getKycLink();
    setPageTitle("KYC Required");
    // eslint-disable-next-line
  }, [kycLink]);

  const handleKYCRedirection = () => {
    if (!kycLink) return;
    window.location.href = kycLink;
  };

  const handleLogout = async () => {
    setLoading(true);
    await dispatch(logout());
    setLoading(false);
  };
  return (
    <>
      <HeaderLogo />
      <div className="max-w-[1024px] mx-auto space-y-4 px-4 min-h-[80vh]">
        <div className="inline-flex space-x-2 px-2 pb-8">
          <SmallButton onClick={handleLogout} text={"Logout"} cancel={true} />
        </div>
        <div className="flex items-center justify-center">
          {error ? (
            <KYCError error={error} />
          ) : (
            <KYCRequiredCard handleKYCRedirection={handleKYCRedirection} />
          )}
        </div>
      </div>

      {loading && <LoadingSpinner />}
      <Footer />
    </>
  );
};

export default KYCRequired;
