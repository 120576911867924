import React from "react";
import { ApprovedSign, InitialReviewSign, PendingSign, RejectedSign } from "../../Icons";

const StatusIcon = ({ status }) => {
  switch (status) {
    case "Initial Review":
      return <InitialReviewSign />;
    case "Pending":
      return <PendingSign />;
    case "Approved":
      return <ApprovedSign />;
    case "Rejected":
      return <RejectedSign className="w-3 h-3"/>;
    default:
      return null;
  }
};

export default StatusIcon;
