import { useState } from "react";
import { useDispatch } from "react-redux";
import { GetPrice } from "../../redux/actions/CryptoTransaction/GetPrice";

const useGetPrice = ({
  selectedFiatAccount,
  activeAccount,
  coinAmount,
  fiatAmount,
  orderType,
  currencyAmountField,
}) => {
  const [isPriceLoading, setIsPriceLoading] = useState(false);
  const [showPriceSummary, setShowPriceSummary] = useState(false);
  const [priceSummary, setPriceSummary] = useState({});
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleGetPrice = async () => {
    if (!selectedFiatAccount || !activeAccount) {
      setError("Please select both fiat and crypto accounts.");
      return;
    }

    if (!coinAmount.value && !fiatAmount.value) {
      setError("Please fill either the crypto or fiat amount to trade.");
      return;
    }

    setIsPriceLoading(true);
    setShowPriceSummary(false);
    setError("");

    try {
      const getPrice = await dispatch(
        GetPrice({
          fiat_account_id: selectedFiatAccount.id,
          crypto_account_id: activeAccount.id,
          coin:
            orderType === "BUY"
              ? activeAccount.currency_code
              : selectedFiatAccount.currency_code,
          coin_amount: coinAmount.value,
          fiat_amount: fiatAmount.value,
          order_type: orderType,
          currency_amount_field: currencyAmountField,
        })
      );

      if (getPrice.data.status === "DECLINED") {
        setError(getPrice.data.message);
        setIsPriceLoading(false);
        return;
      }

      if (getPrice && getPrice.data) {
        coinAmount.setValue(getPrice.data.cryptocurrency_amount);
        fiatAmount.setValue(getPrice.data.total_amount);

        setPriceSummary({
          rate: `${getPrice.data.cryptocurrency_amount} ${getPrice.data.cryptocurrency} = ${getPrice.data.total_amount} ${getPrice.data.currency}`,
          commissionRate: `${
            (getPrice.data.merchant_commission_fee /
              getPrice.data.total_amount) *
            100
          }%`,
          commissionFee: `${getPrice.data.merchant_commission_fee} ${getPrice.data.currency}`,
          approxFiatAmount: `${fiatAmount.value} ${getPrice.data.currency}`,
          total_amount: getPrice.data.total_amount,
          cryptocurrency_amount: getPrice.data.cryptocurrency_amount,
          token: getPrice.data.token,
        });
        setShowPriceSummary(true);
      } else {
        setError("Sorry! We could not find any prices for the given amount.");
      }
    } catch (error) {
      setError("Unexpected error occurred. Please try again later.");
    }
    setIsPriceLoading(false);
  };

  return {
    isPriceLoading,
    showPriceSummary,
    priceSummary,
    error,
    handleGetPrice,
    setError,
    setShowPriceSummary,
  };
};

export default useGetPrice;
