import React from "react";

const FilterButton = ({ title, selected, setValue, value }) => {
  return (
    <div
      onClick={() => setValue(value)}
      className={`${
        value === selected
          ? "bg-primary-item text-body-bg"
          : "hover:bg-primary-item text-primary-item hover:text-body-bg"
      } h-9 md:px-4 py-2 cursor-pointer  rounded-full border border-primary-item justify-center items-center gap-2.5 inline-flex`}
    >
      <p className="border-primary-item text-sm font-medium tracking-tight">
        {title}
      </p>
    </div>
  );
};

export default FilterButton;
