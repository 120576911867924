import React from "react";
import SuccessMessage from "../../Common/SuccessMessage";
import HangTightLoading from "../../Common/HangTightLoading";
import Modal from "../../Common/Modal";

const LoadingModal = ({
  isLoading,
  setisLoading,
  isModalOpen,
  setIsModalOpen,
  isSuccess,
  setSuccess,
  title,
  description,
  redirectionAfterClose,
}) => {
  const handleOnCloseModal = () => {
    setIsModalOpen(false);
    setTimeout(() => {
      setSuccess(false);
    }, 500);
    setisLoading(false);
    if (redirectionAfterClose) {
      redirectionAfterClose();
    }
  };

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={isLoading || isSuccess ? false : true}
        backButtonAction={() => {}}
        className="w-[432px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl"
        loading={isLoading || isSuccess}
      >
        <div className="px-6 pt-2">
          {isLoading && (
            <HangTightLoading message="Please wait while we process your request..." />
          )}
          {isSuccess && !isLoading && (
            <div className="p-6">
              <SuccessMessage
                title={title}
                description={description}
                handleCloseButton={handleOnCloseModal}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default LoadingModal;
