import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const getClientKYCLink = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.get("client/client-kyc-link");

    return res.data;
  } catch (err) {
    const message = err.response?.data?.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response?.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
