import React from "react";

const ArrowIcon = ({ className  }) => {
  return (
    <div className={className}>
      <svg
        width="16"
        height="8"
        viewBox="0 0 16 8"
        fill="true"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Arrow"
          d="M16 8H0L6.58579 1.41421C7.36684 0.633165 8.63317 0.633165 9.41421 1.41421L16 8Z"
          // fill="white"
          className="fill-current text-popup-bg"
        />
      </svg>
    </div>
  );
};

export default ArrowIcon;
