import React, { useEffect, useState } from "react";
import TertiaryButton from "../Buttons/TertiaryButton";
import TransactionItem from "./partials/TransactionItem";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
// import { GetCurrenySymbol } from "../../helper"
import EmptyRecentTransactions from "../Empty/EmptyRecentTransactions";
import { scrollToTopWithEase } from "../../helper/ScrollHelper";

const RecentTransactions = ({
  transactions,
  setActiveTransaction,
  activeTransaction,
  setShowRecentTransaction,
  type = "accounts",
}) => {
  const { currency_code } = useSelector((state) => state.activeAccount.activeAccount);
  const { activeCard } = useSelector((state) => state.activeCard);
  const [currencySymbol, setCurrencySymbol] = useState("");

  useEffect(() => {
    if (type === "card") {
      setCurrencySymbol("$")
      // setCurrencySymbol(GetCurrenySymbol(activeCard.currency ? activeCard.currency : "USD", "13", "13"));
    } else {
      setCurrencySymbol(currency_code);
      // setCurrencySymbol(GetCurrenySymbol(currency_code, "13", "13"));
    }
  }, [currency_code, type, activeCard]);
  return (
    <div className="md:px-4 space-y-4">
      <div className="flex justify-between items-center">
        <div className="text-body-text text-sm font-medium leading-tight tracking-tight">
          Recent transactions
        </div>
        {type === "card" ? (
          <Link to="/card-statement">
            <TertiaryButton text="See card statement" />
          </Link>
        ) : (
          <Link to="/all-transactions">
            <TertiaryButton text="See All Transactions" />
          </Link>
        )}
      </div>
      <div className="space-y-2">
        {transactions.length ? (
          transactions.map((transaction) => (
            <div
              key={transaction.id}
              onClick={() => {
                setActiveTransaction(transaction);
                scrollToTopWithEase(300);
                setShowRecentTransaction(true);
              }}
            >
              <TransactionItem
                transaction={transaction}
                activeTransaction={activeTransaction}
                currencySymbol={currencySymbol}
                type={type}
              />
            </div>
          ))
        ) : (
          <div>
            <EmptyRecentTransactions />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecentTransactions;
