import React from "react";

const GreenEllipse = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="green-ellipse"
          cx="4"
          cy="4"
          r="3.5"
          fill="#11A48B"
          stroke="#32D2B7"
        />
      </svg>
    </div>
  );
};

export default GreenEllipse;
