import React, { useEffect, useState } from "react";
import CommonButton from "../../Buttons/CommonButton";
import { NavigationButton, SmallButton } from "../../Buttons";
import DateSelector from "../../DateSelector";
import FilterButton from "../../AllTransactions/SideNav/partials/FilterButton";
import Sidebar from "../../Common/SideBar";
import { FilterIcon } from "../../Icons";

const TransactionsFiltersSidebar = ({
  transactionStatus,
  setTransactionStatus,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  fetchTransactions,
  period,
  setPeriod,
  resetFilters,
  accounts,
  children,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleOnCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSelectPeriod = (value) => {
    setPeriod(value);
    setFromDate("");
    setToDate("");
    handleOnCloseSidebar();
  };

  const handleRestFilters = () => {
    resetFilters();
    handleOnCloseSidebar();
  };

  const handletransactionStatus = (status) => {
    setTransactionStatus(status);
    handleOnCloseSidebar();
  };

  return (
    <>
      <div onClick={() => setIsSidebarOpen(true)}>
        <CommonButton>
          <div className="flex items-center justify-center">
            <FilterIcon />
            <span className="ml-2">Filters</span>
          </div>
        </CommonButton>
      </div>
      <Sidebar
        open={isSidebarOpen}
        handleOnCloseSidebar={handleOnCloseSidebar}
        header={true}
        crossButton={true}
        crossButtonAction={handleOnCloseSidebar}
        title={"Filters"}
        className="bg-popup-bg overflow-scroll hide-scrollbar"
      >
        <div className="flex flex-col space-y-6 min-h-screen mb-3">
          {children}

          <div className="space-y-4">
            <p className="text-body-text text-sm font-medium tracking-tight">
              Select Period
            </p>

            <div className="grid grid-cols-3 gap-3 auto-rows-max md:pr-6">
              <FilterButton
                value={"1"}
                selected={period}
                setValue={handleSelectPeriod}
                title="Last Month"
              />
              <FilterButton
                value={"3"}
                selected={period}
                setValue={handleSelectPeriod}
                title="3 Months"
              />
              <FilterButton
                value={"6"}
                selected={period}
                setValue={handleSelectPeriod}
                title="6 Months"
              />
              <FilterButton
                value={"12"}
                selected={period}
                setValue={handleSelectPeriod}
                title="Last Year"
              />
            </div>
          </div>

          {setTransactionStatus && (
            <div className="space-y-4">
              <p className="text-body-text text-sm font-medium tracking-tight">
                Transaction status
              </p>
              <div className="grid grid-cols-3 gap-3 auto-rows-max pr-10">
                <FilterButton
                  selected={transactionStatus}
                  value={"pending"}
                  title="Pending"
                  setValue={handletransactionStatus}
                />
                <FilterButton
                  selected={transactionStatus}
                  value={"approved"}
                  title="Approved"
                  setValue={handletransactionStatus}
                />
                <FilterButton
                  selected={transactionStatus}
                  value={"Rejected"}
                  title="Rejected"
                  setValue={handletransactionStatus}
                />
                <FilterButton
                  selected={transactionStatus}
                  value={"initial review"}
                  title="Initial"
                  setValue={handletransactionStatus}
                />

                {transactionStatus !== "" && (
                  <SmallButton
                    onClick={() => {
                      resetFilters();
                      handletransactionStatus("")
                    }}
                    cancel={true}
                    text="Reset"
                  />
                )}
              </div>
            </div>
          )}

          <div className="space-y-4">
            <p className="text-body-text text-sm font-medium tracking-tight">
              Custom date
            </p>
            <div className="flex flex-col space-y-3">
              <DateSelector
                text="From"
                name="from"
                id="from"
                placeholder="Select from date"
                onChange={(e) => {
                  setFromDate(e.target.value);
                  setPeriod("");
                }}
                selectedDate={fromDate}
                disableFutureDates={true}
                scrollInModal={true}
              />

              <DateSelector
                text="To"
                name="to"
                id="to"
                placeholder="Select to date"
                onChange={(e) => {
                  setToDate(e.target.value);
                  setPeriod("");
                }}
                selectedDate={toDate}
                disableFutureDates={true}
                scrollInModal={true}
              />
            </div>
          </div>

          <div className="flex justify-between w-full md:space-x-28 space-x-20">
            <NavigationButton
              text="Reset Filters"
              onClick={handleRestFilters}
              disabled={fromDate || toDate || period ? false : true}
            />

            <CommonButton
              onClick={() => {
                fetchTransactions();
                handleOnCloseSidebar();
              }}
              disabled={!fromDate || !toDate || period}
            >
              Apply Filters
            </CommonButton>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default TransactionsFiltersSidebar;
