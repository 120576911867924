const ChangePasswordIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12.2 12.9C11.926 12.8968 11.6576 12.9776 11.4309 13.1316C11.2042 13.2855 11.0301 13.5052 10.9321 13.7611C10.8341 14.017 10.8168 14.2968 10.8825 14.5628C10.9483 14.8288 11.094 15.0683 11.3 15.249V16.5C11.3 16.7387 11.3948 16.9676 11.5636 17.1364C11.7324 17.3052 11.9613 17.4 12.2 17.4C12.4387 17.4 12.6676 17.3052 12.8364 17.1364C13.0052 16.9676 13.1 16.7387 13.1 16.5V15.249C13.306 15.0683 13.4517 14.8288 13.5175 14.5628C13.5832 14.2968 13.5659 14.017 13.4679 13.7611C13.3699 13.5052 13.1958 13.2855 12.9691 13.1316C12.7424 12.9776 12.474 12.8968 12.2 12.9ZM16.7 9.3V7.5C16.7 6.30653 16.2259 5.16193 15.382 4.31802C14.5381 3.47411 13.3935 3 12.2 3C11.0065 3 9.86193 3.47411 9.01802 4.31802C8.17411 5.16193 7.7 6.30653 7.7 7.5V9.3C6.98392 9.3 6.29716 9.58446 5.79081 10.0908C5.28446 10.5972 5 11.2839 5 12V18.3C5 19.0161 5.28446 19.7028 5.79081 20.2092C6.29716 20.7155 6.98392 21 7.7 21H16.7C17.4161 21 18.1028 20.7155 18.6092 20.2092C19.1155 19.7028 19.4 19.0161 19.4 18.3V12C19.4 11.2839 19.1155 10.5972 18.6092 10.0908C18.1028 9.58446 17.4161 9.3 16.7 9.3ZM9.5 7.5C9.5 6.78392 9.78446 6.09716 10.2908 5.59081C10.7972 5.08446 11.4839 4.8 12.2 4.8C12.9161 4.8 13.6028 5.08446 14.1092 5.59081C14.6155 6.09716 14.9 6.78392 14.9 7.5V9.3H9.5V7.5ZM17.6 18.3C17.6 18.5387 17.5052 18.7676 17.3364 18.9364C17.1676 19.1052 16.9387 19.2 16.7 19.2H7.7C7.46131 19.2 7.23239 19.1052 7.0636 18.9364C6.89482 18.7676 6.8 18.5387 6.8 18.3V12C6.8 11.7613 6.89482 11.5324 7.0636 11.3636C7.23239 11.1948 7.46131 11.1 7.7 11.1H16.7C16.9387 11.1 17.1676 11.1948 17.3364 11.3636C17.5052 11.5324 17.6 11.7613 17.6 12V18.3Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default ChangePasswordIcon;