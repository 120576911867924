const FileIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M3.33203 2H8.66536L9.33203 5.66667L12.9987 6L12.6654 14H3.33203V2Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.19922 7.53307C4.19922 7.27534 4.40815 7.06641 4.66589 7.06641H10.8659C11.1236 7.06641 11.3326 7.27534 11.3326 7.53307C11.3326 7.79081 11.1236 7.99974 10.8659 7.99974H4.66589C4.40815 7.99974 4.19922 7.79081 4.19922 7.53307Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.19922 9.80065C4.19922 9.54292 4.40815 9.33398 4.66589 9.33398H8.86589C9.12362 9.33398 9.33255 9.54292 9.33255 9.80065C9.33255 10.0584 9.12362 10.2673 8.86589 10.2673H4.66589C4.40815 10.2673 4.19922 10.0584 4.19922 9.80065Z"
          fill="white"
        />
        <path
          d="M13.3346 5.96065C13.3277 5.89941 13.3143 5.83907 13.2946 5.78065V5.72065C13.2626 5.6521 13.2198 5.58909 13.168 5.53398L9.16797 1.53398C9.11286 1.48213 9.04985 1.43937 8.9813 1.40732H8.9213C8.85358 1.36848 8.77878 1.34355 8.7013 1.33398H4.66797C4.13754 1.33398 3.62883 1.5447 3.25376 1.91977C2.87868 2.29484 2.66797 2.80355 2.66797 3.33398V12.6673C2.66797 13.1978 2.87868 13.7065 3.25376 14.0815C3.62883 14.4566 4.13754 14.6673 4.66797 14.6673H11.3346C11.8651 14.6673 12.3738 14.4566 12.7488 14.0815C13.1239 13.7065 13.3346 13.1978 13.3346 12.6673V6.00065C13.3346 6.00065 13.3346 6.00065 13.3346 5.96065ZM9.33463 3.60732L11.0613 5.33398H10.0013C9.82449 5.33398 9.65492 5.26375 9.5299 5.13872C9.40487 5.0137 9.33463 4.84413 9.33463 4.66732V3.60732ZM12.0013 12.6673C12.0013 12.8441 11.9311 13.0137 11.806 13.1387C11.681 13.2637 11.5114 13.334 11.3346 13.334H4.66797C4.49116 13.334 4.32159 13.2637 4.19656 13.1387C4.07154 13.0137 4.0013 12.8441 4.0013 12.6673V3.33398C4.0013 3.15717 4.07154 2.9876 4.19656 2.86258C4.32159 2.73756 4.49116 2.66732 4.66797 2.66732H8.0013V4.66732C8.0013 5.19775 8.21202 5.70646 8.58709 6.08153C8.96216 6.4566 9.47087 6.66732 10.0013 6.66732H12.0013V12.6673Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default FileIcon;