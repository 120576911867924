const InternalTransferIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.81683 16.6953H3.40748C3.02395 16.6953 2.71303 16.3844 2.71303 16.0009C2.71303 15.6173 3.02395 15.3064 3.40748 15.3064H4.56488L4.56488 7.06568H3.1016L3.0462 7.10404C2.74348 7.31361 2.32818 7.2381 2.1186 6.93538C1.90902 6.63266 1.98453 6.21736 2.28725 6.00778L9.88903 1.00093L17.7397 6.00778C18.0424 6.21736 18.1179 6.63266 17.9084 6.93538C17.6988 7.2381 17.2835 7.31361 16.9808 7.10404L16.9194 7.06568H15.213V9.89827C15.1057 9.89266 14.9976 9.88982 14.8889 9.88982C14.5257 9.88982 14.1699 9.9215 13.8241 9.98226V7.06568H10.5834V11.6641C9.98397 12.2592 9.50724 12.9778 9.19451 13.7785L9.19451 7.06568H5.95377L5.95377 15.3064H8.81685C8.79106 15.5344 8.77782 15.7661 8.77782 16.0009C8.77782 16.2357 8.79106 16.4674 8.81683 16.6953ZM9.88893 2.66754L5.38438 5.67679H14.6993L9.88893 2.66754Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 22.7227C18.866 22.7227 22 19.5886 22 15.7227C22 11.8567 18.866 8.72266 15 8.72266C11.134 8.72266 8 11.8567 8 15.7227C8 19.5886 11.134 22.7227 15 22.7227ZM13.976 12.2848C14.2086 12.0522 14.2086 11.6751 13.976 11.4425C13.7435 11.21 13.3664 11.21 13.1338 11.4425L10.7299 13.8465C10.6163 13.9549 10.5455 14.1079 10.5455 14.2774C10.5455 14.3237 10.5507 14.3688 10.5608 14.4121C10.585 14.5171 10.6381 14.6169 10.7199 14.6987C10.8404 14.8192 10.9997 14.8773 11.1577 14.8729H19.3198C19.6487 14.8729 19.9153 14.6063 19.9153 14.2774C19.9153 13.9485 19.6487 13.6818 19.3198 13.6818H12.579L13.976 12.2848ZM11.141 16.5728C10.8121 16.5728 10.5455 16.8394 10.5455 17.1683C10.5455 17.4973 10.8121 17.7639 11.141 17.7639H17.882L16.4848 19.1612C16.2522 19.3937 16.2522 19.7708 16.4848 20.0034C16.7173 20.236 17.0944 20.236 17.327 20.0034L19.7409 17.5895C19.7998 17.5305 19.8438 17.4623 19.8729 17.3896C19.8977 17.3277 19.9124 17.2607 19.9149 17.1906C19.9155 17.1758 19.9155 17.1609 19.9149 17.1461C19.9096 17.0012 19.8516 16.8579 19.7409 16.7472C19.6831 16.6895 19.6165 16.646 19.5453 16.617C19.4757 16.5885 19.3996 16.5728 19.3198 16.5728H11.141Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default InternalTransferIcon;