const scrollPage = (value) => {
  window.scrollBy({
    top: value,
    left: 0,
    behavior: "smooth",
  });
};

// Todo: The same code of lines have been repeated with slight changes.
//       We need to refactor this code but later, not now.
 

const scrollIntoViewWithEase = (element, duration = 500) => {
  const start = window.scrollY;
  const targetPosition = element.getBoundingClientRect().top + start;
  const startTime = performance.now();

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const scroll = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const ease = easeInOutQuad(progress);

    window.scrollTo(0, start + (targetPosition - start) * ease);

    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};

const scrollToTopWithEase = (duration = 500) => {
  const start = window.scrollY;
  const startTime = performance.now();

  const easeInOutQuad = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const scroll = (currentTime) => {
    const timeElapsed = currentTime - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const ease = easeInOutQuad(progress);

    window.scrollTo(0, start * (1 - ease)); // Scroll smoothly towards 0 (the top)

    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};


export { scrollPage, scrollIntoViewWithEase, scrollToTopWithEase };
