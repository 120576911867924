import React from "react";

const TopupHeaders = () => {
  return (
    <thead>
      <tr className="hidden md:table-row">
        {["Date", "Topup", "Status", "Amount"].map(
          (heading, index) => (
            <th
              key={index}
              scope="col"
              className="bg-popup-bg sticky top-0 border-b border-divider-border z-[1] py-3.5 pl-4 pr-3 text-left text-xs font-medium text-paragraph-text sm:pl-6 lg:pl-8"
            >
              {heading}
            </th>
          )
        )}
      </tr>
      <tr className="table-row md:hidden">
        {[ "Topup", "Status", "Amount"].map((heading, index) => (
          <th
            key={index}
            scope="col"
            className="bg-popup-bg sticky top-0 border-b border-divider-border z-[1] py-3.5 pl-4 pr-3 text-left text-xs font-medium text-paragraph-text sm:pl-6 lg:pl-8"
          >
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TopupHeaders;
