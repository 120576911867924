import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BackBtnIcon, DropdownCrossIcon } from "../../Icons";
import GetThemeClass from "../../../helper/GetThemeClass";

const Sidebar = ({
  header,
  backButton,
  backButtonAction,
  title,
  open,
  crossButton,
  crossButtonAction,
  children,
}) => {
  return (
    <>
      <Transition.Root show={open ? open : false} as={Fragment}>
        <Dialog
          as="div"
          className={`relative z-30 sm:hidden ${GetThemeClass(
            "light",
            "dark"
          )} bg-popup-bg]`}
          onClose={() => {}}
        >
          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as="div"
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="-transition ease-in-out duration-300 transform"
              leaveFrom="-translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative flex h-screen flex-1 flex-col bg-popup-bg pt-5 pb-4">
                <Transition.Child
                  as="div"
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  {header && (
                    <div className="flex items-center justify-between px-3 py-2">
                      <div className=" flex space-x-3">
                        {backButton && (
                          <button onClick={backButtonAction}>
                            <BackBtnIcon />
                          </button>
                        )}
                        {title && (
                          <span className="text-xl font-medium text-body-text">
                            {title}
                          </span>
                        )}
                      </div>
                      {crossButton && (
                        <button onClick={crossButtonAction}>
                          <DropdownCrossIcon />
                        </button>
                      )}
                    </div>
                  )}
                </Transition.Child>

                <div
                  className="h-full w-screen flex-shrink-0 divide-y divide-body-bg bg-popup-bg overflow-y-auto"
                  aria-label="Sidebar"
                >
                  <div className="right-0 z-10 min-w-max origin-top-right px-4 pt-2 pb-20">
                    {children}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Sidebar;
