import { Link } from "react-router-dom";
import Label from "../../../Label";
import Button from "../../../Button";

const PrimaryCardButton = ({ label, link = null, icon }) => {
  return (
    <Link to={link ? link : ""}>
      <div className="w-[51px] h-[74px] flex-col justify-center items-center gap-2 inline-flex">
        <Button
          className="flex justify-center items-center w-[42px] py-[9px] px-4 bg-primary-Icon-bg bg-opacity-30 rounded-[100px] hover:bg-icon-btn-hover
          hover:ease-in-out hover:duration-300 dark:hover:bg-active-state-bg"
          icon={icon}
        />
        <Label
          className="text-center text-primary-item text-xs md:text-sm font-medium leading-normal tracking-tight whitespace-nowrap"
          text={label}
        />
      </div>
    </Link>
  );
};
export default PrimaryCardButton;
