import React from "react";

const PriceSummary = ({
  rate,
  commissionRate,
  commissionFee,
  approxFiatAmount,
}) => {
  return (
    <div className="p-6 max-[360px]:px-2 bg-primarycard-gradient rounded-3xl shadow-box-shadow">
      <div className="text-body-text">
        <h2 className="text-xl font-bold mb-2">Price Summary</h2>
        <p>
          Rate: <span className="font-semibold">{rate}</span>
        </p>
        <p>
          Commission Rate:{" "}
          <span className="font-semibold">{commissionRate}</span>
        </p>
        <p>
          Commission Fee: <span className="font-semibold">{commissionFee}</span>
        </p>
        <p>
          Approx FIAT Amount:{" "}
          <span className="font-semibold">{approxFiatAmount}</span>
        </p>
      </div>
    </div>
  );
};

export default PriceSummary;
