import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";

export const serviceRequestVerifyOTP = (data) => async (dispatch) => {
  try {
    const res = await commonService.post(
      "/client/mobile-requests/verify-otp",
      data
    );

    return res.data;
  } catch (error) {
    const message = error.response.data.message || error.message;

    ResponseErrorHandling(error.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return error.response.data;
  }
};
