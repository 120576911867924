import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOwnCompany } from "../../redux/actions/lookups/getOwnCompany";

const useCompanyData = () => {
  const [companyData, setCompanyData] = useState("");
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.ownCompany);

  useEffect(() => {
    const fetchCompanyData = async () => {
      const data = await dispatch(getOwnCompany());
      setCompanyData(data);
    };
    if (!company) {
      fetchCompanyData();
    } else {
      setCompanyData(company);
    }
  }, [dispatch, company]);

  return companyData;
};

export default useCompanyData;
