import React from "react";
import Label from "../Label";
import { TertiaryButton } from "../Buttons";
import { Link } from "react-router-dom";

const ErrorPage = ({
  errorCode = "404",
  message = "Not Found",
  buttonTitle = "Back to Home",
  buttonLink = "/",
  description = "The page you are looking for might have been removed had its name changed or is temporarily unavailable.",
}) => {
  return (
    <div className="flex flex-col max-w-[768px] text-center mx-auto px-4 h-[80vh] justify-between items-center text-body-text py-10">
      <div className="flex flex-col items-center space-y-6 flex-grow justify-center">
        <Label
          text={`${errorCode} | ${message}`}
          className="text-center text-4xl font-bold text-primary-item"
        />
        <span className="text-body-text" >
            {description}
        </span>
        <Link to={buttonLink}>
          <TertiaryButton text={buttonTitle} />
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
