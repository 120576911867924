import React from "react";

const EURSymbol = ({ width, height, fillColor }) => {
  return (
    <svg
      width={width ? width : 22}
      height={height ? height : 24}
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3125 19.8594C13.9167 19.8594 15.1875 19.4531 16.125 18.6406C17.0521 17.8385 17.625 16.7292 17.8438 15.3125H21.6562C21.4062 17.6667 20.4583 19.5885 18.8125 21.0781C17.1875 22.5469 15.0208 23.2812 12.3125 23.2812C9.69792 23.2812 7.5625 22.6094 5.90625 21.2656C4.23958 19.9427 3.09896 18.1094 2.48438 15.7656H0.5V13.0781H2.04688C2.02604 12.8906 2.01562 12.474 2.01562 11.8281C2.01562 11.2969 2.02604 10.9583 2.04688 10.8125H0.5V8.125H2.42188C2.97396 5.71875 4.10417 3.80729 5.8125 2.39062C7.53125 1.00521 9.69792 0.3125 12.3125 0.3125C15.0208 0.3125 17.2083 1.06771 18.875 2.57812C20.5417 4.08854 21.4688 6.0625 21.6562 8.5H17.8125C17.6875 7.09375 17.1562 5.95312 16.2188 5.07812C15.2812 4.20312 13.9792 3.76562 12.3125 3.76562C9.29167 3.76562 7.30729 5.21875 6.35938 8.125H14.2031L13.5625 10.8125H5.875C5.85417 10.9583 5.84375 11.2969 5.84375 11.8281C5.84375 11.9323 5.86458 12.349 5.90625 13.0781H13.0781L12.4375 15.7656H6.45312C7.41146 18.4948 9.36458 19.8594 12.3125 19.8594Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default EURSymbol;
