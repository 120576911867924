import { AllCountriesActions } from "../../action-types";

const countries = JSON.parse(localStorage.getItem("countries"));

const initialState = {
  countries: countries ? countries : null
};

export default function allCountriesReducer(state = initialState, action) {
  switch (action.type) {
    case AllCountriesActions.GET_ALL_COUNTRIES_DATA:
      return {
        countries: action.payload,
      };
    default:
      return state;
  }
}
