import React from "react";

const RejectedSign = ({ className }) => {
  return (
    <div className={className}>
      <svg
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.47626 1.10901C10.023 1.49833 10.5017 1.97705 10.891 2.52374C11.5893 3.50443 12 4.70362 12 6C12 9.31371 9.31371 12 6 12C4.70362 12 3.50443 11.5894 2.52374 10.891C1.97705 10.5017 1.49833 10.023 1.10901 9.47626C0.410649 8.49557 0 7.29638 0 6C0 2.68629 2.68629 0 6 0C7.29638 0 8.49557 0.410649 9.47626 1.10901ZM9.78012 3.12901L3.12901 9.78012L3.60487 10.0613C4.30713 10.4763 5.12643 10.7143 6 10.7143C8.60363 10.7143 10.7143 8.60363 10.7143 6C10.7143 5.12642 10.4763 4.30713 10.0613 3.60487L9.78012 3.12901ZM2.21988 8.87099L8.87099 2.21988L8.39513 1.93869C7.69287 1.52372 6.87358 1.28571 6 1.28571C3.39637 1.28571 1.28571 3.39637 1.28571 6C1.28571 6.87358 1.52372 7.69287 1.93869 8.39513L2.21988 8.87099Z"
          fill="#EC0C00"
        />
      </svg>
    </div>
  );
};

export default RejectedSign;
