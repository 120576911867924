import React, { useState } from "react";
import { DropdownCrossIcon, EditIcon, FileIcon } from "../../../Icons";
import {
  FormatBalance,
  FormatDate,
  GetTransactionsTypeIcon,
} from "../../../../helper";
import SecondaryButton from "../../../Buttons/SecondaryButton";
import { useSelector } from "react-redux";
import HelpModal from "../../../Modals/HelpModal";
import GetTransactionStatus from "./partials/GetTransactionStatus";
import { useNavigate } from "react-router-dom";
import TopupCardModal from "../../../Modals/Card/ToupCardModal";
import useModules from "../../../../hooks/useModules";
import EditTransactionButton from "./partials/EditTransactionButton";
import ViewFileButton from "../../../Buttons/ViewFileButton";
import CommonButton from "../../../Buttons/CommonButton";

const ViewRecentTransaction = ({
  transaction,
  setShowRecentTransaction,
  setActiveTransaction,
  type = "accounts",
  fetchTopups,
}) => {
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const { currency_code, is_crypto } = useSelector(
    (state) => state.activeAccount.activeAccount
  );
  const { activeCard } = useSelector((state) => state.activeCard);

  const isCrypto = useModules("Crypto");
  const isBitpace = useModules("Bitpace");

  const handleOpenUrl = (url) => {
    window.open(url, "_blank");
  };

  const navigate = useNavigate();

  const handleTransactionClick = (transaction) => {
    switch (transaction.nature) {
      case "Withdraw":
        navigate("/crypto-withdraw", { state: { transaction: transaction } });
        break;
      case "Sell":
        navigate("/crypto-sell", { state: { transaction: transaction } });
        break;
      case "Buy":
        navigate("/crypto-buy", { state: { transaction: transaction } });
        break;
      case "TT":
        if (transaction.beneficiary_id) {
          navigate("/transfer", { state: { transaction: transaction } });
        } else {
          navigate("/deposit", { state: transaction.id });
        }
        break;
      case "Internal":
        navigate("/transfer", { state: { transaction: transaction } });
        break;
      default:
        break;
    }
  };

  const handleIsCryptoEnabled = () => {
    const { nature } = transaction;
    if (isCrypto && isBitpace && ["Buy", "Sell", "Withdraw"].includes(nature)) {
      return null;
    }
    if (nature === "Top-up" && activeCard.status === "Active") {
      return (
        <TopupCardModal
          activeCard={activeCard}
          transaction={transaction}
          setActiveTransaction={setActiveTransaction}
          refreshTable={fetchTopups}
        >
          <CommonButton>
            <div className="flex items-center justify-center">
              <EditIcon />
              Edit Transaction
            </div>
          </CommonButton>
        </TopupCardModal>
      );
    }
    if (
      ["TT", "Internal"].includes(nature) ||
      (isCrypto && !isBitpace && nature !== "Top-up")
    ) {
      return (
        <EditTransactionButton
          handleTransactionClick={handleTransactionClick}
          transaction={transaction}
        />
      );
    }

    return null;
  };

  const getCurrenySumbolFromAccountTitle = (account_title) => {
    let currency = account_title.split("(")[1];
    currency = currency.split(")")[0];
    // return GetCurrenySymbol(currency, "13", "13");
    return currency;
  };

  return (
    <>
      <div
        onClick={() => {
          setShowRecentTransaction(false);
          setActiveTransaction(null);
        }}
      >
        <div className="hover:bg-hover-state-bg rounded-full w-6 h-6 p-4 flex items-center justify-center cursor-pointer">
          <DropdownCrossIcon className="" />
        </div>
      </div>
      <div
        className={`flex items-center text-body-text ${
          transaction.status === "Rejected" &&
          "line-through text-paragraph-text"
        } text-3xl font-medium leading-[48px] tracking-tight`}
      >
        <div className="space-x-2 mx-2 flex items-center">
          <span>{transaction.type === "Credit" ? "+" : "-"}</span>

          {/* {type === "card"
            ? GetCurrenySymbol(
                activeCard.currency ? activeCard.currency : "USD"
              )
            : GetCurrenySymbol(currency_code)} */}

          <span>
            {type === "card"
              ? transaction.account_title
                ? getCurrenySumbolFromAccountTitle(transaction.account_title)
                : "USD"
              : currency_code}
          </span>
          <span>
            {is_crypto && transaction.crypto_amount
              ? FormatBalance(transaction.crypto_amount, is_crypto)
              : FormatBalance(transaction.amount)}
          </span>
        </div>
      </div>

      <div className="self-stretch grow shrink basis-0 justify-start items-center gap-4 inline-flex">
        {GetTransactionsTypeIcon(type, transaction.type)}

        <div className="flex-col justify-start items-start inline-flex w-64">
          <p className="text-body-text line-clamp-1 pr-5 text-base font-medium leading-normal tracking-tight">
            {transaction.remarks}
          </p>
          <div className="flex-col justify-center items-center gap-2.5 flex">
            <div className="text-center text-paragraph-text text-sm font-normal leading-tight tracking-tight">
              {FormatDate(transaction.date, "d m, yyyy", "h:M a")}
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 bg-body-bg rounded-3xl">
        {transaction.status && (
          <div className="flex items-start justify-between py-4">
            <p className="text-paragraph-text text-sm font-medium leading-tight tracking-tight">
              Status
            </p>
            <div className="flex items-center text-body-text text-sm font-medium leading-tight tracking-tight">
              <GetTransactionStatus status={transaction.status} />
            </div>
          </div>
        )}

        {transaction.status === "Rejected" && (
          <ListItem title="Rejected Reason" value={transaction.reject_reason} />
        )}
        <ListItem title="Type" value={transaction.type} />
        <ListItem title="Transaction ID" value={transaction.id} />
        <ListItem title="Nature" value={transaction.nature} />
        {type === "card" && transaction.account_title && (
          <ListItem title="From account" value={transaction.account_title} />
        )}
        <ListItem title="Remarks" value={transaction.remarks} />
        {transaction && transaction.docs && transaction.docs.length > 0 && (
          <div>
            {/* Check if any non-payment documents exist */}
            {transaction.docs.some((doc) => doc.is_payment !== "1") && (
              <>
                <ListItem title="Supporting Documents" />
                <div className="grid grid-cols-3">
                  {transaction.docs.map((doc) =>
                    doc.is_payment !== "1" ? (
                      <div key={doc.id || doc.url} className="mb-4">
                        <ViewFileButton
                          text="View"
                          onClick={() => handleOpenUrl(doc.url)}
                          icon={<FileIcon />}
                        />
                      </div>
                    ) : null
                  )}
                </div>
              </>
            )}

            {/* Check if any payment documents exist */}
            {transaction.docs.some((doc) => doc.is_payment === "1") && (
              <>
                <ListItem title="Payment Advice Document" />
                <div className="grid grid-cols-3">
                  {transaction.docs.map((doc) =>
                    doc.is_payment === "1" ? (
                      <div key={doc.id || doc.url} className="mb-4">
                        <ViewFileButton
                          text="View"
                          onClick={() => handleOpenUrl(doc.url)}
                          icon={<FileIcon />}
                        />
                      </div>
                    ) : null
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <HelpModal
        HelpModalOpen={helpModalOpen}
        setHelpModalOpen={setHelpModalOpen}
      >
        <SecondaryButton text="Get Help" />
      </HelpModal>
      {transaction.status === "Rejected" && handleIsCryptoEnabled()}
    </>
  );
};

const ListItem = ({ title, value }) => {
  return (
    <div className="flex items-start justify-between py-4 gap-3">
      <p className="text-paragraph-text text-sm font-medium leading-tight tracking-tight">
        {title}
      </p>
      <p className="text-body-text text-sm font-medium leading-tight tracking-tight max-w-56">
        {value}
      </p>
    </div>
  );
};

export default ViewRecentTransaction;
