import React from "react";
import Label from "../Label";

const FormTextAreaField = ({
  label,
  name,
  id,
  rows,
  cols,
  placeholder,
  value,
  onChange,
  readOnly,
}) => {
  return (
    <div
      className={`w-full px-3 py-2 relative rounded-xl bg-input-bg border border-divider-border shadow-sm focus-within:border-primary-item ${
        readOnly ? "read-only:bg-secondary-icon-bg" : "bg-input-bg"
      }`}
    >
      <Label
        htmlFor={id}
        className="block text-xs font-medium text-body-text"
        text={label}
      />
      <textarea
        name={name}
        id={id}
        rows={rows}
        cols={cols}
        value={value}
        onChange={onChange}
        className="block w-full bg-transparent border-0 p-0 text-sm font-medium text-body-text placeholder-paragraph-text placeholder:font-normal focus:ring-0"
        placeholder={placeholder}
      />
    </div>
  );
};

export default FormTextAreaField;
