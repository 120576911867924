import { CreditMoneyIcon, DebitMoneyIcon } from "../../components/Icons";
import CardDepositIcon from "../../components/Icons/CardDepositIcon";
import CardWithdrawIcon from "../../components/Icons/CardWithdrawIcon";
import CryptoCreditIcon from "../../components/Icons/CryptoCreditIcon";
import CryptoDebitIcon from "../../components/Icons/CryptoDebitIcon";

const GetTransactionsTypeIcon = (type, transaction_type) => {
  switch (type) {
    case "card":
      return (
        <>
          {transaction_type === "Credit" ? (
            <CardDepositIcon />
          ) : (
            <CardWithdrawIcon />
          )}
        </>
      );
    case "account":
      return (
        <>
          {transaction_type === "Credit" ? (
            <CreditMoneyIcon />
          ) : (
            <DebitMoneyIcon />
          )}
        </>
      );
    case "crypto":
      return (
        <>
          {transaction_type === "Credit" ? (
            <CryptoCreditIcon />
          ) : (
            <CryptoDebitIcon />
          )}
        </>
      );
    default:
      return (
        <>
          {transaction_type === "Credit" ? (
            <CreditMoneyIcon />
          ) : (
            <DebitMoneyIcon />
          )}
        </>
      );
  }
};

export default GetTransactionsTypeIcon;
