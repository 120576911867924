import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";

export const balanceCertificateRequest = (data) => async (dispatch) => {
  try {
    data.from_web = true;
    const res = await commonService.post("/client/mobile-requests/balance-certificate", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  }
};
