import React, { useState, useEffect } from "react";

const Slider = () => {
  const sliderData = [
    {
      id: 1,
      title: "All in one",
      subTitle: "Banking Solution ✨",
    },
    // {
    //   id: 2,
    //   title: "We made,",
    //   subTitle: "Crypto for everyone! ✨",
    // },
    {
      id: 3,
      title: "Manage",
      subTitle: "All accounts in one place! ✨",
    },
  ];

  const [activeSlide, setActiveSlide] = useState(0);
  const [fade, setFade] = useState(false);
  let loginImg = localStorage.getItem("company") ? JSON.parse(localStorage.getItem("company")).login_page_image : ""

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setActiveSlide((prevSlide) =>
          prevSlide < sliderData.length - 1 ? prevSlide + 1 : 0
        );
        setFade(false);
      }, 400); // Transition duration
    }, 3000);

    return () => clearInterval(interval);
  });

  const handleClick = (index) => {
    setFade(true);
    setTimeout(() => {
      setActiveSlide(index);
      setFade(false);
    }, 400); // Transition duration
  };

  return (
    <div className="w-[649px] rounded-3xl hidden md:block relative">
      <div
        className="absolute inset-0 w-full bg-cover bg-center bg-no-repeat rounded-3xl"
        style={{ backgroundImage: `url(${loginImg})` }}
        // style={{ backgroundImage: 'url("assets/gifs/login-bg-2.gif")' }}
      >
        {/* Dark overlay */}
        <div className="absolute inset-0 w-full bg-side-gradient opacity-100 rounded-3xl"></div>
        {/* Content overlay */}
        <div className="absolute bottom-0 z-10 flex flex-col space-y-4 justify-end pl-[72px] pb-[72px]">
          <div className="flex space-x-2">
            {sliderData.map((slide, index) => (
              <div
                key={slide.id}
                className="w-8 h-1 cursor-pointer py-3"
                onClick={() => handleClick(index)}
              >
                <div
                  className={`w-8 h-1 left-0 top-0 ${
                    activeSlide === index ? "bg-white" : "opacity-50 bg-white"
                  }`}
                />
                <div
                  className={`w-[0px] h-1 left-0 top-0 ${
                    activeSlide === index ? "bg-white" : ""
                  }`}
                />
              </div>
            ))}
          </div>
          <div
            className={`transition-opacity duration-500 ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          >
            <span className="text-white text-2xl font-semibold">
              {sliderData[activeSlide].title}
            </span>
            <br />
            <span className="text-white text-[32px] font-semibold">
              {sliderData[activeSlide].subTitle}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
