import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";

export const VerifyTopUpOTP = (data) => async (dispatch) => {
  dispatch({
    type: CommonActionTypes.PENDING,
    payload: true,
  });

  try {
    const res = await commonService.post(
      "/client/card-top-up/verify-otp",
      data
    );

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
