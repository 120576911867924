import { UserInfoActionTypes } from "../../action-types";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

const initialState = {
  userInfo: userInfo ? userInfo : null,
};

export default function userInfoReducer(state = initialState, action) {
  switch (action.type) {
    case UserInfoActionTypes.USER_INFO:
      return {
        userInfo: action.payload,
      };
    default:
      return state;
  }
}
