import { ResponseErrorHandling } from "../../../../helper";
import commonService from "../../../../services/Common/service";
import FormDataService from "../../../../services/FormDataService";
import { CommonActionTypes } from "../../../action-types";

export const getClientFiles = (type) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.get(
      `/lookups/client/files-config?type=${type}`
    );

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const uploadClientFiles = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await FormDataService.post("/user/register/upload-files", data);

    return res.data;
  } catch (err) {
    const message = err.response.data.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
