const FilterCurrencies = (currencies, filterType) => {
  switch (filterType) {
    case "exclude_fiat":
      return currencies.filter(
        (currency) =>
          !["HKD", "RUB", "GBP"].includes(currency.code) &&
          currency.is_crypto !== 1
      );
    case "crypto":
      return currencies.filter((currency) => currency.is_crypto === 1);
    case "fiat":
      return currencies.filter((currency) => currency.is_crypto !== 1);
    default:
      return currencies;
  }
};

export default FilterCurrencies;
