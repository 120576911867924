import React from 'react'
import AddAccountModal from '../../Modals/AddAccount/AddAccountModal'
import CommonButton from '../../Buttons/CommonButton'
import { EmptyExchangeIcons } from '../../Icons'

const EmptyCryptoTransactions = () => {
  return (
    <div className="md:max-w-[400px] mt-6">
      <div className="flex flex-col items-center justify-center space-y-10">
        <EmptyExchangeIcons />
        <h2 className="font-normal text-center text-paragraph-text">
          You have no <b>USD</b> or <b>EUR</b> currency accounts. For crypto transactions, you need one of these currency
          accounts. Click on this button to add a new currency account.
        </h2>
        <AddAccountModal>
          <CommonButton>Add Currency Account</CommonButton>
        </AddAccountModal>
      </div>
    </div>
  )
}

export default EmptyCryptoTransactions