const ChangeCardPinIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M6.60059 10.2007V8.40053C6.60059 5.42124 7.50067 3 12.0011 3C16.5016 3 17.4016 5.42124 17.4016 8.40053V10.2007"
          className="stroke-primary-item"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.5013 20.9983H7.50044C3.90009 20.9983 3 20.0982 3 16.4979V14.6977C3 11.0974 3.90009 10.1973 7.50044 10.1973H16.5013C20.1017 10.1973 21.0018 11.0974 21.0018 14.6977V16.4979C21.0018 20.0982 20.1017 20.9983 16.5013 20.9983Z"
          className="stroke-primary-item"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9965 16H16.0054"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.9955 16H12.0045"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99451 16H8.00349"
          className="stroke-primary-item"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default ChangeCardPinIcon;