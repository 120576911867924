import React from "react";
import { DropdownCheckIcon } from "../../../Icons";

const CurrencyItem = ({ currency, selectedItem }) => {
  const isSelected = selectedItem.includes(currency.id);
  return (
    <div
      className={`flex items-center justify-start relative border mt-3 w-full px-4 py-3 rounded-3xl cursor-pointer ${
        isSelected
          ? "border-icon-btn bg-option-list-selected"
          : "border-divider-border bg-popup-bg hover:bg-option-list-hover"
      }`}
    >
      {isSelected && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 -top-1 bg-[#EFC660] rounded-full shadow border-2 border-white">
          <DropdownCheckIcon />
        </div>
      )}

      {currency.logo ? (
        <img
          src={currency.logo}
          alt="logo"
          className="w-10 h-10 rounded-full mr-3"
        />
      ) : (
        <img
          className="w-10 h-10 mr-3 rounded-full"
          src={require("../../../../assets/images/avatar.png")}
          alt="currency_logo"
        />
      )}
      <div className="flex-col justify-start items-start gap-1 inline-flex">
        <h2 className="text-body-text text-sm font-medium leading-tight tracking-tight">
          {currency.code}
        </h2>
        <h3 className="text-paragraph-text text-sm font-normal leading-tight tracking-tight">
          {currency.name}
        </h3>
      </div>
    </div>
  );
};

export default CurrencyItem;
