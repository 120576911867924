import { ModulesActionTypes } from "../../action-types";

const modules = JSON.parse(localStorage.getItem("modules"));

const initialState = {
  modules: modules ? modules : []
};

export default function moduleReducer(state = initialState, action) {
  switch (action.type) {
    case ModulesActionTypes.ADD_MODULES:
      return {
        ...state,
        // Merge new modules with the existing state
        modules: [...state.modules, ...action.payload],
      };
    
    case ModulesActionTypes.UPDATE_MODULES:
      return {
        ...state,
        modules: state.modules.map((module) =>
          // Fix the property name for comparison to match "module"
          module.module === action.payload.module
            ? { ...module, status: action.payload.status }
            : module
        ),
      };
    
    default:
      return state;
  }
}
