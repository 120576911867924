import React, { useEffect, useState } from "react";
import Modal from "../../Common/Modal";
import { useDispatch } from "react-redux";
import EnterOTP from "../../Common/EnterOTP";
import HangTightLoading from "../../Common/HangTightLoading";
import {
  sendGuestOtp,
  verifyGuestOtp,
} from "../../../redux/actions/register/otp";
import { registerDraftClient } from "../../../redux/actions/register/draftClient";
import { useNavigate } from "react-router-dom";
import { AuthActionTypes } from "../../../redux/action-types";

const RegistrationOTPModal = ({
  isLoading,
  setisLoading,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [Error, setError] = useState("");
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = useState(60);
  const userDraftData = JSON.parse(localStorage.getItem("userDraftData"));
  const navigate = useNavigate();

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOTP = async () => {
    setisLoading(true);

    try {
      const res = await dispatch(
        verifyGuestOtp(userDraftData.email_address, otp.join(""))
      );
      if (!res.status) {
        setError(res.error);
        setisLoading(false);
        return;
      }

      const registerUser = await dispatch(registerDraftClient(userDraftData));
      if (!registerUser.success) {
        setError(registerUser.error);
        setisLoading(false);
        return;
      }

      const data = {
        token: registerUser.token,
        message: registerUser.message,
        success: registerUser.success,
        account_type: localStorage.getItem("account_type"),
        status: "Draft",
      };

      localStorage.removeItem("userDraftData");
      localStorage.setItem("user", JSON.stringify(data));

      dispatch({
        type: AuthActionTypes.LOGIN,
        payload: data,
      });
      navigate("/");
    } catch (error) {
      setError(error);
    } finally {
      setisLoading(false);
    }
  };

  const handleResendCode = async () => {
    setResendDisabled(true);
    setisLoading(true);
    await dispatch(sendGuestOtp(userDraftData.email_address));
    setisLoading(false);
    setCounter(60);
  };

  useEffect(() => {
    if (isModalOpen) {
      setCounter(60);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isLoading && resendDisabled) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            setResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLoading, resendDisabled]);
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={handleOnCloseModal}
        header={isLoading ? false : true}
        backButtonAction={() => {}}
        headerTitle={"Enter OTP"}
        className="w-[432px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl py-3"
        loading={isLoading}
      >
        <div className="px-6 pt-2">
          {!isLoading ? (
            <EnterOTP
              handleOTP={handleOTP}
              otp={otp}
              setOtp={setOtp}
              error={Error}
              setError={setError}
              resendDisabled={resendDisabled}
              counter={counter}
              handleResendCode={handleResendCode}
            />
          ) : (
            isLoading && <HangTightLoading message={"Please wait while we process your request."} />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RegistrationOTPModal;
