import { Outlet } from "react-router-dom";
import { useModules } from "../../hooks";
import ErrorPage from "../../components/ErrorPage";

const CryptoRoutes = () => {
  // const isBitpaceEnabled = useModules("Bitpace");
  const isCryptoEnabled = useModules("Crypto");

  return (
    <>
      {isCryptoEnabled ? (
        <>
          <Outlet />
        </>
      ) : (
        <ErrorPage />
      )}
    </>
  );
};

export default CryptoRoutes;
