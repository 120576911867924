import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import FormDataService from "../../../../services/FormDataService";
import { CommonActionTypes } from "../../../action-types";

export const uploadProfilePicture = (profile_photo) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await FormDataService.post("/user/upload-photo", profile_photo);

    return res.data;
  } catch (error) {
    const message = error.response.data.message || error.message;

    ResponseErrorHandling(error.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
