import { RecentCardTransactionsAction } from "../../action-types";

// const recentCardTransactions = JSON.parse(
//   localStorage.getItem("recentCardTransactions")
// );

const initialState = {
  recentCardTransactions: null
};

export default function cardTransactions(state = initialState, action) {
  switch (action.type) {
    case RecentCardTransactionsAction.GET_RECENT_CARD_TRANSACTIONS:
      return {
        recentCardTransactions: action.payload,
      };
    case RecentCardTransactionsAction.UPDATE_RECENT_CARD_TRANSACTIONS:
      return {
        ...state,
        recentCardTransactions: action.payload.last_10,
      };
    default:
      return state;
  }
}
