import React from "react";
import { NavigationButton, PrimaryButton } from "../../../Buttons";
import Label from "../../../Label";
import FormInputField from "../../../FormInputField";

const EmployerInfo = ({ handleNext, handlePrev, formData, onChange }) => {
  const isFormValid = () => {
    return (
      formData.occupation_business &&
      formData.employer_business_name &&
      formData.job_title &&
      formData.applicant_annual_income_usd &&
      formData.employer_address
    );
  };

  return (
    <div className="space-y-4 md:w-[375px]">
      <div>
        <Label
          className="block text-sm font-bold text-body-text mb-3"
          htmlFor="employer-info"
          text="Employer Information"
        />
        <FormInputField
          label="Occupation / Business type"
          name="occupation_business"
          id="occupation_business"
          type="text"
          placeholder="Enter your occupation or business"
          value={formData.occupation_business}
          onChange={onChange}
        />
      </div>
      <div>
        <FormInputField
          label="Employer / Business name"
          name="employer_business_name"
          id="employer_business_name"
          type="text"
          placeholder="Enter your occupation or business name"
          value={formData.employer_business_name}
          onChange={onChange}
        />
      </div>
      <div>
        <FormInputField
          label="Job title"
          name="job_title"
          id="job_title"
          type="text"
          placeholder="Enter your job title"
          value={formData.job_title}
          onChange={onChange}
        />
      </div>
      <div>
        <FormInputField
          label="Estimated Gross Annual Income (USD)"
          name="applicant_annual_income_usd"
          id="applicant_annual_income_usd"
          type="number"
          placeholder="Enter your income in USD"
          value={formData.applicant_annual_income_usd}
          onChange={onChange}
        />
      </div>
      <div>
        <FormInputField
          label="Address"
          name="employer_address"
          id="employer_address"
          type="text"
          placeholder="Enter your occupation or business address"
          value={formData.employer_address}
          onChange={onChange}
        />
      </div>

      <hr className="border border-divider-border"/>

      <div className="flex w-full space-x-4">
        <NavigationButton onClick={handlePrev} text="Back" />
        <PrimaryButton
          onClick={handleNext}
          text="Continue"
          disabled={!isFormValid()}
        />
      </div>
    </div>
  );
};
export default EmployerInfo;
