import React from "react";
import { HelpIcon } from "../../Icons";

const SecondaryButton = ({ text, icon, ...props }) => {
  return (
    <button
      className="flex w-full space-x-2 bg-secondary-icon-bg p-3 rounded-full hover:bg-hover-state-bg disabled:bg-[#E9E8E3]  disabled:bg-opacity-50"
      {...props}
    >
      <HelpIcon />
      <p className="text-primary-item text-sm font-medium leading-normal tracking-tight">
        {text}
      </p>
    </button>
  );
};

export default SecondaryButton;
