import React from "react";

const Input = ({
  type,
  name,
  id,
  value,
  onChange,
  placeholder,
  className,
  required,
  isDisabled,
  ...rest
}) => (
  <input
    type={type}
    name={name}
    id={id}
    value={value}
    onChange={onChange}
    placeholder={placeholder ? placeholder : ""}
    className={className}
    required={required}
    disabled={isDisabled ? true : false}
    {...rest}
  />
);

export default Input;
