import React from "react";

const BTCSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 15}
      height={height ? height : 31}
      viewBox="0 0 15 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.336 5.72V0.919998H9.384V5.72H7.336ZM3.048 5.72V0.919998H5.096V5.72H3.048ZM7.336 30.136V25.336H9.384V30.136H7.336ZM3.048 30.136V25.336H5.096V30.136H3.048ZM0.104 27V4.152H5.352C7.144 4.152 8.63733 4.33333 9.832 4.696C11.048 5.05867 11.9547 5.656 12.552 6.488C13.1707 7.32 13.48 8.42933 13.48 9.816C13.48 10.712 13.3307 11.512 13.032 12.216C12.7547 12.8987 12.328 13.4747 11.752 13.944C11.1973 14.392 10.504 14.7013 9.672 14.872V15.032C10.5253 15.1813 11.2827 15.4587 11.944 15.864C12.6267 16.2693 13.16 16.8453 13.544 17.592C13.9493 18.3387 14.152 19.3093 14.152 20.504C14.152 21.8907 13.8533 23.0747 13.256 24.056C12.68 25.016 11.848 25.752 10.76 26.264C9.672 26.7547 8.37067 27 6.856 27H0.104ZM5.928 13.88C7.67733 13.88 8.872 13.5707 9.512 12.952C10.1733 12.312 10.504 11.3733 10.504 10.136C10.504 8.87733 10.0987 7.98133 9.288 7.448C8.49867 6.89333 7.22933 6.616 5.48 6.616H2.984V13.88H5.928ZM2.984 16.28V24.568H6.312C8.08267 24.568 9.32 24.1733 10.024 23.384C10.728 22.5947 11.08 21.56 11.08 20.28C11.08 19.4693 10.92 18.7653 10.6 18.168C10.3013 17.5707 9.78933 17.112 9.064 16.792C8.33867 16.4507 7.34667 16.28 6.088 16.28H2.984Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};
export default BTCSymbol;
