import { ResponseErrorHandling } from "../../../../helper";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes, AllCountriesActions } from "../../../action-types";

export const getCountries = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const res = await commonService.get("/lookups/countries");
    const countries = res.data.data;
    
    localStorage.setItem("countries", JSON.stringify(countries));
    dispatch({
      type: AllCountriesActions.GET_ALL_COUNTRIES_DATA,
      payload: countries,
    });

    return countries;
  } catch (err) {
    const message = err.response.data.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
