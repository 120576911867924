import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import {
  CommonActionTypes,
  clientHelpReasonsActionTypes,
} from "../../../action-types";

export const getHelpReasons = () => async (dispatch) => {
  try {
    const res = await commonService.get("/lookups/help-reasons");
    const helpReasons = res.data.data;

    const clientHelpReasons = helpReasons.reduce((accumulator, option) => {
      accumulator.push({ label: option.reason, value: option.id });
      return accumulator;
    }, []);

    localStorage.setItem("clientHelpReasons", JSON.stringify(clientHelpReasons));
    dispatch({
      type: clientHelpReasonsActionTypes.GET_CLIENT_HELP_REASONS,
      payload: clientHelpReasons,
    });

    return clientHelpReasons;
  } catch (err) {
    const message = err.response.data.message || err.message || err.toString();

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};
