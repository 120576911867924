import React from "react";
import { DownArrowIcon } from "../../Icons";
import { useSelector } from "react-redux";

const AccountDropdown = ({ activeAccount }) => {
  const { name } =  useSelector((state) => state.ownCompany.company);
  return (
    <>
      {activeAccount && (
        <div className="relative flex w-full justify-start items-center border border-divider-border py-2 px-3 bg-input-bg rounded-xl cursor-pointer">
          <div className="flex">
            <img
              src={activeAccount?.logo}
              alt="flag"
              className="w-10 h-10 rounded-full"
            />
            <div className="px-3">
              <p className="text-primary-item text-sm font-medium leading-5 tracking-[0.07px] ">
                {activeAccount.account_number}
              </p>
              <p className="text-primary-item font-normal text-sm leading-5 tracking-[0.07px] whitespace-nowrap">
                {activeAccount.currency_code} · {name}
              </p>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-[18px] hover:cursor-pointer">
            <DownArrowIcon className="h-5 w-5" />
          </div>
        </div>
      )}
    </>
  );
};

export default AccountDropdown;
