import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getCurrencies } from "../../redux/actions/lookups/Currencies";
import { FilterCurrencies } from "../../helper";

const useCurrencies = (filterType = "") => {
  const [currencies, setCurrencies] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCurrencies = async () => {
      try {
        const currencies = await dispatch(getCurrencies());
        setCurrencies(FilterCurrencies(currencies, filterType));
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };
    fetchCurrencies();
    // eslint-disable-next-line
  }, []);
  return currencies;
};

export default useCurrencies;
