import React, { useCallback, useEffect } from "react";
import StatusIcon from "../../../Common/StatusIcon";
import { AllTransactionsTableSkeleton } from "../../../Skeleton";
import EmptyRecentTransactions from "../../../Empty/EmptyRecentTransactions";
import TopupHeaders from "./partials/TopupHeaders";
import {
  FormatBalance,
  FormatDate,
  // GetCurrenySymbol,
  GetTransactionsTypeIcon,
} from "../../../../helper";
import LoadMoreTransactions from "../../../Skeleton/LoadMoreTransactions";

const CardTopupTable = ({
  topups,
  handleSelectTransaction,
  activeTransaction,
  isLoading,
  loadMoreTransactions,
  scrollRef,
  hasMore,
  setPage,
  page,
  loadMore,
}) => {
  const handleScroll = useCallback(() => {
    const container = scrollRef.current;
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight - 10
    ) {
      if (hasMore && !isLoading) {
        setPage((prev) => prev + 1);
        loadMoreTransactions(page + 1); // Increment page before fetching
      }
    }
  }, [hasMore, isLoading, page, loadMoreTransactions, setPage, scrollRef]);

  useEffect(() => {
    const container = scrollRef.current;
    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCurrenySumbol = (account_title) => {
    let currency = account_title.split("(")[1];
    currency = currency.split(")")[0];
    // return GetCurrenySymbol(currency, "13", "13");
    return currency;
  };

  return (
    <div
      ref={scrollRef}
      className="shadow-sm md:h-[670px] h-[470px] min-w-full overflow-y-auto rounded-3xl bg-primarycard-gradient hide-scrollbar"
    >
      {isLoading ? (
        <AllTransactionsTableSkeleton />
      ) : (
        <>
          <table className="w-full">
            <TopupHeaders />

            {topups.length > 0 && (
              <tbody className="bg-primarycard-gradient">
                {topups.map((topups, index) => (
                  <>
                    <tr
                      key={topups.id}
                      onClick={() => handleSelectTransaction(topups)}
                      className={`cursor-pointer hover:bg-hover-state-bg ${
                        activeTransaction && activeTransaction.id === topups.id
                          ? "bg-active-state-bg"
                          : ""
                      }`}
                    >
                      <td className="hidden md:table-cell whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-body-text sm:pl-6 rounded-l-3xl">
                        <span>{FormatDate(topups.date, "d m, yyyy")}</span>
                      </td>

                      <td className="max-w-[200px] pl-2 py-4 md:pl-6 text-sm font-medium text-body-text md:table-cell md:rounded-none rounded-l-3xl">
                        <div className="flex items-center justify-start w-full">
                          {GetTransactionsTypeIcon("card", topups.type)}

                          <div className="flex flex-col px-3">
                            <p className="line-clamp-1 md:max-w-28 max-w-24 text-sm font-medium w-full">
                              {topups.remarks}
                            </p>
                            <span className="text-paragraph-text text-xs block md:hidden">
                              {topups.account_title.split(" ")[2]}
                            </span>
                            <span className="text-paragraph-text text-xs md:block hidden line-clamp-1 truncate max-w-28 w-full">
                              {topups.account_title}
                            </span>
                          </div>
                        </div>
                      </td>

                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-body-text sm:pl-6 lg:pl-4">
                        <div className="flex items-center">
                          <StatusIcon status={topups.status} />
                          <span className="px-1">{topups.status}</span>
                        </div>
                      </td>
                      <td
                        className={`
                         ${
                           topups.type === "Credit"
                             ? "text-highlighter-green"
                             : "text-highlighter-red"
                         }
                        whitespace-nowrap items-center space-x-1 py-4 pl-4 pr-3 text-sm font-semibold sm:pl-6 lg:pl-8 rounded-r-3xl`}
                      >
                        <div className="flex items-center space-x-1">
                          <span>{topups.type === "Credit" ? "+ " : "- "}</span>
                          <span>{getCurrenySumbol(topups.account_title)}</span>
                          <span>{FormatBalance(topups.amount)}</span>
                        </div>
                      </td>
                    </tr>
                    {index !== topups.length - 1 && (
                      <tr className="h-2"></tr> // Spacer row
                    )}
                  </>
                ))}
              </tbody>
            )}
          </table>
          {topups.length === 0 && (
            <div className="flex items-center justify-center h-3/4">
              <EmptyRecentTransactions />
            </div>
          )}
        </>
      )}

      {loadMore && <LoadMoreTransactions />}
    </div>
  );
};

export default CardTopupTable;
