import { OnboardingActionTypes } from "../../../action-types";

export const updateUserStatus = (status) => async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("user", JSON.stringify({ ...user, status }));
  dispatch({
    type: OnboardingActionTypes.UPDATE_CLIENT_STATUS,
    payload: { status },
  });
};
