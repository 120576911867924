import React from "react";
import Skeleton from "../../Common/Skeleton";

const OtherCardsSkeletons = () => {
  return (
    <div className="space-y-10 pt-3">
      <Skeleton className="rounded-full h-6 w-[95px]" />
      <div className="flex flex-col space-y-[61px] pb-12">
        {[1, 2, 3].map((item) => (
          <div key={item} className="flex w-full items-center justify-between">
            <div className="flex items-center space-x-3">
              <Skeleton className="rounded-xl h-12 w-20 mx-2" />
              <span className="flex flex-col space-y-1">
                <Skeleton className="rounded-full w-[146px] h-[16px]" />
                <Skeleton className="rounded-full w-[135px] h-[16px]" />
              </span>
            </div>
            <Skeleton className="rounded-full w-[20px] h-[16px]" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OtherCardsSkeletons;
