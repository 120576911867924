import { ElgibleCurrenciesActions } from "../../action-types";

const eligibleCurrencies = JSON.parse(
  localStorage.getItem("eligibleCurrencies")
);

const initialState = {
  eligibleCurrencies: eligibleCurrencies ? eligibleCurrencies : null,
};

export default function eligibleCurrenciesReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ElgibleCurrenciesActions.GET_ELIGIBLE_CURRENCIES:
      return {
        eligibleCurrencies: action.payload,
      };
    default:
      return state;
  }
}
