import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getClientFiles,
  uploadClientFiles,
} from "../../../redux/actions/register/ClientFiles";
import { Label } from "../../../components";
import { PrimaryButton, SmallButton } from "../../../components/Buttons";
import LoadingModal from "../../../components/Modals/LoadingModal";
import Steps from "../../../components/Common/Steps";
import ProgressBar from "../../../components/ProgessBar";
import { logout } from "../../../redux/actions/auth";
import { updateUserStatus } from "../../../redux/actions/register/updateUserStatus";
import RequiredDocsSkeleton from "../../../components/Skeleton/RequiredDocsSkeleton";
import { getRejectedDraftClient } from "../../../redux/actions/register/draftClient";
import { setPageTitle } from "../../../helper/metaDataHelper";
import ErrorMessage from "../../../components/Common/ErrorMessage";
import RequiredDocFileUpload from "../../../components/RequiredDocFileUpload";

const RequiredDocumentsPage = ({ user }) => {
  const dispatch = useDispatch();
  const [fileConfigs, setFileConfigs] = useState([]);
  const [files, setFiles] = useState({});
  const [step] = useState(6);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState("");
  const [apiError, setApiError] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [fileUrls, setFileUrls] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const labels = [
    "Personal info",
    user.account_type === "individual" ? "Employer info" : "Company",
    "Identity verification",
    "Currency",
    "Signature",
    "Required documents",
  ];

  useEffect(() => {
    setPageTitle("Required Documents");
  }, []);

  useEffect(() => {
    const fetchClientFiles = async () => {
      const response = await dispatch(getClientFiles(user.account_type));
      setFileConfigs(response.data);
    };

    fetchClientFiles();
  }, [dispatch, user.account_type]);

  useEffect(() => {
    const fetchRejectedDraftClientFiles = async () => {
      const res = await dispatch(getRejectedDraftClient());
      const urls = res.data.clientFiles.reduce((acc, file) => {
        acc[`file-${file.file_id}`] = file.url;
        return acc;
      }, {});
      setFileUrls(urls);
    };

    fetchRejectedDraftClientFiles();
  }, [dispatch]);

  const handleFileChange = (name, selectedFiles) => {
    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles, [name]: selectedFiles };
      // Remove empty file arrays
      if (!selectedFiles.length) {
        delete updatedFiles[name];
      }
      return updatedFiles;
    });
    if (error || apiError) {
      setError([]);
      setApiError("");
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      return;
    }

    const formData = new FormData();

    isloadingModal(true);

    Object.keys(files).forEach((key) => {
      files[key].forEach((file) => {
        formData.append(key, file);
      });
    });

    const res = await dispatch(uploadClientFiles(formData));
    if (res.success) {
      setisLoading(false);
      setSuccess(true);
    } else {
      setisLoading(false);
      setSuccess(false);
      setApiError(
        res.error ? res.error : "Something went wrong please try again."
      );
    }
  };

  const redirectionAfterClose = async () => {
    await dispatch(updateUserStatus("Pending"));
  };

  const isloadingModal = (status) => {
    setisLoading(status);
    setIsModalOpen(status);
  };

  const handleLogout = async () => {
    isloadingModal(true);
    await dispatch(logout());
    isloadingModal(false);
  };

  const getMissingMandatoryFiles = () => {
    const mandatoryFileIds = fileConfigs
      .filter((config) => config.is_mandatory)
      .map((config) => `file-${config.id}${config.is_multiple ? "[]" : ""}`);

    const uploadedFileIds = Object.keys(files);

    const missingFiles = mandatoryFileIds.filter(
      (id) => !uploadedFileIds.includes(id)
    );
    return missingFiles;
  };

  const isFormValid = () => {
    const missingFiles = getMissingMandatoryFiles();
    if (missingFiles.length === 0) {
      return true;
    }
    setError(missingFiles);
    setApiError("Please upload all mandatory files.");
    return missingFiles.length === 0;
  };

  return (
    <>
      <div className="md:max-w-[992px] mx-auto md:min-h-[86vh]">
        <div className="inline-flex space-x-2 px-2 pb-8">
          <SmallButton onClick={handleLogout} text={"Logout"} cancel={true} />
        </div>
        <div className="flex flex-col md:items-center md:justify-center pb-3">
          <Steps step={step} labels={labels} />
          <ProgressBar step={step} labels={labels} />
        </div>
        <div className="flex flex-col md:items-center px-3 justify-center md:p-6 pb-6">
          <div className="space-y-4 w-full md:w-[750px]">
            <Label
              className="block text-sm font-bold text-body-text mb-3"
              htmlFor="required-documents"
              text="Required documents"
            />
            {apiError && (
              <ErrorMessage
                message={apiError}
                onClose={() => setApiError("")}
              />
            )}
            {fileConfigs.length ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {fileConfigs.map((config) => (
                    <div key={config.id} className="min-h-">
                      <RequiredDocFileUpload
                        name={`file-${config.id}${
                          config.is_multiple ? "[]" : ""
                        }`}
                        id={`file-${config.id}`}
                        onChange={handleFileChange}
                        title={config.title}
                        isMultiple={!!config.is_multiple}
                        maxCount={config.max_count ? config.max_count : 1}
                        mandatory={config.is_mandatory}
                        error={error}
                        url={fileUrls[`file-${config.id}`]}
                        files={uploadedFiles}
                        setFiles={setUploadedFiles}
                      />
                    </div>
                  ))}
                </div>
                <hr className="border border-divider-border" />
                <div className="flex md:w-96 w-full space-x-3">
                  <PrimaryButton onClick={handleSubmit} text="Submit" />
                </div>
              </>
            ) : (
              <RequiredDocsSkeleton />
            )}
          </div>
        </div>
      </div>
      <LoadingModal
        isLoading={isLoading}
        setisLoading={setisLoading}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isSuccess={isSuccess}
        setSuccess={setSuccess}
        redirectionAfterClose={redirectionAfterClose}
        title={"Registration complete"}
        description={
          "Please note that your account registration is currently pending approval. Our team will review your application, and once approved, you will have full access to our services."
        }
      />
    </>
  );
};

export default RequiredDocumentsPage;
