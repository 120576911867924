import React, { useState } from "react";
import FormatDate from "../../../helper/FormatDate";
import ToolTip from "../../Header/ToolTip";
import { ApprovedSign, RejectedSign } from "../../Icons";
import GetTransactionStatus from "../../RecentTranactions/partials/ViewRecentTransaction/partials/GetTransactionStatus";

const ServiceRequestsTable = ({
  ServiceRequests,
  activeRequest,
  setIsModalOpen,
  setShowServiceRequest,
  formateRequestType,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="shadow-sm min-w-full overflow-y-auto rounded-3xl bg-primarycard-gradient h-[70vh] hide-scrollbar">
      <table className="w-full">
        <thead className="">
          <tr className="">
            {[
              "Date",
              "Currency",
              "Account",
              "On behalf of",
              "Request Type",
              "Status",
              "OTP Status",
            ].map((heading, index) => (
              <th
                key={index}
                scope="col"
                className={`bg-popup-bg sticky top-0 border-b border-divider-border z-[1] py-3.5 pl-4 pr-3 text-left text-xs font-medium text-paragraph-text sm:pl-6 lg:pl-8
                  ${
                    [
                      "Currency",
                      "Account",
                      "OTP Status",
                      "On behalf of",
                    ].includes(heading) && "hidden md:table-cell"
                  }
                  `}
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>

        <tbody className="bg-primarycard-gradient">
          {ServiceRequests.map((request) => (
            <tr
              key={request.id}
              onClick={() => {
                setShowServiceRequest(request);
                setIsModalOpen(true);
              }}
              className={`cursor-pointer rounded-full hover:rounded-full ${
                activeRequest && activeRequest.id === request.id
                  ? "bg-active-state-bg"
                  : "hover:bg-hover-state-bg "
              }`}
            >
              <td className="whitespace-nowrap hover:table-cell rounded-s-3xl  py-4 pl-4 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                <span className="flex">
                  {FormatDate(request.created_at, "dd m, yyyy")}
                </span>
                <span className="text-paragraph-text text-xs">
                  {FormatDate(request.created_at, "", "h:M a")}
                </span>
              </td>

              <td className="whitespace-nowrap hidden md:table-cell py-4 pl-4 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                {request.currency ? (
                  <div className="flex space-x-2">
                    <img
                      src={request.currency.logo}
                      alt="currency logo"
                      className="w-8 h-8 rounded-full"
                    />
                    <span>
                      <div className="flex flex-col">
                        <span className="font-semibold text-sm">
                          {request.currency.code}
                        </span>
                        <p className="font-medium text-xs text-paragraph-text line-clamp-1 w-[90px]">
                          {request.currency.name}
                        </p>
                      </div>
                    </span>
                  </div>
                ) : (
                  "--"
                )}
              </td>

              <td className="whitespace-nowrap hidden md:table-cell py-4 pl-4 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                {request.account ? request.account.title : "--"}
              </td>

              <td className="whitespace-nowrap hidden md:table-cell py-4 pl-4 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                {request.client && request.client.name}
              </td>

              <td className="whitespace-nowrap py-4 pl-4 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                {formateRequestType(request.type)}
              </td>

              <td className="relative whitespace-nowrap md:rounded-none rounded-r-3xl hover:table-cell py-4 pl-4 pr-3 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                {request.status === "Rejected" ? (
                  <>
                    <div
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                      className="flex items-center"
                    >
                      <RejectedSign className={"w-3 h-3"} />
                      <span className="px-1 text-highlighter-red">
                        {request.status}
                      </span>
                    </div>
                    <ToolTip
                      text={request.reject_reason}
                      className={"w-36 text-pretty z-50"}
                      showTooltip={showTooltip}
                    />
                  </>
                ) : (
                  <div className="flex items-center">
                    <GetTransactionStatus status={request.status} />
                  </div>
                )}
              </td>

              <td className="relative whitespace-nowrap hidden md:table-cell md:rounded-r-3xl hover:table-cell rounded-e-3xl  py-4 pl-4 text-sm font-medium text-body-text sm:pl-6 lg:pl-8">
                <div className="flex items-center pr-3">
                  {request.is_otp_verified ? (
                    <>
                      <ApprovedSign />
                      <span className="px-1">Verified</span>
                    </>
                  ) : (
                    <>
                      <RejectedSign className="w-3 h-3" />
                      <span className="px-1">Unverified</span>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceRequestsTable;
