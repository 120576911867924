import { CardActionTypes } from "../../../action-types";

export const setActiveCard = (activeAccount) => {
  localStorage.setItem("activeAccount", JSON.stringify(activeAccount));
  return {
    type: CardActionTypes.SET_ACTIVE_CARD,
    payload: activeAccount,
  };
};

export const setClientCards = (clientCards) => {
  localStorage.setItem("clientCards", JSON.stringify(clientCards));
  return {
    type: CardActionTypes.FETCH_CLIENT_CARDS,
    payload: clientCards,
  };
}
