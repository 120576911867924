import React from "react";
import EmptyTransactionIcon from "../../Icons/EmptyTransactionIcon";

const EmptyRecentTransactions = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-6">
      <EmptyTransactionIcon className="w-12 h-12" />
      <span className="text-center text-sm text-paragraph-text font-medium">
        After your first transaction, you will able to view it here
      </span>
    </div>
  );
};

export default EmptyRecentTransactions;
