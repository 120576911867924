import React from "react";
import { DropdownCheckIcon } from "../../../../Icons";

const ListItem = ({ title, subTitle, selectedItem, itemId, logo }) => {
  return (
    <div
      className={`flex items-center justify-start relative border mt-4 w-full px-4 py-3 rounded-3xl cursor-pointer ${
        selectedItem.id === itemId
          ? "border-icon-btn bg-option-list-selected"
          : "border-divider-border hover:bg-option-list-hover"
      }`}
    >
      {selectedItem.id === itemId && (
        <div className="absolute w-6 h-6 flex pt-0.5 justify-center right-0 -top-1 bg-[#EFC660] rounded-full shadow border-2 border-white">
          <DropdownCheckIcon />
        </div>
      )}

      {logo ? (
        <img src={logo} alt="logo" className="w-10 h-10 rounded-full mr-3" />
      ) : (
        <img
          className="w-10 h-10 mr-3 rounded-full"
          src={require("../../../../../assets/images/avatar.png")}
          alt="profile_picture"
        />
      )}
      <div className="flex-col justify-start items-start gap-1 inline-flex">
        <h2 className="text-body-text text-sm font-medium leading-tight tracking-tight">
          {title}
        </h2>
        <h3 className="text-paragraph-text text-sm font-normal leading-tight tracking-tight">
          {subTitle}
        </h3>
      </div>
    </div>
  );
};

export default ListItem;
