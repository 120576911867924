import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import {
  ClientInfoActionTypes,
  CommonActionTypes,
} from "../../../action-types";

export const getCryptoAccounts = () => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    const { data } = await commonService.get("/client/crypto/accounts");

    // localStorage.setItem("crypto_accounts", JSON.stringify(data.data));

    const cryptoAccounts = data.data.map((account) => {
      account["is_crypto"] = true;
      return account;
    });  

    dispatch({
      type: ClientInfoActionTypes.ADD_CLIENT_CRYOTO_ACCOUNTS,
      payload: cryptoAccounts,
    });
    return cryptoAccounts;
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      "An unknown error occurred.";

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });

    return err.response?.data || { success: false, message };
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
