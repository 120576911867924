import { CommonActionTypes } from "../../action-types";

const initialState = {
  isError: false,
  isLoading: false,
  message: "",
};

export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case CommonActionTypes.PENDING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case CommonActionTypes.REJECTED:
      return {
        ...state,
        message: action.payload.message,
        isError: action.payload.isError,
      };
    case CommonActionTypes.RESET:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    default:
      return state;
  }
};
