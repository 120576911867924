import React from "react";
import Skeleton from "../../Common/Skeleton";

const RequiredDocsSkeleton = () => {
  return (
    <div>
      <Skeleton className="rounded-md h-4 w-44 mb-5 flex items-center justify-between" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[1, 2, 3, 4, 5, 6].map((item) => (
          <Skeleton key={item} className="rounded-xl h-[70px] px-3 py-3 space-y-2">
            <Skeleton className="rounded-md w-full h-7 flex items-center justify-between" />
            <Skeleton className="rounded-md h-3 mx-6 flex items-center justify-between" />
          </Skeleton>
        ))}
      </div>
      <Skeleton className="rounded-md h-1 my-4 flex items-center justify-between" />
      <Skeleton className="rounded-full h-12 w-[23rem] flex items-center justify-between" />
    </div>
  );
};

export default RequiredDocsSkeleton;
