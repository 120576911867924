const BackBtnIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25195 12.5C5.25195 12.8315 5.38365 13.1495 5.61807 13.3839L10.6181 18.3839C11.1062 18.872 11.8977 18.872 12.3858 18.3839C12.874 17.8957 12.874 17.1043 12.3858 16.6161L8.26972 12.5L12.3858 8.38388C12.874 7.89573 12.874 7.10427 12.3858 6.61612C11.8977 6.12796 11.1062 6.12796 10.6181 6.61612L5.61807 11.6161C5.38365 11.8505 5.25195 12.1685 5.25195 12.5Z"
          className="fill-primary-item"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 13.6998H8V11.2998H18C18.6627 11.2998 19.2 11.8371 19.2 12.4998C19.2 13.1625 18.6627 13.6998 18 13.6998Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default BackBtnIcon;
