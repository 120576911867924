import React from "react";
import { Logo } from "../Icons";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import SwitchUserButton from "../Buttons/SwitchUserButton";

const HeaderLogo = () => {
  const { user } = useSelector((state) => state.user);
  return (
 <div className="relative flex justify-center items-center h-20">
      <Link to="/" className="flex justify-center items-center">
        <Logo className="text-gray-500" />
      </Link>
      {user && user.isSwitch && (
        <div className="absolute right-10 top-1/2 transform -translate-y-1/2">
          <SwitchUserButton user={user} />
        </div>
      )}
    </div>
  );
};

export default HeaderLogo;
