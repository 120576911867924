import { useState, useEffect } from "react";
import {
  DarkModeIcon,
  HelpIcon,
  LightModeIcon,
  LogoutIcon,
  ManagebeneficiariesIcon,
  ReportIcon,
  SettingIcon,
  StatementIcon,
  UpArrowIcon,
} from "../../Icons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/actions/auth";
import Sidebar from "../../Common/SideBar";
import { SetThemeClass } from "../../../helper";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProfileSidebar = ({
  profilePicture,
  email,
  userName,
  setHelpModalOpen,
  setBalanceCertificate,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [mode, setMode] = useState(localStorage.getItem("theme") || "light");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 375) {
        setSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMode = () => {
    const newMode = mode === "light" ? "dark" : "light";
    localStorage.setItem("theme", newMode);
    SetThemeClass(newMode);
    setMode(newMode);
    setSidebarOpen(false);
  };

  const handelLogout = async () => {
    await dispatch(logout());
  };
  return (
    <>
      <div
        onClick={() => setSidebarOpen(!sidebarOpen)}
        className={classNames(
          sidebarOpen
            ? "bg-active-state-bg"
            : "bg-secondary-icon-bg hover:bg-hover-state-bg",
          "flex items-center rounded-full p-2  "
        )}
      >
        {profilePicture ? (
          <img
            className="w-8 h-8 rounded-full"
            src={profilePicture ? profilePicture : "assets/gifs/loader.gif"}
            alt="profile_picture"
          />
        ) : (
          <img
            className="w-8 h-8 rounded-full"
            src={require("../../../assets/images/avatar.png")}
            alt="profile_picture"
          />
        )}
        <label className="max-[445px]:hidden max-[576px]:pl-2 text-body-text text-sm font-medium leading-tight tracking-tight">
          {userName && userName}
        </label>
        <div className="mx-3">
          <UpArrowIcon
            className={`h-6 w-6 text-PrimaryBtn ${
              sidebarOpen
                ? "rotate--180 transition-transform duration-500"
                : "rotate-180 transition-transform duration-500"
            }`}
          />
        </div>
      </div>

      <Sidebar
        header={true}
        backButton={true}
        backButtonAction={() => setSidebarOpen(false)}
        open={sidebarOpen}
      >
        <div className="flex justify-between h-full">
          <div className="flex flex-col space-y-1">
            <span className="text-body-text font-medium leading-tight tracking-tight">
              {userName && userName}
            </span>
            <label className="text-paragraph-text text-sm font-medium leading-tight tracking-tight">
              {email && email}
            </label>
          </div>
          <div className="flex items-center">
            {profilePicture ? (
              <img
                className="w-12 h-12 rounded-full border-2 border-divider-border"
                src={profilePicture ? profilePicture : "assets/gifs/loader.gif"}
                alt="profile_picture"
              />
            ) : (
              <img
                className="w-12 h-12 rounded-full border-2 border-divider-border"
                src={require("../../../assets/images/avatar.png")}
                alt="profile_picture"
              />
            )}
          </div>
        </div>

        <hr className="h-px my-2 bg-divider-border border-0 " />
        <MenuItem
          title={
            mode === "dark" ? "Switch to Light Mode" : "Switch to Dark Mode"
          }
          className={"max-[575px]:block hidden"}
          icon={mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
          onClick={handleMode}
        />
        <MenuItem
          title="Help & Support"
          icon={<HelpIcon />}
          onClick={() => {
            setHelpModalOpen(true);
            setSidebarOpen(false);
          }}
        />

        {user && !user.is_basic_client && (
          <>
            <MenuItem
              title="Manage Beneficiaries"
              icon={<ManagebeneficiariesIcon />}
              onClick={() => setSidebarOpen(false)}
              href={"/manage_beneficiery"}
            />
            <MenuItem
              title="Balance Certificate"
              icon={<ReportIcon />}
              onClick={() => {
                setSidebarOpen(false);
                setBalanceCertificate(true);
              }}
            />
            <MenuItem
              title="Services Requests List"
              icon={<StatementIcon />}
              onClick={() => setSidebarOpen(false)}
              href={"/services-requests"}
            />
          </>
        )}
        <MenuItem
          title="Settings"
          icon={<SettingIcon />}
          href={"/settings"}
          onClick={() => setSidebarOpen(false)}
        />
        <MenuItem title="Logout" icon={<LogoutIcon />} onClick={handelLogout} />
      </Sidebar>
    </>
  );
};

const MenuItem = ({ title, icon, onClick, className, href }) => {
  return (
    <Link to={href} onClick={onClick} className={className}>
      <div>
        <div className="self-stretch p-2 hover:bg-body-bg w-full rounded-full justify-start items-center gap-x-2.5 inline-flex">
          <div className="relative rounded-full">
            <div className="bg-primary-Icon-bg w-12 h-12 rounded-full flex items-center justify-center">
              {icon}
            </div>
          </div>
          <label className="w-[244px] text-primary-item text-base font-medium leading-tight tracking-tight">
            {title}
          </label>
        </div>
      </div>
    </Link>
  );
};

export default ProfileSidebar;
