import React from "react";
import { DropdownCrossIcon } from "../../Icons";

const ErrorMessage = ({ message, onClose }) => {
  if (!message) return null;

  return (
    <div className="rounded-md bg-highlighter-red/10 px-4 py-3 mb-4">
      <div className="flex">
        {message && // Ensure message is not null or undefined
          (Array.isArray(message) && message.length > 1 ? (
            <ul className="ml-3">
              {message.map((msg, index) => (
                <li
                  key={index}
                  className="list-disc text-sm font-medium text-highlighter-red"
                >
                  {msg}
                </li>
              ))}
            </ul>
          ) : (
            <div className="ml-2">
              <p className="text-sm font-medium text-highlighter-red">
                {message}
              </p>
            </div>
          ))}

        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className="inline-flex rounded-md p-1.5 text-highlighter-red"
              onClick={onClose}
            >
              <DropdownCrossIcon className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
