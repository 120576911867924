import React from "react";

const ViewSupportingDocument = ({ supportingDocument }) => {
  const openSupportingDocument = (item) => {
    const url = URL.createObjectURL(item);
    window.open(url, "_blank");
  };

  return (
    <>
      {supportingDocument ? (
        <div className="grid md:grid-cols-5 grid-cols-3 pt-3 gap-3">
          {supportingDocument.supporting_documents.map((doc, index) => (
            <div
              key={index}
              className="flex justify-between items-center hover:bg-option-list-hover rounded-2xl p-3"
            >
              <span
                className="text-primary-item text-sm font-medium underline cursor-pointer px-2"
                onClick={() => openSupportingDocument(doc)}
              >
                View {index + 1}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <span className="text-primary-item text-sm font-medium md:pt-1">
          No Supporting Document
        </span>
      )}
    </>
  );
};

export default ViewSupportingDocument;
