import { CommonActionTypes } from "../../../action-types";
import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import FormDataService from "../../../../services/FormDataService";

export const CryptoWithdraw = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.append("from_web", true);
    const res = await FormDataService.post(
      "/client/crypto-transaction-requests/withdraw",
      data
    );
    const withdraw = res.data;

    return withdraw;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};

export const UpdateCryptoWithdraw = (data) => async (dispatch) => {
  dispatch({ type: CommonActionTypes.PENDING, payload: true });

  try {
    data.append("from_web", true);
    const res = await FormDataService.post(
      "/client/crypto-transaction-requests/update-withdraw",
      data
    );

    return res.data;  
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
    return err.response.data;
  } finally {
    dispatch({ type: CommonActionTypes.RESET, payload: true });
  }
};
