import ResponseErrorHandling from "../../../helper/ResponseErrorHandling";
import commonService from "../../../services/Common/service";
import { CommonActionTypes } from "../../action-types";

export const fetchAccountStatement = (data) => async (dispatch) => {
    try {
      const res = await commonService.post("/client/account-statement/pdf", data, { responseType: "blob" });

      return res;
    } catch (err) {
      const message = err.response.data.message || err.message;
      ResponseErrorHandling(err.response, dispatch);

      dispatch({
        type: CommonActionTypes.REJECTED,
        payload: { message: message, isError: true },
      });
      
      return err.response.data;
    }
  };
