import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const useModules = (module = "") => {
  const allModules = useSelector((state) => state.modules.modules);
  
  const storedModule = useMemo(() => 
    allModules.find(({ module: modName }) => modName === module), 
    [module, allModules]
  );

  const [isModuleEnabled, setIsModuleEnabled] = useState(storedModule?.status || false);

  useEffect(() => {
    if (storedModule && storedModule.status !== isModuleEnabled) {
      setIsModuleEnabled(storedModule.status);
    }
  }, [storedModule, isModuleEnabled]);

  return isModuleEnabled;
};

export default useModules;
