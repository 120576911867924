import React from "react";

const CNYSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 21}
      height={height ? height : 23}
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7656 0.59375V0.921875L14.75 10.5156H18.2656V13.5312H12.8906L12.7031 13.8125V16.0938H18.2656V19.0938H12.7031V23H8.92188V19.0938H3.17188V16.0938H8.92188V13.8438L8.73438 13.5312H3.17188V10.5156H6.8125L0.671875 0.921875V0.59375H5.15625L10.7812 10.1406H10.9062L16.4844 0.59375H20.7656Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default CNYSymbol;
