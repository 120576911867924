import React from "react";

const ETHSymbol = ({ height, width, fillColor }) => {
  return (
    <svg
      width={width ? width : 18}
      height={height ? height : 23}
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.92 23V20.408H17.048V23H0.92ZM2.84 12.344V9.752H15.128V12.344H2.84ZM1.56 2.744V0.151999H16.408V2.744H1.56Z"
        className={fillColor ? fillColor : "fill-body-text"}
      />
    </svg>
  );
};

export default ETHSymbol;
