import React from "react";
import Skeleton from "../../Common/Skeleton";

const RecentTransactionsSkeleton = () => {
  return (
    <>
      <div className="flex justify-between items-center max-[360px]:px-1.5">
        <Skeleton className="rounded-3xl w-[132px] h-5" />
        <Skeleton className="rounded-3xl w-[175px] h-7" />
      </div>
      {[1, 2, 3].map((item) => (
        <div key={item} className="flex flex-col space-y-3 px-2">
          <Skeleton className="rounded-3xl h-[76px] flex items-center justify-between">
            <div className="flex space-x-3">
              <Skeleton className="rounded-full h-10 w-10 mx-4" />
              <span className="flex flex-col space-y-1">
                <Skeleton className="rounded-full w-[146px] h-[16px]" />
                <Skeleton className="rounded-full w-[135px] h-[16px]" />
              </span>
            </div>
            <Skeleton className="rounded-full w-[100px] h-[16px] mr-5" />
          </Skeleton>
        </div>
      ))}
    </>
  );
};

export default RecentTransactionsSkeleton;
