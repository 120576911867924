import React from "react";
import Skeleton from "../../Common/Skeleton";

const CardStatementTableSkeleton = () => {
  return (
    <div className="flex flex-col md:max-w-full max-w-[372px] space-y-">
      <Skeleton className="flex flex-col space-y-3">
        <div className="flex justify-between">
          {[1, 2, 3].map((item) => (
            <Skeleton key={item} className="rounded-full h-3 w-24 mx-10 mt-5" />
          ))}
        </div>

        <Skeleton className="rounded-full h-1 w-full" />
      </Skeleton>

      <div className="flex flex-col w-full rounded-3xl-1 space-y-2">
        {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
          <Skeleton
            key={item}
            className="flex items-center justify-between px-5 py-4 rounded-3xl"
          >
            <Skeleton className="rounded-full h-5 w-24 hidden md:block" />
            <span className="flex items-center space-x-2">
              <Skeleton className="rounded-full h-10 w-10" />
              <Skeleton className="rounded-full h-5 w-20" />
            </span>
            <Skeleton className="rounded-full h-5 w-20" />
          </Skeleton>
        ))}
      </div>
    </div>
  );
};

export default CardStatementTableSkeleton;
