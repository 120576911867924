import React, { useState, useEffect } from "react";
import Modal from "../../Common/Modal";
import { useDispatch } from "react-redux";
import EnterOTP from "../../Common/EnterOTP";
import TransactionCompleted from "../../Common/TransactionCompleted";
// import HangTightLoading from "../../Common/HangTightLoading";
import LoadingSpinner from "../../Common/LoadingSpinner";

const TransactionOTPModal = ({
  TrnId,
  setTrnId,
  isLoading,
  setisLoading,
  isModalOpen,
  setIsModalOpen,
  handleTransaction,
  reduxaction,
  navigateUrl,
  handleViewTransaction,
  refreshTable = false,
}) => {
  const [Success, setSuccess] = useState(false);
  const [Error, setError] = useState("");
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [counter, setCounter] = useState(60);

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
    setOtp(["", "", "", "", "", ""]);
    setSuccess(false);
    setCounter(60);
  };

  const handleOTP = async () => {
    const data = {
      trn_id: TrnId,
      otp: otp.join(""),
    };
    setisLoading(true);
    const res = await dispatch(reduxaction(data));
    if (res.success) {
      refreshTable && refreshTable(); // Refresh the topup table, after successful transaction update.
      setTrnId("");
      setSuccess(true);
    } else {
      setError(res.error);
    }
    setisLoading(false);
  };

  const handleResendCode = () => {
    setCounter(60);
    setResendDisabled(true);
    handleTransaction();
  };

  useEffect(() => {
    if (isModalOpen) {
      setCounter(60);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isLoading && resendDisabled) {
      const timer = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter === 1) {
            setResendDisabled(false);
            clearInterval(timer);
            return 0;
          }
          return prevCounter - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isLoading, resendDisabled]);

  return (
    <>
      {!isLoading ? (
        <Modal
          isOpen={isModalOpen}
          handleOnCloseModal={handleOnCloseModal}
          header={isLoading || Success ? false : true}
          backButtonAction={() => {}}
          headerTitle={"Enter OTP"}
          className="w-[432px] overflow-scroll hide-scrollbar bg-popup-bg shadow-xl rounded-3xl py-3"
          loading={isLoading || Success}
        >
          <div className="md:px-6 px-6 max-[360px]:px-4 pt-2">
            {!isLoading && !Success ? (
              <EnterOTP
                handleOTP={handleOTP}
                otp={otp}
                setOtp={setOtp}
                error={Error}
                setError={setError}
                resendDisabled={resendDisabled}
                counter={counter}
                handleResendCode={handleResendCode}
              />
            ) : isLoading ? (
              <LoadingSpinner />
            ) : Success && !isLoading ? (
              <TransactionCompleted
                title={"Transactions Completed"}
                description={
                  "Your transaction request is now completed. Click on view transaction to see details."
                }
                navigateUrl={navigateUrl}
                handleViewTransaction={handleViewTransaction}
                onClose={handleOnCloseModal}
              />
            ) : (
              ""
            )}
          </div>
        </Modal>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default TransactionOTPModal;
