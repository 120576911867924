import React, { useEffect, useState } from "react";
import { SetThemeClass } from "./helper";
import AppRoutes from ".//containers/Routes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { setFavicon } from "./helper/metaDataHelper";
import { useCompanyData } from "./hooks";
import ActiveSessionExpireModal from "./components/Modals/ActiveSessionExpierModal";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const companyData = useCompanyData();

  useEffect(() => {
    // const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
    //   .matches
    //   ? "dark"
    //   : "light";
    const storedTheme = localStorage.getItem("theme");

    if (storedTheme) {
      SetThemeClass(storedTheme);
      setTheme(storedTheme);
    } else {
      localStorage.setItem("theme", "light");
      SetThemeClass("light");
      setTheme("light");
    }
  }, []);

  useEffect(() => {
    if (companyData) {
      setFavicon();
    }
  }, [companyData]);

  return (
    <div
      id="theme_id"
      className={`${theme} bg-body-bg bg-no-repeat bg-right-top min-h-screen`}
    >
      <AppRoutes />
      <ActiveSessionExpireModal />
    </div>
  );
}

export default App;
