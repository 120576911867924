import { CardActionTypes } from "../../action-types";

const activeCard = JSON.parse(localStorage.getItem("activeCard"));
const initialState = {
  activeCard: activeCard ? activeCard : null,
};

export default function activeCardReducer(state = initialState, action) {
  switch (action.type) {
    case CardActionTypes.SET_ACTIVE_CARD:
      return {
        activeCard: action.payload,
      };
    default:
      return state;
  }
}
