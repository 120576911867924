import React from "react";

const ViewFileButton = ({ text, icon, ...props }) => {
  return (
    <button
      className="flex items-center space-x-2 justify-center bg-secondary-icon-bg py-3 px-4 rounded-full hover:bg-hover-state-bg disabled:bg-secondary-icon-bg/50 disabled:cursor-not-allowed"
      {...props}
    >
      {icon && icon}
      <p className="text-primary-item text-sm font-medium ">{text}</p>
    </button>
  );
};

export default ViewFileButton;
