import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { BackBtnIcon, DropdownCrossIcon } from "../../Icons";
import { GetThemeClass } from "../../../helper";

const Modal = ({
  isOpen,
  handleOnCloseModal,
  className,
  header,
  backButton,
  headerTitle,
  backButtonAction,
  loading,
  children,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-30 ${GetThemeClass("light", "dark")}`}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 transition-opacity ${
              loading
                ? "backdrop-blur-xl bg-black/30 dark:bg-black/20"
                : "opacity-60 bg-neutral-800 "
            } `}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-body-text sm:items-center px-1.5 sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={className + " shadow-xl dark:shadow-body-bg"}
              >
                {header && (
                <Dialog.Title
                  className={"sticky pt-2 top-0 z-10 mx-1 bg-popup-bg"}
                >
                    <div className="flex justify-between items-center pb-2 px-6 max-[360px]:px-4">
                      <div className="flex justify-center items-center space-x-2">
                        {backButton && (
                          <div
                            className="cursor-pointer fill-primary-item"
                            onClick={backButtonAction}
                          >
                            <BackBtnIcon />
                          </div>
                        )}
                        <h2
                          tabIndex={0}
                          className="text-body-text text-xl font-medium"
                        >
                          {headerTitle}
                        </h2>
                      </div>
                      <div
                        className="cursor-pointer fill-primary-item hover:bg-hover-state-bg p-1 rounded-full"
                        onClick={handleOnCloseModal}
                      >
                        <DropdownCrossIcon />
                      </div>
                    </div>
                </Dialog.Title>
                  )}
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
