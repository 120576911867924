import ResponseErrorHandling from "../../../../helper/ResponseErrorHandling";
import commonService from "../../../../services/Common/service";
import { CommonActionTypes } from "../../../action-types";

export const TransactionsHistory = (data) => async (dispatch) => {
  try {
    const res = await commonService.post("/client/account/chart-data-dc", data);
    const transactionsHistory = res.data;

    return transactionsHistory;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};

export const getAllTransactions = (data) => async (dispatch) => {
  try {
    const transactionsHistory = await commonService.post(
      "/client/account/transactions",
      data
    );

    return transactionsHistory.data.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};

export const getTransactionDetails = (data) => async (dispatch) => {
  try {
    const transactionDetails = await commonService.post(
      "/client/transaction-details",
      data
    );

    return transactionDetails.data;
  } catch (err) {
    const message = err.response.data.message || err.message;

    ResponseErrorHandling(err.response, dispatch);
    

    dispatch({
      type: CommonActionTypes.REJECTED,
      payload: { message, isError: true },
    });
  }
};
