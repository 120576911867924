import React from "react";
import { GreenEllipse } from "../../Icons";
import AddNewCardModal from "../../Modals/Card/AddNewCardModal";
import CommonButton from "../../Buttons/CommonButton";

const EmptyCard = () => {
  return (
    <>
      <div className="space-y-6 border border-dropdown-popup-bg bg-primarycard-gradient px-3 py-6 shadow-box-shadow flex flex-col items-center rounded-3xl">
        <div className="flex items-center justify-center">
          <p className="text-body-text font-semibold text-xl md:text-2xl">
            It's time for your first digital card!
          </p>
        </div>
        <div className="bg-card-black-bg md:block relative rounded-2xl w-full md:w-[400px] max-[360px]:w-[335px] md:h-[250px] bg-cover bg-center bg-no-repeat">
          <div className="text-white w-full h-full rounded-2xl md:p-3 p-1">
            <div className="p-2 text-white">
              <div className="flex justify-between">
                <span className="flex items-center justify-center space-x-2">
                  <GreenEllipse />
                  <p className="text-white">Status | · · 1234</p>
                </span>
              </div>
            </div>
            <div className="pt-6">
              <p className="text-white text-xs pt-8 px-2 py-1">Card balance</p>

              <p className="text-white text-2xl font-medium px-2">$ ****</p>

              <div className="text-white text-base font-normal uppercase leading-normal tracking-tight pt-8 px-2 md:mb-0 mb-3 ">
                Member Card
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between space-y-4 md:w-[400px] w-full">
          <AddNewCardModal>
            <CommonButton>Request a new card</CommonButton>
          </AddNewCardModal>
        </div>
      </div>
    </>
  );
};

export default EmptyCard;
