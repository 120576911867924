import React, { useState } from "react";
import ToolTip from "../ToolTip";
import { HelpIcon } from "../../Icons";

const Help = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="relative cursor-pointer max-[576px]:hidden ">
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowTooltip(false)}
        className="w-12 h-12 px-3 py-[7px] bg-secondary-icon-bg hover:bg-hover-state-bg active:bg-active-state-bg rounded-[100px] justify-center items-center gap-2.5 inline-flex"
      >
        <HelpIcon className="h-6 w-6 text-primary-Icon-bg" />
        <ToolTip
          text="Get Help"
          showTooltip={showTooltip}
          className="top-12 min-w-max"
        />
      </div>
    </div>
  );
};

export default Help;
