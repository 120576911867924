import React from "react";
import Skeleton from "../../Common/Skeleton";

const CompanyTypesSkeleton = () => {
  return (
    <div className="space-y-4">
      <Skeleton className="rounded-full w-[150px] h-4" />
      {[1, 2, 3, 4].map((item) => (
        <div key={item} className="flex  items-center space-x-3">
          <Skeleton className="rounded-full w-4 h-4" />
          <Skeleton className={"rounded-full w-[200px] h-3"} />
        </div>
      ))}
    </div>
  );
};

export default CompanyTypesSkeleton;
