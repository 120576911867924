import React from "react";
import { GrayEllipse, YellowEllipse } from "../Icons";

const ProgressBar = ({ step, labels }) => {
  const lineWidth = labels.length === 2 ? "w-52 md:w-72" : "w-28 md:w-36";
  return (
    <div className="md:flex hidden justify-center items-center">
      {Array.from({ length: labels.length }, (_, index) => {
        const currentStep = index + 1;
        return (
          <div
            key={index}
            className="flex flex-col justify-start items-start -space-x-5"
          >
            <div>
              <div className="flex items-center">
                {currentStep === step ? (
                  <YellowEllipse
                    className={"fill-icon-btn stroke-icon-btn"}
                  />
                ) : currentStep < step ? (
                  <YellowEllipse
                    className={"fill-primary-item"}
                  />
                ) : (
                  <GrayEllipse />
                )}
                {currentStep < labels.length && (
                  <div
                    className={`h-1 transition-colors ${
                      index < step - 1 ? "bg-primary-item" : "bg-divider-border"
                    } ${lineWidth}`}
                  ></div>
                )}
              </div>
            </div>
            {labels && labels[index] && (
              <p className="text-sm font-medium leading-[0.07px] my-4 whitespace-nowrap text-primary-item">
                {labels[index]}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ProgressBar;
