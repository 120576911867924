const DownArrowIcon = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 28">
          <path
            id="Vector (Stroke)"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.001 16C12.2662 16 12.5205 15.8946 12.7081 15.7071L17.7081 10.7071C18.0986 10.3166 18.0986 9.68342 17.7081 9.29289C17.3176 8.90237 16.6844 8.90237 16.2939 9.29289L12.001 13.5858L7.70808 9.29289C7.31756 8.90237 6.68439 8.90237 6.29387 9.29289C5.90335 9.68342 5.90335 10.3166 6.29387 10.7071L11.2939 15.7071C11.4814 15.8946 11.7358 16 12.001 16Z"
            className="fill-primary-item"
          />
        </g>
      </svg>
    </div>
  );
};

export default DownArrowIcon;