import React, { useState } from "react";
import { TrashIcon } from "../../Icons";
import ConfirmModal from "../../Modals/ConfirmModal";

const PreviousDocs = ({ documents, handleDeleteDocument }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleDeleteDocumentAction = async () => {
    setLoading(true);
    await handleDeleteDocument(selectedDoc);
    setIsModalOpen(false);
    setLoading(false);
  };

  return (
    <div>
      {documents.length > 0 && (
        <div>
          <h4 className="text-primary-item mt-2 text-sm font-semibold mb-2">
            Existing Supporting Documents:
          </h4>
          <ul className="grid md:grid-cols-3 gap-2">
            {documents.map((doc, index) => (
              <li
                key={index}
                className="col-span-1 flex justify-between items-center hover:bg-option-list-hover rounded-2xl p-2"
              >
                <a
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-primary-item text-sm font-medium underline cursor-pointer px-2"
                >
                  View {index + 1}
                </a>
                <TrashIcon
                  onClick={() => {
                    setSelectedDoc(doc);
                    setIsModalOpen(true);
                  }}
                  className="cursor-pointer hover:bg-option-list-selected rounded-3xl p-1.5"
                  width={"20px"}
                  height={"20px"}
                />
              </li>
            ))}
            <ConfirmModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleAction={handleDeleteDocumentAction}
              loading={loading}
              message={"Are you sure you want to delete this document?"}
            />
          </ul>
        </div>
      )}
    </div>
  );
};

export default PreviousDocs;
