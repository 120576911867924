const SetThemeClass = (theme) => {
  const themeElement = document.getElementById("theme_id");
  const body = document.body;

  if (themeElement) {
    body.classList.add("bg-body-bg");
    if (theme === "light") {
      themeElement.classList.remove("dark", "lg:bg-dark-bg");
      themeElement.classList.add("light", "lg:bg-light-bg");
      body.classList.remove("dark");
      body.classList.add("light");
    } else if (theme === "dark") {
      themeElement.classList.remove("light", "lg:bg-light-bg");
      themeElement.classList.add("dark", "lg:bg-dark-bg");
      body.classList.remove("light");
      body.classList.add("dark");
    }
  } else {
    console.error('Element with id "theme_id" not found.');
  }
};

export default SetThemeClass;
