import React from "react";
import EmptyExchangeIcons from "../../Icons/EmptyExchangeIcons";
import CommonButton from "../../Buttons/CommonButton";
import AddAccountModal from "../../Modals/AddAccount/AddAccountModal";

const EmptyExchange = () => {
  return (
    <div className="md:max-w-[400px] mt-6">
      <div className="flex flex-col items-center justify-center space-y-10">
        <EmptyExchangeIcons />
        <h2 className="font-normal text-center text-paragraph-text">
          You have only one currency account. To exchange, you need two
          accounts. Click on this button to add a new currency account.
        </h2>
        <AddAccountModal>
          <CommonButton>Add Currency Account</CommonButton>
        </AddAccountModal>
      </div>
    </div>
  );
};

export default EmptyExchange;
