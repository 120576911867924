import React from "react";
import { DownArrowIcon } from "../../Icons";
import InitialsAvatar from "../../Common/InitialsAvatar";

const BeneficiaryButton = ({ beneficiaryName, accountNumber, swiftCode }) => {
  return (
    <div className="flex cursor-pointer justify-between bg-popup-bg px-4 py-3 my-3 rounded-3xl border border-divider-border hover:bg-option-list-hover">
      <div className="flex justify-center items-start space-x-3">
        <InitialsAvatar name={beneficiaryName} />
        <div className="flex flex-col space-y-1">
          <div className="text-body-text text-sm font-semibold">
            {beneficiaryName}
          </div>
          <div className="text-paragraph-text text-xs font-normal">
            Account: {accountNumber}
          </div>
          <div className="text-paragraph-text text-xs font-normal">
            Swift code: {swiftCode}
          </div>
        </div>
      </div>
      <DownArrowIcon className="-rotate-90" />
    </div>
  );
};

export default BeneficiaryButton;
