import React, { useState } from "react";
import AccountPopUp from "../../Transactions/Transfer/AccountPopUp";
import AccountDropdown from "../AccountDropdown";

// Todo: reuse this component in the fiat and crypto transactions, once the code merge is done.
const SelectAccountDropdown = ({
  setAccount,
  accounts,
  title,
  slectedAccount,
}) => {
  const [isAccountOpen, setisAccountOpen] = useState(false);

  const handleChangeAccount = async (account) => {
    setAccount(account);
    setisAccountOpen(false);
  };

  return (
    <>
      <AccountPopUp
        clientAccounts={accounts}
        activeAccount={slectedAccount}
        accountPopUpOpen={isAccountOpen}
        setAccountPopUpOpen={setisAccountOpen}
        handleChangeAccount={handleChangeAccount}
        title={title}
      >
        <AccountDropdown activeAccount={slectedAccount} />
      </AccountPopUp>
    </>
  );
};

export default SelectAccountDropdown;
