import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const company = useSelector((state) => state.ownCompany.company);
  const company_name = process.env.REACT_APP_COMPANY_NAME;
  
  return (
    <div className="w-full justify-center items-center inline-flex bg-body-bg">
      <div className="text-center text-paragraph-text text-xs font-medium tracking-tight">
        {currentYear} © {company && company.name ? company.name : company_name}.
      </div>
    </div>
  );
};

export default Footer;