const GrayEllipse = ({ className }) => {
  return (
    <div className={className}>
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="Ellipse 4962"
          cx="6.75"
          cy="6"
          r="6"
          className="fill-paragraph-text"
        />
      </svg>
    </div>
  );
};

export default GrayEllipse;
