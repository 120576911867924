const DropdownCrossIcon = ({ className,onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M13.7697 12L17.8858 7.88388C18.374 7.39573 18.374 6.60427 17.8858 6.11612C17.3977 5.62796 16.6062 5.62796 16.1181 6.11612L12.002 10.2322L7.88584 6.11612C7.39768 5.62796 6.60623 5.62796 6.11807 6.11612C5.62991 6.60427 5.62991 7.39573 6.11807 7.88388L10.2342 12L6.11807 16.1161C5.62991 16.6043 5.62991 17.3957 6.11807 17.8839C6.60623 18.372 7.39768 18.372 7.88584 17.8839L12.002 13.7678L16.1181 17.8839C16.6062 18.372 17.3977 18.372 17.8858 17.8839C18.374 17.3957 18.374 16.6043 17.8858 16.1161L13.7697 12Z"
          className="fill-primary-item"
        />
      </svg>
    </div>
  );
};

export default DropdownCrossIcon;