import React from "react";

const EmptyExchangeIcons = () => {
  return (
    <>
    <div className="hidden dark:block">
      <svg
        width="150"
        height="138"
        viewBox="0 0 150 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1371_56766)">
          <ellipse
            cx="83.5449"
            cy="103.865"
            rx="31.5156"
            ry="11.8183"
            fill="#2D2A27"
          />
        </g>
        <path
          d="M120.943 57.55C121.006 64.9915 119.177 72.3285 115.626 78.8752C112.076 85.422 106.921 90.9646 100.643 94.985C94.3649 99.0054 87.1686 101.372 79.7269 101.864C72.2852 102.356 64.8411 100.958 58.0906 97.7988L42.098 105.887C41.6121 106.129 41.0667 106.227 40.5269 106.168C39.9871 106.109 39.4758 105.897 39.054 105.556C38.6322 105.215 38.3177 104.759 38.1483 104.245C37.9788 103.73 37.9615 103.177 38.0983 102.653L42.5774 85.6031C38.1579 80.1398 35.1139 73.6994 33.6996 66.8203C32.2853 59.9412 32.5419 52.8232 34.4479 46.0612C36.3539 39.2992 39.8541 33.0897 44.6557 27.9517C49.4573 22.8137 55.421 18.8965 62.0483 16.5274C68.6755 14.1583 75.7738 13.4063 82.7498 14.334C89.7258 15.2618 96.3769 17.8425 102.147 21.8604C107.917 25.8783 117.664 35.2952 120.943 41.5078C124.221 47.7205 120.937 50.5484 120.993 57.5714L120.943 57.55Z"
          fill="#615E59"
        />
        <path
          d="M126.567 69.7864C125.586 77.1631 122.747 84.1713 118.314 90.1556C113.881 96.14 108 100.905 101.22 104.006C94.4401 107.106 86.9831 108.441 79.5459 107.885C72.1087 107.329 64.9343 104.9 58.6932 100.826L41.7247 106.592C41.2097 106.764 40.656 106.784 40.1297 106.65C39.6035 106.516 39.127 106.234 38.7572 105.837C38.3873 105.441 38.1398 104.946 38.0442 104.413C37.9486 103.879 38.0089 103.33 38.2179 102.83L45.043 86.576C41.4331 80.5471 39.322 73.7436 38.8861 66.7341C38.4502 59.7247 39.7021 52.7129 42.5373 46.2849C45.3725 39.857 49.7086 34.1995 55.1832 29.7854C60.6577 25.3713 67.1116 22.3288 74.0056 20.9122C80.8995 19.4956 88.0331 19.7461 94.8101 21.6427C101.587 23.5394 107.811 27.027 112.961 31.8142C118.111 36.6013 122.038 42.5489 124.413 49.1599C126.788 55.7708 127.543 62.853 126.614 69.8146L126.567 69.7864Z"
          fill="url(#paint0_linear_1371_56766)"
        />
        <path
          d="M82.5067 86.0145C86.0227 86.0145 88.4782 83.6147 88.4782 80.2661C88.4782 76.8618 86.0227 74.462 82.5067 74.462C79.0466 74.462 76.5352 76.8618 76.5352 80.2661C76.5352 83.6147 79.0466 86.0145 82.5067 86.0145ZM87.976 45.0508H77.0374V55.9893L79.8279 71.1135H85.1297L87.976 55.9893V45.0508Z"
          fill="#615E59"
        />
        <path
          d="M149.277 41.3465C149.307 41.2921 149.325 41.232 149.33 41.1701C149.336 41.1082 149.328 41.0459 149.307 40.9873C149.287 40.9286 149.254 40.875 149.211 40.8299C149.168 40.7848 149.117 40.7493 149.059 40.7256C147.382 40.0571 141.45 38.0726 138.208 42.0734C136.039 44.7029 132.973 46.4362 129.601 46.9392C129.517 46.9505 129.437 46.9873 129.374 47.0446C129.31 47.1019 129.265 47.1771 129.245 47.2603C129.225 47.3435 129.231 47.4308 129.261 47.5108C129.292 47.5908 129.345 47.6597 129.416 47.7086C132.281 49.7408 141.747 54.9196 149.277 41.3465Z"
          fill="url(#paint1_linear_1371_56766)"
        />
        <path
          d="M149.277 41.3465C149.307 41.2921 149.325 41.232 149.33 41.1701C149.336 41.1082 149.328 41.0459 149.307 40.9873C149.287 40.9286 149.254 40.875 149.211 40.8299C149.168 40.7848 149.117 40.7493 149.059 40.7256C147.382 40.0571 141.45 38.0726 138.208 42.0734C136.039 44.7029 132.973 46.4362 129.601 46.9392C129.517 46.9505 129.437 46.9873 129.374 47.0446C129.31 47.1019 129.265 47.1771 129.245 47.2603C129.225 47.3435 129.231 47.4308 129.261 47.5108C129.292 47.5908 129.345 47.6597 129.416 47.7086C132.281 49.7408 141.747 54.9196 149.277 41.3465Z"
          fill="url(#paint2_linear_1371_56766)"
        />
        <path
          d="M23.6193 69.4069C23.6432 69.3632 23.6577 69.3149 23.6617 69.2653C23.6658 69.2156 23.6594 69.1656 23.6428 69.1186C23.6263 69.0715 23.6001 69.0285 23.5659 68.9922C23.5317 68.956 23.4902 68.9274 23.4442 68.9082C22.1017 68.3776 17.3315 66.7857 14.7262 69.9694C12.9877 72.0789 10.531 73.4716 7.82816 73.88C7.76029 73.8891 7.69676 73.9185 7.64591 73.9644C7.59505 74.0102 7.55924 74.0704 7.54317 74.1369C7.52711 74.2035 7.53154 74.2734 7.55588 74.3374C7.58022 74.4014 7.62334 74.4565 7.67958 74.4956C9.96654 76.1511 17.5703 80.3111 23.6193 69.4069Z"
          fill="url(#paint3_linear_1371_56766)"
        />
        <path
          d="M23.6193 69.4069C23.6432 69.3632 23.6577 69.3149 23.6617 69.2653C23.6658 69.2156 23.6594 69.1656 23.6428 69.1186C23.6263 69.0715 23.6001 69.0285 23.5659 68.9922C23.5317 68.956 23.4902 68.9274 23.4442 68.9082C22.1017 68.3776 17.3315 66.7857 14.7262 69.9694C12.9877 72.0789 10.531 73.4716 7.82816 73.88C7.76029 73.8891 7.69676 73.9185 7.64591 73.9644C7.59505 74.0102 7.55924 74.0704 7.54317 74.1369C7.52711 74.2035 7.53154 74.2734 7.55588 74.3374C7.58022 74.4014 7.62334 74.4565 7.67958 74.4956C9.96654 76.1511 17.5703 80.3111 23.6193 69.4069Z"
          fill="url(#paint4_linear_1371_56766)"
        />
        <path
          d="M136.225 63.3864C136.205 63.35 136.192 63.3097 136.189 63.2682C136.186 63.2266 136.191 63.1849 136.205 63.1457C136.219 63.1065 136.242 63.0707 136.271 63.0408C136.3 63.011 136.335 62.9877 136.373 62.9725C137.498 62.5215 141.483 61.1896 143.659 63.8745C145.117 65.6408 147.177 66.8051 149.443 67.1431C149.503 67.1486 149.56 67.173 149.605 67.2127C149.65 67.2524 149.682 67.3054 149.696 67.3642C149.709 67.4231 149.704 67.4847 149.68 67.5402C149.657 67.5958 149.616 67.6425 149.565 67.6737C147.644 69.048 141.282 72.5236 136.225 63.3864Z"
          fill="url(#paint5_linear_1371_56766)"
        />
        <path
          d="M136.225 63.3864C136.205 63.35 136.192 63.3097 136.189 63.2682C136.186 63.2266 136.191 63.1849 136.205 63.1457C136.219 63.1065 136.242 63.0707 136.271 63.0408C136.3 63.011 136.335 62.9877 136.373 62.9725C137.498 62.5215 141.483 61.1896 143.659 63.8745C145.117 65.6408 147.177 66.8051 149.443 67.1431C149.503 67.1486 149.56 67.173 149.605 67.2127C149.65 67.2524 149.682 67.3054 149.696 67.3642C149.709 67.4231 149.704 67.4847 149.68 67.5402C149.657 67.5958 149.616 67.6425 149.565 67.6737C147.644 69.048 141.282 72.5236 136.225 63.3864Z"
          fill="url(#paint6_linear_1371_56766)"
        />
        <path
          d="M0.0592421 46.1626C0.0264923 46.1019 0.00681559 46.035 0.00148021 45.9663C-0.00385517 45.8976 0.00526914 45.8285 0.0282651 45.7635C0.051261 45.6985 0.0876183 45.6391 0.134994 45.589C0.182369 45.5389 0.239712 45.4993 0.303326 45.4728C2.16048 44.7352 8.73482 42.5384 12.3271 46.9638C14.7334 49.8815 18.1368 51.8025 21.8782 52.3548C21.9717 52.3686 22.059 52.4102 22.1287 52.4742C22.1983 52.5381 22.2472 52.6215 22.2689 52.7136C22.2906 52.8056 22.2842 52.9021 22.2505 52.9904C22.2168 53.0788 22.1573 53.155 22.0798 53.2091C18.8908 55.459 8.40053 61.2002 0.0592421 46.1626Z"
          fill="url(#paint7_linear_1371_56766)"
        />
        <path
          d="M0.0592421 46.1626C0.0264923 46.1019 0.00681559 46.035 0.00148021 45.9663C-0.00385517 45.8976 0.00526914 45.8285 0.0282651 45.7635C0.051261 45.6985 0.0876183 45.6391 0.134994 45.589C0.182369 45.5389 0.239712 45.4993 0.303326 45.4728C2.16048 44.7352 8.73482 42.5384 12.3271 46.9638C14.7334 49.8815 18.1368 51.8025 21.8782 52.3548C21.9717 52.3686 22.059 52.4102 22.1287 52.4742C22.1983 52.5381 22.2472 52.6215 22.2689 52.7136C22.2906 52.8056 22.2842 52.9021 22.2505 52.9904C22.2168 53.0788 22.1573 53.155 22.0798 53.2091C18.8908 55.459 8.40053 61.2002 0.0592421 46.1626Z"
          fill="url(#paint8_linear_1371_56766)"
        />
        <path
          d="M0.0592421 46.1626C0.0264923 46.1019 0.00681559 46.035 0.00148021 45.9663C-0.00385517 45.8976 0.00526914 45.8285 0.0282651 45.7635C0.051261 45.6985 0.0876183 45.6391 0.134994 45.589C0.182369 45.5389 0.239712 45.4993 0.303326 45.4728C2.16048 44.7352 8.73482 42.5384 12.3271 46.9638C14.7334 49.8815 18.1368 51.8025 21.8782 52.3548C21.9717 52.3686 22.059 52.4102 22.1287 52.4742C22.1983 52.5381 22.2472 52.6215 22.2689 52.7136C22.2906 52.8056 22.2842 52.9021 22.2505 52.9904C22.2168 53.0788 22.1573 53.155 22.0798 53.2091C18.8908 55.459 8.40053 61.2002 0.0592421 46.1626Z"
          fill="url(#paint9_linear_1371_56766)"
        />
        <path
          d="M22.6857 30.1234C22.3709 28.8887 23.5107 27.7762 24.7374 28.1209L27.2149 28.8171C28.4416 29.1618 28.8351 30.7052 27.9232 31.5952L26.0815 33.3927C25.1697 34.2827 23.6363 33.8518 23.3215 32.617L22.6857 30.1234Z"
          fill="#4C4B48"
        />
        <path
          d="M131.859 8.81116C132.349 6.78423 135.232 6.78423 135.721 8.81116L135.744 8.90525C135.919 9.62959 136.484 10.1951 137.209 10.37L137.303 10.3927C139.33 10.882 139.33 13.7651 137.303 14.2544L137.209 14.2771C136.484 14.4519 135.919 15.0175 135.744 15.7418L135.721 15.8359C135.232 17.8628 132.349 17.8628 131.859 15.8359L131.837 15.7418C131.662 15.0175 131.096 14.4519 130.372 14.2771L130.278 14.2544C128.251 13.7651 128.251 10.882 130.278 10.3927L130.372 10.37C131.096 10.1951 131.662 9.62959 131.837 8.90524L131.859 8.81116Z"
          fill="#4C4B48"
        />
        <path
          d="M49.5675 122.975C50.0568 120.948 52.9399 120.948 53.4292 122.975L53.4519 123.069C53.6267 123.794 54.1923 124.359 54.9166 124.534L55.0107 124.557C57.0376 125.046 57.0376 127.929 55.0107 128.418L54.9166 128.441C54.1923 128.616 53.6267 129.182 53.4519 129.906L53.4292 130C52.9399 132.027 50.0568 132.027 49.5675 130L49.5448 129.906C49.3699 129.182 48.8044 128.616 48.08 128.441L47.986 128.418C45.959 127.929 45.959 125.046 47.986 124.557L48.0801 124.534C48.8044 124.359 49.3699 123.794 49.5448 123.069L49.5675 122.975Z"
          fill="#4C4B48"
        />
        <path
          d="M34.4494 113.701C34.4494 114.87 33.5021 115.817 32.3335 115.817C31.1649 115.817 30.2175 114.87 30.2175 113.701C30.2175 112.533 31.1649 111.585 32.3335 111.585C33.5021 111.585 34.4494 112.533 34.4494 113.701Z"
          stroke="#4C4B48"
          strokeWidth="2.48287"
        />
        <circle
          cx="36.3901"
          cy="9.96676"
          r="2.11595"
          stroke="#4C4B48"
          strokeWidth="2.48287"
        />
        <circle
          cx="141.284"
          cy="108.482"
          r="2.11595"
          stroke="#4C4B48"
          strokeWidth="2.48287"
        />
        <defs>
          <filter
            id="filter0_f_1371_56766"
            x="30.1435"
            y="70.1611"
            width="106.803"
            height="67.4083"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="10.9429"
              result="effect1_foregroundBlur_1371_56766"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1371_56766"
            x1="94.826"
            y1="100.853"
            x2="66.0003"
            y2="33.6723"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2D2C29" />
            <stop offset="1" stopColor="#4D4B48" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1371_56766"
            x1="142.066"
            y1="49.5143"
            x2="139.927"
            y2="40.2953"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1371_56766"
            x1="142.066"
            y1="49.5143"
            x2="139.927"
            y2="40.2953"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2D2C29" />
            <stop offset="1" stopColor="#4D4B48" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1371_56766"
            x1="17.8321"
            y1="75.9662"
            x2="16.1139"
            y2="68.565"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1371_56766"
            x1="17.8321"
            y1="75.9662"
            x2="16.1139"
            y2="68.565"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2D2C29" />
            <stop offset="1" stopColor="#4D4B48" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1371_56766"
            x1="144.817"
            y1="68.8896"
            x2="143.375"
            y2="62.6837"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1371_56766"
            x1="144.817"
            y1="68.8896"
            x2="143.375"
            y2="62.6837"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2D2C29" />
            <stop offset="1" stopColor="#4D4B48" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1371_56766"
            x1="27.7024"
            y1="56.7912"
            x2="20.1688"
            y2="39.2708"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9999" />
            <stop offset="1" stopColor="#FFE7DA" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1371_56766"
            x1="14.2266"
            y1="55.2102"
            x2="11.8589"
            y2="44.9977"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1371_56766"
            x1="14.2266"
            y1="55.2102"
            x2="11.8589"
            y2="44.9977"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#2D2C29" />
            <stop offset="1" stopColor="#4D4B48" />
          </linearGradient>
        </defs>
      </svg>
    </div>


    <div className="block dark:hidden">
      <svg
        width="150"
        height="142"
        viewBox="0 0 150 142"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1371_56115)">
          <ellipse
            cx="83.5449"
            cy="96.8691"
            rx="31.5156"
            ry="11.8183"
            fill="#DDDAD2"
          />
        </g>
        <path
          d="M120.943 50.5539C121.006 57.9954 119.177 65.3324 115.626 71.8792C112.076 78.4259 106.921 83.9685 100.643 87.9889C94.3649 92.0093 87.1686 94.3761 79.7269 94.8682C72.2852 95.3603 64.8411 93.9615 58.0906 90.8027L42.098 98.8905C41.6121 99.1328 41.0667 99.2304 40.5269 99.1717C39.9871 99.113 39.4758 98.9005 39.054 98.5594C38.6322 98.2184 38.3177 97.7634 38.1483 97.2487C37.9788 96.7339 37.9615 96.1813 38.0983 95.6569L42.5774 78.607C38.1579 73.1437 35.1139 66.7033 33.6996 59.8242C32.2853 52.9451 32.5419 45.8271 34.4479 39.0651C36.3539 32.3031 39.8541 26.0936 44.6557 20.9556C49.4573 15.8176 55.421 11.9004 62.0483 9.53131C68.6755 7.16224 75.7738 6.41017 82.7498 7.33795C89.7258 8.26573 96.3769 10.8464 102.147 14.8643C107.917 18.8822 117.664 28.2991 120.943 34.5117C124.221 40.7244 120.937 43.5523 120.993 50.5753L120.943 50.5539Z"
          fill="#E1DDD0"
        />
        <path
          d="M126.567 62.7903C125.586 70.1671 122.747 77.1752 118.314 83.1596C113.881 89.1439 108 93.9091 101.22 97.0096C94.4401 100.11 86.9831 101.445 79.5459 100.889C72.1087 100.333 64.9343 97.9041 58.6932 93.83L41.7247 99.5959C41.2097 99.7677 40.656 99.7879 40.1297 99.6541C39.6035 99.5203 39.127 99.2381 38.7572 98.8414C38.3873 98.4446 38.1398 97.9499 38.0442 97.4165C37.9486 96.8832 38.0089 96.3336 38.2179 95.8335L45.043 79.5799C41.4331 73.551 39.322 66.7475 38.8861 59.738C38.4502 52.7286 39.7021 45.7168 42.5373 39.2888C45.3725 32.8609 49.7086 27.2034 55.1832 22.7893C60.6577 18.3752 67.1116 15.3327 74.0056 13.9161C80.8995 12.4995 88.0331 12.75 94.8101 14.6466C101.587 16.5433 107.811 20.0309 112.961 24.8181C118.111 29.6052 122.038 35.5528 124.413 42.1638C126.788 48.7747 127.543 55.8569 126.614 62.8185L126.567 62.7903Z"
          fill="url(#paint0_linear_1371_56115)"
        />
        <path
          d="M82.5067 79.0145C86.0227 79.0145 88.4782 76.6147 88.4782 73.2661C88.4782 69.8618 86.0227 67.462 82.5067 67.462C79.0466 67.462 76.5352 69.8618 76.5352 73.2661C76.5352 76.6147 79.0466 79.0145 82.5067 79.0145ZM87.976 38.0508H77.0374V48.9893L79.8279 64.1135H85.1297L87.976 48.9893V38.0508Z"
          fill="#BEBAAE"
        />
        <path
          d="M149.277 45.3465C149.307 45.2921 149.325 45.232 149.331 45.1701C149.336 45.1082 149.328 45.0459 149.307 44.9873C149.287 44.9286 149.254 44.875 149.211 44.8299C149.169 44.7848 149.117 44.7493 149.059 44.7256C147.383 44.0571 141.45 42.0726 138.208 46.0734C136.039 48.7029 132.973 50.4362 129.602 50.9392C129.517 50.9505 129.437 50.9873 129.374 51.0446C129.31 51.1019 129.266 51.1771 129.245 51.2603C129.225 51.3435 129.231 51.4308 129.261 51.5108C129.292 51.5908 129.346 51.6597 129.416 51.7086C132.281 53.7408 141.748 58.9196 149.277 45.3465Z"
          fill="url(#paint1_linear_1371_56115)"
        />
        <path
          d="M23.6194 73.4069C23.6433 73.3632 23.6578 73.3149 23.6619 73.2653C23.6659 73.2156 23.6595 73.1656 23.643 73.1186C23.6265 73.0715 23.6002 73.0285 23.566 72.9922C23.5318 72.956 23.4903 72.9274 23.4443 72.9082C22.1019 72.3776 17.3316 70.7857 14.7263 73.9694C12.9878 76.0789 10.5312 77.4716 7.82828 77.88C7.76041 77.8891 7.69689 77.9185 7.64603 77.9644C7.59517 78.0102 7.55936 78.0704 7.54329 78.1369C7.52723 78.2035 7.53166 78.2734 7.556 78.3374C7.58034 78.4014 7.62346 78.4565 7.6797 78.4956C9.96666 80.1511 17.5704 84.3111 23.6194 73.4069Z"
          fill="url(#paint2_linear_1371_56115)"
        />
        <path
          d="M136.225 67.3864C136.205 67.35 136.192 67.3097 136.189 67.2682C136.186 67.2266 136.191 67.1849 136.205 67.1457C136.219 67.1065 136.242 67.0707 136.271 67.0408C136.3 67.011 136.335 66.9877 136.373 66.9725C137.498 66.5215 141.483 65.1896 143.659 67.8745C145.117 69.6408 147.177 70.8051 149.443 71.1431C149.503 71.1486 149.56 71.173 149.605 71.2127C149.65 71.2524 149.682 71.3054 149.696 71.3642C149.709 71.4231 149.704 71.4847 149.68 71.5402C149.657 71.5958 149.616 71.6425 149.565 71.6737C147.644 73.048 141.282 76.5236 136.225 67.3864Z"
          fill="url(#paint3_linear_1371_56115)"
        />
        <path
          d="M0.0592421 50.1665C0.0264923 50.1058 0.00681559 50.0389 0.00148021 49.9702C-0.00385517 49.9015 0.00526914 49.8324 0.0282651 49.7674C0.051261 49.7024 0.0876183 49.643 0.134994 49.5929C0.182369 49.5428 0.239712 49.5032 0.303326 49.4767C2.16048 48.7391 8.73482 46.5424 12.3271 50.9677C14.7334 53.8854 18.1368 55.8064 21.8782 56.3588C21.9717 56.3725 22.059 56.4141 22.1287 56.4781C22.1983 56.542 22.2472 56.6254 22.2689 56.7175C22.2906 56.8095 22.2842 56.906 22.2505 56.9943C22.2168 57.0827 22.1573 57.1589 22.0798 57.213C18.8908 59.4629 8.40053 65.2041 0.0592421 50.1665Z"
          fill="url(#paint4_linear_1371_56115)"
        />
        <path
          d="M0.0592421 50.1665C0.0264923 50.1058 0.00681559 50.0389 0.00148021 49.9702C-0.00385517 49.9015 0.00526914 49.8324 0.0282651 49.7674C0.051261 49.7024 0.0876183 49.643 0.134994 49.5929C0.182369 49.5428 0.239712 49.5032 0.303326 49.4767C2.16048 48.7391 8.73482 46.5424 12.3271 50.9677C14.7334 53.8854 18.1368 55.8064 21.8782 56.3588C21.9717 56.3725 22.059 56.4141 22.1287 56.4781C22.1983 56.542 22.2472 56.6254 22.2689 56.7175C22.2906 56.8095 22.2842 56.906 22.2505 56.9943C22.2168 57.0827 22.1573 57.1589 22.0798 57.213C18.8908 59.4629 8.40053 65.2041 0.0592421 50.1665Z"
          fill="url(#paint5_linear_1371_56115)"
        />
        <path
          d="M22.6854 34.1234C22.3706 32.8887 23.5105 31.7762 24.7372 32.1209L27.2146 32.8171C28.4413 33.1618 28.8348 34.7052 27.923 35.5952L26.0813 37.3927C25.1694 38.2827 23.636 37.8518 23.3212 36.617L22.6854 34.1234Z"
          fill="#E1DDD0"
        />
        <path
          d="M131.859 12.8112C132.349 10.7842 135.232 10.7842 135.721 12.8112L135.744 12.9052C135.919 13.6296 136.484 14.1951 137.209 14.37L137.303 14.3927C139.33 14.882 139.33 17.7651 137.303 18.2544L137.209 18.2771C136.484 18.4519 135.919 19.0175 135.744 19.7418L135.721 19.8359C135.232 21.8628 132.349 21.8628 131.859 19.8359L131.837 19.7418C131.662 19.0175 131.096 18.4519 130.372 18.2771L130.278 18.2544C128.251 17.7651 128.251 14.882 130.278 14.3927L130.372 14.37C131.096 14.1951 131.662 13.6296 131.837 12.9052L131.859 12.8112Z"
          fill="#E1DDD0"
        />
        <path
          d="M49.5673 126.975C50.0565 124.948 52.9396 124.948 53.4289 126.975L53.4516 127.069C53.6265 127.794 54.192 128.359 54.9164 128.534L55.0105 128.557C57.0374 129.046 57.0374 131.929 55.0105 132.418L54.9164 132.441C54.192 132.616 53.6265 133.182 53.4516 133.906L53.4289 134C52.9396 136.027 50.0565 136.027 49.5673 134L49.5445 133.906C49.3697 133.182 48.8041 132.616 48.0798 132.441L47.9857 132.418C45.9588 131.929 45.9588 129.046 47.9857 128.557L48.0798 128.534C48.8041 128.359 49.3697 127.794 49.5445 127.069L49.5673 126.975Z"
          fill="#E1DDD0"
        />
        <path
          d="M34.4494 117.701C34.4494 118.87 33.5021 119.817 32.3335 119.817C31.1649 119.817 30.2175 118.87 30.2175 117.701C30.2175 116.533 31.1649 115.585 32.3335 115.585C33.5021 115.585 34.4494 116.533 34.4494 117.701Z"
          stroke="#E1DDD0"
          strokeWidth="2.48287"
        />
        <circle
          cx="36.3901"
          cy="13.9668"
          r="2.11595"
          stroke="#E1DDD0"
          strokeWidth="2.48287"
        />
        <circle
          cx="141.284"
          cy="112.482"
          r="2.11595"
          stroke="#E1DDD0"
          strokeWidth="2.48287"
        />
        <defs>
          <filter
            id="filter0_f_1371_56115"
            x="30.1435"
            y="63.165"
            width="106.803"
            height="67.4083"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="10.9429"
              result="effect1_foregroundBlur_1371_56115"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1371_56115"
            x1="94.826"
            y1="93.857"
            x2="66.0003"
            y2="26.6762"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1371_56115"
            x1="142.066"
            y1="53.5143"
            x2="139.927"
            y2="44.2953"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1371_56115"
            x1="17.8322"
            y1="79.9662"
            x2="16.1141"
            y2="72.565"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1371_56115"
            x1="144.817"
            y1="72.8896"
            x2="143.375"
            y2="66.6837"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1371_56115"
            x1="27.7024"
            y1="60.7951"
            x2="20.1688"
            y2="43.2747"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF9999" />
            <stop offset="1" stopColor="#FFE7DA" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1371_56115"
            x1="14.2266"
            y1="59.2141"
            x2="11.8589"
            y2="49.0017"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9D5CC" />
            <stop offset="1" stopColor="#EDECE8" />
          </linearGradient>
        </defs>
      </svg>
    </div>
    </>
  );
};

export default EmptyExchangeIcons;
