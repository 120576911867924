import React, { useState, useRef } from "react";
import { AddIcon, DropdownCrossIcon, FileIcon, UploadIcon } from "../Icons";

const RequiredDocFileUpload = ({
  name,
  id,
  onChange,
  title,
  isMultiple,
  maxCount,
  mandatory,
  error
}) => {
  const [files, setFiles] = useState([]);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles].slice(0, maxCount));
    if (onChange) {
      onChange(name, [...files, ...selectedFiles]);
    }
    fileInputRef.current.value = "";
  };

  const clearFile = (fileIndex) => {
    const updatedFiles = files.filter((_, index) => index !== fileIndex);
    setFiles(updatedFiles);
    if (onChange) {
      console.log("updatedFiles", updatedFiles)
      onChange(name, updatedFiles);
    }
  };  

  return (
    <div
      className={`relative w-full px-3 py-2 rounded-xl border
        ${files.length > 0 ? "border-gray-500" : "border-divider-border"}
        ${error.includes(name) ? "border-highlighter-red bg-highlighter-red/20" : "bg-input-bg"}
        shadow-sm focus-within:border-primary-item
      `}
    >
      <div
        className={`flex items-center 
        ${
          files.length > 0
            ? "bg-secondary-icon-bg rounded-lg px-2 py-2"
            : "bg-transparent"
        }
      `}
      >
        <div className="flex-shrink-0">
          <UploadIcon />
        </div>
        <label
          htmlFor={id}
          className={`items-center justify-between pl-2 text-xs font-medium text-primary-item cursor-pointer 
        `}
        >
          {title} {mandatory === 1 && <span className="text-red-500 font-semibold">( * required )</span>}
        </label>
        <input
          ref={fileInputRef}
          type="file"
          id={id}
          name={name}
          className="hidden cursor-pointer"
          multiple={isMultiple}
          onChange={handleFileChange}
          disabled={files.length >= maxCount}
        />
      </div>
      {!files.length && (
        <label
          htmlFor={id}
          className="font-normal cursor-pointer flex items-center ml-7 mt-1 text-xs text-paragraph-text"
        >
          Upload documents
        </label>
      )}
      {files.length > 0 && (
        <div className="mt-2 grid grid-cols-1 max-sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <>
            {files.map((file, index) => (
              <div
                key={index}
                className="flex items-center justify-evenly p-1 bg-option-list-selected rounded-lg cursor-pointer"
              >
                <FileIcon />
                <p className="line-clamp-1 text-xs font-medium text-primary-item">
                  {file.name}
                </p>
                <DropdownCrossIcon onClick={() => clearFile(index)} />
              </div>
            ))}
            {maxCount > files.length && (
              <label
                htmlFor={id}
                className="flex items-center justify-between px-2 py-1 bg-primary-Icon-bg cursor-pointer rounded-lg text-xs font-medium text-primary-item"
              >
                Add more <AddIcon />
              </label>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default RequiredDocFileUpload;
